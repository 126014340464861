/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef } from 'react';
import { loadStripeOnramp } from '@stripe/crypto';
import { ModalWide } from "../../../../../components/shared/Modals";

const StripeOnrampDetail = ({ isOpen, onClose, clientSecret, onSuccess, onError }) => {
  const isMountedRef = useRef(false); // Track if the widget is already mounted
  const onrampSessionRef = useRef(null); // Reference to the onramp session
  const onrampSessionContainer = useRef(null); // Reference to the onramp session

  useEffect(() => {
    if (!isOpen || !clientSecret) return () => {};

    const initializeOnramp = async () => {
      if (isMountedRef.current) return; // Skip if already initialized
      isMountedRef.current = true;

      try {
        const stripeOnramp = await loadStripeOnramp(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

        const onrampSession = await stripeOnramp.createSession({
          clientSecret,
          appearance: {
            theme: 'dark',
            variables: {
              colorPrimary: '#0052ff',
              colorBackground: '#1e2025',
              colorText: '#ffffff',
              colorDanger: '#ff4d4f',
            },
          },
        });

        if (onrampSessionContainer.current) {
          onrampSessionContainer.current.innerHTML = "";
        }

        onrampSession.mount('#stripe-onramp-container');
        onrampSessionRef.current = onrampSession; // Store the session
        isMountedRef.current = true; // Mark as mounted

        // Handle session events
        onrampSession.addEventListener('onramp_session_completed', () => {
          onSuccess();
          onClose();
        });

        onrampSession.addEventListener('onramp_session_errored', (event) => {
          onError(event.error);
        });
      } catch (error) {
        console.error('Failed to initialize Stripe Onramp:', error);
        onError(error);
      }
    };

    initializeOnramp();

    // Cleanup function
    return () => {
      if (onrampSessionRef.current) {
        onrampSessionRef.current = null;
      }
      isMountedRef.current = false; // Reset the flag
    };
  }, [isOpen, clientSecret, onSuccess, onError, onClose]);

  return (
    <ModalWide
      isOpen={isOpen}
      onClose={onClose}
      title="Buy Crypto with Stripe"
      customClassModal="!max-w-[480px]"
      headerTitleLeft={true}
    >
      <div
        id="stripe-onramp-container"
        ref={onrampSessionContainer}
        className="bg-black-dark rounded-lg w-full min-h-[600px]"
      />
    </ModalWide>
  );
};

export default StripeOnrampDetail;
