import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from 'react-router-dom';
import { UseIsMax1023 } from '../../components/shared/MediaQueries'
import { useTranslation } from 'react-i18next'

import Card from "../../components/layouts/Dashboard/Card"

// Hooks
import SettingsNavigation from "./hooks/useSettingsNavigation"
// Utils
import { modalOverlayVariants } from '../../utils/animations';

// Inner component
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import SectionBasic from './SectionBasic'
import Security from './Security'
import ConnectedWallet from './ConnectedWallet';
import Verification from './Verification';
import AccountNumber from './AccountNumber';
import SpendingCap from './SpendingCap';
import Upgrade from './Upgrade';

const MyProfile = ({ setPageTitle }) => {
  const { t } = useTranslation()
  const location = useLocation();
  const isMax1023 = UseIsMax1023();
  const isUpgrade = window.location.search.includes('tab=upgrade');
  const [showSuccess, setShowSuccess] = useState(false)

  const {
    activeIndex,
    showDeleteAccount,
    showMobileSelect,
    handleNavigate,
    handleDeleteAccount,
    toggleMobileSelect
  } = SettingsNavigation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');

    // if (tab === 'upgrade') {
    //   setShowSuccess(false);
    //   setTimeout(() => {
    //     handleNavigate(5);
    //     setShowSuccess(false);
    //   });
    // }
  }, [location.search, handleNavigate]);

  useEffect(() => {
    setPageTitle('My Profile')
  }, [setPageTitle]);

  const renderContent = () => (
    <AnimatePresence>
      {!showDeleteAccount ? (
        <motion.div
          {...modalOverlayVariants}
        >
          {activeIndex === 0 && (
            <>
              <SectionBasic />
              <div className="w-full h-10" />
            </>
          )}

          {activeIndex === 1 && (
            <>
              <Security />
              <div className="w-full h-10" />
            </>
          )}

          {activeIndex === 2 && (
            <>
              <ConnectedWallet />
              <div className="w-full h-10" />
            </>
          )}

          {activeIndex === 3 && (
            <>
              <Verification />
              <div className="w-full h-10" />
            </>
          )}

          {activeIndex === 4 && (
            <>
              <SpendingCap />
              <div className="w-full h-10" />
            </>
          )}

          {activeIndex === 5 && (
            <>
              <AccountNumber />
              <div className="w-full h-10" />
            </>
          )}

          {activeIndex === 6 && (
            <>
              <Upgrade />
              <div className="w-full h-10" />
            </>
          )}
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          xx
        </motion.div>
      )}
    </AnimatePresence>
  )

  if (showSuccess) {
    return (
      <div className="result section successfully-subscribed">
        <div className="center result__center">
          <div className="result__wrap">
            <div className="successfully-subscribed__icon">
              <div className="successfully-subscribed__wrapper">
                <div className="successfully-subscribed__background">
                  <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="successfully-subscribed__shadow">{' '}</div>
            </div>
            <div className="text-white h2 result__title">
              {t('You successfuly subscribed to your Product. We are processing your account...')}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Card className="relative flex flex-row max-1023:flex-col flex-wrap items-start">

      {/* Navigation */}
      {!isMax1023 ? (
        <DesktopNavigation
          activeIndex={activeIndex}
          onNavigate={handleNavigate}
          onDeleteAccount={handleDeleteAccount}
        />
      ) : (
        <>
          <MobileNavigation
            activeIndex={activeIndex}
            showSelect={showMobileSelect}
            onNavigate={handleNavigate}
            onToggleSelect={toggleMobileSelect}
          />
          <div className="w-full h-8" />
        </>
      )}

      {/* Main Content */}
      <div className="py-0 pr-0 pl-8 max-1023:pl-0 w-[calc(100%_-_280px)] max-1023:w-full max-1279:w-[calc(100%_-_210px)]">
        {renderContent()}
      </div>
    </Card>
  )
}

export default MyProfile
