// src/components/layouts/Dashboard/Header/ClientQuestionnaire/index.jsx

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Icons from '../../../../shared/Icons'
import QuestionnaireModal from './components/QuestionnaireModal'

const ClientQuestionnaire = () => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <button
        className="inline-flex relative justify-center items-center bg-black-light hover:bg-black-lighter mr-2 max-767:mr-0 rounded-full w-10 h-10 text-white transition-colors duration-200 outline-none"
        onClick={toggleModal}
        data-tooltip-id="client-questionnaire-tooltip"
        data-tooltip-content={t('questionnaire.tooltipText')}
      >
        <Icons
          nameIcon="message-question"
          className="w-4 h-4"
        />
      </button>

      <Tooltip
        id="client-questionnaire-tooltip"
        className="!bg-black-dark !px-1 !rounded-md"
      />

      <QuestionnaireModal
        isOpen={isModalOpen}
        onClose={toggleModal}
      />
    </>
  )
}

export default ClientQuestionnaire
