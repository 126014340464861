// src/pages/Signup/components/Authenticator/AuthenticatorVerify.jsx

import React, { useState, useRef, useEffect } from "react"
import { motion } from "framer-motion"
import VerificationInput from "react-verification-input";
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { SET_2FA_SECRET } from '../../../../queriesAndMutations'
import Layout from "../Layout"
import { SIGNUP_STEPS } from "../../constant/steps"
import Loader from '../../../../components/shared/Loader'

const AuthenticatorVerify = ({ onBack, onNext, secretKey }) => {
  const { t } = useTranslation()
  const [code, setCode] = useState(['', '', '', '', '', ''])
  const [isVerifying, setIsVerifying] = useState(false)
  const [error, setError] = useState('')
  const inputRefs = useRef([])

  const [set2FaSecret, { loading }] = useMutation(SET_2FA_SECRET)

  const handleVerify = async (fullCode) => {
    if (!fullCode || !secretKey) return

    setIsVerifying(true)
    setError('')

    try {
      await set2FaSecret({
        variables: {
          secret: secretKey,
          token: fullCode
        }
      })
      onNext()
    } catch (err) {
      setError(t('Invalid verification code. Please try again.'))
      setCode(['', '', '', '', '', ''])
      inputRefs.current[0].focus()
    } finally {
      setIsVerifying(false)
    }
  }

  const handleComplete = (code) => {
    handleVerify(code);
  };

  useEffect(() => {
    if (inputRefs.current[0]) inputRefs.current[0].focus()
  }, [])

  return (
    <Layout
      title={t("Enter verification code")}
      description={t("Enter the 6-digit code from your authenticator app")}
      currentStep={3}
      totalSteps={5}
      onBack={onBack}
      stepType={SIGNUP_STEPS.SECURITY}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
        <VerificationInput
          length={6}
          placeholder=""
          validChars="0-9"
          autoFocus
          onComplete={handleComplete}
          classNames={{
            container: "verification-container gap-2 grid grid-cols-6 mb-6 w-full h-14",
            character: "border-[#2b2d33] border-2 border-solid focus:border-white/20 bg-black-light rounded-xl w-full h-12 text-center text-white text-base inline-flex justify-center items-center",
            characterInactive: "character--inactive",
            characterSelected: "character--selected border-white/20",
            characterFilled: "character--filled border-white/20"
          }}
        />

        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="pw:mb-6 pw:text-center pw:text-red-500"
          >
            {error}
          </motion.div>
        )}

        <button
          onClick={() => handleVerify(code.join(''))}
          disabled={loading || isVerifying || code.some(digit => !digit)}
          className={`
            pw:w-full pw:inline-flex pw:justify-center pw:items-center pw:gap-2 pw:px-6 pw:rounded-2xl pw:h-14
            pw:font-medium pw:text-white pw:transition-all pw:duration-200
            ${loading || isVerifying || code.some(digit => !digit)
              ? 'pw:bg-gray-700 pw:cursor-not-allowed'
              : 'pw:bg-primary hover:pw:bg-primary-hover'
            }
          `}
        >
          {(loading || isVerifying) && <Loader className="pw:w-5 pw:h-5" />}
          {loading || isVerifying ? t('Verifying...') : t('Verify Code')}
        </button>
      </div>
    </Layout>
  )
}

export default AuthenticatorVerify
