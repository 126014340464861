import React from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from 'react-i18next'
import Icons from '../../../../components/shared/Icons'

const SECURITY_OPTIONS = {
  PASSKEY: 'passkey',
  AUTHENTICATOR: 'authenticator',
  SMS: 'sms'
}

const SecurityOption = ({
  id,
  icon,
  title,
  description,
  isRecommended,
  isSelected,
  onSelect
}) => {
  const { t } = useTranslation()

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`
        pw:relative pw:border-2 pw:rounded-xl pw:p-6
        pw:transition-all pw:duration-200
        pw:cursor-pointer
        ${isSelected ? 'pw:border-primary pw:bg-black-light' : 'pw:border-[#2b2d33] pw:bg-black-light hover:pw:border-primary/50'}
      `}
      onClick={() => onSelect(id)}
    >
      {isRecommended && (
        <span className="pw:top-4 pw:right-4 pw:absolute pw:bg-primary/10 pw:px-2 pw:py-1 pw:rounded-full pw:text-primary pw:text-xs">
          {t('Recommended')}
        </span>
      )}

      <div className="pw:flex pw:gap-4">
        <div className="pw:flex pw:flex-shrink-0 pw:justify-center pw:items-center pw:bg-black-dark pw:rounded-full pw:w-10 pw:h-10">
          <Icons nameIcon={icon} className="pw:w-5 pw:h-5 pw:text-white" />
        </div>

        <div>
          <h3 className="pw:mb-2 pw:font-semibold pw:text-white">{t(title)}</h3>
          <p className="pw:text-gray-lighter pw:text-sm">{t(description)}</p>
        </div>
      </div>

      <AnimatePresence>
        {isSelected && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="pw:right-4 pw:bottom-4 pw:absolute"
          >
            <Icons nameIcon="check" className="pw:w-6 pw:h-6 pw:text-primary" />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

const SecurityOptions = ({ selectedOption, onOptionSelect }) => {
  const { t } = useTranslation()

  const options = [
    {
      id: SECURITY_OPTIONS.AUTHENTICATOR,
      icon: "smartphone",
      title: t("Authenticator App"),
      description: t("Sign in using codes from Google Authenticator or similar apps"),
      isRecommended: true
    },
    {
      id: SECURITY_OPTIONS.SMS,
      icon: "mobile-message",
      title: t("SMS Authentication"),
      description: t("Receive codes via text message")
    }
  ]

  return (
    <div className="pw:space-y-4 pw:mx-auto pw:w-full pw:max-w-[480px]">
      {options.map((option) => (
        <SecurityOption
          key={option.id}
          {...option}
          isSelected={selectedOption === option.id}
          onSelect={onOptionSelect}
        />
      ))}

      <div className="pw:pt-4 pw:text-center">
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
        <a href="#" className="pw:text-sm hover:pw:text-primary pw:text-white pw:transition-colors pw:duration-200">
          {t('Learn more about security options')}
        </a>
      </div>
    </div>
  )
}

export default SecurityOptions
