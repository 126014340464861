// src/pages/Signup/components/Security/BackupCodes.jsx

import React, { useState } from "react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
import Icons from "../../../../components/shared/Icons"

const BackupCodes = ({ codes, onGenerateNew, onConfirm, isConfirmed }) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const [showCodes, setShowCodes] = useState(false)

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(codes.join('\n'))
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  return (
    <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
      <div className="pw:bg-black-light pw:mb-6 pw:p-6 pw:rounded-xl">
        <div className="pw:flex pw:items-start pw:gap-4 pw:mb-4">
          <div className="pw:flex pw:flex-shrink-0 pw:justify-center pw:items-center pw:bg-primary/10 pw:rounded-full pw:w-10 pw:h-10">
            <Icons nameIcon="shield" className="pw:w-5 pw:h-5 pw:text-primary" />
          </div>
          <div>
            <h3 className="pw:mb-2 pw:font-semibold pw:text-white">{t('Save your backup codes')}</h3>
            <p className="pw:text-gray-lighter pw:text-sm">
              {t("Keep these backup codes in a safe place. You'll need them to access your account if you lose your device.")}
            </p>
          </div>
        </div>

        <div className="pw:mt-6">
          {showCodes ? (
            <div className="pw:bg-black-dark pw:p-4 pw:rounded-lg">
              <div className="pw:gap-4 pw:grid pw:grid-cols-2">
                {codes.map((code, index) => (
                  <div
                    key={index}
                    className="pw:bg-black-light pw:p-2 pw:rounded pw:font-mono pw:text-sm pw:text-white"
                  >
                    {code}
                  </div>
                ))}
              </div>

              <div className="pw:flex pw:justify-between pw:items-center pw:border-[#2b2d33] pw:mt-4 pw:pt-4 pw:border-t">
                <button
                  onClick={handleCopy}
                  className="pw:flex pw:items-center pw:gap-2 hover:pw:text-primary pw:text-white pw:transition-colors pw:duration-200"
                >
                  <Icons nameIcon={isCopied ? "check" : "copy"} className="pw:w-4 pw:h-4" />
                  {isCopied ? t("Copied!") : t("Copy codes")}
                </button>
                <button
                  onClick={onGenerateNew}
                  className="pw:flex pw:items-center pw:gap-2 hover:pw:text-primary pw:text-white pw:transition-colors pw:duration-200"
                >
                  <Icons nameIcon="refresh" className="pw:w-4 pw:h-4" />
                  {t("Generate new codes")}
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setShowCodes(true)}
              className="hover:pw:bg-black-darker pw:bg-black-dark pw:px-4 pw:py-3 pw:rounded-lg pw:w-full pw:text-center pw:text-white pw:transition-colors pw:duration-200"
            >
              <Icons nameIcon="backup-code" className="pw:inline-block pw:mr-2 pw:w-5 pw:h-5" />
              {t("Show backup codes")}
            </button>
          )}
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="pw:flex pw:items-start pw:gap-2 pw:mb-6 pw:text-sm pw:text-yellow-400"
      >
        <Icons nameIcon="alert-circle" className="pw:flex-shrink-0 pw:w-5 pw:h-5" />
        <span>{t("Make sure to save these codes before continuing. You won't be able to see them again!")}</span>
      </motion.div>

      <button
        onClick={onConfirm}
        disabled={!showCodes || isConfirmed}
        className={`
          pw:w-full pw:inline-flex pw:justify-center pw:items-center pw:px-6 pw:rounded-2xl pw:h-14
          pw:font-medium pw:text-white pw:transition-all pw:duration-200
          ${showCodes && !isConfirmed
            ? 'pw:bg-primary hover:pw:bg-primary-hover'
            : 'pw:bg-gray-700 pw:cursor-not-allowed'
          }
        `}
      >
        {isConfirmed ? (
          <span className="pw:flex pw:items-center pw:gap-2">
            <Icons nameIcon="check" className="pw:w-5 pw:h-5" />
            {t("Codes saved")}
          </span>
        ) : (
          t("I've saved my backup codes")
        )}
      </button>
    </div>
  )
}

export default BackupCodes
