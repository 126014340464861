const payments = [
  {
    name: "IBAN",
    desc: "Bank Transfer",
    image: "iban.svg",
    url_site: "https://iban.com",
    cryptos: ['eure'],
    networks: ['gnosis', 'ethereum', 'polygon'],
    supportedProviders: ['metamask', 'walletconnect'],
  },
  {
    name: "Revolut",
    desc: "Debit Card, Bank Transfer",
    image: "revolut.png",
    url_site: "https://www.revolut.com",
    cryptos: [],
    networks: [],
    supportedProviders: ['metamask', 'coinbase', 'walletconnect'],
  },
  {
    name: "Stripe",
    desc: "Debit Card, ACH, Apple Pay",
    image: "stripe.png",
    url_site: "https://stripe.com",
    cryptos: ['btc', 'eth', 'xlm', 'matic', 'sol', 'usdc', 'avax'],
    networks: ['ethereum', 'polygon', 'base'],
    supportedProviders: ['metamask', 'coinbase', 'walletconnect'],
  },
  {
    name: "Volt.io",
    desc: "Instant Bank Transfer",
    image: "volt.png",
    url_site: "https://www.volt.io",
    cryptos: ['eure'],
    networks: ['ethereum', 'polygon', 'base'],
    supportedProviders: ['metamask', 'coinbase', 'walletconnect'],
  },
  {
    name: "Ramp.network",
    desc: "Lorem ipsum dolor sit amet",
    image: "ramp-network.png",
    url_site: "https://ramp.network/",
    cryptos: [],
    networks: [],
    supportedProviders: ['metamask', 'coinbase', 'walletconnect'],
  },
  {
    name: 'Coinbase',
    desc: 'Buy crypto instantly with credit card or bank transfer',
    image: 'coinbase.png',
    url_site: 'https://www.coinbase.com',
    enabled: true,
    supportedRegions: ['US', 'EU', 'UK'],
    cryptos: ['zchf', 'eure', 'xsgd', 'usdc', 'usdt', 'eurc', 'dai'],
    networks: ['ethereum', 'polygon', 'base'],
    supportedProviders: ['metamask', 'coinbase', 'walletconnect'],
  },
  {
    name: 'Mt Pelerin',
    desc: 'Buy crypto instantly with credit card or bank transfer',
    image: 'mtpelerin.png',
    enabled: true,
    supportedRegions: ['US', 'EU', 'UK'],
    cryptos: ['zchf', 'eure', 'xsgd', 'usdc', 'usdt', 'eurc', 'dai'],
    networks: ['gnosis', 'ethereum', 'polygon', 'base'],
    supportedProviders: ['metamask', 'walletconnect'],
  },
  {
    name: 'DFX',
    desc: 'Buy crypto instantly with credit card or bank transfer',
    image: 'dfx_1.svg',
    enabled: true,
    supportedRegions: ['US', 'EU', 'UK'],
    cryptos: ['zchf', 'usdc', 'usdt', 'eurc', 'dai'],
    networks: ['gnosis', 'ethereum', 'polygon', 'base'],
    supportedProviders: ['metamask', 'walletconnect'],
  },
];

export default payments;
