import React from 'react'

const StyleGuide = () => {
  return (
    <div className='page-wrapper'>
      <div className="pw:px-4 pw:py-40">
        <div className="pw:mx-auto pw:max-w-5xl">

          <div className="pw:text-[13px]">Text 13px</div>

        </div>
      </div>
    </div>
  )
}

export default StyleGuide
