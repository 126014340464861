const tokens = [
  {
    name: "zchf",
    symbol: "ZCHF",
    image: "ZCHF.png",
  },
  {
    name: "eure",
    symbol: "EURe",
    image: "EURe-logo.png",
  },
  {
    name: "xsgd",
    symbol: "XSGD",
    image: "XSGD.png",
  },
  {
    name: "usdc",
    symbol: "USDC",
    image: "USDC.png",
  },
  {
    name: "usdt",
    symbol: "USDT",
    image: "USDT.png",
  },
  {
    name: "eurc",
    symbol: "EURC",
    image: "EURC.png",
  },
  {
    name: "dai",
    symbol: "DAI",
    image: "DAI.png",
  },
  // {
  //   name: "busd",
  //   symbol: "BUSD",
  //   image: "BUSD.png",
  // },
]

export default tokens
