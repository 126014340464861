import React, { useEffect, useState } from 'react';
import ChatInterface from './components/ChatInterface';

const DashboardChatPro = ({ setPageTitle }) => {
  useEffect(() => {
    setPageTitle('Investhub AI ChatPro');
  }, [setPageTitle]);

  return (
    <div className="flex flex-1 py-6 md:py-0">
      <ChatInterface />
    </div>
  );
};

export default DashboardChatPro;
