import React from "react"

const InvesthubLogo = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="550" height="130" viewBox="0 0 550 130" fill="none" className={className}>
      <path d="M54.4286 63.9439L43.3316 52.4031L42.4439 56.398L49.9898 63.9439H54.4286Z" fill="#3683F6" />
      <path d="M67.301 63.9439L46.8827 43.5255L45.1071 46.6327L62.4184 63.9439H67.301Z" fill="#3683F6" />
      <path d="M51.7653 35.9796L49.5459 38.6429L74.8469 63.9439H79.7296L51.7653 35.9796Z" fill="#3683F6" />
      <path d="M60.199 27.9898V38.6429L63.3061 41.75V25.7704L60.199 27.9898Z" fill="#3683F6" />
      <path d="M72.1837 21.7755L69.0765 23.1071V47.0765L72.1837 50.6276V21.7755Z" fill="#3683F6" />
      <path d="M81.0612 20L77.9541 20.4439V56.398L81.0612 59.0612V20Z" fill="#3683F6" />
      <path d="M96.5969 20.8878L92.602 20.4439L85.5 27.9898V32.4286L96.5969 20.8878Z" fill="#3683F6" />
      <path d="M105.474 24.4388L102.367 23.1071L85.5 40.4184V44.8571L105.474 24.4388Z" fill="#3683F6" />
      <path d="M113.02 29.3214L110.801 27.5459L85.5 52.8469V57.2857L113.02 29.3214Z" fill="#3683F6" />
      <path d="M121.01 37.7551H110.801L107.25 41.3061H115.24H123.23L121.01 37.7551Z" fill="#3683F6" />
      <path d="M127.224 50.1837L125.893 47.0765H101.48L98.3725 50.1837H127.224Z" fill="#3683F6" />
      <path d="M128.556 55.9541H93.0459L89.4949 59.0612H129L128.556 55.9541Z" fill="#3683F6" />
      <path d="M128.556 70.602L121.454 63.0561H116.571L127.668 74.1531L128.556 70.602Z" fill="#3683F6" />
      <path d="M108.582 63.0561H104.143L124.561 83.4745L125.893 80.3673L108.582 63.0561Z" fill="#3683F6" />
      <path d="M96.1531 63.0561H91.7143L119.679 91.0204L121.454 88.3571L96.1531 63.0561Z" fill="#3683F6" />
      <path d="M111.245 88.3571V99.0102L107.694 101.23V84.8061L111.245 88.3571Z" fill="#3683F6" />
      <path d="M98.8163 75.9286L102.367 79.9235V103.893L98.8163 105.224V75.9286Z" fill="#3683F6" />
      <path d="M93.4898 71.0459L89.9388 67.4949V107L93.4898 106.556V71.0459Z" fill="#3683F6" />
      <path d="M42 67.9388H81.5051L78.398 71.0459H42.4439L42 67.9388Z" fill="#3683F6" />
      <path d="M85.0561 75.0408V70.602L57.9796 97.2347L60.6429 99.4541L85.0561 75.0408Z" fill="#3683F6" />
      <path d="M72.6276 76.8163H43.7755L45.1071 79.9235H69.5204L72.6276 76.8163Z" fill="#3683F6" />
      <path d="M63.75 85.6939H47.7704L49.9898 88.801H60.6429L63.75 85.6939Z" fill="#3683F6" />
      <path d="M85.0561 87.4694V83.0306L65.5255 102.561L68.6327 103.893L85.0561 87.4694Z" fill="#3683F6" />
      <path d="M85.0561 100.342V95.4592L74.8469 105.668L78.398 106.556L85.0561 100.342Z" fill="#3683F6" />
      <path d="M176 84.2397V44.816H181.932V84.2397H176Z" fill="#fff" />
      <path d="M216.661 69.484V84.2397H210.729V69.5966C210.729 68.0009 210.387 66.5553 209.703 65.26C209.037 63.9459 208.115 62.9039 206.936 62.1342C205.777 61.3458 204.455 60.9515 202.972 60.9515C201.49 60.9515 200.187 61.3458 199.066 62.1342C197.963 62.9039 197.098 63.9459 196.47 65.26C195.862 66.5553 195.558 68.0009 195.558 69.5966V84.2397H189.626V56.0799H195.558V59.8815C196.546 58.4547 197.744 57.3377 199.151 56.5305C200.558 55.7044 202.145 55.2914 203.914 55.2914C206.48 55.2914 208.705 55.9391 210.587 57.2344C212.488 58.5298 213.971 60.2475 215.035 62.3877C216.1 64.5278 216.642 66.8933 216.661 69.484Z" fill="#fff" />
      <path d="M238.772 84.2397H231.157L220.748 56.0236H227.079L234.95 77.4813L242.85 56.0236H249.152L238.772 84.2397Z" fill="#fff" />
      <path d="M265.154 84.9718C262.53 84.9718 260.135 84.3054 257.967 82.9725C255.819 81.6396 254.098 79.8561 252.805 77.6221C251.532 75.3694 250.895 72.8819 250.895 70.1598C250.895 68.0947 251.266 66.1705 252.007 64.387C252.748 62.5848 253.766 61.0079 255.058 59.6562C256.37 58.2857 257.891 57.2157 259.621 56.446C261.351 55.6763 263.195 55.2914 265.154 55.2914C267.34 55.2914 269.346 55.742 271.171 56.6431C272.996 57.5254 274.555 58.7551 275.848 60.332C277.141 61.8902 278.091 63.6924 278.7 65.7387C279.308 67.7662 279.489 69.9251 279.241 72.2155H257.254C257.501 73.5108 257.977 74.6841 258.68 75.7354C259.403 76.768 260.315 77.5846 261.418 78.1853C262.54 78.7861 263.785 79.0958 265.154 79.1146C266.599 79.1146 267.91 78.7579 269.089 78.0445C270.287 77.3312 271.266 76.3456 272.026 75.0878L278.044 76.4676C276.903 78.9644 275.182 81.0107 272.882 82.6064C270.582 84.1834 268.005 84.9718 265.154 84.9718ZM257.055 67.738H273.253C273.063 66.3864 272.578 65.1661 271.798 64.0773C271.038 62.9697 270.078 62.0967 268.918 61.4584C267.777 60.8014 266.523 60.4728 265.154 60.4728C263.804 60.4728 262.549 60.792 261.389 61.4303C260.249 62.0685 259.298 62.9415 258.538 64.0491C257.796 65.138 257.302 66.3676 257.055 67.738Z" fill="#fff" />
      <path d="M293.932 84.7747C292.43 84.6809 290.966 84.3805 289.54 83.8736C288.114 83.3667 286.86 82.6815 285.776 81.8179C284.711 80.9356 283.913 79.9031 283.381 78.7204L288.428 76.5802C288.675 77.0871 289.103 77.5846 289.711 78.0727C290.339 78.5608 291.071 78.9644 291.907 79.2836C292.744 79.5839 293.618 79.7341 294.531 79.7341C295.425 79.7341 296.252 79.6121 297.012 79.368C297.792 79.1052 298.428 78.711 298.923 78.1853C299.417 77.6597 299.664 77.0308 299.664 76.2986C299.664 75.4726 299.379 74.8343 298.809 74.3838C298.257 73.9144 297.563 73.5577 296.727 73.3137C295.89 73.0696 295.054 72.835 294.217 72.6097C292.354 72.1967 290.643 71.6335 289.084 70.9201C287.544 70.188 286.318 69.2587 285.405 68.1323C284.493 66.9871 284.036 65.5885 284.036 63.9365C284.036 62.1343 284.521 60.5761 285.491 59.262C286.479 57.9291 287.782 56.9059 289.398 56.1925C291.014 55.4792 292.734 55.1225 294.56 55.1225C296.841 55.1225 298.932 55.6012 300.833 56.5586C302.735 57.516 304.189 58.8302 305.197 60.501L300.491 63.2606C300.206 62.6974 299.778 62.1906 299.208 61.74C298.638 61.2895 297.991 60.9328 297.269 60.6699C296.546 60.3883 295.795 60.2288 295.016 60.1912C294.046 60.1537 293.143 60.2663 292.307 60.5291C291.489 60.7732 290.833 61.1674 290.339 61.7119C289.845 62.2563 289.597 62.9509 289.597 63.7957C289.597 64.6217 289.892 65.2412 290.481 65.6542C291.071 66.0485 291.812 66.3676 292.706 66.6117C293.618 66.8557 294.55 67.1279 295.501 67.4283C297.193 67.9539 298.78 68.6016 300.263 69.3713C301.746 70.1222 302.944 71.0515 303.856 72.1591C304.769 73.2668 305.206 74.6184 305.168 76.2141C305.168 77.9788 304.626 79.5276 303.543 80.8605C302.478 82.1934 301.09 83.2072 299.379 83.9018C297.668 84.5964 295.852 84.8874 293.932 84.7747Z" fill="#fff" />
      <path d="M325.951 61.9371H319.962L319.934 84.2397H314.002L314.03 61.9371H309.496V56.0799H314.03L314.002 47.2377H319.934L319.962 56.0799H325.951V61.9371Z" fill="#fff" />
      <path d="M357.449 69.5121V84.2397H351.517V69.6248C351.517 68.029 351.175 66.5741 350.49 65.26C349.825 63.9459 348.931 62.9039 347.81 62.1342C346.688 61.3645 345.443 60.9797 344.074 60.9797C342.591 60.9797 341.327 61.3645 340.281 62.1342C339.235 62.9039 338.427 63.9459 337.857 65.26C337.306 66.5741 337.03 68.029 337.03 69.6248V84.2397H331.07V42H337.002V59.3746C337.8 57.854 338.846 56.8027 340.138 56.2207C341.45 55.62 342.952 55.3196 344.644 55.3196C347.211 55.3196 349.445 55.9673 351.346 57.2626C353.247 58.558 354.73 60.2851 355.795 62.444C356.859 64.5842 357.411 66.9402 357.449 69.5121Z" fill="#fff" />
      <path d="M363.723 70.7793V56.0799H369.654V70.6667C369.654 72.2624 369.987 73.7173 370.652 75.0314C371.337 76.3268 372.259 77.3687 373.419 78.1572C374.597 78.9269 375.938 79.3117 377.44 79.3117C378.904 79.3117 380.187 78.9269 381.29 78.1572C382.411 77.3687 383.276 76.3268 383.885 75.0314C384.512 73.7173 384.826 72.2624 384.826 70.6667V56.0799H390.758V84.2397H384.826V80.41C383.837 81.8179 382.639 82.9349 381.233 83.761C379.826 84.587 378.238 85 376.47 85C373.923 85 371.689 84.3617 369.768 83.0851C367.867 81.7898 366.394 80.0626 365.348 77.9037C364.303 75.7448 363.761 73.37 363.723 70.7793Z" fill="#fff" />
      <path d="M412.987 55.2914C415.763 55.2914 418.282 55.9579 420.544 57.2908C422.826 58.6237 424.641 60.4165 425.991 62.6693C427.341 64.9033 428.016 67.3907 428.016 70.1316C428.016 72.1779 427.626 74.1022 426.847 75.9044C426.067 77.6878 424.984 79.2648 423.596 80.6352C422.227 81.9869 420.63 83.0476 418.805 83.8173C416.999 84.587 415.059 84.9718 412.987 84.9718C410.991 84.9718 409.242 84.5213 407.74 83.6202C406.257 82.7003 405.04 81.527 404.09 80.1002V84.2397H398.158V42H404.09V60.1631C405.04 58.7175 406.257 57.5442 407.74 56.6431C409.242 55.742 410.991 55.2914 412.987 55.2914ZM412.93 79.2836C414.584 79.2836 416.096 78.8706 417.464 78.0445C418.833 77.2185 419.917 76.1109 420.715 74.7217C421.533 73.3325 421.942 71.8024 421.942 70.1316C421.942 68.4233 421.533 66.8745 420.715 65.4853C419.898 64.096 418.805 62.9978 417.436 62.1906C416.067 61.3645 414.565 60.9515 412.93 60.9515C411.276 60.9515 409.822 61.3645 408.567 62.1906C407.312 63.0166 406.333 64.1242 405.63 65.5134C404.926 66.9026 404.574 68.442 404.574 70.1316C404.574 71.8212 404.926 73.3606 405.63 74.7498C406.333 76.139 407.312 77.2467 408.567 78.0727C409.822 78.8799 411.276 79.2836 412.93 79.2836Z" fill="#fff" />
      <path d="M435.118 84.9718C434.243 84.9718 433.502 84.6715 432.893 84.0707C432.304 83.47 432.009 82.7378 432.009 81.8743C432.009 81.0295 432.304 80.3067 432.893 79.706C433.502 79.1052 434.243 78.8048 435.118 78.8048C435.992 78.8048 436.734 79.1052 437.342 79.706C437.951 80.3067 438.255 81.0295 438.255 81.8743C438.255 82.7378 437.951 83.47 437.342 84.0707C436.734 84.6715 435.992 84.9718 435.118 84.9718Z" fill="#fff" />
      <path d="M445.696 56.0799H451.627V84.2397H445.696V56.0799ZM448.69 51.4335C447.796 51.4335 447.046 51.1425 446.437 50.5606C445.829 49.9786 445.525 49.2465 445.525 48.3641C445.525 47.5005 445.829 46.7778 446.437 46.1958C447.046 45.5951 447.796 45.2947 448.69 45.2947C449.565 45.2947 450.306 45.5951 450.914 46.1958C451.523 46.7778 451.827 47.5005 451.827 48.3641C451.827 49.2465 451.523 49.9786 450.914 50.5606C450.306 51.1425 449.565 51.4335 448.69 51.4335Z" fill="#fff" />
      <path d="M473.741 84.9718C471.117 84.9718 468.722 84.3054 466.555 82.9725C464.406 81.6396 462.686 79.8561 461.393 77.6221C460.119 75.3694 459.482 72.8819 459.482 70.1598C459.482 68.0947 459.853 66.1705 460.594 64.387C461.336 62.5848 462.353 61.0079 463.646 59.6562C464.958 58.2857 466.479 57.2157 468.209 56.446C469.939 55.6763 471.783 55.2914 473.741 55.2914C476.365 55.2914 478.751 55.9579 480.899 57.2908C483.066 58.6237 484.787 60.4165 486.061 62.6693C487.354 64.9221 488 67.4189 488 70.1598C488 72.2061 487.629 74.1209 486.888 75.9044C486.146 77.6878 485.12 79.2648 483.808 80.6352C482.515 81.9869 481.004 83.0476 479.274 83.8173C477.562 84.587 475.718 84.9718 473.741 84.9718ZM473.741 79.1146C475.319 79.1146 476.735 78.7016 477.99 77.8756C479.245 77.0496 480.234 75.9607 480.956 74.609C481.698 73.2386 482.068 71.7555 482.068 70.1598C482.068 68.5265 481.688 67.0247 480.928 65.6542C480.186 64.2838 479.188 63.1949 477.933 62.3877C476.678 61.5617 475.281 61.1487 473.741 61.1487C472.182 61.1487 470.766 61.5617 469.492 62.3877C468.237 63.2137 467.239 64.3119 466.498 65.6824C465.775 67.0528 465.414 68.5453 465.414 70.1598C465.414 71.8306 465.794 73.3418 466.555 74.6935C467.315 76.0452 468.323 77.1246 469.578 77.9319C470.851 78.7204 472.239 79.1146 473.741 79.1146Z" fill="#fff" />
    </svg>
  )
}

export default InvesthubLogo
