import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { omit } from 'lodash'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'

import {
  CHECK_SUBDOMAIN_AVAILABILITY,
  CHECK_DOMAIN_AVAILABILITY,
  PUBLIC_SETTINGS,
  EASY_REGISTER_TENANT,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import ValidationMessage from '../components/common/ValidationMessage'

const TenantEasySetup = () => {
  const { t } = useTranslation()
  const [checkSubdomain, checkSubdomainStatus] = useMutation(CHECK_SUBDOMAIN_AVAILABILITY)
  const [checkDomain, checkDomainStatus] = useMutation(CHECK_DOMAIN_AVAILABILITY)
  const [easyRegisterTenant, easyRegisterTenantStatus] = useMutation(EASY_REGISTER_TENANT)
  const { data } = useQuery(PUBLIC_SETTINGS)
  const tenantBaseDomain = (data && data.publicSettings && data.publicSettings.cloudflare && data.publicSettings.cloudflare.baseDomain) || ''
  const prefixOwnDomain = window.location.host === 'dev.investhub.io' ? 'test-' : ''

  const [values, setValues] = useState({
    subdomainType: '',
    planType: 'evolve_only',
    ownSubdomain: '',
    customDomain: localStorage.getItem('desiredUrl') || '',

    tokenType: '',
    baseCurrency: 'eur',
    minimumInvestment: 1,
    acceptedCurrencies: ['eur', 'usd', 'chf', 'eth', 'usdc'],
    tokenName: '',
    tokenSymbol: '',
    blockchainType: 'MATIC_SIDE_CHAIN',
    acceptedWallets: {
      eth: true,
      ixo_id: true,
      cosmos: true,
      btc: true,
      other: true,
    },
  })
  const [stepErrors, setStepErrors] = useState({})

  const setTextField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }

  const validateDomain = () => {
    const errors = {}
    if (!values.subdomainType) {
      errors.subdomainType = 'You need to select a type of domain.'
    } else if (values.subdomainType === 'ownSubdomain' && !values.ownSubdomain) {
      errors.ownSubdomain = 'You need to provide your subdomain.'
    } else if (values.subdomainType === 'investhubSubdomain') {
      if (!values.customDomain) {
        errors.customDomain = 'You need to select a subdomain.'
      }
    }

    setStepErrors({
      ...stepErrors,
      domain: errors,
    })

    return Object.keys(errors).length === 0
  }

  const stepListEasySetup = [
    'Setup Domain',
  ]

  const onSubmit = () => {
    if (validateDomain()) {
      easyRegisterTenant({
        variables: {
          input: omit(values, [
            'blockchainType',
          ]),
        },
      }).then(() => {
        window.location.href = `${window.location.origin}/dashboard`
      }).catch(() => {})
    }
  }

  const getErrorText = (step, field) => (stepErrors && stepErrors[step] && stepErrors[step][field]) || ''

  const verifyDomain = () => {
    checkDomain({
      variables: {
        host: `${prefixOwnDomain}${values.ownSubdomain}`,
      },
    }).then((response) => {
      if (response.data.checkDomainAvailability) {
        toaster.success(t('Domain "OWNSUBDOMAIN" is available', { OWNSUBDOMAIN: prefixOwnDomain + values.ownSubdomain }))
      } else {
        toaster.error(t('Domain "OWNSUBDOMAIN" is not available', { OWNSUBDOMAIN: prefixOwnDomain + values.ownSubdomain }))
      }
    }).catch(() => {})
  }

  const checkSubdomainAvailable = () => {
    checkSubdomain({
      variables: {
        host: values.customDomain,
      },
    }).then((response) => {
      if (response.data.checkSubdomainAvailability) {
        toaster.success(t('Domain "OWNDOMAIN" is available', { OWNDOMAIN: values.customDomain + tenantBaseDomain }))
      } else {
        toaster.error(t('Domain "OWNDOMAIN" is not available', { OWNDOMAIN: values.customDomain + tenantBaseDomain }))
      }
    }).catch(() => {})
  }

  return (
    <>
      <div className="section-pt80 faq new-tenant">
        <div className="faq__center center new-tenant__center">
          <div className="faq__top new-tenant__center">
            <div className="faq__stage">{t('4 Steps - 60 Seconds')}</div>
            <div className="faq__title h2 new-tenant__title">{t('Easy Setup')}</div>
            <div className="faq__info new-tenant__info">{t('Backing the future of Web3 Entrepreneurs & helping Web2 Entrepreneurs transition to Web3.')}</div>
          </div>

          <div className="faq__row new-tenant__row">
            <div className="faq__col new-tenant__col">
              <div className="new-tenant__stepper-nav tablet-hide mobile-hide">
                <Stepper
                  activeStep={0}
                  orientation="vertical"
                >
                  { stepListEasySetup.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        {t(label)}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>

            <div className="faq__col new-tenant__col" id="tenant_register_form">
              <div className="new-tenant__stepper-content">
                <div className="upload__item">
                  <div className="upload__category">{t('Setup domain')}</div>

                  <div className="upload__fieldset details__fieldset">
                    <div className="field">
                      <div className="field__label">{t('Do you want to have your own Subdomain?')}</div>

                      <RadioGroup
                        row
                        aria-label="subdomaintype"
                        name="subdomaintype"
                        className="field__radio"
                        value={values.subdomainType}
                        onChange={setTextField('subdomainType')}
                      >
                        <FormControlLabel
                          value="ownSubdomain"
                          label={t('Yes - I have my own Subdomain')}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="investhubSubdomain"
                          label={t('No - Continue with TENANTBASEDOMAIN subdomain and verify my own Domain later.')}
                          control={<Radio />}
                        />
                      </RadioGroup>

                      <ValidationMessage error={getErrorText('domain', 'subdomainType')} />
                    </div>

                    {values.subdomainType === 'ownSubdomain' && (
                      <>
                        <TextField
                          value={values.ownSubdomain}
                          onChange={setTextField('ownSubdomain')}
                          className="investhub__field field field-has__button"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Cname')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {checkDomainStatus.loading && (
                                  <CircularProgress />
                                )}
                                {!checkDomainStatus.loading && (
                                  <button
                                    type="button"
                                    className="button button-small"
                                    onClick={verifyDomain}
                                  >
                                    {t('Verify')}
                                  </button>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />

                        <ValidationMessage error={getErrorText('domain', 'ownSubdomain')} />

                        <div className="field">
                          <div className="box-note box-note__orange">
                            {t('Please setup a CNAME DNS record point to the address app.investhub.io (without proxy enabled. We recommend to use CloudFlare). If you wish to set up your subdomain, please make sure to follow the video.')}
                          </div>
                        </div>
                      </>
                    )}

                    {values.subdomainType === 'investhubSubdomain' && (
                      <>
                        <TextField
                          value={values.customDomain}
                          onChange={setTextField('customDomain')}
                          className="investhub__field field field-has__button"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Cname')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <span className="button-stroke button-small button-transparent">
                                  {tenantBaseDomain}
                                </span>
                              </InputAdornment>
                            ),
                          }}
                        />

                        <ValidationMessage error={getErrorText('domain', 'customDomain')} />

                        <div className="field">
                          {checkSubdomainStatus.loading && (
                            <CircularProgress />
                          )}
                          {!checkSubdomainStatus.loading && (
                            <button
                              className="button-stroke"
                              type="button"
                              onClick={checkSubdomainAvailable}
                            >
                              {t('Check Availability')}
                            </button>
                          )}
                        </div>

                        <div className="field">
                          <div className="box-note box-note__orange">
                            {t('You can also set up your cname later in case you want to simply start with a subdomain so you can start configuring your KYC SaaS right away.')}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="field field__video">
                      <div className="video__item video__item-9-16">
                        <iframe
                          src="https://www.youtube.com/embed/6eUCbY5oIuU"
                          title={t('YouTube video player')}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="upload__foot details__foot new-tenant__foot">
                  <div className="details__btns details__btns_flex">
                    { easyRegisterTenantStatus.loading && (
                      <CircularProgress />
                    )}
                    { !easyRegisterTenantStatus.loading && (
                      <button type="button" className="button details__button" onClick={onSubmit}>
                        {t('Complete')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TenantEasySetup
