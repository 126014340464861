import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { ethereum, walletClient } from './useCoinbaseAuth';
import { useMe } from './useMe';
// eslint-disable-next-line import/no-unresolved
import { useAppKitProvider, useAppKitAccount, useAppKit } from '@reown/appkit/react'
import { isProduction, toaster } from '../utils';
import metamaskService from '../services/metamask'
import { ethers } from "ethers";

export const COIN_DECIMALS = {
  zchf: 18,
  dai: 18,
  usdc: 6,
  eure: 18,
  usdt: 6,
  busd: 18,
  rate: 18,
}

export const getUniswapPath = () => {
  const usdc = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
  const weth = "0xC02aaa39b223FE8D0A0e5C4F27eAD9083C756Cc2";
  const zchf = "0xB58E61C3098d85632Df34EecfB899A1Ed80921cB";
  const fee = 3000;

  const path = ethers.utils.solidityPack(
    ["address", "uint24", "address", "uint24", "address"],
    [zchf, fee, weth, fee, usdc]
  );

  return path;
}

export async function verifyPath(provider, path, amountIn) {
  try {
    const quoterAddress = "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6";
    const quoterABI = [
      {
        "inputs": [
          { "internalType": "bytes", "name": "path", "type": "bytes" },
          { "internalType": "uint256", "name": "amountIn", "type": "uint256" }
        ],
        "name": "quoteExactInput",
        "outputs": [{ "internalType": "uint256", "name": "amountOut", "type": "uint256" }],
        "stateMutability": "view",
        "type": "function"
      }
    ];

    const quoter = new ethers.Contract(quoterAddress, quoterABI, provider);

    const amountOut = await quoter.quoteExactInput(path, amountIn);
    console.log('amountOut', amountOut)
    console.log(`Estimated output: ${ethers.utils.formatUnits(amountOut, 6)} USDC`);

    return amountOut;
  } catch (error) {
    console.error("Invalid path or error in quoter:", error);
    return null;
  }
}

export const getCoinAddress = (currency, network = 'base') => {
  let coinAddress;

  switch (network) {
    case 'base':
      switch (currency) {
        case 'dai':
          coinAddress = '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb'
          break;
        case 'usdc':
          coinAddress = '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913'
          break;
        case 'usdt':
          coinAddress = '0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2'
          break;
        case 'eurc':
          coinAddress = '0x60a3E35Cc302bFA44Cb288Bc5a4F316Fdb1adb42'
          break;
        default:
          break;
      }
      break;
    case 'mainnet-only':
      switch (currency) {
        case 'dai':
          coinAddress = '0x6B175474E89094C44Da98b954EedeAC495271d0F'
          break;
        case 'usdc':
          coinAddress = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
          break;
        case 'usdt':
          coinAddress = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
          break;
        case 'eure':
          coinAddress = '0x3231Cb76718CDeF2155FC47b5286d82e6eDA273f'
          break;
        case 'zchf':
          coinAddress = '0xB58E61C3098d85632Df34EecfB899A1Ed80921cB'
          break;
        default:
          break;
      }
      break;
    case 'mainnet':
      switch (currency) {
        case 'dai':
          coinAddress = isProduction ? '0x6B175474E89094C44Da98b954EedeAC495271d0F' : '0xd8A93603fA4b00FC484CC053577481811F32C99B'
          break;
        case 'usdc':
          coinAddress = isProduction ? '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48' : '0x01023e5b60FfC08991c170E447c7b602Cc549c12'
          break;
        case 'usdt':
          coinAddress = isProduction ? '0xdAC17F958D2ee523a2206206994597C13D831ec7' : '0xE2afc4fE6D593F5CfeA0F1b486Fe3eB1367B11aa'
          break;
        case 'eure':
          coinAddress = isProduction ? '0x3231Cb76718CDeF2155FC47b5286d82e6eDA273f' : '0x67b34b93ac295c985e856E5B8A20D83026b580Eb'
          break;
        case 'zchf':
          coinAddress = isProduction ? '0xB58E61C3098d85632Df34EecfB899A1Ed80921cB' : '0xB58E61C3098d85632Df34EecfB899A1Ed80921cB'
          break;
        default:
          break;
      }
      break;
    case 'polygon':
      switch (currency) {
        case 'dai':
          coinAddress = '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'
          break;
        case 'usdc':
          coinAddress = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
          break;
        case 'usdt':
          coinAddress = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
          break;
        case 'eure':
          coinAddress = '0x18ec0A6E18E5bc3784fDd3a3634b31245ab704F6'
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  if (!isProduction && network === 'base') {
    coinAddress = '0xd982703e2e9A6913fB762F89F66C1A9365F426A6'
  }

  return coinAddress;
}

export const getNetworkByEnv = (network) => {
  switch (network) {
  case 'mainnet-only':
    return 'mainnet'

  case 'mainnet':
    return isProduction ? 'mainnet' : 'sepolia'

  case 'polygon':
    return isProduction ? 'polygon' : 'amoy'

  case 'xinfin':
    return isProduction ? 'xinfin' : 'apothem'

  case 'base':
    return isProduction ? 'base' : 'base_sepolia'

  default:
    throw new Error('Network not supported');
  }
}

export const useWeb3Provider = (network = 'base', issuerId = null) => {
  const [provider, setProvider] = useState(null);
  const { data: { me: user } = {} } = useMe()
  const { walletProvider } = useAppKitProvider('eip155')
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount()
  const { open: openAppKitModal } = useAppKit()
  const [isProviderReady, setIsProviderReady] = useState(false)

  useEffect(() => {
    const initializeWeb3 = async () => {
      let providerInstance;
      // setIsProviderReady(false)

      console.log('isWcConnected', isWcConnected)
      console.log('network', network)

      const chainId = metamaskService.getChainIdByName(getNetworkByEnv(network))
      console.log('chainId', chainId)
      console.log('network', network)

      if (!metamaskService.isMetamaskNotAvailable()) {
        console.log('requireNetwork', getNetworkByEnv(network))
        await metamaskService.requireNetwork(getNetworkByEnv(network), async () => {
          console.log('requireNetwork callback')
          const url = new URL(window.location.href)
          url.searchParams.set('issuer', issuerId)
          // window.location.href = url.toString()
          // setIsProviderReady(true)
        })
      }

      if (user.login_provider === 'coinbase') {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }], // base or base sepolia
        });

        providerInstance = ethereum;
      } else if (user.login_provider === 'walletconnect') {
        if (!isWcConnected && openAppKitModal) {
          // toaster.warning('Lost connection. Please connect to WalletConnect to use this feature');

          openAppKitModal({
            enableExplorer: false,
          })
        }

        providerInstance = walletProvider;
      } else {
        if (metamaskService.isMetamaskNotAvailable()) {
          throw new Error('You need to install Metamask to use this feature');
        }

        providerInstance = window.ethereum;
      }

      setProvider(providerInstance);
      // setIsProviderReady(true);
    };

    initializeWeb3();
  }, [user, isWcConnected, walletProvider, network, issuerId, openAppKitModal]);

  return { provider, isProviderReady };
};

export default useWeb3Provider;
