import React from 'react';
import { useTranslation } from 'react-i18next';

import WalletGroup from './WalletGroup';

// Cross component
import Heading from '../Heading';

const AccountNumber = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        title={t('settings.monerium.title')}
      />

      <div className="space-y-6">
        <WalletGroup currency="EUR" />
        <WalletGroup currency="USD" />
      </div>
    </>
  )
}

export default AccountNumber
