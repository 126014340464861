/* eslint-disable no-param-reassign */
// src/hooks/useTableScroll.js
import { useState, useEffect, useCallback } from 'react'

export const useTableScroll = (headerRef, contentRef) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [scrollText, setScrollText] = useState("Scroll to End")
  const [rangeValue, setRangeValue] = useState(0)

  const handleScroll = useCallback(() => {
    if (headerRef.current) {
      const maxScrollLeft = headerRef.current.scrollWidth - headerRef.current.clientWidth
      const scrollLeftPercent = (headerRef.current.scrollLeft / maxScrollLeft) * 100

      setRangeValue(scrollLeftPercent)
      setIsScrolled(headerRef.current.scrollLeft > 0)
      setScrollText(scrollLeftPercent > 80 ? "Scroll to Front" : "Scroll to End")
    }
  }, [headerRef])

  const syncScroll = useCallback((source, target) => {
    target.scrollLeft = source.scrollLeft
  }, [])

  const animateScroll = useCallback((element, target, duration) => {
    const start = element.scrollLeft
    const change = target - start
    const startTime = performance.now()

    const animate = (currentTime) => {
      const elapsed = currentTime - startTime
      const progress = Math.min(elapsed / duration, 1)
      const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
      element.scrollLeft = start + change * easeInOutQuad(progress)

      if (progress < 1) {
        requestAnimationFrame(animate)
      }
    }

    requestAnimationFrame(animate)
  }, [])

  const handleRangeChange = useCallback((e) => {
    const value = parseFloat(e.target.value)
    setRangeValue(value)

    if (headerRef.current && contentRef.current) {
      const maxScrollLeftHeader = headerRef.current.scrollWidth - headerRef.current.clientWidth
      const maxScrollLeftContent = contentRef.current.scrollWidth - contentRef.current.clientWidth

      headerRef.current.scrollLeft = (value / 100) * maxScrollLeftHeader
      contentRef.current.scrollLeft = (value / 100) * maxScrollLeftContent
    }
  }, [headerRef, contentRef])

  const toggleScrollPosition = useCallback(() => {
    [headerRef.current, contentRef.current].forEach((scroll) => {
      if (scroll) {
        const maxScrollLeft = scroll.scrollWidth - scroll.clientWidth
        const targetPosition = scrollText === "Scroll to End" ? maxScrollLeft : 0
        animateScroll(scroll, targetPosition, 500)
      }
    })
    setScrollText((prev) => (prev === "Scroll to End" ? "Scroll to Front" : "Scroll to End"))
  }, [scrollText, headerRef, contentRef, animateScroll])

  useEffect(() => {
    const header = headerRef.current
    const content = contentRef.current

    if (header && content) {
      header.addEventListener('scroll', handleScroll, { passive: true })
      header.addEventListener('scroll', () => syncScroll(header, content), { passive: true })
      content.addEventListener('scroll', () => syncScroll(content, header), { passive: true })

      return () => {
        header.removeEventListener('scroll', handleScroll)
        header.removeEventListener('scroll', () => syncScroll(header, content))
        content.removeEventListener('scroll', () => syncScroll(content, header))
      }
    }

    return () => {}
  }, [headerRef, contentRef, handleScroll, syncScroll])

  return {
    isScrolled,
    scrollText,
    rangeValue,
    handleRangeChange,
    toggleScrollPosition,
  }
}

export default useTableScroll
