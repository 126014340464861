import React from 'react'
import cn from 'classnames'
import Flag from 'react-world-flags'
import Icons from '../../../../../../shared/Icons'

const LanguageTab = ({ languages, selectedLanguage, onSelect }) => (
  <div>
    {languages.map((language) => (
      <button
        key={language.code}
        type="button"
        onClick={() => onSelect(language)}
        className={cn(
          "flex justify-between items-center bg-transparent px-0 py-3 rounded-xl w-full font-medium text-sm transition-colors font-sans duration-200",
          {
            "text-white": selectedLanguage === language.label,
            "text-gray-custom hover:text-white": selectedLanguage !== language.label
          }
        )}
      >
        <span className='flex items-center gap-2'>
          <img src={language.flagIcon} alt={language.label} className='rounded-sm w-6 h-4 object-cover' />

          {language.label}
        </span>
        {selectedLanguage === language.label && (
          <Icons
            nameIcon="rosette-discount-check"
            className="w-4 h-4 text-gray-custom"
          />
        )}
      </button>
    ))}
  </div>
)

export default LanguageTab
