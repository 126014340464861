/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { motion, AnimatePresence } from 'framer-motion'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Avatar from 'react-avatar'

// Shared
import Icons from "../../../../shared/Icons"
import { IssuerSkeleton } from "../../../../shared/Cards"

// Utils
import { generateIssuerUrl } from "../../../../../utils/issuer"

// Dummy data
import { DASHBOARD_DATA_TOKEN_EXCEL } from '../../../../../constant/dataTokenFromExcel'
// import issuers from "../../../../../services/dummyDataIssuers"
// import tokens from "../../../../../services/dummyDataToken"

const TokenCard = ({ company, networks, issuer }) => {
  const history = useHistory()

  const handleClick = (e) => {
    e.preventDefault()

    if (!issuer) return;
    const url = generateIssuerUrl(issuer);
    if (url) {
      history.push(`/explore/tokens/${url}`);
    }
  }

  return (
    <div
      to="#"
      onClick={handleClick}
      className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 hover:opacity-60 m-0 px-0 py-2 rounded-2xl transition-all duration-[0.2s] cursor-pointer"
    >
      <div className="border-0 m-0 p-0 rounded-[50%] w-10 h-10 overflow-hidden align-baseline">
        <Avatar
          name={company}
          round={true}
          size="40"
          className="w-full h-full object-cover"
        />
      </div>

      <div className="flex flex-col flex-wrap flex-[1_1_0] justify-start items-start border-0 m-0 p-0 font-semibold text-white align-baseline">
        <span className="flex-1 font-sans">{company}</span>
        <span className="flex-1 font-sans">Network: {networks}</span>
      </div>

      <div className="bg-transparent w-6 h-6 text-white/15">
        <Icons
          nameIcon="arrow-bar-right"
          className="w-6 h-6"
        />
      </div>
    </div>
  )
}

const Search = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [filteredData, setFilteredData] = useState([])

  const toggleModal = async (e) => {
    if (e) e.preventDefault()
    if (!isOpenModal) {
      setIsLoading(true)
      setIsOpenModal(true)
      setSearchTerm('')
      await new Promise(resolve => setTimeout(resolve, 800))
      setFilteredData(DASHBOARD_DATA_TOKEN_EXCEL)
      setIsLoading(false)
    } else {
      setIsOpenModal(false)
      setSearchTerm('')
      setFilteredData([])
      setIsLoading(true)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") setIsOpenModal(false)
    }
    if (isOpenModal) {
      document.body.style.overflow = 'hidden'
      document.addEventListener('keydown', handleKeyDown)
    } else {
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpenModal])

  useEffect(() => {
    const searchData = async () => {
      setIsLoading(true)
      const searchTermLower = searchTerm.toLowerCase().trim()

      await new Promise(resolve => setTimeout(resolve, 800))

      const filtered = DASHBOARD_DATA_TOKEN_EXCEL.filter(token =>
        token.company.toLowerCase().includes(searchTermLower) ||
        (token.networks && token.networks.toLowerCase().includes(searchTermLower)) ||
        (token.ticker && token.ticker.toLowerCase().includes(searchTermLower))
      )

      setFilteredData(filtered)
      setIsLoading(false)
    }

    const debounceTimer = setTimeout(searchData, 300)
    return () => clearTimeout(debounceTimer)
  }, [searchTerm])

  const location = useLocation()

  useEffect(() => {
    setIsOpenModal(false)
  }, [location])

  const titleSearch = 'font-sans border-0 m-0 mb-2 p-0 font-bold text-gray-lighter text-xs uppercase align-baseline border-0 m-0 mb-2 p-0 font-bold text-gray-lighter text-xs uppercase align-baseline'
  const wrapperCards = 'justify-center items-stretch gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 align-baseline justify-center items-stretch gap-x-3 gap-y-3 border-0 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto my-0 px-0 pt-3 pb-6 align-baseline'

  return (
    <div className="relative z-50">
      <button
        type="button"
        className="inline-flex justify-center items-center bg-black-light rounded-full w-10 h-10 text-white transition-colors duration-[0.2s] outline-none"
        onClick={toggleModal}
        data-tooltip-id="search-tooltip"
        data-tooltip-content="Search"
      >
        <Icons
          nameIcon="search"
          className="w-4 h-4"
        />
      </button>
      <Tooltip id="search-tooltip" className="!bg-black-dark !px-1 !rounded-md" />

      {/* Search modal */}
      <AnimatePresence>
        {isOpenModal && (
          <motion.div
            className="z-50 fixed inset-0 p-6 max-767:p-0 overflow-auto modal scroll-smooth"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className="fixed inset-0 bg-black-light opacity-75 modal__overlay"
              onClick={toggleModal}
            />

            <motion.div
              className="relative top-1/2 z-10 bg-black-dark shadow-lg m-auto rounded-3xl w-full max-w-[638px]"
              initial={{ opacity: 0, y: 'calc(-50% + 16px)' }}
              animate={{ opacity: 1, y: '-50%' }}
              exit={{ opacity: 0, y: 'calc(-50% + 16px)' }}
              transition={{ duration: 0.2 }}
            >
              <div className="relative border-white/20 border-b">
                <Icons
                  nameIcon="search"
                  className="inline-flex top-[calc(50%)] left-8 z-[2] absolute justify-center items-center w-7 h-7 text-gray-lighter -translate-y-3 pointer-events-none"
                />

                <input
                  type="text"
                  placeholder="Type to search an issuer..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="bg-transparent m-0 !py-0 !pr-8 !pl-[5.5rem] max-767:pl-16 w-full !h-[5.25rem] max-767:h-16 !font-sans font-medium text-lg text-white placeholder:text-gray-lighter leading-8 tracking-[-0.02em] outline-none"
                />
              </div>

              <div className="px-6 max-767:px-6 pt-2 pb-6">
                <div className="-mx-6 px-6 max-h-80 overflow-y-auto scrollbar-custom">
                  <div className="justify-center items-stretch gap-x-3 gap-y-3 border-0 mx-auto my-0 px-0 pt-3 pb-6 align-baseline">
                    {isLoading ? (
                      Array(5).fill(null).map((_, index) => (
                        <IssuerSkeleton key={`issuer-skeleton-${index}`} />
                      ))
                    ) : filteredData.length === 0 ? (
                      <div className="py-2 text-gray-lighter text-sm">No results found</div>
                    ) : (
                      <div className="gap-x-3 gap-y-3 grid w-full">
                        {filteredData.map((item) => (
                          <TokenCard
                            key={item.id}
                            imgSrc={item.image}
                            company={item.company}
                            networks={item.networks}
                            issuer={item}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Search
