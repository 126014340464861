import React, { useState, useEffect, useCallback } from "react"
import { motion, AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { createPortal } from 'react-dom'
import Avatar from "react-avatar"
import Icons from "../../../components/shared/Icons"
import { generateIssuerUrl } from "../../../utils/issuer"
// import ShortToken from "../../../components/shared/ShortToken"
// import { getExplorerUrl, cleanAddressString, extractNetwork } from "../../../utils/blockchainUtils"
// import ActionDropdown from "./ActionDropdown"

const getStatusColor = (status) => {
  switch (status && status.toLowerCase()) {
    case 'public':
      return 'bg-green-500/15 text-green-500'
    case 'private':
      return 'bg-yellow-500/15 text-yellow-500'
    case 'expired':
      return 'bg-red-500/15 text-red-500'
    case 'completed':
      return 'bg-primary/15 text-primary'
    case 'to be issued':
      return 'bg-purple-500/15 text-purple-500'
    case 'dividend claimable':
      return 'bg-teal-500/15 text-teal-500'
    case 'off chain in progress':
      return 'bg-orange-500/15 text-orange-500'
    default:
      return 'bg-gray-500/15 text-gray-500'
  }
}

const Cell = ({
  type,
  value,
  customStyle,
  isSticky = false,
  rowData,
  openCollapseId,
  setOpenCollapseId,
  onToggleCollapse,
  isChildRow = false,
  globalDropdownId,
  setGlobalDropdownId
}) => {
  const [isRiskModalOpen, setIsRiskModalOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useHistory()
  const [buttonRect, setButtonRect] = useState(null)
  const [openDropdownId, setOpenDropdownId] = useState(null)
  const isCollapseOpen = openCollapseId === rowData.id
  const isDropdownOpen = globalDropdownId === rowData.id

  useEffect(() => {
    if (isDropdownOpen) {
      setGlobalDropdownId(null)
    }
  }, [openCollapseId, isDropdownOpen, setGlobalDropdownId])

  useEffect(() => {
    if (!isDropdownOpen) {
      setButtonRect(null)
    }
  }, [isDropdownOpen])

  const handleCompanyClick = useCallback((e) => {
    e.stopPropagation()
    if (!isChildRow && rowData.hasChildren) {
      if (isDropdownOpen) {
        setGlobalDropdownId(null)
        setButtonRect(null)
      }
      onToggleCollapse()
    }
  }, [isChildRow, rowData.hasChildren, isDropdownOpen, onToggleCollapse, setGlobalDropdownId])

  const handleActionClick = useCallback((e) => {
    e.stopPropagation()
    if (isChildRow) return

    const rect = e.currentTarget.getBoundingClientRect()

    if (openCollapseId) {
      Promise.resolve().then(() => {
        setButtonRect(rect)
        setOpenCollapseId(null)
        setGlobalDropdownId(rowData.id)
      })
      return
    }

    if (isDropdownOpen) {
      setGlobalDropdownId(null)
      setButtonRect(null)
    } else {
      setButtonRect(rect)
      setGlobalDropdownId(rowData.id)
    }
  }, [isDropdownOpen, rowData.id, isChildRow, setGlobalDropdownId, openCollapseId, setOpenCollapseId])

  const handleNavigateToDetail = useCallback((e) => {
    e.stopPropagation()
    const tokenName = "Enercom AG".toLowerCase().replace(/\s+/g, '-')
    navigate(`/dashboard/tokens/${tokenName}`)
    setGlobalDropdownId(null)
  }, [navigate, setGlobalDropdownId])

  const handleNavigateToInvestment = useCallback((e) => {
    e.stopPropagation()
    const tokenName = "Enercom AG".toLowerCase().replace(/\s+/g, '-')
    navigate(`/dashboard/investment?token=${tokenName}`)
    setGlobalDropdownId(null)
  }, [navigate, setGlobalDropdownId])

  // const getCountryUrl = useCallback((countryCode) => {
  //   return `/dashboard/region/${countryCode.toLowerCase()}`
  // }, [])

  // const renderBlockchainUrl = useCallback((url) => {
  //   const address = url.split('/').pop()
  //   const baseUrl = url.replace(address, '')
  //   return `${baseUrl}...`
  // }, [])

  const renderAction = useCallback(() => {
    if (isChildRow) {
      return <span className="text-white/30">-</span>
    }

    const handleClickDetails = (e) => {
      e.stopPropagation()
      if (!rowData) return

      const url = generateIssuerUrl(rowData)
      if (url) {
        navigate.push(`/explore/tokens/${url}`)
      }
    }

    return (
      <div className="block">
        <button
          onClick={handleClickDetails}
          className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
        >
          Details
        </button>

        {/* {isDropdownOpen && buttonRect && (
          <ActionDropdown
            buttonRect={buttonRect}
            isOpen={isDropdownOpen}
            setOpenDropdownId={setGlobalDropdownId}
            handleNavigateToDetail={handleNavigateToDetail}
            handleNavigateToInvestment={handleNavigateToInvestment}
          />
        )} */}
      </div>
    )
  }, [isChildRow, rowData, navigate])

  const renderCompanyCell = useCallback(() => {
    return (
      <div
        className="flex items-center gap-2 w-full cursor-pointer group"
        onClick={handleCompanyClick}
      >
        <Avatar
          name={value.text || value}
          round={true}
          size="24"
          className="flex-shrink-0 rounded-full"
        />
        {/* <img
          src={rowData.image}
          alt={value.text || value}
          className="flex-shrink-0 rounded-full w-6 h-6"
        /> */}
        <span className="font-medium text-[13px] text-white truncate tracking-[-0.01em]">
          {value.text || value}
        </span>
        {isChildRow && <div className="w-4" />}
        {rowData.hasChildren && !isChildRow && (
          <Icons
            nameIcon={isCollapseOpen ? "chevron-down" : "chevron-right"}
            className="group-hover:text-white flex-shrink-0 w-4 h-4 text-gray-lighter transition duration-[0.2s]"
          />
        )}
      </div>
    )
  }, [value, rowData, isChildRow, isCollapseOpen, handleCompanyClick])

  const renderWalletCell = useCallback(() => {
    if (typeof value === 'string' && value.endsWith('.eth')) {
      return <span className="font-mono text-purple-400">{value}</span>
    }

    if (typeof value === 'object' && value.text && value.url) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="font-medium font-mono text-primary hover:text-primary-hover tracking-[-0.02em] transition-colors duration-[0.2s]"
        >
          {value.text}
        </a>
      )
    }

    return <span>{value}</span>
  }, [value])

  const renderProtocolCell = useCallback(() => {
    if (value === '-') return <span className="text-white/30">-</span>

    if (typeof value === 'object' && value.text && value.url) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="font-medium text-[13px] text-primary tracking-[-0.02em] transition-colors duration-[0.2s] hover:primary-hover"
        >
          {value.text}
        </a>
      )
    }

    return <span className="text-white/30">-</span>
  }, [value])

  const renderTokenContractAddress = useCallback(() => {
    if (value === '-') return <span className="text-white/30">-</span>

    if (typeof value === 'object' && value.text && value.url) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="font-medium text-[13px] text-primary tracking-[-0.02em] transition-colors duration-[0.2s] hover:primary-hover"
        >
          {value.text}
        </a>
      )
    }

    return <span className="text-white/30">-</span>
  }, [value])

  const renderIfObject = useCallback(() => {
    if (value === '-') return <span className="text-white/30">-</span>

    if (typeof value === 'object' && value.text && value.url) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="font-medium text-[13px] text-primary tracking-[-0.02em] transition-colors duration-[0.2s] hover:primary-hover"
        >
          {value.text}
        </a>
      )
    }

    return <span className="text-white/30">-</span>
  }, [value])

  const renderRisks = useCallback((value) => {
    if (value === '-') return <span className="font-medium text-[13px] text-gray-lighter tracking-[-0.01em]">-</span>

    return (
      <>
        <button
          onClick={() => setIsModalOpen(true)}
          className="text-[13px] text-primary hover:text-primary-hover transition-colors duration-200"
        >
          Click to check detail
        </button>

        {createPortal(
          <AnimatePresence>
            {isModalOpen && (
              <div className="z-50 fixed inset-0 flex p-6 max-767:p-0 transition-all duration-[0.2s] overflow-auto modal scroll-smooth">
                <motion.div
                  className="fixed inset-0 bg-black-light opacity-75 cursor-pointer modal__overlay"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.75 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsModalOpen(false)}
                />
                <motion.div
                  className="relative z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[578px] modal__wrapper"
                  initial={{ opacity: 0, translateY: 16 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: 16 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="border-0 m-0 px-6 max-767:px-6 pt-4 pb-6 text-center align-baseline">
                    <div className="flex justify-between items-start mb-4">
                      <h3 className="font-semibold text-lg text-white">Risk Factors</h3>
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="text-gray-400 hover:text-white transition-colors"
                      >
                        <Icons nameIcon="close" className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="max-h-[60vh] text-gray-300 text-sm text-start leading-relaxed overflow-y-auto">
                      {value}
                    </div>
                  </div>
                </motion.div>
              </div>
            )}
          </AnimatePresence>,
          document.getElementById('modal-root')
        )}
      </>
    )
  }, [isModalOpen, setIsModalOpen])

  const renderCompanyData = useCallback((value) => {
    if (value === '-') return <span className="font-medium text-[13px] text-gray-lighter tracking-[-0.01em]">-</span>

    if (typeof value === 'object' && value.text && value.url) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="font-medium text-[13px] text-primary tracking-[-0.02em] transition-colors duration-[0.2s] hover:primary-hover"
        >
          {value.text}
        </a>
      )
    }

    return <span className="text-[13px] truncate tracking-[-0.02em]">{value}</span>
  }, [])

  const renderDiscussionURL = useCallback((value) => {
    if (value === '-') return <span className="font-medium text-[13px] text-gray-lighter tracking-[-0.01em]">-</span>

    if (typeof value === 'object' && value.text && value.url) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="font-medium text-[13px] text-primary tracking-[-0.02em] transition-colors duration-[0.2s] hover:primary-hover"
        >
          {value.text}
        </a>
      )
    }

    return <span className="text-[13px] truncate tracking-[-0.02em]">{value}</span>
  }, [])

  const renderIssuerWhitePaper = useCallback((value) => {
    if (value === '-') return <span className="font-medium text-[13px] text-gray-lighter tracking-[-0.01em]">-</span>

    if (typeof value === 'object' && value.text && value.url) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="font-medium text-[13px] text-primary tracking-[-0.02em] transition-colors duration-[0.2s] hover:primary-hover"
        >
          {value.text}
        </a>
      )
    }

    return <span className="font-medium text-[13px] tracking-[-0.02em]">{value}</span>
  }, [])

  const renderStatusCell = useCallback((value) => {
    return (
      <span className={`
        inline-flex justify-center items-center px-2 py-[2px] rounded-md text-[13px]
        ${getStatusColor(value)}
      `}>
        {value}
      </span>
    )
  }, [])

  // const renderRegistrationNotificationCell = useCallback((value) => {
  //   if (Array.isArray(value)) {
  //     return (
  //       <div className="flex flex-wrap gap-1">
  //         {value.map((item, index) => (
  //           <span key={index}>
  //             <a
  //               href={item.url}
  //               onClick={(e) => {
  //                 e.stopPropagation()
  //                 navigate(item.url)
  //               }}
  //               className="inline-flex items-center text-[13px] text-primary hover:text-primary-hover transition-colors duration-200 cursor-pointer"
  //             >
  //               {item.text}
  //             </a>
  //             {index < value.length - 1 && (
  //               <span className="text-[13px] text-white/70">, </span>
  //             )}
  //           </span>
  //         ))}
  //       </div>
  //     )
  //   }

  //   if (value && typeof value === 'object' && value.text) {
  //     return (
  //       <a
  //         href={value.url}
  //         onClick={(e) => {
  //           e.stopPropagation()
  //           window.open(value.url, '_blank')
  //         }}
  //         className="inline-flex items-center text-[13px] text-primary hover:text-primary-hover transition-colors duration-200 cursor-pointer"
  //       >
  //         {value.text}
  //       </a>
  //     )
  //   }

  //   if (value === '-') {
  //     return <span className="text-white/30">-</span>
  //   }

  //   return <span className="text-[13px]">{value}</span>
  // }, [navigate])

  const renderIssuerUrl = useCallback((value) => {
    if (value === '-') {
      return <span className="text-white/30">-</span>
    }

    if (value && typeof value === 'object' && value.text) {
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="inline-flex items-center text-[13px] text-primary hover:text-primary-hover tracking-[-0.02em] transition-colors duration-200 cursor-pointer"
        >
          {value.text}
        </a>
      )
    }

    return (
      <a
        href={`https://${value}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => e.stopPropagation()}
        className="inline-flex items-center text-[13px] text-primary hover:text-primary-hover tracking-[-0.02em] transition-colors duration-200 cursor-pointer"
      >
        {value}
      </a>
    )
  }, [])

  const renderContent = useCallback(() => {
    if (type === 'action') {
      return renderAction()
    }

    if (!value || value === '-' || value === 'N/A') {
      return <span className="text-white/30">-</span>
    }

    switch (type) {
      case 'company':
        return renderCompanyCell()
      case 'protocol':
        return renderProtocolCell()
      case 'smart_contract_audits':
      case 'smart_contract_insurance':
        return (
          <span className={value === '✓' ? 'text-green-500' : 'text-red-500'}>
            {value}
          </span>
        )
      case 'token_contract_address':
      case 'offering_contract':
        if (typeof value === 'object' && value.text && value.url) {
          return (
            <a
              href={value.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              className="font-medium text-[13px] text-primary hover:text-primary-hover tracking-[-0.02em] transition-colors duration-[0.2s]"
            >
              {value.text}
            </a>
          )
        }
        return <span className="text-[13px] truncate tracking-[-0.02em]">{value}</span>

      case 'incorporation_country':
      case 'company_data':
      case 'registration_license':
      case 'whitepaper_terms_prospectus':
      case 'transparency':
      case 'discussion_url':
      case 'issuer_url':
        if (typeof value === 'object' && value.text && value.url) {
          return (
            <a
              href={value.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              className="font-medium text-[13px] text-primary hover:text-primary-hover tracking-[-0.02em] transition-colors duration-[0.2s]"
            >
              {value.text}
            </a>
          )
        }
        return <span className="text-[13px] truncate tracking-[-0.02em]">{value}</span>

      case 'status':
        return renderStatusCell(value)

      case 'market_cap':
      case 'holders':
        return <span className="font-mono">{value}</span>

      case 'notification':
        if (typeof value === 'object' && value.text && value.url) {
          return renderIfObject(value)
        }
        return <span className="text-[13px] truncate tracking-[-0.02em]">{value}</span>

      case 'risks':
        return renderRisks(value)

      case 'wallet':
        return renderWalletCell(value)

      default:
        if (typeof value === 'object' && value.text && value.url) {
          return renderIfObject(value)
        }
        return <span className="text-[13px] truncate tracking-[-0.02em]">{value}</span>
    }
  }, [
    type,
    value,
    renderAction,
    renderCompanyCell,
    renderProtocolCell,
    renderStatusCell,
    renderRisks,
    renderWalletCell,
    renderIfObject
  ])

  return (
    <td
      className={`
        bg-black-dark
        border-0
        lg:sticky
        flex
        flex-grow
        px-4
        py-3
        text-left
        text-sm
        text-white/70
        ${isSticky ? 'sticky__active td' : ''}
        ${isChildRow ? 'child-cell' : ''}
      `}
      style={customStyle}
    >
      <div className="flex items-center w-full min-w-0">
        {renderContent()}
      </div>
    </td>
  )
}

export default Cell
