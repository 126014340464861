import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useMutation } from '@apollo/client'

import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
} from '@material-ui/core'
// import AuthClient, { generateNonce } from "@walletconnect/auth-client"
// import QRCodeModal from "@walletconnect/qrcode-modal"
import _ from "lodash"
import { toaster } from '../utils'

import config from '../config'
import {
  IS_2FA_NEEDED,
  LOGIN,
  WALLET_SIGN_IN,
} from '../queriesAndMutations'
import { usePasskey } from '../myHooks/usePasskey'
import Icons from './shared/Icons'
import { ModalWide } from './shared/Modals'
import { useMetamaskAuth } from '../myHooks/useMetamaskAuth';

const LoginForm = ({
  onSuccess,
}) => {
  const { t } = useTranslation()
  const [, setCookie] = useCookies()
  const [require2Fa, setRequire2Fa] = useState(false)
  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState(null)
  const [hasInitialized, setHasInitialized] = useState(false)
  const [openQRModel, setOpenQRModel] = useState(false)
  const [walletSignIn, walletSignInStatus] = useMutation(WALLET_SIGN_IN)
  const { authenticateWithPasskey } = usePasskey();
  const [step, setStep] = useState(1)
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const [isMetamaskModalOpen, setIsMetamaskModalOpen] = useState(false)
  const [isCoinbaseModalOpen, setIsCoinbaseModalOpen] = useState(false)
  const { connect } = useMetamaskAuth();
  const [values, setValues] = useState({
    email: localStorage.getItem('email') || '',
    password: '',
    twoFaToken: '',
  })

  // useEffect(() => {
  //   AuthClient.init({
  //     projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  //     metadata: {
  //       name: "react-dapp-auth",
  //       description: "React Example Dapp for Auth",
  //       url: window.location.host,
  //       icons: ["https://dev.investhub.io/favicon.png"],
  //     },
  //   })
  //     .then((authClient) => {
  //       setClient(authClient)
  //       setHasInitialized(true)
  //     })
  //     // eslint-disable-next-line no-console
  //     .catch(console.error)
  // }, [])

  const onWalletSignIn = async (params) => {
    const wallet = params.result.p.iss.split(":")[4]

    const { data } = await walletSignIn({
      variables: {
        input: {
          wallet,
          message: params.result.p.statement,
          signedMessage: params.result.s.s,
          network: 'mainnet',
        },
      },
    })

    setCookie(config.jwtCookie, data.walletSignIn.accessToken, { path: '/' })
    localStorage.setItem('email', `${wallet}@example.com`)

    onSuccess(data.walletSignIn.accessToken)
  }

  // useEffect(() => {
  //   if (!client) return

  //   client.on("auth_response", ({ params }) => {
  //     // eslint-disable-next-line no-console
  //     console.log('params', params)
  //     if ("code" in params) {
  //       // eslint-disable-next-line no-console
  //       console.error(params)
  //       return
  //     }
  //     if ("error" in params) {
  //       // eslint-disable-next-line no-console
  //       console.error(params.error)
  //       toaster.error(params.error.message)
  //       return
  //     }
  //     toaster.success("Auth request successfully approved")
  //     QRCodeModal.close()
  //     setOpenQRModel(false)
  //     onWalletSignIn(params)
  //   })

  //   // eslint-disable-next-line
  // }, [client])

  // const onSignIn = () => {
  //   if (!client) return

  //   client
  //     .request({
  //       aud: window.location.href,
  //       domain: window.location.hostname,
  //       chainId: "eip155:1",
  //       nonce: generateNonce(),
  //       statement: `${(new Date()).getTime()} - Investhub.io - Sign in with wallet.`,
  //     })
  //     .then(({ uri }) => {
  //       setOpenQRModel(true)
  //       QRCodeModal.open(uri, () => {
  //         setOpenQRModel(false)
  //       })
  //     })
  // }

  const setField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }
  const setCheckBox = (fieldName) => ({ target: { checked } }) => {
    setValues({
      ...values,
      [fieldName]: checked,
    })
  }

  const [login] = useMutation(LOGIN)

  const getIs2FaNeeded = async () => {
    const response = await axios.post(config.endpoint, {
      query: IS_2FA_NEEDED.loc.source.body,
      variables: { email: values.email },
    })

    return response.data.data.is2FaNeeded
  }

  const submitLogin = async () => {
    try {
      const { data } = await login({
        variables: {
          input: _.omit(values, ['rememberMe']),
        },
      })

      setCookie(config.jwtCookie, data.login.accessToken, { path: '/' })
      localStorage.setItem('email', values.email)

      if (values.rememberMe) {
        localStorage.setItem(config.jwtCookie, data.login.accessToken)
      }

      onSuccess(data.login.accessToken)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const handleSubmitWithPasskey = async (email) => {
    try {
      if (!values.email) {
        toaster.error('Email is required.');
        return;
      }

      const result = await authenticateWithPasskey(values.email);
      if (result.verified) {
        setCookie(config.jwtCookie, result.token.accessToken, { path: '/' })
        localStorage.setItem('email', values.email)

        if (values.rememberMe) {
          localStorage.setItem(config.jwtCookie, result.token.accessToken)
        }

        onSuccess();
      } else {
        toaster.error('Authentication failed.');
      }
    } catch (error) {
      toaster.error(`Error: ${error.message}`);
    }
  };

  const handleMetamaskLogin = async () => {
    try {
      const result = await connect();
      setCookie(config.jwtCookie, result.metamaskLogin.accessToken, { path: '/' })

      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      console.error('Login failed:', err);
    }
  };

  const handleWalletSelect = (wallet) => {
    setIsWalletModalOpen(false)
    switch (wallet) {
      case 'metamask':
        handleMetamaskLogin()
        break
      case 'coinbase':
        setIsCoinbaseModalOpen(true)
        break
      default:
        break
    }
  }

  if (openQRModel) {
    return ''
  }

  return (
    <>
      <form
        onSubmit={async (event) => {
          event.preventDefault()

          if (!values.email) {
            toaster.error('Email is required.');
            return;
          }

          if (step === 1) {
            setStep(2)
            return
          }

          setLoading(true)
          const is2FaNeeded = await getIs2FaNeeded()

          if (is2FaNeeded === require2Fa) {
            try {
              await submitLogin()
            } catch (error) {}
          } else {
            setRequire2Fa(is2FaNeeded)
          }
          setLoading(false)
        }}
        className="variants__list signinup__form"
      >
        <div className="variants__item signinup__inner">
          <div className="details__fieldset">
            <TextField
              required
              disabled={step === 2}
              value={values.email}
              onChange={setField('email')}
              label={t('Email')}
              className="investhub__field field"
              placeholder={t('Enter Email Address')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            {step === 2 && (
              <>
                <TextField
                  required
                  type="password"
                  label={t('Password')}
                  value={values.password}
                  onChange={setField('password')}
                  className="investhub__field field"
                  placeholder={t('Enter Password')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {require2Fa && (
                  <TextField
                    required
                    label={t('2FA code')}
                    value={values.twoFaToken}
                    onChange={setField('twoFaToken')}
                    className="investhub__field field"
                    placeholder={t('Enter 2FA code')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}

                <div className="field__group">
                  <FormControlLabel
                    className="field field__checkbox"
                    label={t('Remember me')}
                    control={(
                      <Checkbox
                        values="yes"
                        checked={values.rememberMe}
                        onChange={setCheckBox('rememberMe')}
                        color="primary"
                      />
                    )}
                  />

                  <div className="field field__link text-right">
                    <NavLink to="/restore-password">{t('Forgot Password')}</NavLink>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="details__btns signinup__btns">
            {step === 1 && (
              <button
                className="button details__button signinup__button"
                type="button"
                onClick={() => {
                  handleSubmitWithPasskey(values.email)
                }}
              >
                {t('Using Passkey')}
              </button>
            )}

            {loading || walletSignInStatus.loading ? (
              <CircularProgress />
            ) : (
              <button
                className="button details__button signinup__button"
                type="submit"
              >
                {t(step === 1 ? 'Sign in with Password' : 'Sign in')}
              </button>
            )}
          </div>

          <div className="signinup__btns mt-4">
            OR
          </div>

          <div className="details__btns signinup__btns">
            <button
              className="button details__button signinup__button"
              type="button"
              onClick={() => {
                setIsWalletModalOpen(true)
              }}
            >
              {t('Login with Wallet')}
            </button>
          </div>

          { hasInitialized && (
            <div className="details__btns signinup__btns">
              <button
                className="button details__button signinup__button"
                type="button"
                // onClick={onSignIn}
              >
                {t('Login via WalletConnect')}
              </button>
            </div>
          )}
        </div>
      </form>

      <div className="variants__note signinup__note">
        {t('Do not have an account ?')}
        {' '}
        <NavLink to="/signup">{t('Signup')}</NavLink>
      </div>

      <ModalWide
        isOpen={isWalletModalOpen}
        onClose={() => setIsWalletModalOpen(false)}
        title={t("Choose Connect a Wallet")}
        subtitle={t("Choose your wallet from the options below to begin")}
      >
        <div className="pw:max-767:flex pw:max-767:flex-row pw:max-767:flex-wrap pw:justify-center pw:max-767:justify-center pw:items-stretch pw:max-767:items-center pw:gap-x-4 pw:gap-y-4 pw:border-0 pw:grid pw:grid-cols-[1fr_1fr_1fr] pw:grid-rows-[auto] pw:auto-cols-[1fr] pw:mx-auto pw:my-0 pw:px-2 pw:p-0 pw:align-baseline">
          <button
            className="pw:inline-flex pw:justify-center pw:items-center pw:bg-black-light hover:pw:bg-gray-lighter hover:pw:opacity-90 pw:transition-colors pw:duration-[0.2s] pw:font-medium pw:text-white pw:cursor-pointer pw:shadow-[#6F737E_0_0_0_1px_inset] pw:m-0 pw:px-6 pw:rounded-[40px] pw:w-full pw:max-767:w-[initial] pw:!h-12 pw:text-sm"
            onClick={() => {
              handleWalletSelect('metamask')
            }}
            type="button"
          >
            <Icons
              nameIcon="metamask"
              className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
            />
            <span className="pw:inline-block">{t('MetaMask')}</span>
          </button>

          <button
            className="pw:inline-flex pw:justify-center pw:items-center pw:bg-black-light hover:pw:bg-gray-lighter hover:pw:opacity-90 pw:transition-colors pw:duration-[0.2s] pw:font-medium pw:text-white pw:cursor-pointer pw:shadow-[#6F737E_0_0_0_1px_inset] pw:m-0 pw:px-6 pw:rounded-[40px] pw:w-full pw:max-767:w-[initial] pw:!h-12 pw:text-sm"
          >
            <Icons
              nameIcon="walletconnect"
              className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
            />
            <span className="pw:inline-block">{t('WalletConnect')}</span>
          </button>

          <button
            className="pw:inline-flex pw:justify-center pw:items-center pw:bg-black-light hover:pw:bg-gray-lighter hover:pw:opacity-90 pw:transition-colors pw:duration-[0.2s] pw:font-medium pw:text-white pw:cursor-pointer pw:shadow-[#6F737E_0_0_0_1px_inset] pw:m-0 pw:px-6 pw:rounded-[40px] pw:w-full pw:max-767:w-[initial] pw:!h-12 pw:text-sm"
            onClick={() => {
              handleWalletSelect('coinbase')
            }}
            type="button"
          >
            <Icons
              nameIcon="coinbase"
              className="pw:mr-2 pw:rounded-full pw:w-6 pw:h-6"
            />
            <span className="pw:inline-block">{t('Coinbase')}</span>
          </button>
        </div>

        <div className="pw:mt-6 pw:w-full pw:text-[13px] pw:text-center pw:text-gray-lighter">
          {t('Need help?')}{' '}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
          <a href="#" className="hover:pw:opacity-80 pw:font-semibold pw:text-white pw:transition-opacity pw:duration-[0.2s]">{t('Click here')}</a>.
        </div>
      </ModalWide>
    </>
  )
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default LoginForm
