import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useFormContext, Controller } from 'react-hook-form'
import { COUNTRY_LIST } from '../../../../constants/countryList'

import Icons from '../../Icons'

const countries = COUNTRY_LIST.map((country) => ({
  label: country,
  value: country,
}))

const CountryField = ({
  name,
  placeholder,
  defaultCountryValue,
  value,
  label,
  validator,
  setIsCountry,
  readOnly,
  onCountrySelect,
}) => {
  console.log("readOnly", readOnly);
  const [dropdownDirection, setDropdownDirection] = useState('down')
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(defaultCountryValue || value || '')
  const { control } = useFormContext()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCountries, setFilteredCountries] = useState(countries)
  const dropdownContentRef = useRef(null)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    if (!readOnly) {
      setIsOpenDropdown((prevState) => !prevState)
    }
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenDropdown(false)
      setSearchTerm('')
    }
  }

  const handleEscPress = (event) => {
    if (event.key === 'Escape') {
      setIsOpenDropdown(false)
      setSearchTerm('')
    }
  }

  const handleCountrySelect = (country) => {
    setSelectedCountry(country)
    setIsOpenDropdown(false)
    setSearchTerm('')
    if (onCountrySelect) {
      onCountrySelect(country)
    }
  }

  useEffect(() => {
    if (value) {
      setSelectedCountry(value)
    }
  }, [value])

  useEffect(() => {
    if (isOpenDropdown) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - dropdownRect.bottom
      const spaceAbove = dropdownRect.top

      // Get an updated calculation after rendering for accurate dropdown height
      const calculatedHeight = dropdownContentRef.current ? dropdownContentRef.current.scrollHeight + 40 : 300

      if (spaceBelow < calculatedHeight && spaceAbove > spaceBelow) {
        setDropdownDirection('up')
      } else {
        setDropdownDirection('down')
      }
    }

    if (isOpenDropdown) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscPress)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscPress)
    }

    // Cleanup function to remove listeners
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [isOpenDropdown])

  useEffect(() => {
    if (setIsCountry) {
      setIsCountry(isOpenDropdown)
      if (isOpenDropdown) {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleEscPress)
      } else {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('keydown', handleEscPress)
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [isOpenDropdown, setIsCountry])

  useEffect(() => {
    setFilteredCountries(
      countries.filter((country) => country.label.toLowerCase().includes(searchTerm.toLowerCase())),
    )
  }, [searchTerm])

  return (
    <div className="relative w-full field__wrapper" ref={dropdownRef}>
      {label && <label htmlFor={name} className="mb-2 font-semibold">{label}</label>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultCountryValue || value || ''} // Tambahkan value
        rules={validator || {}}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className="relative w-full">
              <div
                className={`flex relative justify-between items-center gap-1 backface-visibility-hidden hover:opacity-90 p-4 rounded-xl w-full text-base leading-6 whitespace-nowrap transition-all duration-[0.2s] cursor-pointer overflow-hidden `}
                onClick={toggleDropdown}
              >
                {selectedCountry || placeholder}
                {!readOnly && (
                  <Icons
                    nameIcon="chevron-down"
                    className={`w-4 h-4 ${isOpenDropdown ? 'rotate-180' : ''}`}
                  />
                )}
              </div>

              <AnimatePresence>
                {isOpenDropdown && (
                <motion.div
                  ref={dropdownContentRef}
                  initial={{ opacity: 0, translateY: dropdownDirection === 'down' ? -16 : 16, visibility: 'hidden' }}
                  animate={{ opacity: 1, translateY: 0, visibility: 'visible' }}
                  exit={{ opacity: 0, translateY: dropdownDirection === 'down' ? -16 : 16, visibility: 'hidden' }}
                  transition={{ duration: 0.2 }}
                  className={`absolute ${dropdownDirection === 'up' ? 'bottom-full mb-0.5' : 'top-full mt-0.5'} z-[11] absolute inset-x-0 bg-black-dark shadow-[#ffffff33_0_0_0_2px_inset] mx-0 mt-0.5 mb-0 p-6 border-solid rounded-xl w-full overflow-hidden align-baseline `}
                >
                  <div className="border-0 m-0 mb-3 p-0 w-full align-baseline">
                    <div className="relative border-0 m-0 p-0 text-[#6F737E] align-baseline">
                      <Icons
                        nameIcon="search"
                        className="inline-flex top-2/4 left-3 z-[2] absolute justify-center items-center w-6 h-6 -translate-y-3 pointer-events-none align-middle"
                      />

                      <input
                        type="text"
                        className="bg-[initial] shadow-[#ffffff33_0_0_0_2px_inset] focus:shadow-[#ffffff66_0_0_0_2px_inset] !py-0 !pr-4 !pl-12 rounded-xl w-full !h-12 font-medium !text-sm text-white !leading-[1.71429] tracking-[-0.02em] transition-all duration-[0.2s] appearance-none outline-none placeholder-[#6F737E]"
                        placeholder="Type to search country..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                  <div className="border-0 -mx-6 my-0 px-6 py-0 max-h-40 overflow-y-auto align-baseline scroll-smooth scrollbar-custom">
                    <div className="w-full h-4" />
                    {filteredCountries.map((country) => (
                      <div
                        key={country.value}
                        className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 hover:opacity-80 m-0 py-2 p-0 font-semibold text-white cursor-pointer align-baseline"
                        onClick={() => {
                          if (!readOnly) {
                            field.onChange(country.value)
                            handleCountrySelect(country.label)
                          }
                        }}
                      >
                        {country.label}
                      </div>
                    ))}
                  </div>
                </motion.div>
                )}
              </AnimatePresence>
            </div>
            {error && (
            <span className="block px-4 pt-1 text-[13px] text-red-200">{error.message}</span>
            )}
          </>
        )}
      />
    </div>
  )
}

export default CountryField
