import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UseIsMax480 } from '../../../shared/MediaQueries'

import { COMPANY_ASSETS } from '../../../../constants/images'

const Header = () => {
  const { t } = useTranslation()
  const isMax480 = UseIsMax480()
  const location = useLocation()

  const linkClass = "pw:hover:text-primary pw:font-semibold pw:text-white pw:transition-all pw:duration-[0.2s]"

  // Check if current path is signup
  const isSignup = location.pathname === '/signup'

  return (
    <div className="pw:relative pw:z-5 pw:flex pw:items-center pw:max-767:px-4 pw:pr-6 pw:pl-6 pw:h-20 pw:md:h-24">
      <div className="inline-flex flex-col justify-start items-start gap-1 pw:mr-auto">
        <Link
          to="/"
          className="pw:flex pw:justify-center pw:items-center pw:mr-auto"
        >
          <img
            src={COMPANY_ASSETS.siteLogo}
            alt="investhub.io"
            className="pw:w-full pw:max-h-[2.625rem]"
          />
        </Link>

        {/* <span className='inline-flex relative items-center gap-1 bg-yellow-400/15 ml-2 px-4 py-1 rounded-md font-medium font-sans text-lg text-yellow-400 tracking-[-0.02em] transition-all duration-200'>Beta Version</span> */}
      </div>

      <div className="pw:relative pw:flex pw:flex-row pw:flex-wrap pw:justify-end pw:items-center pw:gap-2">
        <div className="pw:text-[13px] pw:text-gray-lighter">
          {
            isSignup ?
              <>
                {!isMax480 && t('Already have an account?') + ' '}
                <Link to="/login" className={linkClass}>{t('Sign in')}</Link>
              </>
            :
              <>
                {!isMax480 && t('Don\'t have an account?') + ' '}
                <Link to="/signup" className={linkClass}
                >{t('Sign Up')}</Link>
              </>
          }
        </div>
      </div>
    </div>
  )
}

export default Header
