export const DASHBOARD_NAV_ITEMS = [
  {
    path: "/dashboard",
    tooltipId: "dashboard",
    icon: "home-bookmark",
    label: "Dashboard",
  },
  {
    path: "/tokenlist",
    tooltipId: "tokens",
    icon: "coins",
    label: "Tokens",
  },
  {
    path: "/instant-investment",
    tooltipId: "investment",
    icon: 'pig-money',
    label: "Get Tokens",
  },
  {
    path: "/commission",
    tooltipId: "commission",
    icon: 'pig-money',
    label: "Commission",
    roles: ['REFERRAL_PARTNER']
  },
  {
    path: "/my-profile",
    tooltipId: "myprofile",
    icon: 'settings',
    label: "My Profile"
  }
  // {
  //   path: "/dashboard/settings",
  //   tooltipId: "settings",
  //   icon: "settings",
  //   label: "Settings",
  // },
  // {
  //   path: "/dashboard/chatpro",
  //   tooltipId: "chatpro",
  //   icon: "star-ring",
  //   label: "Invest AI",
  // },
]

export default DASHBOARD_NAV_ITEMS
