import React from 'react'
import WalletProviderButton from "./WalletProviderButton"

const AddWalletConnect = ({ onClose }) => {
  return (
    <div className="flex flex-row max-1023:flex-wrap flex-nowrap justify-center items-center gap-2">
      <WalletProviderButton icon="metamask" name="Metamask" onClick={onClose} />
      <WalletProviderButton icon="walletconnect" name="Wallet Connect" onClick={onClose} />
      <WalletProviderButton icon="coinbase" name="Coinbase" onClick={onClose} />
  </div>
  )
}

export default AddWalletConnect
