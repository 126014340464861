// src/pages/Signup/components/AccountType/index.jsx

import React, { useState, useEffect } from "react"
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Layout from "../Layout"
import { ModalWide } from "../../../../components/shared/Modals"
// Inner
import AccountTypeCard from "./AccountTypeCard"

const USER_ACCOUNT_TYPES = {
  ISSUER: 'ISSUER',
  INVESTOR: 'INVESTOR',
  REFERRAL_PARTNER: 'REFERRAL_PARTNER'
};

const USER_REFERRAL_MODELS = {
  BRING_USER: 'bring-user',
  CHARGE_USER_UPRONT: 'charge-user-upront',
};

const accountTypes = [
  {
    id: USER_ACCOUNT_TYPES.ISSUER,
    title: "Issuer",
    description: "Create and manage your securities, and monitor their issuance process.",
    features: [
      {
        title: "Streamline Issuance",
        description: "Simplify the process of creating and managing your securities."
      },
      {
        title: "Real-Time Monitoring",
        description: "Keep track of your issuance process with real-time updates."
      },
      {
        title: "Comprehensive Management",
        description: "Access detailed analytics and reporting for better decision-making."
      }
    ]
  },
  {
    id: USER_ACCOUNT_TYPES.INVESTOR,
    title: "Investor",
    description: "Manage your investments and track your portfolio's performance.",
    features: [
      {
        title: "Portfolio Management",
        description: "Track and manage your investment portfolio efficiently."
      },
      {
        title: "Investment Analytics",
        description: "Access detailed performance metrics and insights."
      },
      {
          title: "Market Monitoring",
          description: "Stay updated with real-time market data and trends."
      }
    ]
  },
  {
    id: USER_ACCOUNT_TYPES.REFERRAL_PARTNER,
    title: 'Referral Partner',
    description: "Manage your investments and track your portfolio's performance.",
    features: [
      {
        title: 'Streamline Issuance',
        description: 'Simplify the process of creating and managing your securities.'
      },
      {
        title: 'Real-Time Monitoring',
        description: 'Keep track of your issuance process with real-time updates.'
      },
      {
        title: 'Comprehensive Management',
        description: 'Access detailed analytics and reporting for better decision-making.'
      }
    ]
  }
]

const referralPartnerTypes = [
  {
    id: USER_REFERRAL_MODELS.BRING_USER,
    title: "Referral Partner brings user, if this user invests",
  },
  {
    id: USER_REFERRAL_MODELS.CHARGE_USER_UPRONT,
    title: "Referral Partner charges their user upfront to access the opportunity listed in Investhub.io / it's also optional to allow the subscription process in their own Referral Partner Frontend, so there is no SSO/Redirect required",
  },
]

const AccountType = ({ onNext, signupData, setSignupData }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectedType, setSelectedType] = useState(accountTypes[0].id)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedReferralType, setSelectedReferralType] = useState(referralPartnerTypes[0])

  useEffect(() => {
    if (isModalOpen) {
      setSelectedReferralType(referralPartnerTypes[0])
    }
  }, [isModalOpen])

  const handleTypeSelect = (id) => {
    setSelectedType(id)
    setSignupData({
      ...signupData,
      accountType: id
    })
    if (id === 'referral-partner') {
      setIsModalOpen(true)
    }
  }

  const handleReferralTypeSelect = (type) => {
    setSelectedReferralType(type)
    setSignupData({
      ...signupData,
      referralModel: type.id
    })
  }

  const handleModalConfirm = () => {
    setIsModalOpen(false)
  }

  const handleContinue = () => {
    if (selectedType === 'referral-partner') {
      history.push('/dashboard')
    } else {
      onNext(selectedType)
    }
  }

  const getDescriptionText = () => {
    switch (selectedType) {
      case USER_ACCOUNT_TYPES.ISSUER:
        return t("An issuer account is the best way to manage your personal securities efficiently.")
      case USER_ACCOUNT_TYPES.INVESTOR:
        return t("An investor account helps you manage and grow your investment portfolio effectively.")
      case USER_ACCOUNT_TYPES.REFERRAL_PARTNER:
        return t("A referral partner account enables you to grow your business by connecting investors with opportunities.")
      default:
        return ""
    }
  }

  return (
    <Layout
      title={t('Welcome to investhub')}
      currentStep={1}
      totalSteps={5}
      hideProgress
    >
      {/* Left Column - Account Type Selection */}
      <div className="pw:max-1023:w-full pw:w-[580px]">
        <div className="pw:m-0 pw:pb-4 pw:font-semibold pw:text-start pw:text-white pw:text-xl pw:leading-7">
          {t('Choose your account type')}
        </div>

        <div>
          {accountTypes.map((type) => (
            <AccountTypeCard
              key={type.id}
              {...type}
              isSelected={selectedType === type.id}
              onClick={() => handleTypeSelect(type.id)}
            />
          ))}
        </div>

        {selectedType === 'referral-partner' && selectedReferralType && !isModalOpen && (
          <div className="pw:mt-8">
              <div className="pw:m-0 pw:pb-2 pw:font-semibold pw:text-base pw:text-start pw:text-white pw:leading-6">
                  {t('You choosen for Referral Partner')}
              </div>
              <div className="pw:m-0 pw:text-[13px] pw:text-gray-custom pw:text-start pw:leading-5 pw:tracking-[-0.02em]">
                  {selectedReferralType.title}
              </div>
              <div className="pw:w-full pw:h-8" />
          </div>
        )}

        <div className="pw:mt-2">
          <button
            onClick={handleContinue}
            className="pw:inline-flex pw:justify-center pw:items-center pw:bg-primary pw:hover:bg-primary-hover pw:hover:opacity-90 pw:m-0 pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-[100%] pw:text-white pw:normal-case pw:leading-none pw:transition-colors pw:duration-[0.2s] pw:cursor-pointer pw:overflow-visible"
          >
            {t("Continue")}
          </button>
        </div>
      </div>

      {/* Right column - features */}
      <div className="pw:flex pw:flex-row pw:max-1023:flex-col pw:md:justify-between pw:max-1023:gap-10 pw:md:space-x-4">
        <div className="pw:md:flex-1 pw:mt-0 pw:max-1023:w-full pw:max-1023:max-w-full pw:max-w-[448px]">
          <div className="pw:m-0 pb-8 pw:font-normal pw:text-start pw:text-white pw:text-xl pw:leading-7 tracking-[-0.02em]">
            {getDescriptionText()}
          </div>

          {accountTypes.find(type => type.id === selectedType) &&
            accountTypes.find(type => type.id === selectedType).features.map((feature, index) => (
              <div key={index} className="pw:flex pw:flex-col pw:pb-8">
              <div className="pw:flex pw:flex-row pw:gap-x-4 pw:gap-y-4">
                <div className="pw:inline-flex pw:relative pw:justify-center pw:items-center pw:bg-black-light pw:mt-1 pw:rounded-[50%] pw:w-8 pw:h-8" />

                <div className="pw:flex pw:flex-col pw:flex-1 pw:pl-2">
                  <div className="pw:m-0 pw:pb-2 pw:font-semibold pw:text-base pw:text-start pw:text-white pw:leading-6 pw:tracking-[-0.02em]">
                    {t(feature.title)}
                  </div>
                  <div className="pw:flex-1 pw:m-0 pw:font-normal pw:text-gray-lighter pw:text-sm pw:text-start pw:leading-6 pw:tracking-[-0.02em]">
                    {t(feature.description)}
                  </div>
                </div>
              </div>
            </div>
            ))
          }

        </div>
      </div>

      <ModalWide
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={t('Choose Your Referral Partner Model')}
        subtitle={t('You have the option to choose one of the three referral models. Please select the one that suits your needs')}
      >
        <div className="pw:space-y-4 pw:mb-6">
          {referralPartnerTypes.map((referralType) => (
            <label
              key={referralType.id}
              className={`pw:flex pw:flex-row pw:justify-start pw:items-stretch pw:mb-4 pw:border-2 pw:bg-black-light pw:rounded-xl pw:min-h-[120px] pw:cursor-pointer pw:border-solid ${
                (selectedReferralType|| {}).id === referralType.id ? 'pw:border-primary' : 'pw:border-[#2b2d33]'
              }`}
              onClick={() => handleReferralTypeSelect(referralType)}
            >
              <input
                type="radio"
                name="referral-type"
                id={referralType.id}
                checked={(selectedReferralType || {}).id === referralType.id}
                onChange={() => handleReferralTypeSelect(referralType)}
                className="pw:absolute pw:border-0 pw:bg-[0_0] pw:bg-[initial] pw:opacity-0 pw:m-0 pw:p-0 pw:border-solid pw:rounded-none pw:font-[inherit] pw:text-inherit pw:leading-[inherit] pw:overflow-visible pw:align-middle"
              />
              <div className="pw:flex pw:flex-col pw:justify-center pw:px-8 pw:py-12">
                <div className="pw:m-0 pw:font-normal pw:text-sm pw:text-start pw:text-white pw:leading-5 pw:tracking-[-0.02em] pw:transition-[color] pw:duration-[0.02s] pw:ease-[ease-out]">
                  {referralType.title}
                </div>
              </div>
            </label>
          ))}
        </div>

        <button
          onClick={handleModalConfirm}
          className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary hover:pw:opacity-90 pw:m-0 pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-[100%] pw:text-white pw:normal-case pw:leading-none pw:transition-colors pw:duration-[0.2s] pw:cursor-pointer pw:overflow-visible"
        >
          {t('Confirm Selection')}
        </button>
      </ModalWide>
    </Layout>
  )
}

export default AccountType
