// src/pages/Signup/components/Security/index.jsx

import React, { useState } from "react"
import { useTranslation } from 'react-i18next'
import Layout from "../Layout"
import SecurityOptions from "./SecurityOptions"
import BackupCodes from "./BackupCodes"

import { SIGNUP_STEPS } from "../../constant/steps"

const SECURITY_STEPS = {
  OPTIONS: 'options',
  BACKUP: 'backup'
}

const Security = ({ onBack, onNext }) => {
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState(SECURITY_STEPS.OPTIONS)
  const [selectedOption, setSelectedOption] = useState(null)
  const [backupCodes, setBackupCodes] = useState([])
  const [isBackupConfirmed, setIsBackupConfirmed] = useState(false)

  const handleBackupConfirmed = (option) => {
    console.log('handleBackupConfirmed')
    setIsBackupConfirmed(true)
    onNext({
      securityMethod: option || selectedOption,
      backupCodes
    })
  }

  const handleOptionSelect = (option) => {
    console.log('handleOptionSelect', option)
    handleBackupConfirmed(option)
    setSelectedOption(option)
    if (!backupCodes.length) {
      const codes = Array.from({ length: 8 }, () =>
        Array.from({ length: 4 }, () =>
          Math.random().toString(36).substring(2, 6)
        ).join('-')
      )
      setBackupCodes(codes)
    }
    // setCurrentStep(SECURITY_STEPS.BACKUP)
  }

  const handleBack = () => {
    if (currentStep === SECURITY_STEPS.BACKUP) {
      setCurrentStep(SECURITY_STEPS.OPTIONS)
    } else {
      onBack()
    }
  }

  return (
    <Layout
      title={t("Keep your account secure")}
      description={t("Choose how you want to protect your account")}
      currentStep={4}
      totalSteps={5}
      onBack={handleBack}
      stepType={SIGNUP_STEPS.SECURITY}
    >
      {currentStep === SECURITY_STEPS.OPTIONS && (
        <SecurityOptions
          selectedOption={selectedOption}
          onOptionSelect={handleOptionSelect}
        />
      )}

      {currentStep === SECURITY_STEPS.BACKUP && (
        <BackupCodes
          codes={backupCodes}
          onGenerateNew={() => {
            const newCodes = Array.from({ length: 8 }, () =>
              Array.from({ length: 4 }, () =>
                Math.random().toString(36).substring(2, 6)
              ).join('-')
            )
            setBackupCodes(newCodes)
          }}
          onConfirm={handleBackupConfirmed}
          isConfirmed={isBackupConfirmed}
        />
      )}
    </Layout>
  )
}

export default Security
