// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/steps/Review/index.jsx

import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../common/Button'
import StepIndicator from '../../common/StepIndicator'
import Icons from '../../../../../../../shared/Icons'

const Review = ({
  onNext,
  onBack,
  formData,
  progress,
  currentStep,
  totalSteps,
}) => {
  const { t } = useTranslation()

  const reviewFields = [
    {
      key: 'welcome',
      label: 'Initial Purpose',
      getValue: (data) => {
        return data && data.purposeType
          ? t(`questionnaire.welcome.options.${data.purposeType}`)
          : '';
      }
    },
    {
      key: 'experience',
      label: 'Experience Type',
      getValue: (data) => data && data.experienceType && t(`questionnaire.experience.types.${data.experienceType}`)
    },
    {
      key: 'virtual-currency',
      label: 'Virtual Currency Usage',
      getValue: (data) => {
        return data && data.userVirtualCurrency
          ? t('common.yes')
          : t('common.no')
      }
    },
    {
      key: 'transactions-virtual',
      label: 'Virtual Currency Transactions',
      getValue: (data) => data && data.virtualCurrencyPercentage && t(`questionnaire.transactionsVirtual.options.${data.virtualCurrencyPercentage}`)
    },
    {
      key: 'investment-frequency',
      label: 'Investment Frequency',
      getValue: (data) => data && data.investmentFrequency && t(`questionnaire.investmentFrequency.options.${data.investmentFrequency}`)
    },
    {
      key: 'average-investment',
      label: 'Average Investment',
      getValue: (data) => data && data.averageInvestment && t(`questionnaire.averageInvestment.options.${data.averageInvestment}`)
    },
    {
      key: 'asset-experience',
      label: 'Asset Experience',
      getValue: (data) => data && data.assetExperience && t(`questionnaire.assetExperience.options.${data.assetExperience}`)
    },
    {
      key: 'selectedCategory',
      label: 'Selected Category',
      getValue: (data) => data && data.selectedCategory && t(`questionnaire.pricingTables.categories.${data.selectedCategory}.title`)
    }
  ]

  return (
    <>
      <div className="flex flex-col flex-1 justify-start items-start gap-4 px-4 py-6">
        <StepIndicator
          currentStep={currentStep}
          totalSteps={totalSteps}
          progress={progress}
        />

        <div className="flex-1 w-full">
          <button
            className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
            onClick={onBack}
          >
            <Icons nameIcon="arrow-left" className="w-3 h-3" />
            <span className="inline-block">
              {t('common.back')}
            </span>
          </button>
          <div className="block w-full h-6" />

          <div className="mb-4 w-full">
            <div className="mb-2 font-medium text-4xl text-ellipsis text-white leading-[1.1875] tracking-[-0.02em]">
              {t('questionnaire.review.title')}
            </div>
            <p className="border-0 mt-2 p-0 text-gray-lighter">
              {t('questionnaire.review.subtitle')}
            </p>
          </div>

          <div className="space-y-4">
            {reviewFields.map(({ key, label, getValue }) => {
              const value = getValue(formData[key])
              if (!value) return null

              return (
                <div
                  key={key}
                  className="bg-black-light p-4 rounded-xl"
                >
                  <div className="text-[#6F737E] text-sm">
                    {t(`questionnaire.review.fields.${key}`)}
                  </div>
                  <div className="mt-1 text-lg text-white">
                    {value}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="bottom bottom-0 sticky bg-black-dark px-4 py-6 w-full">
        <Button
          fullWidth
          size="md"
          onClick={onNext}
        >
          {t('questionnaire.review.confirm')}
        </Button>
      </div>
    </>
  )
}

export default Review
