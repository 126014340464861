import placeholderImage from "../assets/images/issuer/default-icon-day-v3.svg"

export const DASHBOARD_DATA_TOKEN = [
  {
    id: "token-1",
    name: "Enercom AG",
    symbol: "ENC",
    image: placeholderImage,
    price: "100 CHF",
    change: "+0.8",
    payouts: "5 CHF",
    holding: "1 ENC",
    partner: "Issuer",
    chart: null,
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Discover ENC, a dynamic blockchain initiative by Enercom AG, focusing on innovative energy solutions to power the future.",
  },
  {
    id: "token-2",
    name: "XDCTEQ AG",
    symbol: "TRADA",
    image: placeholderImage,
    price: "100 USD",
    change: "+1.03%",
    payouts: "25 CHF",
    holding: "1 TRADA",
    partner: "Issuer",
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Unlock the potential of advanced data solutions with XDC by XDCTEQ AG, revolutionizing the tech landscape.",
    chart: null,
  },
  {
    id: "token-3",
    name: "CALIDRIS FINTECH AG",
    symbol: "CLT",
    image: placeholderImage,
    price: "0,3 CHF",
    change: "+1.03%",
    payouts: "0 CHF",
    holding: "1 CLT",
    partner: "Issuer",
    chart: null,
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Explore CALI, a cutting-edge digital asset by CALIDRIS FINTECH AG, leading the charge in financial technology innovation.",
  },
  {
    id: "token-4",
    name: "ARBE TECHNOLOGIES",
    symbol: "ARBE",
    image: placeholderImage,
    price: "40 CHF",
    change: "-10.45%",
    payouts: "0 CHF",
    holding: "4 ARBE",
    partner: "SAXO BANK",
    chart: null,
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Power your future with ARBE, crafted by ARBE TECHNOLOGIES for seamless integration in autonomous systems.",
  },
  {
    id: "token-5",
    name: "bTSLA",
    symbol: "bTSLA",
    image: placeholderImage,
    price: "240 CHF",
    change: "-9.23%",
    payouts: "0 CHF",
    holding: "1 bTSLA",
    partner: "BACKED FINANCE",
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Discover bTSLA, bridging the gap between innovative tech investments and blockchain advancements.",
    chart: null,
  },
  {
    id: "token-6",
    name: "ALAN FREI SH 2",
    symbol: "AFS",
    image: placeholderImage,
    price: "1 CHF",
    change: "+1.03%",
    payouts: "2 CHF",
    holding: "0 AFS",
    partner: "Issuer",
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Elevate your investment strategy with ALAN FREI SH 2, where art meets blockchain technology.",
    chart: null,
  },
  {
    id: "token-7",
    name: "Curio AG",
    symbol: "CT1",
    image: placeholderImage,
    price: "1 CHF",
    change: "+0.34",
    payouts: "0 CHF",
    email: "info@equanimity.li",
    holding: "23789 CT1",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Elevate your investment strategy with CUR by Curio AG, where art meets blockchain technology.",
    partner: "Issuer",
    chart: null,
  },
  {
    id: "token-8",
    name: "AMM Bahamas",
    symbol: "IXS",
    image: placeholderImage,
    price: "1 CHF",
    change: "+5.17%",
    payouts: "0 CHF",
    holding: "1 IXS",
    email: "info@equanimity.li",
    partner: "Issuer",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Dive into AMB by AMM Bahamas, offering high-yield opportunities amidst the digital asset waves.",
    chart: null,
  },
  {
    id: "token-9",
    name: "TARUSX",
    symbol: "TAU",
    image: placeholderImage,
    price: "1 CHF",
    change: "+5.17%",
    payouts: "0 CHF",
    holding: "15.87 ENC",
    partner: "AMM Bahamas",
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Secure your wealth with TRE, a comprehensive financial asset solution, perfectly balanced and globally diversified.",
    chart: null,
  },
  {
    id: "token-10",
    name: "Gunther Uecker",
    symbol: "MW142",
    image: placeholderImage,
    price: "1 CHF",
    change: "+0.8",
    payouts: "1 CHF",
    holding: "1 MW142",
    partner: "Masterworks142LLC",
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Experience the fusion of art and financial assets with UECK, the token inspired by Günther Uecker's legacy.",
    chart: null,
  },
  {
    id: "token-11",
    name: "TREASURY",
    symbol: "TRSR-DRC-1",
    image: placeholderImage,
    price: "1 CHF",
    change: "-8.87%",
    payouts: "0 CHF",
    holding: "966 CHF",
    partner: "RENORA.io",
    email: "info@equanimity.li",
    address: "Herrengasse 30, 9490, Vaduz, Liechtenstein",
    text: "Secure your wealth with TRE, a comprehensive financial asset solution, perfectly balanced and globally diversified.",
    chart: null,
  },
]

export default DASHBOARD_DATA_TOKEN
