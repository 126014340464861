import React from 'react'
import { motion } from 'framer-motion'
import { TokenSkeleton, Token } from "../../../../../../shared/Cards"

const TokenList = ({ loading, balances, tokens, getImageUrl }) => (
  <div className="gap-4 grid grid-cols-1 w-full">
    {loading ? (
      Array(balances.length).fill(null).map((_, i) => <TokenSkeleton key={i} />)
    ) : (
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="gap-3 grid grid-cols-1 w-full">
        {balances.map((balance) => {
          const token = tokens.find(t => t.symbol === balance.symbol)
          return (
            <>
              <Token
                key={balance.symbol}
                imgSrc={getImageUrl('tokenSymbol', (token || {}).image)}
                networkIcon={getImageUrl('tokenSymbol', (balance || {}).network.image)}
                tokenName={balance.symbol}
                tokenSymbol={balance.symbol}
                balance={balance.balance}
              />
            </>
          )
        })}
      </motion.div>
    )}
  </div>
)

export default TokenList
