// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/steps/MifidRiskAssessment/index.jsx

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../common/Button'
import StepIndicator from '../../common/StepIndicator'
import Icons from '../../../../../../../shared/Icons'

const MifidRiskAssessment = ({
  onNext,
  onBack,
  progress,
  currentStep,
  totalSteps,
}) => {
  const { t } = useTranslation()
  const [formData, setFormData] = useState({
    investmentLoss: '',
    investmentDuration: '',
    investmentPercentage: ''
  })

  const [isFocused, setIsFocused] = useState({
    investmentLoss: false,
    investmentDuration: false,
    investmentPercentage: false
  })

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleFocus = (field) => {
    setIsFocused(prev => ({
      ...prev,
      [field]: true
    }))
  }

  const handleBlur = (field) => {
    setIsFocused(prev => ({
      ...prev,
      [field]: false
    }))
  }

  const isFormValid = () => {
    return Object.values(formData).every(value => value.trim() !== '')
  }

  const fields = [
    {
      id: 'investmentLoss',
      label: 'What would you do if your investment lost 20% in one month?',
      placeholder: 'Describe your reaction'
    },
    {
      id: 'investmentDuration',
      label: 'How long do you plan to hold your investments?',
      placeholder: 'Enter your investment timeframe'
    },
    {
      id: 'investmentPercentage',
      label: 'What percentage of your savings are you planning to invest?',
      placeholder: 'Enter percentage'
    }
  ]

  return (
    <>
      <div className="flex flex-col flex-1 justify-start items-start gap-4 px-4 py-6">
        <StepIndicator
          currentStep={currentStep}
          totalSteps={totalSteps}
          progress={progress}
        />

        <div className="flex-1 w-full">
          <button
            className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
            onClick={onBack}
          >
            <Icons nameIcon="arrow-left" className="w-3 h-3" />
            <span className="inline-block">
              {t('common.back')}
            </span>
          </button>
          <div className="block w-full h-6" />

          <div className="mb-4 w-full">
            <div className="mb-2 font-medium text-4xl text-ellipsis text-white leading-[1.1875] tracking-[-0.02em]">
              {t('questionnaire.mifidRisk.title')}
            </div>
            <p className="border-0 mt-2 p-0 text-gray-lighter">
              {t('questionnaire.mifidRisk.subtitle')}
            </p>
          </div>

          {fields.map((field) => (
            <div
              key={field.id}
              className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none mb-4 ${
                isFocused[field.id] ? 'before:border-primary' : 'before:border-transparent'
              }`}
            >
              <div className="text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
                {t(`questionnaire.mifidRisk.fields.${field.id}`)}
              </div>

              <div className="flex flex-row flex-wrap justify-between items-center mt-2">
                <div className="flex flex-col flex-wrap flex-1 justify-start items-start">
                  <input
                    type="text"
                    value={formData[field.id]}
                    onChange={(e) => handleInputChange(field.id, e.target.value)}
                    className="relative flex-auto bg-transparent opacity-100 !p-0 border-none w-0 max-h-11 text-4xl text-ellipsis text-left text-white placeholder:text-[#6F737E] transition-opacity duration-[250ms] overflow-hidden pointer-events-auto ease-[ease-in-out] outline-none"
                    placeholder={t(`questionnaire.mifidRisk.placeholders.${field.id}`)}
                    onFocus={() => handleFocus(field.id)}
                    onBlur={() => handleBlur(field.id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bottom bottom-0 sticky bg-black-dark px-4 py-6 w-full">
        <Button
          fullWidth
          size="md"
          onClick={() => onNext(formData)}
          disabled={!isFormValid()}
        >
          {t('common.continue')}
        </Button>
      </div>
    </>
  )
}

export default MifidRiskAssessment
