import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
// import { useHistory } from "react-router-dom"

import { DUMMY_COINBASE } from "../../../../constants/images"

const DummyConnectCoinBase = ({ isOpenModal, toggleModal }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const history = useHistory()

  const handleClick = () => {
    if (currentIndex < DUMMY_COINBASE.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      toggleModal();
      // history("/instant-investment");
    }
  };

  return (
    <>
      <AnimatePresence>
        {isOpenModal && (
          <motion.div
            className="pw:z-50 pw:fixed pw:inset-0 pw:flex pw:max-767:p-0 pw:p-6 pw:overflow-auto pw:modal pw:scroll-smooth"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="overlay"
          >

            <motion.div
              className="pw:relative pw:z-10 pw:bg-black-dark pw:shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] pw:m-auto pw:rounded-3xl pw:w-full pw:max-w-[380px] pw:overflow-hidden modal__wrapper"
              initial={{ opacity: 0, translateY: 16 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: 16 }}
              transition={{ duration: 0.3 }}
            >
              {DUMMY_COINBASE.map((image, index) => (
                index === currentIndex && (
                  <motion.button
                    key={image.alt}
                    className="pw:bg-transparent pw:border-none pw:rounded-2xl pw:w-full pw:h-full pw:overflow-hidden pw:outline-none"
                    initial={{ opacity: 0, y: 16 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 16 }}
                    transition={{ duration: 0.3 }}
                    onClick={handleClick}
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="pw:w-full pw:h-full pw:object-cover"
                    />
                  </motion.button>
                )
              ))}
            </motion.div>

          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default DummyConnectCoinBase
