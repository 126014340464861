// src/pages/SignIn/components/Steps/TwoFactor.jsx
import React, { useState, useRef, useEffect } from "react";
import VerificationInput from "react-verification-input";
import { motion } from "framer-motion";
import Loader from "../../../../components/shared/Loader";
import GetHelpModal from "./GetHelpModal";
import ShortToken from "../../../../components/shared/ShortToken";

const TwoFactor = ({ onContinue, email, walletAddress, signInMethod }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  useEffect(() => {
    // Auto focus first input on mount
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleSubmit = async (fullCode) => {
    if (isLoading) return;

    fullCode = fullCode || code.join("");
    if (fullCode.length !== 6) {
      setError("Please enter a complete 6-digit code");
      return;
    }

    setIsLoading(true);
    setError("");

    try {
      console.log("fullCode", fullCode);

      await onContinue({ twoFaToken: fullCode });
    } catch (err) {
      setError("Invalid verification code. Please try again.");
      // Clear the code fields on error
      setCode(["", "", "", "", "", ""]);
      inputRefs.current[0].focus();
    } finally {
      setIsLoading(false);
    }
  };

  const handleComplete = (code) => {
    handleSubmit(code);
  };

  const renderAuthMethod = () => {
    if (signInMethod && signInMethod.type === 'wallet') {
      return (
        <div className="mb-6 text-center text-gray-lighter text-sm">
          Enter the 6-digit code from your authenticator app for wallet:{" "}
          <span className="font-semibold text-white"><ShortToken original={walletAddress} /></span>
        </div>
      );
    }

    return (
      <div className="mb-6 text-center text-gray-lighter text-sm">
        Enter the 6-digit code from your authenticator app for{" "}
        <span className="font-semibold text-white">{email}</span>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center">
      {renderAuthMethod()}

      <VerificationInput
        length={6}
        placeholder=""
        validChars="0-9"
        autoFocus
        onComplete={handleComplete}
        classNames={{
          container: "verification-container gap-2 grid grid-cols-6 mb-6 w-full h-14",
          character: "border-[#2b2d33] border-2 border-solid focus:border-white/20 bg-black-light rounded-xl w-full h-12 text-center text-white text-base inline-flex justify-center items-center",
          characterInactive: "character--inactive",
          characterSelected: "character--selected border-white/20",
          characterFilled: "character--filled border-white/20"
        }}
      />

      {/* Error Message */}
      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-4 text-red-500 text-sm"
        >
          {error}
        </motion.div>
      )}

      {/* Submit Button */}
      <button
        onClick={() => handleSubmit()}
        disabled={isLoading || code.some((digit) => !digit)}
        className={`
                    inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 !rounded-2xl w-full !h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible relative
                    ${
                      isLoading || code.some((digit) => !digit)
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }
                `}
      >
        {isLoading ? <Loader className="relative" /> : "Verify"}
      </button>

      {/* Help Text */}
      <div className="mt-4 text-[13px] text-center text-gray-lighter">
        Having trouble?{" "}
        <span
          onClick={() => setIsHelpModalOpen(true)}
          className="hover:opacity-80 font-semibold text-white transition-opacity duration-200 cursor-pointer"
        >
          Get help
        </span>
      </div>

      <GetHelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        email={email}
      />
    </div>
  );
};

export default TwoFactor;
