// src/components/layouts/Wrapper/Dashboard/Sidebar/index.jsx

import React from 'react'
import { Link } from "react-router-dom"

// Shared
import InvesthubLogo from '../../../shared/InvesthubLogo'
import Icons from '../../../shared/Icons'
// Inner
import Navigation from "./Navigation"

const Sidebar = ({ isCollapsed, toggleSidebar }) => {
  const sidebarWidthClass = isCollapsed ? 'w-24' : 'w-[21.25rem] max-480:w-full'

  return (
    <>
      <div className={`top-0 bottom-0 left-0 z-[51] fixed flex flex-col bg-black-light ${sidebarWidthClass}`}>
        <div className="relative flex flex-col flex-1 px-4 pt-32 max-767:pt-24">
          <div className={`top-0 right-0 left-0 absolute flex items-center pr-6 pl-7 h-32 max-767:h-24 ${isCollapsed ? 'justify-center' : 'justify-between'}`}>
            {!isCollapsed && (
              <div className='inline-flex flex-col justify-start items-start gap-1 pw:mr-auto'>
                <Link to="/tokenlist" className="flex w-full max-w-[200px] max-480:max-w-[180px] max-h-48px">
                  <InvesthubLogo className="relative -ml-3 w-full h-full" />
                </Link>

                {/* <span className='inline-flex relative items-center gap-1 bg-yellow-400/15 ml-2 px-4 py-1 rounded-md font-medium font-sans text-lg text-yellow-400 tracking-[-0.02em] transition-all duration-200'>Beta Version</span> */}
              </div>
            )}

            <button
              type="button"
              className="tap-highlight-color group outline-none"
              onClick={toggleSidebar}
            >
              <Icons
                nameIcon={isCollapsed ? "sidebar-expand" : "sidebar-collapse"}
                className="group-hover:text-white w-6 h-6 text-white/70 transition-all duration-[0.2s]"
              />
            </button>
          </div>

          <div className="flex flex-col md:pb-6 overflow-auto grow scroll-smooth scrollbar-none">
            <Navigation isCollapsed={isCollapsed} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
