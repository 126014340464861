import React from 'react'

const Card = ({ className, children }) => {
  return (
    <div className={`bg-black-light rounded-2xl mx-10 max-767:mx-4 max-767:mt-6 p-6 max-767:px-4 ${className || ''}`}>
      {children}
    </div>
  )
}

export default Card
