// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/steps/PricingTables/index.jsx
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../common/Button'
import StepIndicator from '../../common/StepIndicator'
import Icons from '../../../../../../../shared/Icons'

const PricingTables = ({
  onNext,
  onBack,
  progress,
  currentStep,
  totalSteps,
}) => {
  const { t } = useTranslation()

  const investorCategories = [
    {
      id: 'sophisticated',
      title: 'Self-certified sophisticated investor',
      subtitle: 'In the last two years you\'ve done ONE of the following:',
      items: [
        'Worked in private equity or in the provision of finance for small and medium enterprises',
        'Been the director of a company with an annual turnover of at least £1 million',
        'Made two or more investments in an unlisted company',
        'Been a member of a network or syndicate of business angels for more than six months'
      ]
    },
    {
      id: 'high-net-worth',
      title: 'High Net Worth Investor',
      subtitle: 'You need to meet ONE of the following criteria:',
      items: [
        'Annual income of £100,000 or more',
        'Net assets of £250,000 or more (excluding primary residence, pension and insurance)',
        'Significant investment experience in unlisted companies',
        'Professional investment qualification'
      ]
    },
    {
      id: 'restricted',
      title: 'Restricted Investor',
      subtitle: 'You agree to the following:',
      items: [
        'Not invest more than 10% of net assets in non-readily realisable securities',
        'Understand risks associated with investment losses',
        'Acknowledge no access to Financial Services Compensation Scheme',
        'Confirm independent investment decisions'
      ]
    }
  ]

  const handleCategorySelect = (categoryId) => {
    onNext({ selectedCategory: categoryId })
  }

  return (
    <>
      <div className="flex flex-col flex-1 justify-start items-start gap-4 px-4 py-6">
        <StepIndicator
          currentStep={currentStep}
          totalSteps={totalSteps}
          progress={progress}
        />

        <div className="flex-1 w-full">
          <button
            className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
            onClick={onBack}
          >
            <Icons nameIcon="arrow-left" className="w-3 h-3" />
            <span className="inline-block">
              {t('common.back')}
            </span>
          </button>
          <div className="block w-full h-6" />

          <div className="mb-8 w-full">
            <div className="mb-2 font-medium text-4xl text-ellipsis text-white leading-[1.1875] tracking-[-0.02em]">
              {t('questionnaire.pricingTables.title')}
            </div>
            <p className="border-0 mt-2 p-0 text-gray-lighter">
              {t('questionnaire.pricingTables.subtitle')}
            </p>
          </div>

          <div className="gap-6 grid grid-cols-1 md:grid-cols-3">
            {investorCategories.map((category) => (
              <div
                key={category.id}
                className="flex flex-col border-2 hover:border-primary bg-black-light p-6 border-transparent rounded-2xl transition-all duration-200"
              >
                <h3 className="mb-2 font-semibold text-white text-xl">
                  {t(`questionnaire.pricingTables.categories.${category.id}.title`)}
                </h3>
                <p className="mb-4 text-gray-lighter text-sm">
                  {t(`questionnaire.pricingTables.categories.${category.id}.subtitle`)}
                </p>
                <ul className="flex-1 space-y-3 mb-6">
                  {category.items.map((item, index) => (
                    <li key={index} className="flex items-start gap-2 text-sm text-white">
                      <span className="inline-block mt-1.5">
                        <Icons nameIcon="check" className="w-3 h-3 text-primary" />
                      </span>
                      <span>{t(`questionnaire.pricingTables.categories.${category.id}.items.${index}`)}</span>
                    </li>
                  ))}
                </ul>
                <Button
                  fullWidth
                  variant="secondary"
                  onClick={() => handleCategorySelect(category.id)}
                >
                  {t('questionnaire.pricingTables.fitDescription')}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PricingTables
