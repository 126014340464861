// src/App.jsx
import React from 'react'
import PropTypes from 'prop-types'
import { Router } from 'react-router-dom'
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts'
import { ApolloProvider } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dictionary from './lang'
import { getLocale } from './utils/lang'
import Root from './Root'
import history from './historyStore'
// import ScrollToTop from './components/ScrollToTop'
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5'
// eslint-disable-next-line
import { createAppKit } from '@reown/appkit/react'
// eslint-disable-next-line
import { mainnet, arbitrum, sepolia, base, baseSepolia } from '@reown/appkit/networks'
import { LanguageProvider } from './context/LanguageContext'
import { isProduction } from './utils'

window.Buffer = require('buffer/').Buffer;

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: dictionary.en,
      },
      de: {
        translation: dictionary.de,
      },
      it: {
        translation: dictionary.it,
      },
    },
    lng: getLocale(),
    fallbackLng: 'en',
  })

// 1. Get projectId
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID
const APP_LOGO_URL = 'https://app.investhub.io/dark-mode-investhub-logo.svg'

// 2. Create a metadata object - optional
const metadata = {
  name: 'Investhub',
  description: 'We are a legal and compliant capital allocation engine. Join a Community of future Web3 Investors and Entrepreneurs',
  url: 'https://app.investhub.io/', // origin must match your domain & subdomain
  icons: [APP_LOGO_URL]
}

createAppKit({
  adapters: [new Ethers5Adapter()],
  metadata: metadata,
  networks: [mainnet, arbitrum, sepolia, base, baseSepolia],
  defaultNetwork: isProduction ? base : baseSepolia,
  projectId,
  features: {
    analytics: true, // Optional - defaults to your Cloud configuration
    email: false, // default to true
    socials: [],
    emailShowWallets: false, // default to true
  }
})

const App = ({ apolloClient }) => (
  <LanguageProvider>
    <CookiesProvider>
      <Router history={history}>
        <ApolloProvider client={apolloClient}>
          {/* <ScrollToTop /> */}
          <Root />
        </ApolloProvider>
      </Router>
      <ToastsContainer position={ToastsContainerPosition.TOP_RIGHT} store={ToastsStore} />
    </CookiesProvider>
  </LanguageProvider>
)

App.propTypes = {
  apolloClient: PropTypes.object.isRequired,
}

export default App
