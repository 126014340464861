import Web3 from 'web3'
import { isProduction } from '../utils'

export default {
  /**
   * Check if Metamask is not installed
   *
   * @return {Boolean}
   */
  isMetamaskNotAvailable() {
    return !window.ethereum
  },

  /**
   * Check if Metamask is installed
   *
   * @return {Boolean}
   */
  isMetamaskAvailable() {
    return !this.isMetamaskNotAvailable()
  },

  /**
   * Check and throw exception if Metamask has not been installed
   *
   * @return {void}
   * @throw {Exception}
   */
  throwIfMetamaskNotAvailable() {
    if (this.isMetamaskNotAvailable()) {
      throw new Error('Metamask not installed')
    }
  },

  /**
   * Get all connected wallet addresses
   *
   * @return {Promise{Array{String}}}
   */
  getAccounts() {
    this.throwIfMetamaskNotAvailable()

    return window.ethereum.request({
      method: 'eth_accounts',
    })
  },

  /**
   * Request to connect wallet with app
   *
   * @return {Promise{Array{String}}}
   */
  requestAccounts() {
    this.throwIfMetamaskNotAvailable()

    return window.ethereum.request({
      method: 'eth_requestAccounts',
    })
  },

  /**
   * Get the address that being selected
   *
   * @return {String}
   */
  getCurrentAddress() {
    this.throwIfMetamaskNotAvailable()

    return window.ethereum.selectedAddress
  },

  /**
   * Create hash for message
   *
   * @param {String} message The message to sign
   *
   * @return {String}
   */
  hashMessage(message) {
    const web3 = new Web3()

    return web3.utils.sha3(message)
  },

  /**
   * Request to sign the message
   *
   * @param {String} address User wallet address
   * @param {String} message The message to sign
   *
   * @return {String}
   */
  async signMessage(address, message) {
    this.throwIfMetamaskNotAvailable()

    return window.ethereum.request({
      method: 'personal_sign',
      params: [message, address],
    })
  },

  /**
   * Request to sign the message
   *
   * @param {String} signaturethe signature to extract address from
   * @param {String} message The raw message that has been signed
   *
   * @return {String}
   */
  getSigner(signature, message) {
    const web3 = new Web3(window.ethereum)

    return web3.eth.personal.ecRecover(message, signature)
  },

  /**
   * Add handle function for account changing event
   *
   * @param {Function} callback
   *
   * @return {void}
   */
  addOnAccountChangeHandle(callback) {
    window.ethereum.on('accountsChanged', callback)
  },

  /**
   * Get wallet in right case
   *
   * @param {String} address The address in lowercase
   *
   * @return {String} the address  in correct case
   */
  getChecksumAddress(address) {
    const web3 = new Web3(window.ethereum)

    return web3.utils.toChecksumAddress(address)
  },

  /**
   * Remove listener for accountsChanged event
   *
   * @param {Function} callback
   *
   * @return {void}
   */
  removeOnAccountChangeHandle(callback) {
    window.ethereum.removeListener('accountsChanged', callback)
  },

  /**
   * Get gasPrice
   */
  getGasPrice() {
    const web3 = new Web3(window.ethereum)

    return web3.eth.getGasPrice()
  },

  /**
   * Get gasLimit
   */
  getGasLimit() {
    return '0x5208'
  },

  getChainIdByName(chainName) {
    const chainMap = {
      mainnet: '0x1',
      ropsten: '0x3',
      sepolia: '0xaa36a7',
      goerli: '0x5',
      kovan: '0x2a',
      polygon: '0x89',
      amoy: '0x13882',
      mumbai: '0x13881',
      xinfin: '0x32',
      apothem: '0x33',
      base_sepolia: '0x14a34',
      base: '0x2105'
    }

    return chainMap[chainName]
  },

  /**
   * request to switch network
   *
   * @param {String} network [mainnet|goerli|kovan|sepolia|ropsten]
   */
  async switchNetwork(network) {
    this.throwIfMetamaskNotAvailable()

    const chainId = this.getChainIdByName(network)

    if (!chainId) {
      throw new Error(`Network '${network}' is not supported`)
    }

    // eslint-disable-next-line no-return-await
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }], // chainId must be in hexadecimal numbers
    })

    return true
  },

  /**
   * Get current chainId
   *
   * @return {Promise{String}}
   */
  getCurrentChainId() {
    return window.ethereum.request({ method: 'eth_chainId' })
  },

  /**
   * Check current network and request to change network if it not matched the required one
   *
   * @param {String} network [mainnet|goerli|kovan|sepolia|ropsten]
   */
  async requireNetwork(network, onChange) {
    const currentChainId = await this.getCurrentChainId()
    const requireChainId = this.getChainIdByName(network)

    if (requireChainId !== currentChainId) {
      await this.switchNetwork(network)

      if (onChange && typeof onChange === 'function') {
        onChange()
      }
    }
  },

  /**
   * Get contract decimal
   *
   * @param address Contract address
   */
  getContractDecimal(address) {
    this.throwIfMetamaskNotAvailable()

    const web3 = new Web3(window.ethereum)
    const contract = new web3.eth.Contract(
      [
        {
          inputs: [],
          name: 'decimals',
          outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      address,
    )

    return contract.methods.decimals().call()
  },

  /**
   * Get contract Token symbol
   *
   * @param address Contract address
   */
  getTokenSymbol(address) {
    this.throwIfMetamaskNotAvailable()

    const web3 = new Web3(window.ethereum)
    const contract = new web3.eth.Contract(
      [
        {
          inputs: [],
          name: 'symbol',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
      ],
      address,
    )

    return contract.methods.symbol().call()
  },

  /**
   * Get token contract address of crowdsale addres
   *
   * @param address Crowdsale contract address
   */
  getTokenAddressOfCrowldsaleContract(address) {
    this.throwIfMetamaskNotAvailable()

    const web3 = new Web3(window.ethereum)
    const contract = new web3.eth.Contract(
      [
        {
          constant: true,
          inputs: [],
          name: 'token',
          outputs: [{ name: '', type: 'address' }],
          payable: false,
          stateMutability: 'view',
          type: 'function',
        },
      ],
      address,
    )

    return contract.methods.token().call()
  },

  /**
   * Check if an address is whitelisted by contract
   *
   * @param {String} contractAddress
   * @param {String} contractAddress
   *
   * @return {Promise{boolean}}
   */
  async isAddressWhitelisted(contractAddress, address) {
    this.throwIfMetamaskNotAvailable()

    const web3 = new Web3(window.ethereum)
    const contract = new web3.eth.Contract(
      [
        {
          constant: true,
          inputs: [
            {
              name: 'user',
              type: 'address',
            },
          ],
          name: 'isWhitelisted',
          outputs: [
            {
              name: '',
              type: 'bool',
            },
          ],
          payable: false,
          stateMutability: 'view',
          type: 'function',
        },
      ],
      contractAddress,
    )

    const result = await contract.methods.isWhitelisted(address).call()
    return result
  },

  /**
   * Get balance of a wallet
   *
   * @param {String} address Wallet address
   *
   * @return {Promise{Int}} Wallet balance in wei
   */
  async getBalance(address) {
    const web3 = new Web3(window.ethereum)

    return +(await web3.eth.getBalance(address))
  },

  /**
   * Require switch network to match smart contract network
   * @param {String} smartContractNetwork The network config of smart contract, must be [mainnet|polygon]
   *
   * @return {Promise}
   */
  async requireNetworkForSmartContract(smartContractNetwork) {
    switch (smartContractNetwork) {
      case 'mainnet':
        await this.requireNetwork(isProduction ? 'mainnet' : 'sepolia')

        break

      case 'polygon':
        await this.requireNetwork(isProduction ? 'polygon' : 'mumbai')

        break

      default:
        throw new Error('Network not supported')
    }
  },

  formatAddress(address) {
    if (address.substr(0, 3) === 'xdc') {
      return `0x${address.substr(3)}`
    }

    return address
  },
}
