import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWide } from '../../../../components/shared/Modals';
import TextField from './TextField';
import NetworkDropdown from './NetworkDropdown';

const NewModal = ({ isOpen, onClose, onSave }) => {
  const { t } = useTranslation();

  return (
    <ModalWide
      isOpen={isOpen}
      onClose={onClose}
      title={t('settings.spending_cap.modal.new')}
      subtitle={t('settings.spending_cap.modal.new_description')}
    >
      <div className="gap-6 grid grid-cols-1 w-full">
        <TextField
          label={t('settings.spending_cap.fields.token')}
          wrapperClass="w-full"
          toolTipText={t('settings.spending_cap.fields.token_tooltip')}
        />

        <NetworkDropdown
          label={t('settings.spending_cap.fields.network')}
          toolTipText={t('settings.spending_cap.fields.network_tooltip')}
        />

        <TextField
          label={t('settings.spending_cap.fields.limit')}
          wrapperClass="w-full"
          toolTipText={t('settings.spending_cap.fields.limit_tooltip')}
        />

        <div className="flex justify-end gap-4">
          <button
            onClick={onSave}
            className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover px-6 rounded-3xl h-10 text-white"
          >
            {t('Create')}
          </button>
          <button
            onClick={onClose}
            className="inline-flex justify-center items-center bg-black-light hover:bg-gray-700 px-6 rounded-3xl h-10 text-white"
          >
            {t('Cancel')}
          </button>
        </div>
      </div>
    </ModalWide>
  );
};

export default NewModal;
