// src/pages/Signup/components/Authenticator/AuthenticatorSetup.jsx

import React, { useState, useEffect, useCallback } from "react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import qrcode from 'qrcode'
import copy from "copy-to-clipboard"
import { useMe } from '../../../../myHooks'
import { NEW_2FA_SECRET, SET_2FA_SECRET } from '../../../../queriesAndMutations'
import { isIOS, isAndroid, getAuthenticatorStoreLink } from "../../../../utils/deviceDetection"
import Layout from "../Layout"
import Icons from "../../../../components/shared/Icons"
import { SIGNUP_STEPS } from "../../constant/steps"
import Loader from '../../../../components/shared/Loader'

const QRCode = ({ url }) => (
  <div className="pw:bg-white/30 pw:mx-auto pw:mb-4 pw:rounded-lg pw:w-48 pw:h-48">
    {url && <img src={url} alt="QR Code" className="w-full h-full" />}
  </div>
)

const AuthenticatorSetup = ({ onBack, onNext }) => {
  const { t } = useTranslation()
  const [twoFaSecretUrl, setTwoFaSecretUrl] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [isGenerating, setIsGenerating] = useState(true)

  const { data: { me } = {} } = useMe()
  const { data: { new2FaSecret } = {}, loading: secretLoading } = useQuery(NEW_2FA_SECRET)

  const storeLink = getAuthenticatorStoreLink()

  const secretToUrl = useCallback(
    (secret) => `otpauth://totp/${encodeURIComponent('InvestHub')}:${encodeURIComponent(me.email || me.wallet_address || me.singpass_uinfin)}?secret=${secret}`,
    [me],
  )

  useEffect(() => {
    if (new2FaSecret && me) {
      setIsGenerating(true)
      qrcode.toDataURL(secretToUrl(new2FaSecret))
        .then((qrCode) => {
          setTwoFaSecretUrl(qrCode)
          setIsGenerating(false)
        })
        .catch(error => {
          console.error('Failed to generate QR code:', error)
          setIsGenerating(false)
        })
    }
  }, [new2FaSecret, secretToUrl, me])

  const handleCopyKey = () => {
    if (!new2FaSecret) return

    try {
      copy(new2FaSecret)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 2000)
    } catch (err) {
      console.error('Failed to copy:', err)
    }
  }

  if (secretLoading || isGenerating) {
    return (
      <Layout
        title={t("Activating Google Auth")}
        description={t("Please wait while we set up your authenticator")}
        currentStep={3}
        totalSteps={5}
        onBack={onBack}
        stepType={SIGNUP_STEPS.AUTHENTICATOR}
      >
        <div className="pw:flex pw:justify-center pw:items-center pw:mx-auto pw:w-full pw:max-w-[480px] pw:h-64">
          <Loader className="pw:w-12 pw:h-12" />
        </div>
      </Layout>
    )
  }

  return (
    <Layout
      title={t("Activate Google Auth")}
      description={t("Scan QR Code or Copy Key")}
      currentStep={3}
      totalSteps={5}
      onBack={onBack}
      stepType={SIGNUP_STEPS.AUTHENTICATOR}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="pw:mb-8 pw:text-center"
        >
          <QRCode url={twoFaSecretUrl} />
          <span className="pw:text-gray-lighter pw:text-sm">{t("QR Code")}</span>
        </motion.div>

        <div className="pw:bg-black-light pw:mb-8 pw:p-6 pw:rounded-xl">
          <div className="md:pw:hidden pw:mb-4">
            <a
              href={storeLink}
              target={isIOS() ? "_blank" : "_self"}
              rel="noreferrer"
              className="pw:block pw:bg-black-dark pw:px-4 pw:py-3 pw:rounded-lg pw:text-center pw:text-white"
            >
              {t("Open/Install Google Authenticator")}
            </a>
          </div>
          <p className="pw:mb-4 pw:text-gray-lighter pw:text-sm">
            {t("Can't scan? Use this key")}
          </p>
          <div className="pw:relative">
            <input
              type="text"
              value={new2FaSecret || ''}
              readOnly
              className="pw:bg-black-dark pw:px-4 pw:py-3 pw:pr-12 pw:rounded-lg pw:w-full pw:text-white"
            />
            <button
              onClick={handleCopyKey}
              className="pw:top-1/2 pw:right-3 pw:absolute pw:-translate-y-1/2"
            >
              <Icons
                nameIcon={isCopied ? "check" : "copy"}
                className={`pw:w-5 pw:h-5 ${isCopied ? 'pw:text-green-500' : 'pw:text-gray-lighter'}`}
              />
            </button>
          </div>
          <p className="pw:mt-4 pw:text-gray-lighter pw:text-sm">
            {t("Please save this key on paper. This key will allow you to recover Google authentication if your phone is lost.")}
          </p>
        </div>

        <button
          onClick={() => onNext({ secretKey: new2FaSecret })}
          disabled={!new2FaSecret}
          className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover disabled:pw:bg-gray-700 pw:bg-primary pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200 disabled:pw:cursor-not-allowed"
        >
          {t("Continue")}
        </button>
      </div>
    </Layout>
  )
}

export default AuthenticatorSetup
