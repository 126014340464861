// src/pages/Signup/components/Authenticator/index.jsx

import React, { useState } from "react"
import AuthenticatorApp from "./AuthenticatorApp"
import AuthenticatorSetup from "./AuthenticatorSetup"
import AuthenticatorVerify from './AuthenticatorVerify'
import AuthenticatorSuccess from "./AuthenticatorSuccess"

const AUTHENTICATOR_STEPS = {
  CHOOSE_APP: 'choose-app',
  SETUP: 'setup',
  VERIFY: 'verify',
  SUCCESS: 'success'
}

const Authenticator = ({ onBack, onNext }) => {
  const [currentStep, setCurrentStep] = useState(AUTHENTICATOR_STEPS.CHOOSE_APP)
  const [secretKey, setSecretKey] = useState(null)

  const handleStepComplete = (step, data = {}) => {
    switch (step) {
      case AUTHENTICATOR_STEPS.CHOOSE_APP:
        setCurrentStep(AUTHENTICATOR_STEPS.SETUP)
        break
      case AUTHENTICATOR_STEPS.SETUP:
        setSecretKey(data.secretKey)
        setCurrentStep(AUTHENTICATOR_STEPS.VERIFY)
        break
      case AUTHENTICATOR_STEPS.VERIFY:
        setCurrentStep(AUTHENTICATOR_STEPS.SUCCESS)
        break
      case 'finish':
        onNext({ secretKey, ...data })
        break
      default:
        break
    }
  }

  const handleBack = () => {
    switch (currentStep) {
      case AUTHENTICATOR_STEPS.CHOOSE_APP:
        onBack()
        break
      case AUTHENTICATOR_STEPS.SETUP:
        setCurrentStep(AUTHENTICATOR_STEPS.CHOOSE_APP)
        break
      case AUTHENTICATOR_STEPS.VERIFY:
        setCurrentStep(AUTHENTICATOR_STEPS.SETUP)
        break
      case AUTHENTICATOR_STEPS.SUCCESS:
        setCurrentStep(AUTHENTICATOR_STEPS.VERIFY)
        break
      default:
        break
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case AUTHENTICATOR_STEPS.CHOOSE_APP:
        return (
          <AuthenticatorApp
            onBack={handleBack}
            onNext={() => handleStepComplete(AUTHENTICATOR_STEPS.CHOOSE_APP)}
          />
        )
      case AUTHENTICATOR_STEPS.SETUP:
        return (
          <AuthenticatorSetup
            onBack={handleBack}
            onNext={(data) => handleStepComplete(AUTHENTICATOR_STEPS.SETUP, data)}
          />
        )
      case AUTHENTICATOR_STEPS.VERIFY:
        return (
          <AuthenticatorVerify
            secretKey={secretKey}
            onBack={handleBack}
            onNext={() => handleStepComplete(AUTHENTICATOR_STEPS.VERIFY)}
          />
        )
      case AUTHENTICATOR_STEPS.SUCCESS:
        return (
          <AuthenticatorSuccess
            onBack={handleBack}
            onFinish={(data) => handleStepComplete('finish', data)}
          />
        )
      default:
        return null
    }
  }

  return renderStep()
}

export default Authenticator
