import { useState, useEffect } from 'react';

const UseIsMax1023 = () => {
    const [isMax1023, setIsMax1023] = useState(window.innerWidth <= 1023);

    useEffect(() => {
        const handleResize = () => {
            setIsMax1023(window.innerWidth <= 1023);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return isMax1023;
};

export default UseIsMax1023;
