import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const REQUEST_SSO_TOKEN = gql `
  mutation requestSsoToken($host: String!) {
    requestSsoToken(host: $host)
  }
`

export const PORT_KYC_TO_TENANT = gql `
  mutation portKycToTenant($host: String!) {
    portKycToTenant(host: $host)
  }
`

export const GET_EVOLVE_ACCESS_TOKEN = gql `
  mutation getEvolveAccessToken {
    getEvolveAccessToken
  }
`
