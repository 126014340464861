import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TokenSkeleton = () => {
  return (
    <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 opacity-60 m-0 px-0 py-2 rounded-2xl transition-all duration-[0.2s] align">
      <div className="border-0 m-0 p-0 rounded-[50%] w-10 h-10 overflow-hidden align-baseline">
        <Skeleton circle width={32} height={32} className="bg-gray-lighter" />
      </div>
      <div className="relative flex flex-row flex-wrap flex-[1_1_0] justify-between items-center border-0 m-0 p-0 font-semibold text-white align-baseline">
        <span className="flex-1">
          <Skeleton width={60} height={16} className="bg-gray-lighter opacity-60 rounded-xl" />
        </span>

        <span className="border-0 m-0 p-0 font-[600] text-[13px] text-gray-lighter align-baseline">
          <Skeleton width={40} height={6} className="bg-gray-lighter opacity-60 rounded-xl" />
        </span>
      </div>
    </div>
  )
}

export default TokenSkeleton
