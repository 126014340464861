import React from "react"
import { useTranslation } from 'react-i18next'
import Layout from "../Layout"
import { SIGNUP_STEPS } from "../../constant/steps"

const Feature = ({ title, description }) => (
  <div className="pw:flex pw:flex-col pw:pb-8">
    <div className="pw:flex pw:flex-row pw:gap-x-4 pw:gap-y-4">
      <div className="pw:inline-flex pw:relative pw:justify-center pw:items-center pw:bg-black-light pw:mt-1 pw:rounded-[50%] pw:w-8 pw:h-8" />
      <div className="pw:flex pw:flex-col pw:flex-1 pw:pl-2">
        <div className="pw:m-0 pw:pb-2 pw:font-semibold pw:text-base pw:text-start pw:text-white pw:leading-6">
          {title}
        </div>
        <div className="pw:flex-1 pw:m-0 pw:font-normal pw:text-[13px] pw:text-base pw:text-gray-lighter pw:text-start pw:leading-6">
          {description}
        </div>
      </div>
    </div>
  </div>
)

const PasskeySetup = ({ onBack, onNext, disable2FA, onDisable2FAChange }) => {
  const { t } = useTranslation()
    return (
      <Layout
        title={t("Set up your passkey")}
        description={t("Create a more secure way to sign in")}
        currentStep={3}
        totalSteps={5}
        stepType={SIGNUP_STEPS.PASSKEY}
      >
        <div className="pw:mx-auto pw:mb-8 pw:w-full pw:max-w-[480px]">
          <div className="pw:flex pw:flex-col pw:m-0 pw:p-0">
            <Feature
              title={t("Enhanced security")}
              description={t("Use biometrics or a pin code to verify your identity")}
            />
            <Feature
              title={t("Works across devices")}
              description={t("Passkeys can be saved on your Apple, Google, or Microsoft cloud and used on all your other devices")}
            />
            <Feature
              title={t("Safe and secure")}
              description={t("Resistant to security issues, like phishing and password breaches")}
            />
          </div>

          <div className="pw:mb-6">
            <label className="pw:relative pw:flex pw:justify-start pw:gap-3 pw:border-0 pw:m-0 pw:p-0 pw:cursor-pointer pw:select-none pw:align-baseline pw:checkbox">
              <input
                type="checkbox"
                checked={disable2FA}
                onChange={(e) => onDisable2FAChange(e.target.checked)}
                className="pw:top-0 pw:left-0 pw:absolute pw:border-0 pw:opacity-0 pw:m-0 pw:p-0"
              />
              <span className="pw:flex pw:border-0 pw:m-0 pw:p-0 pw:align-baseline pw:checkbox__inner">
                <span className="pw:relative after:pw:top-2/4 after:pw:left-2/4 after:pw:absolute after:pw:content-[''] pw:border-2 pw:border-white/60 after:pw:bg-[50%_50%] after:pw:bg-[initial] after:pw:bg-no-repeat after:pw:opacity-0 pw:m-0 pw:p-0 pw:border-solid pw:rounded-md after:pw:w-[17px] pw:w-6 after:pw:h-3 pw:h-6 after:pw:transition-opacity pw:transition-all after:pw:-translate-x-2/4 after:pw:-translate-y-2/4 after:pw:duration-[0.2s] pw:duration-[0.2s] pw:align-baseline pw:checkbox__tick pw:shrink-0" />
              </span>
              <div className="pw:text-[#6f767e] pw:text-sm pw:checkbox__text">
                {t('Disable my weaker 2FA methods like text message to keep my account secure')}
              </div>
            </label>
          </div>

          <div className="pw:flex pw:gap-4">
            <button
              onClick={onBack}
              className="pw:inline-flex pw:flex-1 pw:justify-center pw:items-center hover:pw:bg-gray-lighter pw:bg-black-light pw:px-6 pw:rounded-2xl pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200"
            >
              {t('Not now')}
            </button>
            <button
              onClick={onNext}
              className="pw:inline-flex pw:flex-1 pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary pw:px-6 pw:rounded-2xl pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200"
            >
              {t('Get Started')}
            </button>
          </div>
        </div>
      </Layout>

    )
  }

export default PasskeySetup
