import React from 'react'
import BaseButton from './BaseButton'
import Icons from '../../../components/shared/Icons'

const WalletProviderButton = ({ icon, name, onClick }) => {
  return (
    <BaseButton
      className="bg-black-light hover:bg-gray-lighter hover:opacity-90 shadow-[#6F737E_0_0_0_1px_inset] m-0 px-4 rounded-[40px] w-full max-767:w-[initial] !h-12 text-sm"
      onClick={onClick}
    >
      <Icons
        nameIcon={icon}
        className="mr-2 rounded-full w-6 h-6"
      />
      <span className="inline-flex">{name}</span>
    </BaseButton>
  )
}

export default WalletProviderButton
