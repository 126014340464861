import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  CircularProgress,
} from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { useMe } from '../../../myHooks'

const ManageUpgradeSubscribe = ({ data }) => {
  const { data: { me } = {}, loading: meLoading } = useMe()

  return (
    <>
      <div className="section-pt80 variants pricings">
        <div className="variants__center pricings__center center center__admin">
          { meLoading && data && (
            <div className="variants__top text-center">
              <CircularProgress />
            </div>
          )}
          {!meLoading && data && (
            <>
              <stripe-pricing-table
                pricing-table-id={process.env.REACT_APP_EVOLVE_PRICING_TABLE_ID}
                publishable-key={process.env.REACT_APP_EVOLVE_STRIPE_PUBLISHABLE_KEY}
                client-reference-id={data.id}
                customer-email={me.email || data.email}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

ManageUpgradeSubscribe.propTypes = {
  match: PropTypes.object,
}

export default ManageUpgradeSubscribe
