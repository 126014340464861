import React from 'react'
import { useTranslation } from 'react-i18next'

import TwoFactorAuth from './TwoFactorAuth'
import Passkey from './Passkey'
import IpWhitelist from './IpWhitelist'

import Heading from "../Heading"

const Security = () => {
  const { t } = useTranslation()

  return (
    <>
      <Heading
        title={t('settings.security.title')}
      />

      <>
        <div className="mb-6 font-sans font-semibold text-base text-start text-white leading-6 tracking-[-0.02em]">
          {t('settings.security.multi_factor_authentication')}
        </div>

        <div className="gap-5 grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr]">
          <TwoFactorAuth />
          <Passkey />
        </div>

        <div className="mt-12 max-767:mt-10" />
        <div className="mb-6 font-sans font-semibold text-base text-start text-white leading-6 tracking-[-0.02em]">
          {t('settings.monerium.security.ip_whitelist')}
        </div>
        <IpWhitelist />
      </>
    </>
  )
}

export default Security
