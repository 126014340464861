/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/no-unresolved */
import React from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar';
import { isProduction } from '../../../../utils';

const Transactions = ({
  txHash,
  type,
  amount,
  value,
  to,
  from,
  contract,
  imgSrc,
  networkIcon,
  chainIndex,
  txStatus,
  datetime
}) => {
  const getImage = (src, name) => {
    try {
      // Handle null/undefined src
      if (!src) {
        return (
          <Avatar
            name={name || type}
            size="32"
            round={true}
            className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
          />
        );
      }

      // Handle object module or string src
      const imgSrc = typeof src === 'string' ? src : (src.default || src);

      // Check if src contains default broken image path
      if (imgSrc.includes('default-icon-day-v3')) {
        return (
          <Avatar
            name={name || type}
            size="32"
            round={true}
            className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
          />
        );
      }

      return (
        <img
          src={imgSrc}
          alt={type}
          className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            const avatar = <Avatar
              name={name || type}
              size="32"
              round={true}
              className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
            />;
            e.target.parentNode.appendChild(avatar);
          }}
        />
      );
    } catch (err) {
      return (
        <Avatar
          name={name || type}
          size="32"
          round={true}
          className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
        />
      );
    }
  };

  const getTransactionTitle = () => {
    switch (type) {
      case 'sent': return 'Sent'
      case 'received': return 'Received'
      case 'contract': return 'Smart contract executed'
      case 'approve': return 'Approve'
      case 'transfer': return 'Transfer'
      default: return type
    }
  }

  const getTransactionExplorer = (chainIndex) => {
    switch (chainIndex) {
    case '11155111':
      return 'https://sepolia.etherscan.io/tx';
    case '1':
      return 'https://etherscan.io/tx'
    case '8453':
        return 'https://basescan.org/tx'
    case '137':
      return 'https://polygonscan.com/tx'
    case '58054':
      return 'https://explorer.xinfin.network/tx'
    default:
      return 'https://basescan.org/tx'
    }
  }

  const transactionExplorer = getTransactionExplorer(chainIndex)

  return (
    <a href={`${transactionExplorer}/${txHash}`} target="_blank" rel="noopener noreferrer">
      <div className="flex flex-row flex-nowrap justify-start items-stretch border-0 hover:opacity-45 m-0 p-0 font-semibold text-white transition-all duration-200">

        {/* Images */}
        <div className="relative border-0 m-0 mr-3 p-0 rounded-[50%] w-9 h-9">
          {getImage(imgSrc, amount && amount.split(' ')[1])}

          {networkIcon && (
            <span className="right-0 bottom-0 absolute bg-white rounded-full w-4 h-4 overflow-hidden">
              <img
                src={networkIcon}
                alt="Network"
                className="w-full h-full object-cover"
              />
            </span>
          )}
        </div>

      {/* Description */}
        <div className="flex-col flex-wrap flex-1 justify-center items-start text-ellipsis text-gray-custom whitespace-nowrap overflow-hidden">
          { type === 'sent' && to && (
            <>
              <span className='flex justify-between items-center gap-4 font-sans leading-[1.1]'>
                <span className='text-[15px] text-white capitalize tracking-[-0.02em]'>{type}</span>

                <span className='text-[13px] tracking-[-0.02em]'>{datetime}</span>
              </span>

              <span className='m-0 p-0 font-sans font-semibold text-[13px] tracking-[-0.02em]'>
                {amount} {` to ${to}`}
              </span>
            </>
          )}

          {type === 'received' && from && (
            <>
              <span className='flex justify-between items-center gap-4 font-sans leading-[1.1]'>
                <span className='text-[15px] text-white capitalize tracking-[-0.02em]'>{type}</span>

                <span className='text-[13px] tracking-[-0.02em]'>{datetime}</span>
              </span>

              <span className='m-0 p-0 font-sans font-semibold text-[13px] tracking-[-0.02em]'>
                {amount} {` from ${from}`}
              </span>
            </>
          )}

          {(type === 'contract' || type === 'approve') && contract && (
            <>
              <span className='flex justify-between items-center gap-4 font-sans leading-[1.1]'>
                <span className='text-[15px] text-white capitalize tracking-[-0.02em]'>{type}</span>

                <span className='text-[13px] tracking-[-0.02em]'>{datetime}</span>
              </span>

              <span className='m-0 p-0 font-sans font-semibold text-[13px] tracking-[-0.02em]'>
                {amount} {` contract ${contract}`}
              </span>
            </>
          )}
        </div>
      </div>
    </a>
  )
}

Transactions.propTypes = {
  type: PropTypes.oneOf(['sent', 'received', 'contract']).isRequired,
  amount: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  to: PropTypes.string,
  from: PropTypes.string,
  contract: PropTypes.string,
  imgSrc: PropTypes.string,
  networkIcon: PropTypes.bool
}

export default Transactions
