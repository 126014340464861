import React, { useState, useEffect } from "react";
import { ModalWide } from "../../../../../components/shared/Modals";
import Icons from "../../../../../components/shared/Icons";
import { useFormContext } from "react-hook-form";
import { useLazyQuery } from '@apollo/client';
import { GET_INVESTMENT } from '../../../../../queriesAndMutations';
import { toaster } from "../../../../../utils";

const titles = [
  "Transfer Money to proceed with order",
  "Order Status",
];

const VoltDetail = ({ isOpen, onClose, onNext, voltViban, currentInvestment }) => {
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const { getValues } = useFormContext();
  const [getInvestment] = useLazyQuery(GET_INVESTMENT);

  const handleCopy = (text) => {
    if (!text) return

    navigator.clipboard.writeText(text)
  }

  const handleStepChange = (step, titleIndex) => {
    setCurrentStep(step);
    setCurrentTitleIndex(titleIndex);
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(1);
    } else {
      console.log('setInterval');
      const interval = setInterval(async () => {
        const { data } = await getInvestment({
          variables: {
            id: currentInvestment.id
          }
        })

        console.log('newTransaction', data.getInvestment);

        const { status } = data.getInvestment || {}

        console.log('status', status);

        switch (status) {
          case 'PROCESSING':
            handleStepChange(2, 2)
            break
          case 'FAILED':
            clearInterval(interval)
            toaster.error('Transaction failed! Please contact support for manual proccessing!')
            break;
          case 'PAID':
            clearInterval(interval)
            handleStepChange(3, 2)

            setTimeout(() => {
              onNext();
              onClose();
            }, 2000)
            break
          default:
            break
        }
      }, 5000)

      return () => clearInterval(interval)
    }

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!voltViban) return null

  return (
    <ModalWide
      title={titles[currentTitleIndex]}
      isOpen={isOpen}
      onClose={onClose}
      customClassModal="max-w-[700px]"
    >
      <div>
        {currentStep === 1 && (
          <>
            {/* Order status 1 */}
            <ol className="inline-flex justify-center items-center mx-auto w-full max-w-lg">
              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-1/2 after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Ordered
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span
                  className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 cursor-pointer shrink-0"
                >
                  <Icons nameIcon="loader" className="w-4 h-4 text-white" />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Verifying
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0" />
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Processing
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0" />
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Sending
                </div>
              </li>
            </ol>

            <div className="relative mt-12 mb-4 w-full text-center">
              <div className="my-4 text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
                Please send {getValues('amount')} Euro to your Volt Virtual IBAN:
              </div>

              <div className="mx-auto w-full max-w-md">
                <div className="flex justify-between items-center bg-transparent px-0 py-4 rounded-xl h-12 font-semibold text-gray-lighter text-sm leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear">
                  <div className="">IBAN</div>
                  <button
                    type="button"
                    className="inline-flex justify-end items-center gap-1 bg-transparent hover:opacity-80 text-white transition-opacity duration-[0.2s] ease-linear cursor-pointer outline-none"
                    onClick={() => handleCopy(voltViban.iban)}
                  >
                    {voltViban.iban}
                    <Icons
                      nameIcon="copy"
                      className="inline-flex ml-2 w-4 h-4"
                    />
                  </button>
                </div>
              </div>

              <div className="mx-auto w-full max-w-md">
                <div className="flex justify-between items-center bg-transparent px-0 py-4 rounded-xl h-12 font-semibold text-gray-lighter text-sm leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear">
                  <div className="">Virtual Account ID</div>
                  <button
                    type="button"
                    className="inline-flex justify-end items-center gap-1 bg-transparent hover:opacity-80 text-white transition-opacity duration-[0.2s] ease-linear cursor-pointer outline-none"
                    onClick={() => handleCopy(voltViban.virtual_account_id)}
                  >
                    {voltViban.virtual_account_id}
                    <Icons
                      nameIcon="copy"
                      className="inline-flex ml-2 w-4 h-4"
                    />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            {/* Order status 2 */}
            <ol className="inline-flex justify-center items-center mx-auto w-full max-w-lg">
              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-1/2 after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Ordered
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Verifying
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span
                  className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 cursor-pointer shrink-0"
                >
                  <Icons nameIcon="loader" className="w-4 h-4 text-white" />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Processing
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0" />
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Sending
                </div>
              </li>
            </ol>
            <div className="relative mt-12 mb-4 w-full text-center">
              <div className="my-4 text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
                Waiting to receive your {getValues('amount')} EURO into your Investing IBAN
              </div>
            </div>
          </>
        )}

        {currentStep === 3 && (
          <>
            {/* Order status 3 */}
            <ol className="inline-flex justify-center items-center mx-auto w-full max-w-lg">
              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-1/2 after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Ordered
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white leading-[1.2] tracking-[-0.02em]">
                  Verifying
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span className="relative z-10 flex justify-center items-center bg-[#0052ff] rounded-full w-10 lg:w-12 h-10 lg:h-12 shrink-0">
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-white"
                  />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Processing
                </div>
              </li>

              <li className="after:inline-block relative after:top-1/2 after:right-0 after:absolute flex flex-col items-center after:content-[''] gap-2 after:border-[1px] after:border-[#2b2d33] bg-transparent after:border-b w-full after:w-full after:h-[1px] after:-translate-y-[calc(50%+8px)]">
                <span
                  className="relative z-10 flex justify-center items-center bg-black-light rounded-full w-10 lg:w-12 h-10 lg:h-12 cursor-pointer shrink-0"
                >
                  <Icons nameIcon="loader" className="w-4 h-4 text-white" />
                </span>
                <div className="w-full text-[13px] text-white/60 leading-[1.2] tracking-[-0.02em]">
                  Sending
                </div>
              </li>
            </ol>

            <div className="relative mt-12 mb-4 w-full text-center">
              <div className="my-4 text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
                You received your {getValues('amount')} Euro in your Investing Iban, {getValues('amount')} EURe are minted to your wallet.
              </div>
            </div>
          </>
        )}
      </div>
    </ModalWide>
  );
};

export default VoltDetail;
