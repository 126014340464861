import React from 'react'
import cn from 'classnames'

const Heading = ({
  title,
  description
}) => {
  return (
    <div className="flex justify-between items-center mb-8">
      <div>
        <div
          className={cn(
            "font-sans font-semibold text-2xl text-start text-white leading-7 tracking-[-0.02em]",
            {
              "mb-2": description
            }
          )}
        >
          {title}
        </div>
        {description && (
          <p className="text-gray-lighter text-sm">{description}</p>
        )}
      </div>
  </div>

  )
}

export default Heading
