import { useState, useEffect } from 'react'
import { useKeyPress } from './useKeyPress'

export const useOverlay = () => {
  const [isOpen, setIsOpen] = useState(false)

  useKeyPress('Escape', () => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto'
    return () => { document.body.style.overflow = 'auto' }
  }, [isOpen])

  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen((prev) => !prev),
  }
}

export default useOverlay
