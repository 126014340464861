// src/pages/DashboardIssuer/index.jsx

import React, { useEffect } from "react";
import { DASHBOARD_DATA_TOKEN } from "../../constant/dummyDataToken";
import Icons from "../../components/shared/Icons";

const PricingCard = ({ title, price, isPopular }) => (
  <div
    className={`relative flex flex-col bg-black-light p-8 rounded-2xl w-full overflow-hidden ${
      isPopular ? "bg-opacity-80" : ""
    }`}
  >
    {isPopular && (
      <div className="top-4 right-4 absolute bg-primary/10 px-2 py-1 rounded-full">
        <span className="font-medium text-primary text-xs">Most popular</span>
      </div>
    )}

    <h3 className="mb-4 font-semibold text-white text-xl">{title}</h3>

    <div className="flex items-baseline mb-6">
      <span className="font-bold text-4xl text-white">CHF {price}</span>
      <span className="ml-2 text-gray-lighter text-sm">per month</span>
    </div>

    <button className="inline-flex justify-center items-center gap-2 bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-4 rounded-[40px] h-10 font-medium text-white transition-colors duration-[0.2s]">
      Start trial
    </button>
  </div>
);

const PricingSection = () => (
  <div className="mb-12 max-767:mb-8">
    <h2 className="mb-5 font-normal text-center text-white text-xl leading-7 tracking-[-0.02em]">
      Select plan for wct12.investhub.io
    </h2>

    <div className="gap-4 grid grid-cols-3 max-1023:grid-cols-1 w-full">
      <PricingCard title="Starter Plan" price="39" />

      <PricingCard title="Medium Plan" price="150" />

      <PricingCard title="Premium Plan" price="499" isPopular={true} />
    </div>
  </div>
);

const DashboardIssuer = ({ setPageTitle, match: { params: { tokenName } } }) => {
  const tokenData = DASHBOARD_DATA_TOKEN.find(
    (token) => token.name.toLowerCase().replace(/\s+/g, "-") === tokenName
  );

  useEffect(() => {
    if (!tokenData) {
      window.location.href = "/dashboard/tokens";
      return;
    }

    setPageTitle(`Token Detail`);
  }, [tokenData, setPageTitle]);

  if (!tokenData) return null;

  return (
    <div className="flex flex-1 mx-auto max-1023:px-5 py-6 md:py-0 w-full max-w-5xl">
      <div className="relative flex justify-start items-start max-w-full grow">
        <div className="relative flex flex-col md:pt-18 max-w-full grow">
          {/* Heading */}
          <div className="mb-10 max-767:mb-8 text-left">
            <button
              onClick={() => {
                window.location.href = "/dashboard/tokens"
              }}
              className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
            >
              <Icons nameIcon="chevron-left" className="w-3 h-3" />
              <span className="inline-block">Back to Tokens</span>
            </button>

            <div className="w-full h-10" />

            <div className="flex flex-row flex-wrap justify-start items-start gap-4 mb-4">
              <figure className="relative rounded-full w-16 h-16 overflow-hidden">
                <img
                  src={tokenData.image}
                  alt={tokenData.name}
                  className="top-0 left-0 absolute w-full h-full object-cover"
                />
              </figure>

              <div className="relative top-1 font-bold text-5xl text-white max-1023:text-[2.5rem] leading-[3.5rem] max-1023:leading-[3rem] -tracking-wide max-1023:tracking-[-.045em]">
                {tokenData.name}
              </div>
            </div>

            {tokenData.text && (
              <div className="text-[1.5rem] text-gray-lighter leading-[2.25rem] tracking-[-.03em]">
                {tokenData.text}
              </div>
            )}

            <div className="flex flex-row flex-wrap mt-10 max-767:mt-8 mr-auto">
              <button className="border-[2px] border-primary m-0 px-4 py-1 rounded-[22px] font-medium text-[13px] text-white">
                Levels
              </button>
              <button className="border-[2px] m-0 px-4 py-1 border-transparent rounded-[22px] font-medium text-[13px] text-gray-lighter">
                Managers
              </button>
              <button className="border-[2px] m-0 px-4 py-1 border-transparent rounded-[22px] font-medium text-[13px] text-gray-lighter">
                Country
              </button>
              <button className="border-[2px] m-0 px-4 py-1 border-transparent rounded-[22px] font-medium text-[13px] text-gray-lighter">
                Info
              </button>
              <button className="border-[2px] m-0 px-4 py-1 border-transparent rounded-[22px] font-medium text-[13px] text-gray-lighter">
                Token
              </button>
              <button className="border-[2px] m-0 px-4 py-1 border-transparent rounded-[22px] font-medium text-[13px] text-gray-lighter">
                Services
              </button>
              <button className="border-[2px] m-0 px-4 py-1 border-transparent rounded-[22px] font-medium text-[13px] text-gray-lighter">
                Payment Method
              </button>
            </div>
          </div>
          {/* End heading */}

          {/* Content */}
          <div className="max-1023:block flex flex-row flex-wrap justify-start items-start p-0">
            {/* Content column 1 */}
            <div className="flex-1 max-1023:order-4 pr-16 max-1023:pr-0 max-1279:pr-12 grow">
              <div className="relative mb-12 max-767:mb-8 pb-[56.25%] rounded-2xl w-full overflow-hidden">
                <iframe
                  src="https://www.youtube.com/embed/-QZ_CKHeU_0"
                  frameBorder="0"
                  allowFullScreen
                  title="YouTube video player"
                  className="top-0 left-0 absolute w-full h-full"
                />
              </div>

              {/* Company */}
              <div className="mb-5 font-normal text-start text-white text-xl leading-7 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out]">
                Company
              </div>

              <div className="mt-3">
                <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
                  Email:{" "}
                  <span className="text-white/70">{tokenData.email}</span>
                </div>

                <div className="relative font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
                  Address:{" "}
                  <span className="text-white/70">{tokenData.address}</span>
                </div>
              </div>
              <div className="w-full h-10" />
              {/* End company */}

              {/* Dataroom */}
              <div className="mb-5 font-normal text-start text-white text-xl leading-7 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out]">
                Dataroom
              </div>

              <div className="gap-4 grid grid-cols-[1fr_1fr_1fr] max-480:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] w-full">
                <div className="inline-flex justify-center items-center bg-black-light mb-4 p-8 max-480:p-4 rounded-2xl w-full overflow-hidden">
                  <img
                    src="https://kyc.equanimity.li/file/3be2cdb7-2097-4df5-8679-731d5fe014b2.jpg?origin=https://kyc.equanimity.li"
                    alt="equanimity"
                    className="rounded-xl w-full h-full object-center object-contain"
                  />
                </div>

                <div className="inline-flex justify-center items-center bg-black-light mb-4 p-8 max-480:p-4 rounded-2xl w-full overflow-hidden">
                  <img
                    src="https://kyc.equanimity.li/file/c78a6efc-244a-4eb6-bb0c-97f56dead95d.png?origin=https://kyc.equanimity.li"
                    alt="equanimity"
                    className="rounded-xl w-full h-full object-center object-contain"
                  />
                </div>

                <div className="inline-flex justify-center items-center bg-black-light mb-4 p-8 max-480:p-4 rounded-2xl w-full overflow-hidden">
                  <img
                    src="https://kyc.equanimity.li/file/3be2cdb7-2097-4df5-8679-731d5fe014b2.jpg?origin=https://kyc.equanimity.li"
                    alt="equanimity"
                    className="rounded-xl w-full h-full object-center object-contain"
                  />
                </div>
              </div>
              {/* End dataroom */}
            </div>
            {/* End content column 1 */}

            {/* Content column 2 */}
            <div className="max-1023:mt-8 w-[308px] max-1023:w-full shrink-0">
              <div className="bg-black-light p-8 rounded-2xl w-full overflow-hidden">
                <div className="m-0 pb-2 font-normal text-start text-white text-xl leading-7 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out]">
                  $454,088 raised
                </div>

                <div className="mb-6">
                  {/* Progress bar */}
                  <div className="block relative bg-gray-lighter rounded-sm w-full h-1 overflow-hidden">
                    <div
                      className="block top-0 left-0 z-[2] absolute bg-primary h-full"
                      style={{ width: "80%" }}
                    />
                  </div>
                  {/* End progress bar */}

                  <div className="flex flex-row flex-wrap justify-between items-center mt-2 w-full">
                    <div className="border-0 m-0 mb-2 p-0 font-bold text-gray-lighter text-xs uppercase align-baseline">
                      225 Investor
                    </div>

                    <div className="border-0 m-0 mb-2 p-0 font-bold text-white/70 text-xs align-baseline">
                      80%
                    </div>
                  </div>
                </div>

                <div className="m-0 font-semibold text-base text-start text-white leading-6 tracking-[-0.02em] transition-[color] duration-[0.15s] ease-[ease-out]">
                  Invest in {tokenData.name}
                </div>

                <div className="flex-1 mb-4 font-normal text-[13px] text-base text-gray-lighter text-start leading-6 transition-[color] duration-[0.02s] ease-[ease-out]">
                  It's a free, quick and easy to sign up
                </div>

                <div className="gap-4 grid grid-cols-[1fr_1fr] grid-rows-[auto] auto-cols-[1fr]">
                  <button className="inline-flex justify-center items-center gap-2 border-[2px] border-white/20 bg-transparent hover:bg-black-dark hover:opacity-90 m-0 px-4 hover:border-black-dark border-solid rounded-[40px] h-10 font-medium text-[100%] text-white hover:text-white normal-case leading-none transition-colors duration-[0.2s] overflow-visible">
                    Invest
                  </button>
                  <button className="inline-flex justify-center items-center gap-2 border-[2px] border-white/20 hover:border-primary-hover bg-transparent hover:bg-primary-hover hover:opacity-90 m-0 px-4 border-solid rounded-[40px] h-10 font-medium text-[100%] text-white hover:text-white normal-case leading-none transition-colors duration-[0.2s] overflow-visible">
                    Login
                  </button>
                </div>
                <div className="flex justify-start items-start gap-1 mt-3 w-full text-[13px] text-gray-lighter">
                  <Icons
                    nameIcon="users"
                    className="inline-flex relative top-[2px] w-4 h-4"
                  />
                  Last investment{" "}
                  <strong className="font-semibold text-white/70">
                    1 minute ago
                  </strong>
                </div>
              </div>
            </div>
            {/* End content column 2 */}
          </div>
          {/* End content */}

          {/* Pricing */}
          <div className="w-full h-10" />
          <PricingSection />
          {/* End pricing */}
        </div>
      </div>
    </div>
  );
};

export default DashboardIssuer;
