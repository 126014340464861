import React from 'react';
import styles from './DemographicsAnalysis.module.scss';

const RiskScoreCircle = ({ score }) => (
  <div className="flex flex-row flex-wrap gap-1">
    <svg viewBox="0 0 36 36" className={styles.circleProgress}>
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="rgba(255, 255, 255, 0.1)"
        strokeWidth="3"
      />
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#0052FF"
        strokeWidth="3"
        strokeDasharray={`${score}, 100`}
      />
    </svg>
    <div className="w-full font-sans text-[13px] text-gray-lighter truncate tracking-[-0.02em]">
      {score}%{' '}Risk Score
    </div>
  </div>
);

const DistributionBar = ({ label, value, percentage }) => (
  <div className={styles.distributionItem}>
    <div className="flex justify-between border-white/5 mb-[6px] pb-[6px] border-b border-solid">
      <span className="font-sans text-[13px] text-gray-lighter tracking-[-0.02em]">{label}</span>
      <span className="font-sans text-sm text-white tracking-[-0.02em]">{percentage}%</span>
    </div>
    <div className={styles.progressBar}>
      <div
        className={styles.progressFill}
        style={{ width: `${percentage}%` }}
      />
    </div>
  </div>
);

const RecommendationCard = ({ children, variant }) => (
  <div className={`${styles.recommendationCard} ${styles[variant]}`}>
    {children}
  </div>
);

const DemographicsAnalysis = () => {
  return (
    <div className={styles.wrapper}>
      <h2 className="mb-6 font-sans font-semibold text-lg text-start text-white leading-6 tracking-[-0.02em]">Demographics Analysis</h2>

      <div className="border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-full min-w-0 text-start text-white transition-all duration-[0.2s] ease-linear outline-none">
        <div className="mb-4 font-sans font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s]">Investment risk profile</div>

        <div className="flex items-center gap-6">
          <RiskScoreCircle score={70} />
          <span className="text-right flex-1 font-bold font-sans text-gray-lighter tracking-[-0.02em]">MODERATE</span>
        </div>
      </div>

      <div className="mt-6 max-767:mt-10" />

      <div className="border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-full min-w-0 text-start text-white transition-all duration-[0.2s] ease-linear outline-none">
        <div className="mb-4 font-sans font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s]">Portfolio Distribution</div>
        <>
          <DistributionBar
            label="Age Group: 25-34"
            percentage={80}
          />
          <DistributionBar
            label="Income: Mid-High"
            percentage={60}
          />
          <DistributionBar
            label="Experience: 3-5 years"
            percentage={70}
          />
        </>
      </div>

      <div className="mt-6 max-767:mt-10" />

      <div className='border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-full min-w-0 text-start text-white transition-all duration-[0.2s] ease-linear outline-none'>
        <h3 className="mb-4 font-sans font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s]">Recommended Actions</h3>
        <div className='space-y-4'>
          <RecommendationCard variant="success">
            Consider diversifying into stable assets
          </RecommendationCard>
          <RecommendationCard variant="info">
            Increase DeFi exposure gradually
          </RecommendationCard>
        </div>
      </div>
    </div>
  );
};

export default DemographicsAnalysis
