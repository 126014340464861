import React from "react";
import { useFormContext } from "react-hook-form";
import PageHeading from "../../../../components/layouts/InstantInvestment/PageTitle";

// Inner
import Buy from "./Buy";
import Invest from "./Invest";
import Processing from "./Processing";

const StepReviewProcessOrder = ({ currentInvestment, currentIssuer }) => {
  const { getValues } = useFormContext()
  const values = getValues();

  return (
    <>
      <Buy values={values} />
      <div className="w-full h-4" />
      <Invest values={values} currentIssuer={currentIssuer} />
      <div className="w-full h-4" />

      <div className="w-full h-4" />
      <Processing currentInvestment={currentInvestment} currentIssuer={currentIssuer} />
    </>
  );
};

export default StepReviewProcessOrder;
