// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/steps/BusinessHistory/index.jsx
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../common/Button'
import StepIndicator from '../../common/StepIndicator'
import Icons from '../../../../../../../shared/Icons'

const BusinessHistory = ({
  onNext,
  onBack,
  progress,
  currentStep,
  totalSteps,
}) => {
  const { t } = useTranslation()
  const [selectedActivities, setSelectedActivities] = useState([])

  const businessActivities = [
    {
      id: 'private-equity',
      content: 'Worked in private equity or in the provision of finance for small and medium enterprises'
    },
    {
      id: 'director',
      content: 'Been the director of a company with an annual turnover of at least £1 million'
    },
    {
      id: 'business-angles',
      content: 'Been a member of a network or syndicate of business angels for more than six months'
    },
    {
      id: 'none',
      content: 'None of these apply to me'
    }
  ]

  const handleActivityToggle = (id) => {
    if (id === 'none') {
      setSelectedActivities(['none'])
      return
    }

    setSelectedActivities(prev => {
      if (prev.includes('none')) {
        return [id]
      }

      const newSelection = prev.includes(id)
        ? prev.filter(item => item !== id)
        : [...prev, id]

      return newSelection.length ? newSelection : []
    })
  }

  const handleContinue = () => {
    if (selectedActivities.length > 0) {
      onNext({ businessHistory: selectedActivities })
    }
  }

  return (
    <>
      <div className="flex flex-col flex-1 justify-start items-start gap-4 px-4 py-6">
        <StepIndicator
          currentStep={currentStep}
          totalSteps={totalSteps}
          progress={progress}
        />

        <div className="flex-1 w-full">
          <button
            className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
            onClick={onBack}
          >
            <Icons nameIcon="arrow-left" className="w-3 h-3" />
            <span className="inline-block">
              {t('common.back')}
            </span>
          </button>
          <div className="block w-full h-6" />

          <div className="mb-4 w-full">
            <div className="mb-2 font-medium text-4xl text-ellipsis text-white leading-[1.1875] tracking-[-0.02em]">
              {t('questionnaire.businessHistory.title')}
            </div>
            <p className="border-0 mt-2 p-0 text-gray-lighter">
              {t('questionnaire.businessHistory.subtitle')}
            </p>
          </div>

          {businessActivities.map((activity) => (
            <label
              key={activity.id}
              className={`flex flex-row justify-start border-solid items-stretch mb-4 border-2 bg-black-light rounded-xl cursor-pointer ${
                selectedActivities.includes(activity.id) ? "border-primary" : "border-transparent"
              }`}
              onClick={() => handleActivityToggle(activity.id)}
            >
              <input
                type="checkbox"
                name="business-history"
                checked={selectedActivities.includes(activity.id)}
                onChange={() => handleActivityToggle(activity.id)}
                className="absolute border-0 bg-[0_0] bg-[initial] opacity-0 m-0 p-0 border-solid rounded-none font-[inherit] text-inherit leading-[inherit] overflow-visible align-middle"
              />
              <div className="flex flex-col justify-center p-8 w-full">
                <div className="m-0 pb-2 font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s] ease-[ease-out]">
                  {t(`questionnaire.businessHistory.options.${activity.id}`)}
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>

      <div className="bottom bottom-0 sticky bg-black-dark px-4 py-6 w-full">
        <Button
          fullWidth
          size="md"
          onClick={handleContinue}
          disabled={selectedActivities.length === 0}
        >
          {t('common.continue')}
        </Button>
      </div>
    </>
  )
}

export default BusinessHistory
