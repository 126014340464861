import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { UseIsMax767 } from '../shared/MediaQueries'
import Icons from '../shared/Icons'

const FloatingLegalDisclaimer = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = UseIsMax767()

  const slideVariants = {
    hidden: { y: "100%" },
    visible: { y: 0 },
    exit: { y: "100%" }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <>
      <motion.button
        className="inline-flex flex-row flex-wrpa justify-center items-center gap-2 opacity-50 hover:opacity-100 font-sans font-semibold text-white tracking-[-0.02em] transition-all duration-[0.2s]"
        // animate={{
        //   y: [-4, 4]
        // }}
        // transition={{
        //   duration: 1.5,
        //   repeat: Infinity,
        //   repeatType: "reverse",
        //   ease: "easeInOut"
        // }}
        onClick={() => setIsOpen(true)}
      >
        {!isMobile && (
          <Icons
            nameIcon='chevron-down'
            className="w-4 h-4 rotate-180"
          />
        )}
        <span className='block w-full'>
          Click to check Legal Disclaimer
        </span>
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <>
            <div className="top-0 left-0 z-[9998] fixed border-0 bg-black-light opacity-75 w-full h-full min-h-full pointer-events-none"/>

            <motion.div
              className="top-0 left-0 z-[9999] fixed border-0 bg-black-dark m-0 max-767:px-6 p-24 max-767:pb-6 w-full h-full min-h-full text-white overflow-x-auto scroll-smooth scrollbar-custom"
              variants={slideVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{
                type: "tween",
                duration: 0.3
              }}
            >

              <button
                className="inline-flex top-8 right-8 absolute justify-center items-center bg-[#0000] hover:opacity-75 shadow-[inset_0_0_0_2px_#fff] hover:shadow-[inset_0_0_0_2px_#6F737E] rounded-[50%] w-8 h-8 text-white hover:text-gray-lighter transition-all duration-[0.2s] cursor-pointer"
                onClick={() => setIsOpen(false)}
              >
                <Icons nameIcon="close" className="w-4 h-4" />
              </button>

              <div className="relative z-5 flex items-center px-12 max-767:px-4 font-sans">
                <div className="mx-auto w-full max-w-[1120px]">
                  <div className="mb-4 font-semibold text-white text-xl leading-[3.5rem] -tracking-wide max-1023:tracking-[-.045em]">
                    {t('legalDisclaimer.title')}

                    <div className="content-divide mt-4 [&>p]:font-sans [&>p]:text-sm [&>p]:font-normal [&>p]:text-gray-lighter [&>p]:leading-6 [&>p+div]:mt-6 [&>div]:m-0 [&>div]:pb-2 [&>div]:font-normal [&>div]:text-white [&>div]:text-lg [&>div]:leading-7  column-gap-40  max-1023:column-gap-24 ">
                      <div>{t('legalDisclaimer.sections.importantLegal.title')}</div>
                      <p>{t('legalDisclaimer.sections.importantLegal.content')}</p>

                      <div>{t('legalDisclaimer.sections.jurisdictional.title')}</div>
                      <p>{t('legalDisclaimer.sections.jurisdictional.content')}</p>

                      <div>{t('legalDisclaimer.sections.information.title')}</div>
                      <p>{t('legalDisclaimer.sections.information.content')}</p>

                      <div>{t('legalDisclaimer.sections.noReliance.title')}</div>
                      <p>{t('legalDisclaimer.sections.noReliance.content')}</p>

                      <div>{t('legalDisclaimer.sections.liability.title')}</div>
                      <p>{t('legalDisclaimer.sections.liability.content')}</p>

                      <div>{t('legalDisclaimer.sections.endorsement.title')}</div>
                      <p>{t('legalDisclaimer.sections.endorsement.content')}</p>

                      <div>{t('legalDisclaimer.sections.terms.title')}</div>
                      <p>{t('legalDisclaimer.sections.terms.content')}</p>

                      <div>{t('legalDisclaimer.sections.updates.title')}</div>
                      <p>{t('legalDisclaimer.sections.updates.content')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </>

        )}
      </AnimatePresence>
    </>
  )
}

export default FloatingLegalDisclaimer
