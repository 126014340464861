import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import {
  TextField,
  CircularProgress,
} from '@material-ui/core'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import classNames from 'classnames'
import { PUBLIC_SETTINGS } from '../../../queriesAndMutations'
import Icon from '../../Icon'
import styles from './styles.module.scss'

const SubscribeForm = () => {
  const { t } = useTranslation()
  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)

  return (
    <>
      <div className={styles.footer__category}>
        {t("Join Newsletter")}
      </div>

      <div className={styles.footer__text}>
        {t("Signup for our newsletter to get the latest news in your inbox.")}
      </div>

      {publicSettings?.mailchimp?.formUrl && (
        <MailchimpSubscribe
          url={publicSettings.mailchimp.formUrl}
          render={({ subscribe, status, message }) => {
            if (!status || status === 'error') {
              return (
                <>
                  <form
                    id="form_newsletter"
                    action=""
                    className={classNames('subscription mc-subscription', styles.subscription)}
                    onSubmit={(event) => {
                      event.preventDefault()
                      const formData = Object.fromEntries(new FormData(document.getElementById('form_newsletter')))
                      subscribe(formData)
                    }}
                  >
                    <TextField
                      placeholder="Your email"
                      name="EMAIL"
                      type="email"
                      className={styles.subscription__input}
                      id="form_newsletter_field"
                    />
                    <button className={classNames('subscription__btn', styles.subscription__button)} type="submit">
                      <Icon name="arrow-next" className={classNames("arrow-next")} size={20} />
                    </button>
                  </form>
                  {message && (<div className="footer__text">{message}</div>)}
                </>
              )
            }

            if (status === 'sending') {
              return (
                <>
                  <CircularProgress />
                </>
              )
            }

            if (status === 'success') {
              return (
                <>
                  <div className="footer__text">{message}</div>
                </>
              )
            }

            return null
          }}
        />
      )}
    </>
  )
}

export default SubscribeForm
