import React, { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { useFormContext, Controller } from 'react-hook-form'
import { COUNTRY_LIST } from '../../../../constants/countryList'
import Icons from '../../Icons'

const countries = COUNTRY_LIST.map((country) => ({
  label: country,
  value: country,
}))

const SimpleCountryField = ({
  name,
  placeholder,
  defaultCountryValue,
  label,
  validator,
  toolTipText, // Assuming you'll add a tooltip text prop
  setIsCountry,
  wrapperClass,
}) => {
  const [dropdownDirection, setDropdownDirection] = useState('down')

  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState(defaultCountryValue || '')
  const [isFocused, setIsFocused] = useState(false)
  const { control } = useFormContext()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredCountries, setFilteredCountries] = useState(countries)

  const dropdownContentRef = useRef(null)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsOpenDropdown((prevState) => !prevState)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenDropdown(false)
      setSearchTerm('')
    }
  }

  const handleEscPress = (event) => {
    if (event.key === 'Escape') {
      setIsOpenDropdown(false)
      setSearchTerm('')
    }
  }

  const handleCountrySelect = (country) => {
    setSelectedCountry(country)
    setIsOpenDropdown(false)
    setSearchTerm('')
  }
  useEffect(() => {
    if (isOpenDropdown) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - dropdownRect.bottom
      const spaceAbove = dropdownRect.top

      // Get an updated calculation after rendering for accurate dropdown height
      const calculatedHeight = dropdownContentRef.current ? dropdownContentRef.current.scrollHeight + 40 : 300

      if (spaceBelow < calculatedHeight && spaceAbove > spaceBelow) {
        setDropdownDirection('up')
      } else {
        setDropdownDirection('down')
      }
    }

    if (isOpenDropdown) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('keydown', handleEscPress)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscPress)
    }

    // Cleanup function to remove listeners
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [isOpenDropdown])

  useEffect(() => {
    if (setIsCountry) {
      setIsCountry(isOpenDropdown)
      if (isOpenDropdown) {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleEscPress)
      } else {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('keydown', handleEscPress)
      }
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleEscPress)
    }
  }, [isOpenDropdown, setIsCountry])

  useEffect(() => {
    setFilteredCountries(
      countries.filter((country) => country.label.toLowerCase().includes(searchTerm.toLowerCase())),
    )
  }, [searchTerm])

  useEffect(() => {
    if (defaultCountryValue) {
      setIsFocused(true)
    }
  }, [defaultCountryValue])

  return (
    <div className={`relative ${wrapperClass || ''}`} ref={dropdownRef}>
      {label && (
      <label htmlFor={name} className={`flex flex-row flex-wrap gap-1 mb-3 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s] font-semibold ${isFocused ? 'text-white' : 'text-gray-lighter'}`}>
        {label}
        {toolTipText && (
        <>
          <span className="relative top-[1px] cursor-pointer" data-tooltip-id={name}>
            <Icons
              nameIcon="alert-circle"
              className="w-[14px] h-[14px]"
            />
          </span>
          <Tooltip
            id={name}
            auto
            effect="solid"
            className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-bold !text-white !text-xs"
          >
            {toolTipText}
          </Tooltip>
        </>
        )}
      </label>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={selectedCountry}
        rules={validator || {}}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className="relative w-full">
              <div
                className={`inline-flex relative justify-between items-center gap-1 p-4 w-full text-base leading-6 font-normal text-white text-start bg-black-dark ease-linear rounded-xl border-2 border-solid min-w-0 opacity-90 transition-all duration-[0.2s] cursor-pointer ${!selectedCountry ? 'text-red' : ''} ${isFocused ? 'border-white/10' : 'border-transparent'}`}
                onClick={() => {
                  toggleDropdown()
                  setIsFocused(true)
                }}
                onBlur={() => {
                  if (!selectedCountry) {
                    setIsFocused(false)
                  }
                }}
              >
                {selectedCountry || placeholder}
                <Icons
                  nameIcon="chevron-down"
                  className={`w-4 h-4 ${isOpenDropdown ? 'rotate-180' : ''}`}
                />
              </div>

              <AnimatePresence>
                {isOpenDropdown && (
                <motion.div
                  ref={dropdownContentRef}
                  initial={{ opacity: 0, translateY: dropdownDirection === 'down' ? -16 : 16, visibility: 'hidden' }}
                  animate={{ opacity: 1, translateY: 0, visibility: 'visible' }}
                  exit={{ opacity: 0, translateY: dropdownDirection === 'down' ? -16 : 16, visibility: 'hidden' }}
                  transition={{ duration: 0.2 }}
                  className={`absolute ${dropdownDirection === 'up' ? 'bottom-full mb-0.5' : 'top-full mt-0.5'} z-[9] absolute inset-x-0 bg-black-dark shadow-[#ffffff33_0_0_0_2px_inset] mx-0 mt-0.5 mb-0 p-6 border-solid rounded-xl w-full overflow-hidden align-baseline`}
                >
                  <div className="border-0 m-0 mb-3 w-full align-baseline">
                    <div className="relative border-0 m-0 p-0 text-gray-lighter align-baseline">
                      <Icons
                        nameIcon="search"
                        className="inline-flex top-2/4 left-3 z-[2] absolute justify-center items-center w-6 h-6 -translate-y-3 pointer-events-none align-middle"
                      />

                      <input
                        type="text"
                        className="block border-[#72798a] border-2 bg-[initial] py-0 pr-4 pl-12 rounded-xl w-full h-11 font-medium text-sm text-white leading-[1.71429] tracking-[-0.02em] transition-[border-color] duration-[0.2s] appearance-none outline-none placeholder-[#6F737E]"
                        placeholder="Type to search country..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="border-0 -mx-6 my-0 px-6 py-0 max-h-80 overflow-y-auto align-baseline scroll-smooth scrollbar-custom">
                    <div className="w-full h-4" />
                    {filteredCountries.map((country) => (
                      <div
                        key={country.value}
                        className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 hover:opacity-80 m-0 py-2 p-0 font-semibold text-white cursor-pointer align-baseline"
                        onClick={() => {
                          field.onChange(country.value)
                          handleCountrySelect(country.label)
                        }}
                      >
                        {country.label}
                      </div>
                    ))}
                  </div>
                </motion.div>
                )}
              </AnimatePresence>
            </div>
            {error && (
            <span className="block pt-1 text-[13px] text-red-200">{error.message}</span>
            )}
          </>
        )}
      />
    </div>
  )
}

export default SimpleCountryField
