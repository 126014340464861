// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/common/Button/index.jsx

import React from 'react'
import { motion } from 'framer-motion'
import classnames from 'classnames'

const Button = ({
  children,
  onClick,
  variant = 'primary',
  size = 'md',
  fullWidth = false,
  disabled = false,
  type = 'button',
  className,
  ...props
}) => {
  const baseStyles = "inline-flex justify-center items-center font-medium text-white rounded-2xl transition-colors duration-200"

  const variants = {
    primary: "bg-[#0052ff] hover:bg-[#0148dd] disabled:opacity-50 disabled:cursor-not-allowed",
    secondary: "bg-black-light border-2 border-white/20 hover:border-white/40",
    ghost: "bg-transparent hover:bg-white/5"
  }

  const sizes = {
    sm: "px-4 h-10 text-sm",
    md: "px-6 h-14 text-base",
    lg: "px-8 h-16 text-lg"
  }

  const classes = classnames(
    baseStyles,
    variants[variant],
    sizes[size],
    {
      'w-full': fullWidth,
    },
    className
  )

  return (
    <motion.button
      type={type}
      whileTap={{ scale: disabled ? 1 : 0.98 }}
      className={classes}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </motion.button>
  )
}

export default Button
