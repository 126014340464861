// src/pages/Signup/components/SMS/CodeVerification.jsx

import React, { useState, useEffect, useRef } from "react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
import VerificationInput from "react-verification-input"
import Layout from "../Layout"
import { useMutation } from '@apollo/client'
import { VERIFY_PHONE_NUMBER, SAVE_AND_SEND_VERIFY_PHONE_NUMBER_SMS } from '../../../../queriesAndMutations'
import { SIGNUP_STEPS } from "../../constant/steps"

const CodeVerification = ({ phoneNumber, onBack, onNext }) => {
  const { t } = useTranslation()
  const [code, setCode] = useState(['', '', '', ''])
  const [timeLeft, setTimeLeft] = useState(30)
  const [isVerifying, setIsVerifying] = useState(false)
  const [error, setError] = useState('')
  const [canResend, setCanResend] = useState(false)
  const inputRefs = useRef([])
  const timerRef = useRef(null)

  const [verifyPhone] = useMutation(VERIFY_PHONE_NUMBER)
  const [resendCode] = useMutation(SAVE_AND_SEND_VERIFY_PHONE_NUMBER_SMS)

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current)
          setCanResend(true)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timerRef.current)
  }, [])

  const handleVerify = async (fullCode) => {
    setIsVerifying(true)
    setError('')

    try {
      const { data } = await verifyPhone({
        variables: {
          code: fullCode
        }
      })

      if (data && data.verifyPhoneNumber) {
        onNext()
      } else {
        throw new Error('Invalid code')
      }
    } catch (err) {
      setError(t('Invalid verification code. Please try again.'))
      setCode(['', '', '', ''])
      inputRefs.current[0].focus()
    } finally {
      setIsVerifying(false)
    }
  }

  const handleResend = async () => {
    setCanResend(false)
    setTimeLeft(220)
    setError('')

    try {
      const { data } = await resendCode({
        variables: {
          phoneNumber: `+${phoneNumber}`
        }
      })

      if (data && !data.saveAndSendVerifyPhoneNumberSms) {
        setError(t('Failed to send verification code. Please try again.'))
        setCanResend(true)
        return
      }

      clearInterval(timerRef.current)
      timerRef.current = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timerRef.current)
            setCanResend(true)
            return 0
          }
          return prev - 1
        })
      }, 1000)
    } catch (err) {
      setError(t('Failed to send verification code. Please try again.'))
      setCanResend(true)
    }
  }

  return (
    <Layout
      title={t("Enter verification code")}
      description={t(`We've sent a code to ${phoneNumber}`)}
      currentStep={3}
      totalSteps={5}
      stepType={SIGNUP_STEPS.SMS}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
        <div className="pw:mb-6 pw:text-center">
          <p className="pw:text-gray-lighter">
            {t("Code expires in")} <span className="pw:text-white">{timeLeft}s</span>
          </p>
        </div>

        <VerificationInput
          length={4}
          placeholder=""
          validChars="0-9"
          autoFocus
          onChange={(value) => setError('')}
          onComplete={handleVerify}
          classNames={{
            container: "verification-container gap-2 grid grid-cols-6 mb-6 w-full h-14",
            character: `border-[#2b2d33] border-2 border-solid focus:border-white/20 bg-black-light rounded-xl w-full h-12 text-center text-white text-base inline-flex justify-center items-center
              ${error ? 'pw:border-red-500' : 'pw:border-[#2b2d33]'}`,
            characterInactive: "character--inactive",
            characterSelected: "character--selected border-white/20",
            characterFilled: "character--filled border-white/20"
          }}
        />

        {error && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="pw:mb-6 pw:text-center pw:text-red-500"
          >
            {error}
          </motion.div>
        )}

        <div className="pw:mb-8 pw:text-center">
          <button
            onClick={handleResend}
            disabled={!canResend}
            className={`
              pw:text-sm pw:transition-colors pw:duration-200
              ${canResend
                ? 'pw:text-primary hover:pw:text-primary-hover'
                : 'pw:text-gray-lighter pw:cursor-not-allowed'
              }
            `}
          >
            {t("Resend code")}
          </button>
        </div>

        <button
          onClick={() => handleVerify(code.join(''))}
          disabled={isVerifying || code.some(digit => !digit)}
          className={`
            pw:w-full pw:inline-flex pw:justify-center pw:items-center pw:px-6 pw:rounded-2xl pw:h-14
            pw:font-medium pw:text-white pw:transition-all pw:duration-200
            ${isVerifying || code.some(digit => !digit)
              ? 'pw:bg-gray-700 pw:cursor-not-allowed'
              : 'pw:bg-primary hover:pw:bg-primary-hover'
            }
          `}
        >
          {isVerifying ? t('Verifying...') : t('Verify Code')}
        </button>
      </div>
    </Layout>
  )
}

export default CodeVerification
