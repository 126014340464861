// src/pages/Signup/components/Authenticator/AuthenticatorApp.jsx

import React from "react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
import Layout from "../Layout"
import Icons from "../../../../components/shared/Icons"

import { SIGNUP_STEPS } from "../../constant/steps"

const StoreButton = ({ platform, icon, link }) => {
  const { t } = useTranslation()
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="pw:inline-flex pw:justify-center pw:items-center pw:gap-1 hover:pw:bg-gray-lighter pw:bg-black-light pw:shadow-[#6F737E_0_0_0_1px_inset] pw:m-0 pw:px-6 pw:rounded-[40px] pw:max-767:w-[initial] pw:w-full pw:h-10 pw:font-medium pw:text-[100%] pw:text-sm pw:text-white pw:normal-case pw:leading-none pw:transition-colors pw:duration-[0.2s] pw:cursor-pointer pw:overflow-visible pw:backface-visibility-visible"
    >
      <Icons nameIcon={icon} className="pw:w-6 pw:h-6 pw:text-white" />
      <span className="pw:text-white">{t(platform)}</span>
    </a>
  )
}

const AuthenticatorApp = ({ onBack, onNext }) => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t("Get your code from Google Authenticator")}
      description={t("Now you can open and copy your code, then return to this screen to enter it")}
      currentStep={3}
      totalSteps={5}
      onBack={onBack}
      stepType={SIGNUP_STEPS.AUTHENTICATOR}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="pw:mx-auto pw:w-full pw:max-w-[480px]"
      >
        <div className="pw:bg-black-light pw:mb-8 pw:p-6 pw:rounded-xl">
          <h3 className="pw:mb-4 pw:font-semibold pw:text-center pw:text-white">
            {t("Download Google Authenticator if you haven't:")}
          </h3>
          <div className="pw:max-767:flex pw:max-767:flex-row pw:max-767:flex-wrap pw:justify-center pw:max-767:justify-center pw:items-stretch pw:max-767:items-center pw:gap-x-4 pw:gap-y-4 pw:border-0 pw:grid pw:grid-cols-[1fr_1fr] pw:grid-rows-[auto] pw:auto-cols-[1fr] pw:mx-auto pw:my-0 pw:px-2 pw:p-0 pw:align-baseline">
            <StoreButton
              platform="iOS"
              icon="apple-app"
              link="https://apps.apple.com/app/google-authenticator/id388497605"
            />
            <StoreButton
              platform="Android"
              icon="google-app"
              link="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            />
          </div>
        </div>

        <div className="pw:mb-8 pw:text-center">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
          <a
            href="#"
            className="hover:pw:text-primary pw:text-white pw:transition-colors pw:duration-200"
          >
            {t("Learn more about Google Authenticator")}
          </a>
        </div>

        <button
          onClick={onNext}
          className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200"
        >
          {t("I have Google Authenticator")}
        </button>
      </motion.div>
    </Layout>
  )
}

export default AuthenticatorApp
