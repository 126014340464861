import React from 'react'

const AccountTypeCard = ({
  id,
  title,
  description,
  isSelected,
  onClick
}) => {
  return (
    <label
      className={`
        pw:flex pw:flex-row pw:justify-start pw:items-stretch pw:mb-4 pw:border-2 pw:bg-black-light pw:rounded-xl pw:min-h-[120px] pw:cursor-pointer pw:border-solid
        ${isSelected ? "pw:border-primary" : "pw:border-[#2b2d33]"}
      `}
    >
      <input
        type="radio"
        name="account-type"
        id={id}
        checked={isSelected}
        onChange={() => onClick(id)}
        className="pw:absolute pw:border-0 pw:bg-[0_0] pw:bg-[initial] pw:opacity-0 pw:m-0 pw:p-0 pw:border-solid pw:rounded-none pw:font-[inherit] pw:text-inherit pw:leading-[inherit] pw:overflow-visible pw:align-middle"
      />

      <div
        onClick={onClick}
        className="pw:flex pw:flex-col pw:justify-center pw:px-8 pw:py-12"
      >
        <div className="pw:m-0 pw:pb-2 pw:font-semibold pw:text-base pw:text-start pw:text-white pw:leading-6 pw:transition-[color] pw:duration-[0.02s] pw:ease-[ease-out]">
            {title}
        </div>

        <div className="pw:m-0 pw:font-normal pw:text-gray-custom pw:text-sm pw:text-start pw:leading-5 pw:transition-[color] pw:duration-[0.02s] pw:ease-[ease-out]">
            {description}
        </div>
      </div>
    </label>
  )
}

export default AccountTypeCard
