import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import Loader from '../../../../components/shared/Loader'
import Icons from '../../../../components/shared/Icons'

import { DISABLE_2FA } from '../../../../queriesAndMutations'

const DisableTwoFactorAuth = ({
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [twoFaToken, setTwoFaToken] = useState('')
  const [step, setStep] = useState(0)
  const [isFocusedConfirmDisabling, setIsFocusedConfirmDisabling] = useState(false);
  const [error, setError] = useState('')
  const [disable2Fa, disable2FaStatus] = useMutation(DISABLE_2FA)

  const validateField = () => {
    if (!twoFaToken) {
      setError(t('Please enter 2FA token'))
      return false
    }
    setError('')
    return true
  }

  const onSubmitToken = async (event) => {
    event.preventDefault()
    if (!validateField()) return

    try {
      await disable2Fa({ variables: { token: twoFaToken } })
      await onComplete()
      setStep(2)
    } catch (error) {
      setError(error.message || t('Invalid 2FA token'))
    }
  }

  const renderFirstStep = () => (
    <>
      <form onSubmit={onSubmitToken} className="">
        <div className='relative'>
          <label
            htmlFor="confirm_disabling"
            className={`flex font-semibold flex-row flex-wrap gap-1 mb-3 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors font-sans duration-[0.2s] ${isFocusedConfirmDisabling ? 'text-white' : 'text-gray-lighter'}`}
          >
            Disabling 2FA
            <span className='relative top-[1px] cursor-pointer' data-tooltip-id="confirm_disabling">
              <Icons
                nameIcon='alert-circle'
                className="w-[14px] h-[14px]"
              />
            </span>
            <Tooltip
              id="confirm_disabling"
              auto
              effect="solid"
              className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-bold font-sans !text-white !text-xs"
            >
              {t('To confirm disabling 2FA, please, enter 2FA token')}
            </Tooltip>
          </label>

          <div className="relative">
            <input
              type="text"
              id="confirm_disabling"
              placeholder='Enter 2FA token'
              className={`!border-solid w-full text-base leading-6 font-normal min-w-0 text-white  text-start !p-4 bg-[#1e2025] transition-all duration-[0.2s] ease-linear rounded-xl outline-none border-2  font-sans ${isFocusedConfirmDisabling ? 'border-white/10 opacity-100' : 'border-white/5 opacity-90'}`}
              onFocus={() => setIsFocusedConfirmDisabling(true)}
              onBlur={() => {
                if (!twoFaToken) {
                  setIsFocusedConfirmDisabling(false)
                }
                validateField()
              }}
              value={twoFaToken}
              onChange={({ target: { value } }) => {
                setTwoFaToken(value)
                if (error) validateField()
              }}
            />
          </div>
          {error && (
            <span className="block relative pt-1 font-sans text-[13px] text-left text-red-500 tracking-[-0.02em]">
              {error}
            </span>
          )}
        </div>

        <div className="flex justify-center mt-6 w-full">
          <button type='submit' className='inline-flex justify-center items-center gap-1 bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 rounded-3xl h-12 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none'>
            {t('Confirm')}

            {disable2FaStatus.loading && (
              <Loader className='scale-75' />
            )}
          </button>
        </div>
      </form>
    </>
  )

  const renderSuccess = () => (
    <>
      <div className="popup__info">
        {t('Success! 2FA was successfully disabled!')}
      </div>
      <div className="popup__btns">
        <button type="button" onClick={onComplete} className="js-popup-close button popup__button">{t('Close')}</button>
      </div>
    </>
  )

  return (
    <>
      {/* <Stepper activeStep={step} className="stepper__wrapper">
        <Step key={1} className="stepper__item">
          <StepLabel>{t('Confirmation')}</StepLabel>
        </Step>
        <Step key={2} className="stepper__item">
          <StepLabel>{t('Success')}</StepLabel>
        </Step>
      </Stepper>
      <div className="popup__info">{' '}</div> */}
      <>
        {step === 0 && renderFirstStep()}
        {step === 2 && renderSuccess()}
      </>
    </>
  )
}

DisableTwoFactorAuth.propTypes = {
  onClose: propTypes.func.isRequired,
  onComplete: propTypes.func,
}

export default DisableTwoFactorAuth
