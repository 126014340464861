import { useState } from 'react';
import { useMutation, useLazyQuery, gql } from '@apollo/client';
import { toaster } from '../utils';

const CHECK_USER_EXISTS = gql`
  query CheckUserExists($email: String, $wallet: String) {
    checkUserExists(email: $email, wallet: $wallet)
  }
`;

const REQUEST_EMAIL_CODE_FOR_LOGIN = gql`
  mutation RequestEmailCodeForLogin($email: String!) {
    requestEmailVerificationCodeForLogin(email: $email)
  }
`;

const REQUEST_EMAIL_CODE_FOR_REGISTRATION = gql`
  mutation RequestEmailCodeForRegistration($email: String!) {
    requestEmailVerificationCodeForRegistration(email: $email)
  }
`;

const VERIFY_EMAIL_AND_REGISTER = gql`
  mutation VerifyEmailAndRegister($email: String!, $code: String!) {
    verifyEmailAndRegister(email: $email, code: $code) {
      accessToken
      refreshToken
    }
  }
`;

const VERIFY_EMAIL_AND_LOGIN = gql`
  mutation VerifyEmailAndLogin($email: String!, $code: String!, $twoFaToken: String) {
    verifyEmailAndLogin(email: $email, code: $code, twoFaToken: $twoFaToken) {
      token {
        accessToken
        refreshToken
      }
      requireTwoFactor
    }
  }
`;

export const useEmailVerification = (isRegistration = false) => {
  const [loading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [countdown, setCountdown] = useState(0);

  // Check if email exists query
  const [checkUser] = useLazyQuery(CHECK_USER_EXISTS, {
    onError: (error) => {
      toaster.error(error.message);
    }
  });

  // Request code mutations
  const [requestEmailCodeForLogin] = useMutation(REQUEST_EMAIL_CODE_FOR_LOGIN);
  const [requestEmailCodeForRegistration] = useMutation(REQUEST_EMAIL_CODE_FOR_REGISTRATION);

  // Verify code mutations
  const [verifyAndRegister] = useMutation(VERIFY_EMAIL_AND_REGISTER);
  const [verifyAndLogin] = useMutation(VERIFY_EMAIL_AND_LOGIN);

  // Start countdown timer
  const startCountdown = () => {
    setCountdown(60);
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Send verification code
  const sendVerificationCode = async (email) => {
    try {
      setLoading(true);

      if (isRegistration) {
        // Check if email exists for registration
        const { data: existsData } = await checkUser({
          variables: { email }
        });

        console.log("existsData", existsData);

        if (existsData && existsData.checkUserExists) {
          toaster.error('Email already exists');
          return false;
        }

        await requestEmailCodeForRegistration({
          variables: {
            email
          }
        });
      } else {
        await requestEmailCodeForLogin({
          variables: { email }
        });
      }

      setCodeSent(true);
      startCountdown();
      return true;
    } catch (error) {
      toaster.error(error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Verify code and complete authentication
  const verifyCode = async (email, code, twoFaToken) => {
    try {
      setLoading(true);

      if (isRegistration) {
        const { data } = await verifyAndRegister({
          variables: { email, code }
        });
        return data.verifyEmailAndRegister;
      } else {
        const { data } = await verifyAndLogin({
          variables: { email, code, twoFaToken }
        });
        return data.verifyEmailAndLogin;
      }
    } catch (error) {
      toaster.error(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    codeSent,
    countdown,
    sendVerificationCode,
    verifyCode,
    canResend: countdown === 0,
    checkUser,
  };
};

export default useEmailVerification;
