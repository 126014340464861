import React, { useState, useEffect } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import Loader from '../components/Loader'
import { TENANT_STATUS } from '../constants/tenant'
import {
  GET_TENANT,
  SETUP_TENANT_SSL,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import ControlDashboardBreadcrumbs from '../components/ControlDashboardBreadcrumbs'

const TenantSetupDns = ({ match }) => {
  const { t } = useTranslation()
  const tenantId = +match.params.tenantId
  const {
    data: { getTenant: tenant } = {},
    loading,
    error,
  } = useQuery(GET_TENANT, {
    variables: {
      id: tenantId,
    },
  })

  const [redirectToDashboard, setRedirectToDashboard] = useState(false)
  useEffect(() => {
    if (error) {
      setRedirectToDashboard(true)
      return
    }

    if (tenant && tenant.status !== TENANT_STATUS.AWAIT_DNS_SETUP) {
      toaster.warning('DNS setup is not required for now.')
      setRedirectToDashboard(true)
    }
  }, [tenant, error])

  const [setupTenantSsl, setupTenantSslStatus] = useMutation(SETUP_TENANT_SSL)

  const renderTenantName = (domain) => {
    const parts = domain.split('.')

    if (parts.length > 2) {
      parts.splice(parts.length - 2, 2)
      return parts.join('.')
    }

    return '@'
  }

  const verifyDomain = () => {
    setupTenantSsl({
      variables: {
        id: tenantId,
      },
    }).then(() => {
      toaster.success('DNS setup is valid')
      setTimeout(() => {
        window.location.href = `${window.location.origin}/dashboard`
      }, 1000)
    }).catch(() => {})
  }

  if (redirectToDashboard) {
    return <Redirect to="/dashboard" />
  }

  return (
    <>
      <ControlDashboardBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/dashboard" className="breadcrumbs__link">{t('Dashboard')}</NavLink>
        </li>
      </ControlDashboardBreadcrumbs>

      <div className="section pt80 faq discover tenant">
        <div className="discover__center tenant__center center">
          { loading && (
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "2rem" }}>
              <Loader />
            </div>
          )}

          {!loading && (
            <>
              <div className="faq__top tenant__top">
                <div className="faq__title tenant__title h2">
                  {t('Setup DNS')}
                  {tenant && ` for ${tenant.host}`}
                </div>

                <div className="faq__info discover__info">
                  {t('To setup the DNS, you can go to DNS setting of your domain and add a DNS record with following information')}
                </div>
              </div>

              <div className="faq__row tenant__row">
                <div className="faq__col tenant__col">
                  <div className="investhub-table__wrapper">
                    <div className="investhub-table__box investhub-table__box-styled">
                      <div className="investhub-table__row">
                        <div className="investhub-table__col">
                          {t('Type')}
                        </div>
                        <div className="investhub-table__col">
                          {t('Name')}
                        </div>
                        <div className="investhub-table__col">
                          {t('Content')}
                        </div>
                        <div className="investhub-table__col">
                          {t('Proxy')}
                        </div>
                      </div>

                      <div className="investhub-table__row">
                        <div className="investhub-table__col">
                          <div className="investhub-table__label">
                            {t('Type')}
                          </div>
                          {t('CNAME')}
                        </div>

                        <div className="investhub-table__col">
                          <div className="investhub-table__label">
                            {t('Name')}
                          </div>
                          {tenant && renderTenantName(tenant.host)}
                        </div>

                        <div className="investhub-table__col">
                          <div className="investhub-table__label">
                            {t('Content')}
                          </div>
                          app.investhub.io
                        </div>

                        <div className="investhub-table__col">
                          <div className="investhub-table__label">
                            {t('Proxy')}
                          </div>
                          {t('DNS only')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="footer__text" style={{ marginTop: "3rem", marginBottom: "0" }}>
                    <p>
                      {t('When it has been set up, click on button below to verify and complete setup.')}
                    </p>
                    <p>
                      <em>
                        <strong>
                          {t('Note')}
                          :
                          {' '}
                        </strong>
                        {t('The DNS may take a few minutes to apply the change.Therefore, if the app cannot get the DNS record, please try again after a few minutes.')}
                      </em>
                    </p>
                  </div>

                  {setupTenantSslStatus.loading ? (
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: "2rem" }}>
                      <Loader />
                    </div>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={verifyDomain}
                        className="button-stroke button-small faq__button"
                      >
                        {t('Verify DNS setup')}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

TenantSetupDns.propTypes = {
  match: PropTypes.object,
}

export default TenantSetupDns
