// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/steps/Complete/index.jsx

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import confetti from 'canvas-confetti'
import { motion } from 'framer-motion'
import Button from '../../common/Button'
import StepIndicator from '../../common/StepIndicator'

const Complete = ({
  onNext,
  progress,
  currentStep,
  totalSteps,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const duration = 3 * 1000
    const animationEnd = Date.now() + duration
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min
    }

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        clearInterval(interval)
        return
      }

      const particleCount = 50 * (timeLeft / duration)

      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      })
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      })
    }, 250)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div className="flex flex-col flex-1 justify-center items-center gap-4 px-4 py-6 text-center">

        <div className="w-full max-w-md">
          <div className="mb-4">
            <h1 className="font-medium text-4xl text-white leading-[1.1875] tracking-[-0.02em]">
              {t('questionnaire.complete.title')}
            </h1>
            <p className="mt-2 text-gray-lighter">
              {t('questionnaire.complete.subtitle')}
            </p>
          </div>

          {/* Success Icon */}
          <div className="flex justify-center mb-8">
            <div className="inline-flex justify-center items-center bg-primary/10 rounded-full w-20 h-20">
              <div className="text-primary">
                <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 6L9 17L4 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-0 sticky bg-black-dark px-4 py-6 w-full">
        <Button
          fullWidth
          size="md"
          onClick={onNext}
        >
          {t('questionnaire.complete.close')}
        </Button>
      </div>
    </>
  )
}

export default Complete
