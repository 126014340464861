import React from 'react'
import cn from 'classnames'

const HeadingTab = ({ index, activeTab, onClick, children }) => (
  <button
    type="button"
    onClick={() => onClick(index)}
    className={cn(
      "inline-flex justify-center items-center gap-1  m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]",
      {
        " bg-gray-700": index === activeTab,
        "bg-black-light hover:bg-gray-700 hover:opacity-90": index !== activeTab
      }
    )}
  >
    {children}
  </button>
)

export default HeadingTab
