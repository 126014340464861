import React from 'react'
// import PageHeader from '../Header'
// import PageFooter from '../Footer'

import SiteHeader from '../SiteHeader/SiteHeader'
import SiteFooter from '../SiteFooter/SiteFooter'

const Page = ({ children }) => (
  <div className="outer pagewrap v2">
    <SiteHeader />
    <div className="outer__inner">
      {children}
    </div>

    <SiteFooter />
  </div>
)

export default Page
