// src/services/languageDetection.js

import { supportedLanguages } from '../utils/lang';

const COUNTRY_LANGUAGE_MAP = {
  US: 'en',
  GB: 'en',
  DE: 'de',
  IT: 'it',
  BG: 'bg',
  HR: 'hr',
  CZ: 'cs',
  DK: 'da',
  NL: 'nl',
  EE: 'et',
  FI: 'fi',
  FR: 'fr',
  GR: 'el',
  HU: 'hu',
  IS: 'is',
  IE: 'ga',
  LV: 'lv',
  LT: 'lt',
  LU: 'lb',
  MT: 'mt',
  NO: 'no',
  PL: 'pl',
  PT: 'pt',
  RO: 'ro',
  SK: 'sk',
  SI: 'sl',
  ES: 'es',
  SE: 'sv',
  TR: 'tr'
};

const COUNTRY_CURRENCY_MAP = {
  US: 'usdc',
  GB: 'eure',
  DE: 'eure',
  IT: 'eure',
  SG: 'xsgd',
  CH: 'zchf',
};

const getCountry = async () => {
  if (process.env.NODE_ENV === 'development') {
    return 'IT';
  }

  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();

    return data.country_code;
  } catch (error) {
    console.error('Error:', error);
    return 'US';
  }
};

export const detectUserCountry = async () => {
  return getCountry();
};

export const getLanguageFromCountry = (countryCode) => {
  const languageCode = COUNTRY_LANGUAGE_MAP[countryCode] || 'en';
  return supportedLanguages[languageCode];
};

export const getCountriesForLanguage = (languageCode) => {
  return Object.entries(COUNTRY_LANGUAGE_MAP)
    .filter(([_, langCode]) => langCode === languageCode)
    .map(([country]) => country);
};

export const getCurrencyFromCountry = (countryCode) => {
  return COUNTRY_CURRENCY_MAP[countryCode] || 'eure';
};
