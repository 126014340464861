import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

const TextFieldPrefixEnd = ({
  name,
  placeholder,
  label,
  validator,
  defaultValue,
  customClassOnField,
  textPrefix,
  onFocus,
  onBlur,
}) => {
  const { control } = useFormContext()

  return (
    <div className="relative field__wrapper">
      {label && <label htmlFor={name} className="mb-2 font-semibold">{label}</label>}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        rules={validator ? {
          required: 'Text is required',
          pattern: {
            value: /.{3,}/,
            message: 'Textfield must be at least 3 characters long',
          },
        } : {}}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className="relative">
              <input
                {...field}
                type="text"
                id={name}
                className={`${customClassOnField} w-full text-base leading-6 font-normal min-w-0 text-white opacity-90 text-start p-4 pr-24 pl-0 bg-black-light transition-colors duration-[0.2s] ease-linear focus:bg-gray-700 rounded-xl outline-none`}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              {textPrefix && <span className="right-0 absolute inset-y-0 flex items-center pr-3 text-white">{textPrefix}</span>}
            </div>
            {error && <span className="block relative pt-1 text-[13px] text-red-200 tracking-[-0.02em]">{error.message}</span>}
          </>
        )}
      />
    </div>
  )
}

export default TextFieldPrefixEnd
