/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from 'react'
import moment from 'moment';
import useAddressAssets from "../../../../../../../myHooks/useAddressAssets"
import tokens from "../../../../../../../services/listDataToken"
import MyAssets from './MyAssets'
import TokenList from './TokenList'
import TransactionList from './TransactionList'

const TabContent = ({ tabHeading, user, onClose }) => {
  const { balances, loading, transactions, totalValue } = useAddressAssets(user.wallet_address)

  const getNetworkName = (chainIndex) => {
    const networks = {
      '137': 'Polygon',
      '8453': 'Base',
    };
    return networks[chainIndex] || 'Unknown';
  };

  const getNetworkImage = (chainIndex) => {
    const networks = {
      '137': 'polygon.png',
      '8453': 'base.svg',
      '1': 'ethereum.png',
      '11155111': 'ethereum.png',
    };
    return networks[chainIndex] || 'Unknown';
  };

  const getTransactionType = (methodId) => {
    if (methodId) {
      switch (methodId) {
        case '0x095ea7b3': return 'approve'
        case '0x23b872dd': return 'transfer'
        default: return 'contract'
      }
    }
    return ''
  }

  const formatRelativeTime = (timestamp) => {
    const now = moment()
    const time = moment(parseInt(timestamp))
    const diff = now.diff(time, 'seconds')

    if (diff < 60) return `${diff}s`
    if (diff < 3600) return `${Math.floor(diff/60)}m`
    if (diff < 86400) return `${Math.floor(diff/3600)}h`
    if (diff < 2592000) return `${Math.floor(diff/86400)}d`
    if (diff < 31536000) return `${Math.floor(diff/2592000)}mo`
    return `${Math.floor(diff/31536000)}y`
  }

  const formatBalances = (balances) => {
    return balances.map(balance => {
      return {
        ...balance,
        network: {
          name: getNetworkName(balance.chainIndex),
          symbol: 'ETH',
          image: getNetworkImage(balance.chainIndex)
        },
      }
    })
  }

  const formatTransactions = (txList) => {
    if (!txList) return [];

    return txList.map(tx => {
      const token = tokens.find(t => t.symbol === tx.symbol) || { image: 'default-token.png' };

      return {
        name: tx.symbol,
        symbol: tx.symbol,
        image: token.image,
        network: {
          name: getNetworkName(tx.chainIndex),
          symbol: 'ETH',
          image: getNetworkImage(tx.chainIndex)
        },
        type: tx.methodId ? getTransactionType(tx.methodId) : tx.from[0].address === user.wallet_address ? 'sent' : 'received',
        amount: `${tx.amount} ${tx.symbol}`,
        value: tx.txFee ? `${tx.txFee} ETH` : '',
        to: tx.to[0].address.slice(0, 6) + '...' + tx.to[0].address.slice(-4),
        from: tx.from[0].address.slice(0, 6) + '...' + tx.from[0].address.slice(-4),
        contract: tx.methodId ? tx.to[0].address.slice(0, 6) + '...' + tx.to[0].address.slice(-4) : undefined,
        timestamp: new Date(parseInt(tx.txTime)).toLocaleString(),
        datetime: formatRelativeTime(tx.txTime),
        status: tx.txStatus,
        tag: tx.tag,
        chainIndex: tx.chainIndex,
        txHash: tx.txHash,
        txStatus: tx.txStatus
      };
    });
  };

  const getImageUrl = (type, imageName) => {
    try {
      return require(`../../../../../../../assets/images/${type}/${imageName}`);
    } catch (err) {
      return require(`../../../../../../../assets/images/issuer/default-icon-day-v3.svg`)
    }
  }

  const content = {
    "My Assets": <MyAssets loading={loading} totalValue={totalValue} />,
    "Tokens": <TokenList loading={loading} balances={formatBalances(balances)} tokens={tokens} getImageUrl={getImageUrl} />,
    "Transactions": <TransactionList
      loading={loading}
      transactions={formatTransactions(transactions)}
      getImageUrl={getImageUrl}
      onClose={onClose}
    />
  }

  return <>{content[tabHeading]}</>
}

export default TabContent
