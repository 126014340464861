// src/pages/SignIn/components/steps/GetHelpModal.jsx

import React from "react";
import { ModalWide } from "../../../../components/shared/Modals";

const HelpOption = ({ icon, title, description, onClick }) => (
  <button
    onClick={onClick}
    className="flex flex-col flex-nowrap justify-between items-start max-767:gap-6 border-[2px] hover:border-white/20 bg-black-light p-6 max-767:p-4 border-transparent border-solid rounded-[24px] min-h-[108px] max-767:min-h-[initial] text-white transition-all duration-[0.2s] ease-linear cursor-pointer outline-none"
  >
    <div className="flex flex-row gap-x-4 gap-y-4">
      <div className="inline-flex relative justify-center items-center bg-black-dark mt-1 rounded-[50%] w-8 h-8" />

      <div className="flex flex-col flex-1 pl-2">
        <div className="m-0 pb-2 font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.15s] ease-[ease-out]">
          {title}
        </div>

        <div className="flex-1 m-0 font-normal text-[13px] text-base text-gray-lighter text-start leading-6 transition-[color] duration-[0.02s] ease-[ease-out]">
          {description}
        </div>
      </div>
    </div>
  </button>
);

const GetHelpModal = ({ isOpen, onClose, email }) => {
  const handleResendCode = () => {
    // Implement resend code logic
    onClose();
  };

  const handleTrySMS = () => {
    // Implement SMS fallback logic
    onClose();
  };

  const handleContactSupport = () => {
    // Open support in new tab
    window.open("https://support.investhub.com", "_blank");
    onClose();
  };

  return (
    <ModalWide
      isOpen={isOpen}
      onClose={onClose}
      title="Need help signing in?"
      subtitle={`We'll help you get back into your account for ${email}`}
    >
      <div className="flex flex-col gap-3">
        <HelpOption
          icon="refresh"
          title="Resend verification code"
          description="Get a new code sent to your authenticator app"
          onClick={handleResendCode}
        />

        <HelpOption
          icon="smartphone"
          title="Use SMS verification instead"
          description="We'll send a code to your registered phone number"
          onClick={handleTrySMS}
        />

        <HelpOption
          icon="headset"
          title="Contact Support"
          description="Get help from our support team"
          onClick={handleContactSupport}
        />

        <div className="mt-2 text-[13px] text-center text-gray-lighter">
          You can also read our{" "}
          <a
            href="https://help.investhub.com/2fa-troubleshooting"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 font-semibold text-white transition-opacity duration-200"
          >
            2FA troubleshooting guide
          </a>
        </div>
      </div>
    </ModalWide>
  );
};

export default GetHelpModal;
