import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_AUTHORIZATION_URL = gql`
  query GetAuthorizationUrl($context: String) {
    authorizationUrl(context: $context) {
      authorizationUrl
      nonce
    }
  }
`;
