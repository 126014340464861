import React, { useEffect } from 'react'
// import { useHotkeys } from 'react-hotkeys-hook'
import { motion, AnimatePresence } from 'framer-motion'
import { UseIsMax767 } from '../../../../../../shared/MediaQueries'
import cn from 'classnames'
import Icons from '../../../../../../shared/Icons'

const overlayVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  }
}

const sidebarVariants = {
  desktop: {
    closed: { x: 320 },
    open: { x: 0 }
  },
  mobile: {
    closed: { y: '100%' },
    open: { y: 0 }
  }
}

const ContentToggle = ({ isOpen, onClose, children }) => {
  // useHotkeys('esc', onClose)
  const isMobile = UseIsMax767()

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          className={cn(
            "top-2 z-[999] fixed flex flex-row h-[calc(100vh-16px)] max-767:top-0 max-767:h-full max-767:w-full right-1 max-767:right-0"
          )}
          variants={overlayVariants}
          initial="closed"
          animate="open"
          exit="closed"
          transition={{ duration: 0.2 }}
        >

          {/* Sidebar panel */}
          <motion.div
            className="flex w-full"
            variants={isMobile ? sidebarVariants.mobile : sidebarVariants.desktop}
            initial="closed"
            animate="open"
            exit="closed"
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 30
            }}
          >
            {/* Cursor close drawer */}
            {!isMobile && (
              <motion.div
                className="bg-gray-lighter/10 hover:bg-gray-lighter/30 hover:-mr-2 py-6 pr-7 pl-4 rounded-tl-3xl rounded-tr-none rounded-bl-3xl rounded-br-none h-full transition-all duration-200 cursor-pointer group"
                onClick={onClose}
              >
                <Icons
                  nameIcon='chevron-right'
                  className="group-hover:text-white/60 w-6 h-6 text-white transition-color duration-200"
                />
              </motion.div>
            )}

            {/* Content */}
            <div className="relative max-767:top-0 max-767:absolute flex flex-col flex-nowrap border-white/15 bg-black-dark border border-solid rounded-xl max-767:rounded-none w-[320px] max-767:w-full max-w-[320px] max-767:max-w-full h-full scrollbar-custom">
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ContentToggle
