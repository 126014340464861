/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React from "react";

const Invest = ({ values, currentIssuer }) => {
  console.log('values', values)

  const getNetworkImage = (network) => {
    const networks = {
      'polygon': 'polygon.png',
      'base': 'base.svg',
      'ethereum': 'ethereum.png',
      'mainnet': 'ethereum.png',
    };
    return networks[network] || 'Unknown';
  };

  const getImageUrl = (type, imageName) => {
    try {
      return require(`../../../../../assets/images/${type}/${imageName}`);
    } catch (err) {
      return require(`../../../../../assets/images/issuer/default-icon-day-v3.svg`)
    }
  }

  return (
    <div className="relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent">
      <div className="text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
        Invest
      </div>

      <div className="flex flex-row flex-wrap justify-between items-center mt-2">
        <div className="flex flex-col flex-wrap flex-1 justify-start items-start">
          <div className="relative flex-auto bg-transparent opacity-100 p-0 border-none w-full max-h-11 text-4xl text-ellipsis text-left text-white placeholder:text-[#6F737E] tracking-[-0.02em] transition-opacity duration-[250ms] overflow-hidden pointer-events-auto ease-[ease-in-out] outline-none">
            {values.token_amount} {values.token_name}
          </div>

          <div className="mt-[6px] text-[13px] text-white/70 leading-[1.2] tracking-[-0.02em]">
            -
          </div>
        </div>

        <div className="flex flex-col justify-start items-start">
          <div className="mb-6 rounded-full w-10 h-10 overflow-hidden">
            <img
              src={getImageUrl('tokenSymbol', getNetworkImage(currentIssuer.network))}
              alt={currentIssuer.network}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invest;
