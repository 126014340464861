import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import Icons from '../../components/shared/Icons';
import NAVIGATION_ITEMS from './constant/menuConfig';
import { dropdownFilterVariants } from '../../utils/animations';

const MobileNavigation = ({ activeIndex, showSelect, onNavigate, onToggleSelect }) => {
  return (
    <div className="relative w-full">
      <button
        className={`w-full block relative bg-none m-0 py-0 pr-0 pl-4 border-none rounded-xl font-sans h-10 font-semibold text-left text-sm leading-10 tracking-[-0.02em] transition-all duration-[0.2s] cursor-pointer select-none
        ${showSelect ? 'text-white !shadow-[#ffffff33_0_0_0_2px_inset]' : 'text-white/70 !shadow-[#ffffff33_0_0_0_2px_inset]'}`}
        onClick={() => onToggleSelect()}
      >
        <div className="block z-50 text-nowrap overflow-hidden text-overflow-ellipsis">
          {NAVIGATION_ITEMS[activeIndex].label}
          <Icons
            nameIcon='chevron-down'
            className={`w-4 h-4 absolute right-4 top-3 ${showSelect ? 'rotate-180' : ''}`}
          />
        </div>

        <AnimatePresence>
          {showSelect && (
            <motion.ul
              {...dropdownFilterVariants}
              className="top-full z-[9] absolute inset-x-0 bg-[#1e2025] shadow-[#ffffff33_0_0_0_2px_inset] mx-0 mt-0.5 mb-0 px-0 py-2 border-solid rounded-xl w-full overflow-hidden"
            >
              {NAVIGATION_ITEMS.map((item, index) => (
                <li
                  key={item.id}
                  className={`block border-0 bg-none m-0 px-4 py-2 min-h-[auto] font-semibold text-left text-sm
                  leading-6 tracking-[-0.02em] transition-all duration-[0.2s] cursor-pointer font-sans
                  ${index === activeIndex ? "text-[#0052ff]" : "text-white/70 hover:text-white"}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onNavigate(index);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </motion.ul>
          )}
        </AnimatePresence>
      </button>
    </div>
  );
};

export default MobileNavigation;
