export const generateIssuerUrl = (issuer) => {
  if (!issuer || !issuer.url_detail_issuer) {
    return null;
  }

  // Ensure URL is properly formatted
  const cleanUrl = issuer.url_detail_issuer.replace(/^explore\/tokens\//, '').trim();
  return cleanUrl || null;
}

export const findIssuerByIdentifier = (issuers, fullPath) => {
  if (!issuers || !issuers.length || !fullPath) return null;

  // Check exact match first
  const exactMatch = issuers.find(issuer => {
    const issuerPath = issuer.url_detail_issuer.replace(/^explore\/tokens\//, '')
    return issuerPath.toLowerCase().trim() === fullPath.toLowerCase().trim()
  });

  if (exactMatch) return exactMatch;

  // Check for FL-number format
  if (fullPath.startsWith('FL-')) {
    return issuers.find(issuer => {
      if (!issuer || !issuer.incorporation_country || !issuer.incorporation_country.url) return false;
      const match = issuer.incorporation_country.url.match(/FL-[\d.-]+/);
      if (!match) return false;
      return fullPath.includes(match[0]);
    });
  }

  // Check for contract address match
  if (fullPath.includes('/0x')) {
    const addressFromPath = fullPath.split('/').pop();
    return issuers.find(issuer => {
      const issuerPath = issuer.url_detail_issuer.replace(/^explore\/tokens\//, '')
      return issuerPath.toLowerCase().includes(addressFromPath.toLowerCase());
    });
  }

  return null;
};
