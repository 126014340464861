import React from 'react'
// import PageFooter from '../Footer'
import SiteFooter from '../SiteFooter/SiteFooter'
import AppAdminBar from '../common/AppAdminBar'

const PageAdminDashboard = ({ children }) => (
  <div className="admin outer pagewrap v2">
    <AppAdminBar />
    <div className="outer__inner">
      {children}
    </div>

    <SiteFooter />
  </div>
)

export default PageAdminDashboard
