import React, { useState } from 'react'
import Icons from '../Icons'

const ShortToken = ({ original = '', hasCopy }) => {
  const [icon, setIcon] = useState('copy')

  const handleCopy = () => {
    if (!original) return

    navigator.clipboard.writeText(original).then(() => {
      setIcon('checks')
      setTimeout(() => setIcon('copy'), 300)
    })
  }

  const getShortAddress = () => {
    if (!original || original.length < 10) return original
    return `${original.slice(0, 6)}...${original.slice(-4)}`
  }

  return (
    <>
      {hasCopy ? (
        <button
          type="button"
          className="inline-flex justify-start items-center bg-transparent p-0 outline-none"
          onClick={handleCopy}
        >
          {getShortAddress()}
          <Icons
            nameIcon={icon}
            className="inline-flex ml-2 w-4 h-4"
          />
        </button>
      ) : (
        <>{getShortAddress()}</>
      )}
    </>
  )
}

export default ShortToken
