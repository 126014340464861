import React, { useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import Icons from '../../../../components/shared/Icons';

const ActionDropdown = ({
  buttonRect,
  isOpen,
  setOpenDropdownId,
  handleNavigateToDetail,
  handleNavigateToInvestment,
  token,
  cap
}) => {
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  if (!buttonRect) return null;

  const dropdownWidth = 200;
  const windowHeight = window.innerHeight;
  const spaceBelow = windowHeight - buttonRect.bottom;

  const shouldShowAbove = spaceBelow < 150;
  const top = shouldShowAbove ? buttonRect.top - 8 : buttonRect.bottom + 8;

  let left = buttonRect.right - dropdownWidth;
  if (left < 0) left = 0;
  if (left + dropdownWidth > window.innerWidth) {
    left = window.innerWidth - dropdownWidth;
  }

  const tableContainer = document.querySelector('.table-container');
  const scrollOffset = tableContainer ? tableContainer.scrollLeft : 0;
  left += scrollOffset;

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setOpenDropdownId(null);
    }, 100);
  };

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={dropdownRef}
          initial={{ opacity: 0, y: shouldShowAbove ? -10 : 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: shouldShowAbove ? -10 : 10 }}
          transition={{ duration: 0.2 }}
          style={{
            position: 'fixed',
            top: shouldShowAbove ? 'unset' : top,
            bottom: shouldShowAbove ? (windowHeight - top) : 'unset',
            left,
            zIndex: 50,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="flex flex-col flex-wrap justify-start items-start gap-2 border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-[200px]"
        >
          <button
            className="flex justify-start items-center gap-2 bg-transparent px-0 py-4 rounded-xl w-full h-12 font-semibold text-gray-lighter text-sm hover:text-white leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear cursor-pointer outline-none"
            onClick={(e) => {
              e.stopPropagation();
              handleNavigateToInvestment(e, token, cap);
              setOpenDropdownId(null);
            }}
          >
            <Icons nameIcon="edit" className="w-5 h-5" />
            <span className="relative">Edit</span>
          </button>

          <button
            className="flex justify-start items-center gap-2 bg-transparent px-0 py-4 rounded-xl w-full h-12 font-semibold text-gray-lighter text-sm hover:text-white leading-6 tracking-[-0.005em] transition-colors duration-[0.2s] ease-linear cursor-pointer outline-none"
            onClick={(e) => {
              e.stopPropagation();
              handleNavigateToDetail(e);
              setOpenDropdownId(null);
            }}
          >
            <Icons nameIcon="trash" className="w-5 h-5" />
            <span className="relative">Delete</span>
          </button>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default ActionDropdown;
