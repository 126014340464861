import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import Icons from '../../../components/shared/Icons'
import { useClickOutside } from '../../../hooks/common/useClickOutside'
import { useAdvancedFilter } from "../../../hooks/features/filter/useAdvancedFilter"
import { modalOverlayVariantsOpacity, dropdownFilterVariants } from '../../../utils/animations'

const optionAssets = [
  { id: "assets-all", content: "All Assets" },
  { id: "assets-tradable", content: "Tradable" },
  { id: "assets-gainers", content: "Gainers" },
  { id: "assets-losers", content: "Losers" },
]

const AdvancedFilter = () => {
  const {
    searchTerm,
    selectedOptions,
    isFocusSearch,
    setIsFocusSearch,
    handleSearch,
    handleOptionSelect,
    resetFilters,
  } = useAdvancedFilter()

  const [isOpen, setIsOpen] = useState(false)
  const toggleDropdown = (forceState) => {
    setIsOpen((prev) => forceState || !prev)
  }

  const ref = useClickOutside(isOpen, () => toggleDropdown(false))

  return (
    <div className="relative" ref={ref}>
      <button
        type="button"
        onClick={() => toggleDropdown()}
        className="inline-flex justify-center items-center bg-black-light rounded-full w-10 h-10 text-white transition-colors duration-[0.2s] outline-none"
      >
        <Icons
          nameIcon="filter"
          className="w-4 h-4"
        />
      </button>

      <AnimatePresence>
        {isOpen && (
        <>
          <motion.div
            className="z-[991] fixed inset-0 bg-black-light cursor-pointer modal__overlay"
            {...modalOverlayVariantsOpacity}
            onClick={() => toggleDropdown(false)}
          />

          <motion.div
            {...dropdownFilterVariants}
            className="top-full max-480:!top-1/2 -right-4 lg:right-0 max-480:right-0 max-480:!left-1/2 z-[999] max-480:fixed absolute border-2 border-white/10 bg-black-dark mt-2 p-6 border-solid rounded-2xl w-[320px] max-480:!w-[90vw] max-480:!-translate-x-1/2 max-480:!-translate-y-1/2 overflow-hidden"
          >
            <div className="inline-block relative border-0 my-0 mr-auto mb-6 ml-0 font-semibold text-white text-xl max-767:text-lg leading-[1.6] tracking-[-0.02em] align-baseline">
              Showing 10 of 100 Tokens
            </div>

            <form className="relative mx-0 mt-0 mb-6 p-0 w-full align-baseline">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className={`border-2 border-solid m-0 py-0 pr-5 pl-11 rounded-xl w-full h-10 font-semibold text-[15px] text-white/70 leading-[1.6] transition-colors duration-[0.2s] ease-linear appearance-none outline-none bg-black-light ${isFocusSearch ? 'border-white/10 text-white' : 'border-transparent opacity-90'}`}
                placeholder="Search for tokens"
                onFocus={() => setIsFocusSearch(true)}
                onBlur={() => setIsFocusSearch(false)}
              />

              <button
                type="button"
                className="inline-flex left-0 absolute inset-y-0 justify-center items-center bg-[initial] bg-none m-0 py-0 pl-0 w-10 h-10 cursor-pointer"
              >
                <Icons
                  nameIcon="search"
                  className={`transition-[stroke] duration-[0.2s] align-middle h-5 w-5 ${isFocusSearch ? 'text-white' : 'text-white/70'}`}
                />
              </button>
            </form>

            <div className="relative mb-6">
              <div className="text-[13px] text-gray-lighter leading-[1.2] tracking-[-0.02em]">
                Showing
              </div>

              <div className="flex flex-col gap-[10px] mt-5 w-full">
                {optionAssets.map((option) => (
                  <label
                    key={option.id}
                    htmlFor={option.id}
                    className=""
                    onClick={() => handleOptionSelect(option.id)}
                  >
                    <input
                      type="checkbox"
                      name="option-assets"
                      id={option.id}
                      checked={selectedOptions.includes(option.id)}
                      onChange={() => handleOptionSelect(option.id)}
                      className="absolute border-0 bg-[0_0] bg-[initial] opacity-0 m-0 p-0 border-solid rounded-none font-[inherit] text-inherit leading-[inherit] overflow-visible align-middle"
                    />

                    <div className="flex flex-row-reverse m-0 p-0 cursor-pointer align-baseline">
                      <div className={`transition-all duration-[0.2s] align-baseline h-6 w-6 shrink-0 relative m-0 p-0 rounded-md border-solid border-2 inline-flex justify-center items-center ${selectedOptions.includes(option.id) ? 'border-primary bg-primary' : 'border-white/20 bg-transparent'}`}>
                        <Icons
                          nameIcon="check"
                          className={`w-4 h-4 text-white transition-opacity duration-[0.2s] ${selectedOptions.includes(option.id) ? 'opacity-100' : 'opacity-0'}`}
                        />
                      </div>
                      <div className={`flex-1 m-0 font-semibold text-sm leading-[1.2] tracking-[-0.02em] text-start transition-[color] duration-[0.02s] ease-[ease-out] ${selectedOptions.includes(option.id) ? 'text-white' : 'text-white/70'}`}>
                        {option.content}
                      </div>
                    </div>
                  </label>
                ))}
              </div>

              <div className="flex flex-row flex-wrap justify-end items-center gap-[12px] mt-8 w-full">
                <button
                  type="button"
                  onClick={resetFilters}
                  className="inline-flex justify-center items-center bg-black-light hover:bg-gray-lighter hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
                >
                  Reset
                </button>

                <button
                  type="button"
                  onClick={() => toggleDropdown(false)}
                  className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
                >
                  Apply
                </button>
              </div>
            </div>
          </motion.div>
        </>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AdvancedFilter
