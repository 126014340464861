import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const EmailField = ({
  name,
  placeholder,
  label,
  icon,
  defaultValue = '',
  customClassOnField,
  onChange,
  onFocus,
  onBlur,
  disabled,
  error
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [hasValue, setHasValue] = useState(!!defaultValue);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    setHasValue(!!newValue);

    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = (e) => {
    setIsFocused(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className="pw:relative pw:field__wrapper">
      {label && (
        <label htmlFor={name} className="pw:mb-2 pw:font-semibold">
          {label}
        </label>
      )}

      <div className="pw:relative">
        {icon && (
          <span
            className={classNames(
              'pw:left-[6px] pw:absolute pw:inset-y-0 pw:flex pw:items-center',
              {
                'pw:text-white': isFocused || hasValue
              }
            )}
          >
            {icon}
          </span>
        )}

        <input
          type="email"
          id={name}
          name={name}
          value={value}
          disabled={disabled}
          className={classNames(
            'pw:w-full pw:text-base pw:leading-6 pw:font-normal pw:min-w-0 pw:text-white pw:opacity-90',
            'pw:text-start pw:p-4 pw:bg-black-light pw:transition-colors pw:duration-[0.2s]',
            'pw:ease-linear pw:rounded-xl pw:appearance-none pw:outline-none',
            'pw:autofill:shadow-[inset_0_0_0px_1000px_#6F737E]',
            'pw:autofill-focus:shadow-[inset_0_0_0px_1000px_#6F737E]',
            { 'pw:pl-12': icon },
            customClassOnField
          )}
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>

      {error && (
        <span className="pw:block pw:relative pw:pt-1 pw:text-[13px] pw:text-red-200 pw:tracking-[-0.02em]">
          {error}
        </span>
      )}
    </div>
  );
};

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.node,
  defaultValue: PropTypes.string,
  customClassOnField: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.string
};

export default EmailField
