// src/pages/DashboardTokens/Table/Header.jsx

import React from 'react'
import { createPortal } from 'react-dom'
import { Tooltip } from 'react-tooltip'
import Icons from '../../../components/shared/Icons'

const Header = ({
  title,
  subtitle,
  hasSorter,
  customStyle,
  textTooltip,
  isScrolled,
  isFirstColumn,
}) => {
  const formattedTitle = title.toLowerCase().replace(/\s+/g, '')

  const tooltipContent = textTooltip && (
    <Tooltip
      id={formattedTitle}
      auto
      effect="solid"
      className="!inline-flex z-[51] !justify-center !items-center !bg-[#6f767e] !p-[8px] !rounded-md max-w-xl !font-bold !text-[15px] !text-white !leading-[1.3] !tracking-[-0.02em]"
    >
      {textTooltip}
    </Tooltip>
  )

  const headerClassName = `
        z-40
        lg:sticky
        flex
        flex-grow
        bg-black-light
        px-4
        py-4
        font-normal
        text-left
        text-sm
        text-white/70
        group
        ${isScrolled && isFirstColumn ? 'sticky__active' : ''}
    `

  return (
    <th
      scope="col"
      className={headerClassName}
      style={customStyle}
    >
      <div className="flex items-center gap-2 w-full">
        <span className="text-ellipsis tracking-[-0.02em] overflow-hidden hover:overflow-visible select-none">
          <p className="font-bold">{title}</p>
          {subtitle && <div className="text-[#6F737E] text-xs">{subtitle}</div>}
        </span>

        {textTooltip && (
        <>
          <span
            className="inline-flex relative flex-[1.25rem] justify-center items-center border-2 border-white/20 border-solid rounded-full max-w-[1.25rem] h-[1.25rem] text-white transition-colors"
            data-tooltip-id={formattedTitle}
          >
            <Icons nameIcon="questions" className="w-3 h-3" />
          </span>
          {createPortal(tooltipContent, document.body)}
        </>
        )}

        {hasSorter && (
        <span className="group-hover:text-white inline-flex relative justify-center items-center w-4 h-4 text-[#6F737E] transition-colors">
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path className="opacity-60" d="M11.293 3.293a1 1 0 0 1 1.414 0l6 6a.95 .95 0 0 1 .073 .082l.006 .008l.016 .022l.042 .059l.009 .015l.007 .01l.014 .027l.024 .044l.007 .017l.01 .02l.012 .032l.015 .034l.007 .025l.008 .02l.005 .026l.012 .037l.004 .028l.006 .025l.003 .026l.006 .033l.002 .03l.003 .028v.026l.002 .033l-.002 .033v.026l-.003 .026l-.002 .032l-.005 .029l-.004 .03l-.006 .024l-.004 .03l-.012 .035l-.005 .027l-.008 .019l-.007 .026l-.015 .033l-.012 .034l-.01 .018l-.007 .018l-.024 .043l-.014 .028l-.007 .009l-.009 .016l-.042 .058l-.012 .019l-.004 .003l-.006 .01a1.006 1.006 0 0 1 -.155 .154l-.009 .006l-.022 .016l-.058 .042l-.016 .009l-.009 .007l-.028 .014l-.043 .024l-.018 .007l-.018 .01l-.034 .012l-.033 .015l-.024 .006l-.021 .009l-.027 .005l-.036 .012l-.029 .004l-.024 .006l-.028 .003l-.031 .006l-.032 .002l-.026 .003h-.026l-.033 .002h-12c-.89 0 -1.337 -1.077 -.707 -1.707l6 -6z" />
            <path className="opacity-60" d="M18 13l.033 .002h.026l.026 .003l.032 .002l.031 .006l.028 .003l.024 .006l.03 .004l.035 .012l.027 .005l.019 .008l.026 .007l.033 .015l.034 .012l.018 .01l.018 .007l.043 .024l.028 .014l.009 .007l.016 .009l.051 .037l.026 .017l.003 .004l.01 .006a.982 .982 0 0 1 .154 .155l.006 .009l.015 .02l.043 .06l.009 .016l.007 .009l.014 .028l.024 .043l.005 .013l.012 .023l.012 .034l.015 .033l.007 .026l.008 .02l.005 .026l.012 .036l.004 .029l.006 .024l.003 .028l.006 .031l.002 .032l.003 .026v.026l.002 .033l-.002 .033v.026l-.003 .026l-.002 .032l-.006 .031l-.003 .028l-.006 .024l-.004 .03l-.012 .035l-.005 .027l-.008 .019l-.007 .026l-.015 .033l-.012 .034l-.01 .018l-.007 .018l-.024 .043l-.014 .028l-.007 .009l-.009 .016l-.042 .058l-.012 .019l-.004 .003l-.006 .01l-.073 .081l-6 6a1 1 0 0 1 -1.414 0l-6 -6c-.63 -.63 -.184 -1.707 .707 -1.707h12z" />
          </svg>
        </span>
        )}
      </div>
    </th>
  )
}

export default Header
