import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import {
  Container,
  CircularProgress,
} from '@material-ui/core'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

import { REGISTRATION_CONFIRM } from '../queriesAndMutations'

const SignUpConfirmCheck = ({ history }) => {
  const { t } = useTranslation()
  const query = history.location.search ? history.location.search.substr(1) : ''
  const [registerConfirm, registerConfirmStatus] = useMutation(REGISTRATION_CONFIRM)
  const token = (new URLSearchParams(query)).get('token')
  const [, setCookie] = useCookies(['jwt'])

  useEffect(() => {
    if (token) {
      registerConfirm({
        variables: {
          input: {
            token,
          },
        },
      }).then(({ data }) => {
        setCookie('jwt', data.registrationConfirm.accessToken, { path: '/' })
        localStorage.setItem('jwt', data.registrationConfirm.accessToken)

        setTimeout(() => {
          window.location.href = `${window.location.origin}/tokenlist`
        }, 1000)
      }).catch(() => {})
    }
  }, [registerConfirm, token, setCookie])

  if (!token) {
    return (
      <Redirect to="/tokenlist" />
    )
  }

  return (
    <Container>
      { registerConfirmStatus.loading && (
        <CircularProgress />
      )}
      { registerConfirmStatus.error && (
        <Redirect to="/tokenlist" />
      )}
      { registerConfirmStatus.data && (
        <div className="text-center">
          <h1>{t('Account activated')}</h1>
          <p>{t('Your account is activated. You can now login to the site. Redirecting...')}</p>
        </div>
      )}
    </Container>
  )
}

export default SignUpConfirmCheck
