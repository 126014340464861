// src/pages/Signup/components/SignupMethod/Passkey/PasskeyCreation.jsx

import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
// import Layout from "../Layout"
import Icons from "../../../../components/shared/Icons"
import Loader from "../../../../components/shared/Loader"

// import { SIGNUP_STEPS } from "../../constant/steps"

const PasskeyCreation = ({ onBack, onNext }) => {
  const { t } = useTranslation()

  return (
    // <Layout
    //   title={t("Create Your Passkey")}
    //   description={t("Follow your browser's prompts to create a passkey")}
    //   currentStep={3}
    //   totalSteps={5}
    //   onBack={onBack}
    //   stepType={SIGNUP_STEPS.PASSKEY}
    // >
      <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
        <div className="pw:bg-black-light pw:mb-8 pw:p-6 pw:rounded-2xl">
          <h3 className="pw:mb-4 pw:font-semibold pw:text-white">
            {t("Your browser will ask you to:")}
          </h3>
          <ul className="pw:space-y-4">
            <li className="pw:flex pw:flex-row pw:gap-x-2 pw:gap-y-2">
              <div className="pw:inline-flex pw:relative pw:justify-center pw:items-center pw:bg-primary/15 pw:mt-1 pw:rounded-[50%] pw:w-8 pw:h-8">
                <Icons
                  nameIcon="check"
                  className="pw:w-4 pw:h-4 pw:text-primary"
                />
              </div>

              <div className="pw:flex pw:flex-col pw:flex-1 pw:mt-1 pw:pl-2">
                <div className="pw:flex-1 pw:m-0 pw:font-normal pw:text-[13px] pw:text-base pw:text-gray-lighter pw:text-start pw:leading-6 pw:transition-[color] pw:duration-[0.02s] pw:ease-[ease-out]">
                  {t("Use your device's screen lock (fingerprint, face, or PIN)")}
                </div>
              </div>
            </li>
            <li className="pw:flex pw:flex-row pw:gap-x-2 pw:gap-y-2">
              <div className="pw:inline-flex pw:relative pw:justify-center pw:items-center pw:bg-primary/15 pw:mt-1 pw:rounded-[50%] pw:w-8 pw:h-8">
                <Icons
                  nameIcon="check"
                  className="pw:w-4 pw:h-4 pw:text-primary"
                />
              </div>

              <div className="pw:flex pw:flex-col pw:flex-1 pw:mt-2 pw:pl-2">
                <div className="pw:flex-1 pw:m-0 pw:font-normal pw:text-[13px] pw:text-base pw:text-gray-lighter pw:text-start pw:leading-6 pw:transition-[color] pw:duration-[0.02s] pw:ease-[ease-out]">
                  {t("Save this passkey to your account")}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <button
          onClick={onNext}
          className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200"
        >
          {t("Create Passkey")}
        </button>
      </div>
    // </Layout>
  )
}

export default PasskeyCreation
