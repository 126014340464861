// src/pages/Signup/components/SignupMethod/Passkey/index.jsx

import React, { useState } from 'react'
import PasskeySetup from './PasskeySetup'
import PasskeyCreation from './PasskeyCreation'
import PasskeyForm from './PasskeyForm'
import PasskeySuccess from './PasskeySuccess'

const PASSKEY_STEPS = {
  SETUP: 'setup',
  CREATION: 'creation',
  SUCCESS: 'success',
  FORM: 'form',
}

const PasskeyModal = ({ me, onClose, onComplete }) => {
  const [step, setStep] = useState(PASSKEY_STEPS.SETUP)
  const [disable2FA, setDisable2FA] = useState(true)

  const handleStepComplete = (nextStep) => {
    if (nextStep === 'finish') {
      onComplete({ disable2FA })
      return
    }
    setStep(nextStep)
  }

  const renderStep = () => {
    switch (step) {
      case PASSKEY_STEPS.SETUP:
        return (
          <PasskeySetup
            onClose={onClose}
            onNext={() => handleStepComplete(PASSKEY_STEPS.CREATION)}
            disable2FA={disable2FA}
            onDisable2FAChange={setDisable2FA}
          />
        )
      case PASSKEY_STEPS.CREATION:
        return (
          <PasskeyCreation
            onBack={() => handleStepComplete(PASSKEY_STEPS.SETUP)}
            onNext={() => handleStepComplete(PASSKEY_STEPS.FORM)}
          />
        )
      case PASSKEY_STEPS.FORM:
        return (
          <PasskeyForm
            me={me}
            onBack={() => handleStepComplete(PASSKEY_STEPS.CREATION)}
            onNext={() => handleStepComplete(PASSKEY_STEPS.SUCCESS)}
          />
        )
      case PASSKEY_STEPS.SUCCESS:
        return (
          <PasskeySuccess
            onBack={() => handleStepComplete(PASSKEY_STEPS.FORM)}
            onFinish={() => handleStepComplete('finish')}
          />
        )
      default:
        return null
    }
  }

  return renderStep()
}

export default PasskeyModal
