import React, { useEffect } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import Loader from '../components/Loader'

import { useMe } from '../myHooks'
import { CHECK_VALID_TENANT } from '../queriesAndMutations'

const TenantRedirect = () => {
  const { t } = useTranslation()
  const query = new URLSearchParams(window.location.search)
  const host = query.get('host')
  const allowSyncData = query.get('allowSyncData')
  const { data: { me } = {}, loading } = useMe()
  const {
    data: { checkValidTenant: isTenantValid } = {},
    loading: tenantChecking,
  } = useQuery(CHECK_VALID_TENANT, {
    variables: { host },
  })

  useEffect(() => {
    if (!loading && !me && isTenantValid) {
      window.location.href = `https://${host}`
    }
  }, [me, loading, host, isTenantValid])

  return (
    <>
      <div className="section-pt80 variants signinup">
        <div className="variants__center signinup__center center">
          {(tenantChecking || loading) && (
            <>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: "2rem" }}>
                <Loader />
              </div>
              <div className="variants__info signinup__info">
                {t('Redirecting...')}
              </div>
            </>
          )}

          {!tenantChecking && !isTenantValid && (
            <>
              <p
                style={{
                  color: "#EF466F",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  marginBottom: "1.5rem",
                }}
              >
                {t('Invalid tenants')}
              </p>
              <NavLink className="button" to="/dashboard">{t('Back to home')}</NavLink>
            </>
          )}

          {!loading && me && isTenantValid && (
            <Redirect
              to={`/sso/request?redirect=${encodeURIComponent(`https://${host}/sso/auth`)}&allowSyncData=${allowSyncData}`}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default TenantRedirect
