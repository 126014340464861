// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/steps/Welcome/index.jsx

import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../common/Button'
import StepIndicator from '../../common/StepIndicator'

const Welcome = ({
  onNext,
  progress,
  currentStep,
  totalSteps,
  countryCode
}) => {
  const { t } = useTranslation()

  const handleContinue = () => {
    onNext({ acknowledged: true })
  }

  const infoCards = [
    {
      id: "purpose-commercial",
      content: "The answers you provide will not be used for commercial purposes"
    },
    {
      id: "purpose-experience",
      content: "There's a category for most people irrespective of experience"
    }
  ]

  return (
    <>
      {/* Content wrapper */}
      <div className="flex flex-col flex-1 justify-start items-start gap-4 px-4 py-6">
        <StepIndicator
          currentStep={currentStep}
          totalSteps={totalSteps}
          progress={progress}
        />

        <div className="flex-1 w-full">
          <div className="mb-4 w-full">
            <div className="mb-2 font-medium text-4xl text-ellipsis text-white leading-[1.1875] tracking-[-0.02em]">
              {t('questionnaire.welcome.title')}
            </div>
            <p className="border-0 mt-2 p-0 text-gray-lighter">
              {t('questionnaire.welcome.subtitle')}
            </p>
          </div>

          {(countryCode === 'DE' || countryCode === 'CA') && (
            <div className="flex flex-col gap-2 bg-black-light p-4 rounded-xl w-full">
              <div className="flex items-center gap-2">
                <span className="text-xl">📍</span>
                <span className="text-white">
                  {t('questionnaire.welcome.detectedLocation', {
                    country: countryCode === 'DE' ? 'Germany' : 'Canada'
                  })}
                </span>
              </div>
              <p className="text-[#6f767e]">
                {t('questionnaire.welcome.regulationNotice', {
                  country: countryCode === 'DE' ? 'German' : 'Canadian'
                })}
              </p>
            </div>
          )}

          {infoCards.map((info) => (
            <div
              key={info.id}
              className="flex flex-row justify-start items-stretch border-2 bg-black-light mb-4 border-transparent rounded-xl min-h-[120px]"
            >
              <div className="flex flex-col justify-center p-8">
                <div className="m-0 pb-2 font-semibold text-base text-start text-white leading-6">
                  {t(`questionnaire.welcome.options.${info.id}`)}
                </div>
              </div>
            </div>
          ))}
        </div>

        {(countryCode === 'DE' || countryCode === 'CA') && (
          <p className="text-[#6f767e] text-[13px]">
            {t('questionnaire.welcome.countryRegulationNote')}
          </p>
        )}
      </div>
      {/* End content wrapper */}

      {/* Sticky footer */}
      <div className="bottom bottom-0 sticky bg-black-dark px-4 py-6 w-full">
        <Button
          fullWidth={true}
          size="md"
          onClick={handleContinue}
        >
          {t('common.continue')}
        </Button>

        <div className="mt-4 font-medium text-[13px] text-gray-lighter">
          {t('questionnaire.welcome.disclaimer')}
        </div>
      </div>
      {/* End sticky footer */}
    </>
  )
}

export default Welcome
