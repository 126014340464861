// src/pages/DashboardTokens/AddWallet/TokenWithPrice.jsx

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Icons from '../../../components/shared/Icons'
import ShortToken from '../../../components/shared/ShortToken'

const DetailItem = ({ label, value }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">{label}</div>
      <div className="m-0 font-semibold text-[13px] text-sm text-start text-white leading-6 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out]">{value}</div>
    </div>
  )
}

const DetailItemChange = ({ label, value, isPositive = true }) => {
  return (
    <div className="flex justify-between items-center w-full">
      <div className="text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">{label}</div>
      <div className={`inline-flex justify-center items-center gap-1 m-0 font-semibold text-[13px] text-sm text-start leading-6 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out] ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
        <Icons
          nameIcon={isPositive ? 'caret-up' : 'caret-down'}
          className="w-3 h-3"
        />
        {value}
      </div>
    </div>
  )
}

const TokenWithPrice = ({ token }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const details = [
    { label: 'Symbol', value: token.symbol },
    {
      label: 'Contract Address',
      value: token.contractAddress === '-' ? '-' : (
        <ShortToken
          original={token.contractAddress}
          hasCopy
        />
      ),
    },
    { label: 'Quantity', value: token.quantity.toLocaleString() },
    { label: 'Price', value: `$${token.price.toLocaleString()}` },
    // {
    //   label: 'Change (24H)',
    //   value: `${Math.abs(token.change24h)}%`,
    //   type: 'change',
    //   isPositive: token.change24h >= 0,
    // },
    { label: 'Value', value: `$${token.value.toLocaleString()}` },
  ]

  return (
    <div className="relative mb-2">
      <div
        className="inline-flex justify-start items-center w-full cursor-pointer group"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="inline-flex flex-1 justify-start items-center gap-2">
          <div className="relative border-0 m-0 mr-3 p-0 rounded-[50%] w-9 h-9">
            <img
              src={token.icon}
              alt={token.symbol}
              className="rounded-full w-8 h-8"
            />
            {token.networkIcon && (
              <span className="right-0 bottom-0 absolute bg-white rounded-full w-4 h-4 overflow-hidden">
                <img
                  src={token.networkIcon}
                  alt="Network"
                  className="w-full h-full object-cover"
                />
              </span>
            )}
          </div>
          <div className="font-bold text-sm text-white tracking-[-0.02em]">{token.symbol}</div>
        </div>
        <motion.button
          animate={{ rotate: isExpanded ? 180 : 0 }}
          className="group-hover:text-white inline-flex justify-center items-center bg-transparent w-6 h-6 text-white/70 transition-colors duration-[.2s] cursor-pointer"
        >
          <Icons
            nameIcon="chevron-down"
            className="w-4 h-4"
          />
        </motion.button>
      </div>

      <AnimatePresence>
        {isExpanded && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="relative overflow-hidden"
        >
          <div className="flex flex-col flex-wrap justify-start items-start gap-2 py-4 w-full">
            {details.map((detail) => (
              detail.type === 'change' ? (
                <DetailItemChange
                  key={`detail-${detail.value}`}
                  label={detail.label}
                  value={detail.value}
                  isPositive={detail.isPositive}
                />
              ) : (
                <DetailItem
                  key={`detail-${detail.value}`}
                  label={detail.label}
                  value={detail.value}
                />
              )
            ))}
          </div>
        </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default TokenWithPrice
