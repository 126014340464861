import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ModalWide } from '../../../../components/shared/Modals'
import { useMe } from '../../../../myHooks'
import Icons from '../../../../components/shared/Icons';
import PasskeyModal from './PasskeyModal'
import { useTranslation } from 'react-i18next'

const SectionTwoFactorSkeleton = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={12} className="bg-gray-lighter !w-full" />
    <Skeleton width={100} height={20} className="bg-gray-lighter !w-full" />
  </div>
)

const Passkey = () => {
  const { t } = useTranslation()
  const [isPasskeyDialogOpened, setIsPasskeyDialogOpened] = useState(false)
  const { data: { me } = {}, loading, refetch } = useMe({ fetchPolicy: 'network-only' })

  if (loading) return <SectionTwoFactorSkeleton />

  return (
    <div>
      <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 mb-4 pb-4 border-b border-b-white/10 border-solid'>
        <div className="flex flex-row flex-wrap flex-1 gap-1 font-sans font-semibold text-[13px] text-gray-lighter leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s]">
          Passkey

          <span
            className="relative top-[1px] cursor-pointer"
            data-tooltip-id='google-authenticator'
          >
            <Icons
              nameIcon="alert-circle"
              className="w-[14px] h-[14px]"
            />
          </span>

          <Tooltip
            id="google-authenticator"
            auto
            effect="solid"
            className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-bold !font-sans !text-white !text-xs"
          >
            Coming Soon
          </Tooltip>
        </div>

        <label className="inline-block relative ml-auto cursor-pointer">
          <input
            type="checkbox"
            className="absolute opacity-0 w-0 h-0"
            onChange={() => setIsPasskeyDialogOpened(true)}
            disabled={me.isPasskeyRegistered}
            checked={me.isPasskeyRegistered}
          />
          <span className={`flex items-center justify-center transition-all duration-[0.2s] ${me.isPasskeyRegistered ? 'text-white' : 'text-gray-lighter'}`}>
            <span className={`block relative border-2 border-white/10 bg-[#1e2025] border-solid rounded-3xl w-12 h-6 transition-all duration-[0.2s] toggle-checkbox__box before:content-[""] before:absolute  before:w-5 before:h-5 before:transition-all before:duration-[0.2s] before:rounded-[50%] before:left-0.5 before:top-2/4 ${me.isPasskeyRegistered ? 'before:bg-primary before:translate-x-6 before:-translate-y-2/4' : 'before:bg-white before:-translate-y-2/4 '}`} />
          </span>
        </label>
      </div>
      {/* Your content here */}

      <ModalWide
        isOpen={isPasskeyDialogOpened}
        onClose={() => setIsPasskeyDialogOpened(false)}
        className="investhub-modal investhub-modal__m"
        title={t('settings.security.setup_your_passkey')}
        subtitle={t('settings.security.setup_your_passkey_description')}
        customClassModal="!max-w-[480px]"
      >
        <PasskeyModal
          me={me}
          onClose={() => setIsPasskeyDialogOpened(false)}
          onComplete={async () => {
            await refetch()
            setIsPasskeyDialogOpened(false)
          }}
        />
      </ModalWide>
    </div>
  )
}

export default Passkey
