// src/pages/Signup/components/SignupMethod/index.jsx

import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Layout from '../Layout'
import WalletOptions from './WalletOptions'
import EmailForm from './EmailForm'
import {
  ModalWide,
  DummyConnectCoinBase,
} from '../../../../components/shared/Modals'
import { useMetamaskAuth } from '../../../../myHooks/useMetamaskAuth';
import { useCookies } from 'react-cookie'
import config from '../../../../config'
import { SIGNUP_STEPS } from '../../constant/steps'
import EmailVerification from './EmailVerification'
import classNames from 'classnames';
import { SINGPASS_ASSETS_IMAGES } from "../../../../constant/images";
import { isProduction, toaster } from "../../../../utils";
import { useMutation, useLazyQuery } from '@apollo/client';
import { SINGPASS_CALLBACK, GET_AUTHORIZATION_URL } from '../../../../queriesAndMutations';

const SignupMethod = ({
  accountType,
  signupData,
  onBack,
  onNext
}) => {
  const { t } = useTranslation()

  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false)
  const [isCoinbaseModalOpen, setIsCoinbaseModalOpen] = useState(false)
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
  const { connect, loading, error } = useMetamaskAuth();
  const [email, setEmail] = useState('')
  const [, setCookie] = useCookies()
  const [getAuthorizationUrl] = useLazyQuery(GET_AUTHORIZATION_URL);

  const handleMethodSelect = (method, data = {}) => {
    switch (method) {
      case 'wallet':
        setIsWalletModalOpen(true)
        break
      case 'passkey':
        onNext({ method, data })
        break
      case 'email':
        setEmail(data.email)
        setIsEmailModalOpen(true)
        break
      default:
        break
    }
  }

  const handleMetamaskRegister = async () => {
    try {
      console.log('signupData', signupData);

      const result = await connect(signupData);

      console.log('result', result);

      setCookie(config.jwtCookie, result.metamaskRegister.accessToken, { path: '/' })
      onNext(result)
    } catch (err) {
      console.error('Login failed:', err);
    }
  };

  const handleWalletSelect = (wallet) => {
    setIsWalletModalOpen(false)
    switch (wallet) {
      case 'metamask':
        handleMetamaskRegister()
        break
      case 'coinbase':
        setIsCoinbaseModalOpen(true)
        break
      default:
        break
    }
  }

  const getTitle = () => {
    const baseText = t("Choose Signup Method")
    if (accountType === "issuer") {
      return `${baseText} - ${t('Issuer Account')}`
    }
    return `${baseText} - ${t('Investor Account')}`
  }

  const getDescription = () => {
    if (accountType === "issuer") {
      return t("Select a secure way to create and access your issuer account")
    }
    return t("Select how you want to secure your investor account")
  }

  const handleClickSingPass = async () => {
    console.log('handleClickSingPass');
    const { data } = await getAuthorizationUrl(
      {
        variables: {
          context: 'signup'
        }
      }
    );
    console.log(data);
    const { nonce } = data.authorizationUrl;

    // store in local storage
    localStorage.setItem('singpass_nonce', nonce);

    localStorage.setItem('signupData', JSON.stringify({
      ...signupData,
      accountType,
    }));

    window.location.href = data.authorizationUrl.authorizationUrl;
  }

  if (isEmailModalOpen) {
    return (
      <Layout
      title={getTitle()}
      description={getDescription()}
      currentStep={2}
      totalSteps={5}
      onBack={onBack}
      stepType={SIGNUP_STEPS.SIGNUP_METHOD}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[382px]">
        <EmailVerification
          email={email}
          onNext={() => {
            setIsEmailModalOpen(false)
            onNext({
              method: 'email',
              data: signupData,
            })
          }}
        />
      </div>
    </Layout>
    )
  }

  return (
    <Layout
      title={getTitle()}
      description={getDescription()}
      currentStep={2}
      totalSteps={5}
      onBack={onBack}
      stepType={SIGNUP_STEPS.SIGNUP_METHOD}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[382px]">
        {/* Wallet and Passkey Options */}
        <div>
            <div className="pw:text-[13px] pw:text-gray-lighter pw:leading-[1.2] pw:tracking-[-0.02em]">
              {t('Sign up with')}
            </div>
            <div className="pw:justify-center pw:items-stretch pw:gap-x-4 pw:gap-y-4 pw:max-480:gap-x-[12px] pw:max-480:gap-y-[12px] pw:border-0 pw:grid pw:grid-cols-[1fr_1fr] pw:max-480:grid-cols-[1fr] pw:grid-rows-[auto] pw:auto-cols-[1fr] pw:mx-auto pw:max-480:mt-4 pw:mt-2 pw:mb-0 pw:p-0 pw:align-baseline">
              <WalletOptions
                  onWalletClick={() => handleMethodSelect('wallet')}
                  onPasskeyClick={() => handleMethodSelect('passkey')}
              />
              <div className={classNames("max-480:col-span-1 w-full text-center col-[span_2]", {
                "hidden": isProduction
              })}>
                <button
                  type="button"
                  onClick={handleClickSingPass}
                  className="inline-flex justify-center items-center bg-[#F4333D] hover:bg-[#B0262D] max-480:mx-auto px-6 rounded-2xl w-full h-14 font-bold text-white normal-case leading-none transition-al duration-200"
                >
                  <span className="inline-flex mr-1 text-lg tracking-normal">Authenticate with</span>
                  <img
                    src={SINGPASS_ASSETS_IMAGES.logoWhite}
                    alt="Retrieve myinfo with sing pass"
                    className="relative top-[2px] w-auto h-4 object-contain"
                    aria-label="sing pass"
                  />
                </button>
              </div>
            </div>
        </div>

          <div className="pw:w-full pw:h-4" />

          <EmailForm onNext={(data) => handleMethodSelect('email', data)} />

          <ModalWide
            isOpen={isWalletModalOpen}
            onClose={() => setIsWalletModalOpen(false)}
            title={t("Choose Connect a Wallet")}
            subtitle={t("Choose your wallet from the options below to begin")}
          >
            <WalletOptions
              isModalView
              onWalletSelect={handleWalletSelect}
              signupData={signupData}
              onClose={() => setIsWalletModalOpen(false)}
              onNext={onNext}
            />
          </ModalWide>

          <DummyConnectCoinBase
              isOpenModal={isCoinbaseModalOpen}
              toggleModal={() => setIsCoinbaseModalOpen(!isCoinbaseModalOpen)}
          />
      </div>
    </Layout>
  )
}

export default SignupMethod
