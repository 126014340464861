import React, { useState } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { CheckboxField } from '../../../components/shared/Fields'

import Heading from '../Heading'

const Verification = () => {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked)
  }

  return (
    <>
      <Heading
        title={t('settings.verification.title')}
        description={t('settings.verification.description')}
      />

      <div className="flex flex-col flex-wrap justify-center items-center bg-white/20 px-8 py-12 rounded-2xl w-full text-center text-white">
        <div className="mx-auto w-full max-w-screen-sm">

          <div className="m-0 mb-6 font-sans font-semibold text-center text-white text-xl leading-7 tracking-[-0.02em]">
            {t('settings.verification.liveness.title')}
          </div>
          <ol className="flex flex-row flex-wrap justify-start items-start gap-1 mb-8 font-sans text-left text-white/70 tracking-[-0.02em]">
            <li>{t("settings.verification.liveness.acknowledge.one")}</li>
            <li>{t("settings.verification.liveness.acknowledge.two")}</li>
          </ol>

          <CheckboxField
            name="acknowledge-reviewed-and-accept"
            label={t('settings.verification.liveness.confirm')}
            isTextWhite={true}
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>

      <div className="mt-6 text-center">
        <button
          className={cn(
            "inline-flex justify-center items-center gap-4 px-6 py-4 rounded-3xl font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] outline-none m-0",
            {
              "bg-gray-custom/20 cursor-not-allowed": !isChecked,
              "bg-primary hover:bg-primary-hover hover:opacity-90 cursor-pointer overflow-visible": isChecked
            }
          )}
          disabled={!isChecked}
        >
          {t("settings.verification.liveness.continue_confirmation")}
        </button>
      </div>
    </>
  )
}

export default Verification
