// src/Root.js

/* eslint-disable */
/* eslint-disable vars-on-top */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
import { useQuery } from '@apollo/client'
import { useEffect } from 'react'
import routes from './routes'
import { setTitle } from './utils'
import { PUBLIC_SETTINGS, ME } from './queriesAndMutations'

const Root = () => {
  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const { data } = useQuery(ME)
  const intercomAppId = ((publicSettings || {}).others || {}).intercom_app_id

  useEffect(() => {
    if (intercomAppId) {
      window.intercomSettings = data && data.me && data.me.email ? {
        app_id: intercomAppId,
        name: data.me.first_name,
        email: data.me.email,
        created_at: data.me.created_at,
      } : {
        app_id: intercomAppId,
      };

      (function () {
        const w = window
        const ic = w.Intercom
        if (typeof ic === 'function') {
          ic('reattach_activator')
          ic('update', w.intercomSettings)
        } else {
          const d = document
          var i = function () {
            i.c(arguments)
          }
          i.q = []
          i.c = function (args) {
            i.q.push(args)
          }
          w.Intercom = i
          const l = function () {
            const s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = `https://widget.intercom.io/widget/${intercomAppId}`
            const x = d.getElementsByTagName('script')[0]
            x.parentNode.insertBefore(s, x)
          }
          l()
        }
      }())
    }
  }, [intercomAppId, data])

  setTitle('Investhub.io')
  return routes()
}

export default Root
