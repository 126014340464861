// src/pages/Signup/components/SMS/PhoneInputStep.jsx

import React, { useState } from "react"
import classNames from "classnames"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Layout from "../Layout"
import styles from './phone.module.css'
import { useMutation } from '@apollo/client'
import { SAVE_AND_SEND_VERIFY_PHONE_NUMBER_SMS } from '../../../../queriesAndMutations'

import { SIGNUP_STEPS } from "../../constant/steps"

const PhoneInputStep = ({ onBack, onNext, phoneNumber, setPhoneNumber }) => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")

  const [saveAndSendSms] = useMutation(SAVE_AND_SEND_VERIFY_PHONE_NUMBER_SMS)

  const handlePhoneChange = (value) => {
    setPhoneNumber(value)
    setError("")
  }

  const validatePhone = () => {
    if (phoneNumber.length < 9) {
      setError(t("Phone number is too short"))
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    if (!validatePhone()) return

    setIsLoading(true)
    try {
      const { data } = await saveAndSendSms({
        variables: {
          phoneNumber: `+${phoneNumber}`
        }
      })

      if (data && data.saveAndSendVerifyPhoneNumberSms) {
        onNext()
      } else {
        setError(t("Failed to send verification code. Please try again."))
      }
    } catch (err) {
      setError(t("Failed to send verification code. Please try again."))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Layout
      title={t("Add Phone Number")}
      description={t("We'll send you a verification code by SMS")}
      currentStep={3}
      totalSteps={5}
      onBack={onBack}
      stepType={SIGNUP_STEPS.SMS}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
        <div className={classNames(styles.wrapper, { [styles.focused]: isFocused })}>
          <div className={styles.label}>
            {t("Phone number")}
          </div>

          <div className={styles.field}>
            <PhoneInput
              country="id"
              value={phoneNumber}
              onChange={handlePhoneChange}
              containerClass="phone-container"
              buttonClass="phone-button"
              placeholder={t("Enter your phone number")}
              inputProps={{
                required: true,
                autoFocus: true
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>

          {error && (
            <motion.p
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className={styles.error}
            >
              {error}
            </motion.p>
          )}
        </div>

        <p className={styles.hint}>
          {t("Make sure to use a phone number that can receive SMS messages.")}
        </p>

        <div className={styles.spacer} />

        <button
          onClick={handleSubmit}
          disabled={isLoading || !phoneNumber}
          className={styles.button}
        >
          {isLoading ? t('Sending Code...') : t('Send Code')}
        </button>
      </div>
    </Layout>
  )
}

export default PhoneInputStep
