import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const SINGPASS_CALLBACK = gql`
  mutation SingpassCallback($input: SingpassCallbackInput!) {
    singpassCallback(input: $input) {
      success
      claims
      userInfo
      mappingData
    }
  }
`;

export const SINGPASS_REGISTER = gql`
  mutation SingpassRegister($input: SingpassRegisterInput!) {
    singpassRegister(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const SINGPASS_LOGIN = gql`
  mutation SingpassLogin($input: SingpassLoginInput!) {
    singpassLogin(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
