// src/pages/DashboardTokens/AddWallet/Success.jsx

import React from 'react'
import Icons from '../../../components/shared/Icons'

const Success = () => {
  return (
    <div className="flex flex-col flex-1 justify-center items-center border-0 m-0 mb-3 p-0 w-full text-center align-baseline">
      <Icons
        nameIcon="check-color"
        className="mx-auto w-32 h-32"
      />

      <div className="block m-0 pb-4 w-full text-2xl text-white break-words leading-6">
        Token added
      </div>
      <p className="inline-flex justify-center items-center mt-6 mb-4 text-[#6f767e] text-[13px]">
        You&apos;ve successfully added your token.
      </p>
    </div>
  )
}

export default Success
