import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const CREATE_MANAGE_UPGRADE = gql`
  mutation createManageUpgrade($input: ManageUpgradeInput) {
    createManageUpgrade(input: $input) {
      id
      status
      manage_settings
    }
  }
`;
