// src/context/LanguageContext.jsx
import React, { createContext, useState, useEffect } from 'react';
import i18n from 'i18next';
import { supportedLanguages, setLocale, getLocale } from '../utils/lang';
import { detectUserCountry, getLanguageFromCountry } from '../services/languageDetection';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(getLocale() || 'en');

  const updateLanguage = (langCode) => {
    if (supportedLanguages[langCode]) {
      setLocale(langCode);
      i18n.changeLanguage(langCode);
      setCurrentLanguage(langCode);
    }
  };

  useEffect(() => {
    // Initialize with stored preference or default
    const storedLang = getLocale();
    if (storedLang && supportedLanguages[storedLang]) {
      updateLanguage(storedLang);
    } else {
      updateLanguage('en');
    }
  }, []);

  // Debuging
  useEffect(() => {
    const initLanguage = async () => {
      try {
        const country = await detectUserCountry();
        const detectedLang = getLanguageFromCountry(country);
        updateLanguage((detectedLang || {}).code || 'en');
      } catch (error) {
        console.error('Language detection error:', error);
      }
    };

    if (!getLocale()) {
      initLanguage();
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ isLoading, currentLanguage, updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
