const host = process.env.REACT_APP_API_HOST || 'http://localhost:5052'
const endPoint = process.env.REACT_APP_API_ENDPOINT || 'graphql'

module.exports = {
  jwtCookie: process.env.REACT_APP_JWT_COOKIE || 'jwt',
  backend_url: host,
  endpoint: `${host}/${endPoint}`,
  fileEndpoint: host !== 'http://localhost:5000' ? '/file' : `${host}/file`,
  CDP_API_KEY: process.env.REACT_APP_CDP_API_KEY,
  WC_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
}
