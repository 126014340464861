import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'
import qrcode from 'qrcode'
import { useTranslation } from 'react-i18next'
import VerificationInput from "react-verification-input";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { NEW_2FA_SECRET, SET_2FA_SECRET } from '../../../../queriesAndMutations'
import { useMe } from '../../../../myHooks'
import Icons from '../../../../components/shared/Icons'
import Loader from '../../../../components/shared/Loader'

const BarcodeSkeleton = () => {
  return (
    <div className="flex justify-center items-center mb-6">
      <Skeleton
        width={200}
        height={200}
        className="bg-gray-lighter opacity-60 rounded-xl"
      />
    </div>
  )
}

const SetupTwoFactorAuth = ({
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [twoFaSecretUrl, setTwoFaSecretUrl] = useState('')
  const [isCopied, setIsCopied] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { data: { me } = {} } = useMe()
  const { data: { new2FaSecret } = {} } = useQuery(NEW_2FA_SECRET)
  const [set2FaSecret, set2FaSecretStatus] = useMutation(SET_2FA_SECRET)

  const secretToUrl = useCallback(
    (secret) => `otpauth://totp/${encodeURIComponent('InvestHub')}:${encodeURIComponent(me.email)}?secret=${secret}`,
    [me],
  )

  const handleCopyKey = async () => {
    try {
      await navigator.clipboard.writeText(new2FaSecret);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleComplete = async (code) => {
    setVerificationCode(code);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      await set2FaSecret({
        variables: {
          secret: new2FaSecret,
          token: verificationCode
        }
      });

      if (onComplete) {
        onComplete();
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderTwoFactor = () => {
    return (
      <>
        <div className='flex flex-col flex-nowrap justify-center items-center gap-1 mb-6'>
          <div className="inline-flex justify-center items-center gap-1">
            <Icons
              nameIcon='download'
              className="relative top-[-1px] w-4 h-4 text-gray-lighter"
            />
            <span className="font-sans text-[13px] text-gray-lighter">{t("settings.security.download_google_authenticator")}</span>
          </div>

          <div className="inline-flex justify-center items-center gap-1">
            <Icons
              nameIcon='scan-barcode'
              className="relative top-[-1px] w-4 h-4 text-gray-lighter"
            />
            <span className="font-sans text-[13px] text-gray-lighter">{t("settings.security.scan_google_authenticator")}</span>
          </div>
        </div>

        <div className="flex flex-col flex-wrap justify-center items-center mb-6 text-center">
          {/* Image barcode */}
          {twoFaSecretUrl ? (
            <img src={twoFaSecretUrl} alt="" className='mb-6 rounded-xl' />
          ) : (
            <BarcodeSkeleton />
          )}

          <div className="mb-2 px-2 max-767:px-0 font-sans text-[13px] text-gray-lighter">
            {t('settings.security.secret_code_google_authenticator')}
          </div>

          {/* Copy secret key */}
          <div className="relative mb-4 w-full">
            <input
              type="text"
              value={new2FaSecret ? `${new2FaSecret.slice(0, 12)}...` : ''}
              readOnly
              className="border-2 border-white/10 bg-[#1e2025] opacity-100 !p-4 !border-solid rounded-xl w-full min-w-0 font-normal font-sans text-base text-start text-white leading-6 transition-all duration-[0.2s] ease-linear outline-none tex-twhite"
            />
            <button
              onClick={handleCopyKey}
              className="top-1/2 right-3 absolute transform -translate-y-1/2"
            >
              <Icons
                nameIcon={isCopied ? "check" : "copy"}
                className={`w-5 h-5 ${isCopied ? 'text-green-500' : 'text-gray-lighter'}`}
              />
            </button>
          </div>

          {/* Field 6-code verification */}
          <div className="text-center text-gray-lighter text-sm">
            <div className="mb-2 px-2 max-767:px-0 font-sans text-[13px] text-gray-lighter">
              {t('settings.security.google_code')}
            </div>

            <VerificationInput
              length={6}
              placeholder=""
              validChars="0-9"
              autoFocus
              onComplete={handleComplete}
              classNames={{
                container: "verification-container gap-[2px] grid grid-cols-6 mb-6 w-full",
                character: "border-[#2b2d33] border-2 border-solid focus:border-white/20 bg-black-light rounded-xl w-12 h-12 text-center text-white text-base inline-flex justify-center items-center",
                characterInactive: "character--inactive",
                characterSelected: "character--selected border-white/20",
                characterFilled: "character--filled border-white/20"
              }}
            />
            {error && (
              <div className="block relative pt-1 text-[13px] text-red-200 tracking-[-0.02em]">
                {error}
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-center w-full">
          <button
            type='submit'
            disabled={verificationCode.length !== 6 || isLoading}
            className={`inline-flex justify-center items-center gap-1 bg-primary  m-0 px-6 rounded-3xl h-12 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s]  overflow-visible outline-none ${verificationCode.length !== 6 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-hover hover:opacity-90 cursor-pointer'}`}
          >
            {t('settings.security.activated_google')}
            {isLoading && <Loader className='scale-75' />}
          </button>
        </div>

      </>
    )
  }

  useEffect(() => {
    if (new2FaSecret) {
      qrcode.toDataURL(secretToUrl(new2FaSecret)).then((qrCode) => {
        setTwoFaSecretUrl(qrCode)
      })
    }
  }, [new2FaSecret, secretToUrl])

  return (
    <form onSubmit={handleSubmit}>
      {renderTwoFactor()}
    </form>
  )
}

SetupTwoFactorAuth.propTypes = {
  onClose: propTypes.func.isRequired,
  onComplete: propTypes.func,
}

export default SetupTwoFactorAuth
