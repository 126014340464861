import { gql } from '@apollo/client';

export const CREATE_INVESTMENT = gql`
  mutation CreateInvestment($input: CreateInvestmentInput!) {
    createInvestment(input: $input) {
      id
      amount
      currency
      token_amount
      token_name
      status
      first_name
      last_name
      birth_date
      place_of_birth
      nationality
      residential_address
      residential_address_two
      city
      postal_code
      country_of_residence
      wallet_address
    }
  }
`;

export const UPDATE_INVESTMENT = gql`
  mutation UpdateInvestment($id: Int!, $input: UpdateInvestmentInput!) {
    updateInvestment(id: $id, input: $input) {
      id
      amount
      currency
      token_amount
      token_name
      status
      first_name
      last_name
      birth_date
      place_of_birth
      nationality
      residential_address
      residential_address_two
      city
      postal_code
      country_of_residence
      wallet_address
    }
  }
`;

export const PAY_INVESTMENT = gql`
  mutation PayInvestment($input: PaymentInput!) {
    payInvestment(input: $input) {
      success
      payment_method
      redirect_url
      bank_details {
        bank_name
        account_number
        reference
      }
      wallet_address
      reference
      client_secret
      amount
      access_token
      refresh_token
      expiry
      iban
      virtual_account_id
    }
  }
`;

export const COMPLETE_INVESTMENT = gql`
  mutation CompleteInvestment($tenantId: Int, $investmentId: Int!, $tnxHash: String!) {
    completeInvestment(tenantId: $tenantId, investmentId: $investmentId, tnxHash: $tnxHash)
  }
`;
