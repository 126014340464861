import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment-timezone'
import {
  TextField,
  CircularProgress,
  Dialog,
  MenuItem,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'
import {
  GET_BLACKLIST,
  ADD_TO_BLACKLIST,
  REMOVE_FROM_BLACKLIST,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import { COUNTRY_LIST } from '../constants/countryList'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'

const AdminBlacklist = () => {
  const { t } = useTranslation()
  const { data: { getBlacklist: data } = {}, loading, refetch } = useQuery(GET_BLACKLIST)
  const [addToBlacklist, addToBlacklistStatus] = useMutation(ADD_TO_BLACKLIST)
  const [removeFromBlacklist, removeFromBlacklistStatus] = useMutation(REMOVE_FROM_BLACKLIST)
  const defaultNewRecord = {
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    nationality: '',
    passportNumber: '',
    companyName: '',
    comment: '',
  }
  const [newRecord, setNewRecord] = useState({ ...defaultNewRecord })
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)
  const updateNewRecord = (field) => (e) => {
    if (field === 'dateOfBirth') {
      setNewRecord({
        ...newRecord,
        dateOfBirth: e && !!e.getTime() ? moment(e).format('YYYY-MM-DD') : null,
      })
    } else {
      setNewRecord({
        ...newRecord,
        [field]: e.target.value,
      })
    }
  }

  const addRecord = () => {
    addToBlacklist({
      variables: {
        input: newRecord,
      },
    }).then(() => {
      refetch()
      toaster.success('New record has been added')
      setNewRecord({ ...defaultNewRecord })
    }).catch(() => {})
  }
  const deleteSelectedRecord = () => {
    removeFromBlacklist({
      variables: {
        id: selectedRecord.id,
      },
    }).then(() => {
      setIsConfirmDeleteDialogOpen(false)
      setSelectedRecord(null)
      toaster.success('Record has been deleted')
      refetch()
    }).catch(() => {})
  }
  const confirmDeleteRecord = (record) => () => {
    setSelectedRecord(record)
    setIsConfirmDeleteDialogOpen(true)
  }
  const renderRecordInfo = (record) => (
    <>
      {record.firstName && (
        <div>
          <strong>
            {t('First Name')}
            :
          </strong>
          {' '}
          {record.firstName}
        </div>
      )}
      {record.lastName && (
        <div>
          <strong>
            {t('Last Name')}
            :
          </strong>
          {' '}
          {record.lastName}
        </div>
      )}
      {record.dateOfBirth && (
        <div>
          <strong>
            {t('Date of Birth')}
            :
          </strong>
          {' '}
          {record.dateOfBirth}
        </div>
      )}
      {record.nationality && (
        <div>
          <strong>
            {t('Nationality')}
            :
          </strong>
          {' '}
          {t(record.nationality)}
        </div>
      )}
      {record.passportNumber && (
        <div>
          <strong>
            {t('Passport Number')}
            :
          </strong>
          {' '}
          {record.passportNumber}
        </div>
      )}
      {record.companyName && (
        <div>
          <strong>
            {t('Company Name')}
            :
          </strong>
          {' '}
          {record.companyName}
        </div>
      )}
    </>
  )

  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Blacklist')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq admin-settings">
        <div className="faq__center admin-settings__center center">
          <div className="faq__top admin-settings__top">
            <div className="faq__title admin-settings__title h2">{t('Blacklist')}</div>
          </div>

          <div className="faq__row admin-settings__row">
            <div className="faq__col admin-settings__col">
              { loading && (
                <CircularProgress />
              )}

              { data && (
                <>
                  <div className="upload__item">
                    <div className="upload__category">{t('Records')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="investhub-table__wrapper">
                        <div className="investhub-table__box investhub-table__box-styled">
                          <div className="investhub-table__row">
                            <div className="investhub-table__col">{t('ID')}</div>
                            <div className="investhub-table__col">{t('Info')}</div>
                            <div className="investhub-table__col">{t('Comment')}</div>
                            <div className="investhub-table__col">{t('Create At')}</div>
                            <div className="investhub-table__col">{t('Action')}</div>
                          </div>

                          {data.length
                            ? data.map((row) => (
                              <div className="investhub-table__row" key={row.id}>
                                <div className="investhub-table__col investhub-table__col-middle">
                                  <div className="investhub-table__label">{t('ID')}</div>
                                  {row.id}
                                </div>
                                <div className="investhub-table__col investhub-table__col-middle">
                                  <div className="investhub-table__label">{t('Info')}</div>
                                  {t(renderRecordInfo(row))}
                                </div>
                                <div className="investhub-table__col investhub-table__col-middle">
                                  <div className="investhub-table__label">{t('Comment')}</div>
                                  {row.comment}
                                </div>
                                <div className="investhub-table__col investhub-table__col-middle">
                                  <div className="investhub-table__label">{t('Create At')}</div>
                                  {moment(+row.createdAt).tz('Europe/Vaduz').format('YYYY-MM-DD HH:mm z')}
                                </div>
                                <div className="investhub-table__col investhub-table__col-middle">
                                  <div className="investhub-table__label">{t('Action')}</div>
                                  <button
                                    type="button"
                                    className="preview__clear preview__clear-red no-margin__top"
                                    onClick={confirmDeleteRecord(row)}
                                  >
                                    <DeleteForeverIcon />
                                    {t('Delete')}
                                  </button>
                                </div>
                              </div>
                            )) : (
                              <div className="investhub-table__row investhub-table__row-empty">
                                <div className="investhub-table__col">
                                  {t('No record found')}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="upload__item">
                    <div className="upload__category">{t('Add new record')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field__group field__group-3">
                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('First name')}
                          value={newRecord.firstName}
                          onChange={updateNewRecord('firstName')}
                        />

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Last Name')}
                          value={newRecord.lastName}
                          onChange={updateNewRecord('lastName')}
                        />

                        <div className="investhub__field field investhub-date">
                          <div className="field__label">
                            {t('Date of Birth')}
                          </div>

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              format="dd.MM.yyyy"
                              openTo="year"
                              views={['year', 'month', 'date']}
                              value={newRecord.dateOfBirth}
                              onChange={updateNewRecord('dateOfBirth')}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>

                      <div className="field__group field__group-3">
                        <div className="field">
                          <div className="field__label">{t('Nationality')}</div>

                          <TextField
                            select
                            value={newRecord.nationality}
                            onChange={updateNewRecord('nationality')}
                            className="select investhub-select select-trans"
                          >
                            {COUNTRY_LIST.map((country) => (
                              <MenuItem key={country} value={country}>{t(country)}</MenuItem>
                            ))}
                          </TextField>
                        </div>

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Passport Number')}
                          value={newRecord.passportNumber}
                          onChange={updateNewRecord('passportNumber')}
                        />

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          label={t('Company Name')}
                          value={newRecord.companyName}
                          onChange={updateNewRecord('companyName')}
                        />
                      </div>

                      <TextField
                        className="investhub__field field field-textarea"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        multiline
                        minRows={8}
                        label={t('Comment')}
                        value={newRecord.comment}
                        onChange={updateNewRecord('comment')}
                      />
                    </div>

                  </div>

                  <div className="upload__foot details__foot">
                    <div className="details__btns details__btns_clear">
                      {addToBlacklistStatus.loading ? (
                        <CircularProgress />
                      ) : (
                        <button type="button" className="button details__button" onClick={addRecord}>{t('Add to Backlist')}</button>
                      )}
                    </div>
                  </div>

                  <Dialog
                    open={isConfirmDeleteDialogOpen}
                    onClose={() => { setIsConfirmDeleteDialogOpen(false) }}
                    className="investhub-modal investhub-modal__m"
                  >
                    <ModalInnerMaterialDialog
                      modalTitle={t('Delete Confirmation')}
                      modalOnClickClose={() => { setIsConfirmDeleteDialogOpen(false) }}
                    >
                      <div className="upload__item">
                        <div className="upload__category">{t('Record information')}</div>

                        <div className="field">
                          <div className="variants__info">
                            {selectedRecord && renderRecordInfo(selectedRecord)}
                          </div>
                        </div>

                        <div className="field">
                          <div className="variants__info">
                            <strong>{t('Do you really want to delete this record?')}</strong>
                          </div>
                        </div>
                      </div>

                      <div className="upload__foot details__foot">
                        <div className="details__btns details__btns_clear">
                          {removeFromBlacklistStatus.loading
                            ? (
                              <CircularProgress />
                            ) : (
                              <>
                                <button
                                  type="button"
                                  onClick={() => { setIsConfirmDeleteDialogOpen(false) }}
                                  className="button-stroke button-small"
                                >
                                  {t('Cancel')}
                                </button>
                                {selectedRecord && (
                                  <button
                                    type="button"
                                    className="button-pink button-small"
                                    onClick={deleteSelectedRecord}
                                  >
                                    {t('Delete')}
                                  </button>
                                )}
                              </>
                            )}
                        </div>
                      </div>

                    </ModalInnerMaterialDialog>
                  </Dialog>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminBlacklist
