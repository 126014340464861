// src/pages/SignIn/components/Steps/TwoFactor.jsx
import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import VerificationInput from "react-verification-input";
import Loader from "../../../../components/shared/Loader";
import GetHelpModal from "../../../SignIn/components/steps/GetHelpModal";
import { useEmailVerification } from "../../../../myHooks/useEmailVerification";
import { useCookies } from "react-cookie";
import config from "../../../../config";

const EmailVerification = ({ onNext, email }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  const [error, setError] = useState("");
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [, setCookie] = useCookies()
  // Initialize email verification hook
  const {
    loading: isLoading,
    codeSent,
    countdown,
    sendVerificationCode,
    verifyCode,
    canResend
  } = useEmailVerification(true); // true means this is for registration

  const handleKeyDown = (index, e) => {
    // Handle backspace
    if (e.key === "Backspace") {
      // If current field is empty and we're not at the first field
      if (!code[index] && index > 0) {
        const newCode = [...code];
        newCode[index - 1] = ""; // Clear the previous field
        setCode(newCode);
        inputRefs.current[index - 1].focus();
      } else {
        // Clear current field
        const newCode = [...code];
        newCode[index] = "";
        setCode(newCode);
      }
      e.preventDefault(); // Prevent default backspace behavior
    }

    // Handle left arrow
    if (e.key === "ArrowLeft" && index > 0) {
      inputRefs.current[index - 1].focus();
    }

    // Handle right arrow
    if (e.key === "ArrowRight" && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (fullCode) => {
    if (isLoading) return;

    fullCode = fullCode || code.join("");
    if (fullCode.length !== 6) {
      setError("Please enter a complete 6-digit code");
      return;
    }

    setError("");

    try {
      const result = await verifyCode(email, fullCode);

      console.log('result', result)

      setCookie(config.jwtCookie, result.accessToken, { path: '/' });

      await onNext({ emailCode: fullCode });
    } catch (err) {
      setError("Invalid verification code. Please try again.");
      // Clear the code fields on error
      setCode(["", "", "", "", "", ""]);
      inputRefs.current[0].focus();
    }
  };

  const handleResendCode = async () => {
    if (!canResend) return;

    setError("");
    setCode(["", "", "", "", "", ""]);
    await sendVerificationCode(email);
    inputRefs.current[0].focus();
  };

  const handlePaste = (e, currentIndex) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const numbers = paste.replace(/\D/g, '').slice(0, 6).split('');

    if (numbers.length) {
      let newCode = [...code];

      if (numbers.length === 1) {
        newCode[currentIndex] = numbers[0];
        setCode(newCode);

        if (currentIndex < 5) {
          inputRefs.current[currentIndex + 1].focus();
        }
      } else {
        numbers.forEach((num, i) => {
          if (i < 6) newCode[i] = num;
        });
        setCode(newCode);

        const nextEmptyIndex = newCode.findIndex((digit) => !digit);
        const focusIndex = nextEmptyIndex === -1 ? 5 : nextEmptyIndex;
        inputRefs.current[focusIndex].focus();
      }

      if (newCode.every((digit) => digit !== '')) {
        setTimeout(() => handleSubmit(newCode.join('')), 0);
      }
    }
  };

  const handleChange = (index, value) => {
    // Only allow single digit
    const digit = value.slice(-1);

    // Only allow numbers
    if (digit && !/^\d+$/.test(digit)) return;

    const newCode = [...code];
    newCode[index] = digit;
    setCode(newCode);

    // Clear any existing errors
    if (error) setError("");

    // Auto-advance to next field
    if (digit && index < 5) {
      inputRefs.current[index + 1].focus();
    }

    // Check if we have all 6 digits
    if (digit && index === 5) {
      // Use setTimeout to ensure state is updated before validation
      setTimeout(() => {
        const fullCode = newCode.join("");
        if (fullCode.length === 6) {
          handleSubmit(fullCode);
        }
      }, 0);
    }
  };

  const handleComplete = (code) => {
    handleSubmit(code);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="mb-6 text-center text-gray-lighter text-sm">
        Enter the 6-digit code from your email:{" "}
        <span className="font-semibold text-white">{email}</span>
      </div>

      {/* Code Input Grid */}
      <VerificationInput
        length={6}
        placeholder=""
        validChars="0-9"
        autoFocus
        onComplete={handleComplete}
        classNames={{
          container: "verification-container gap-2 grid grid-cols-6 mb-6 w-full h-14",
          character: "border-[#2b2d33] border-2 border-solid focus:border-white/20 bg-black-light rounded-xl w-full h-12 text-center text-white text-base inline-flex justify-center items-center",
          characterInactive: "character--inactive",
          characterSelected: "character--selected border-white/20",
          characterFilled: "character--filled border-white/20"
        }}
      />

      {/* Error Message */}
      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-4 text-red-500 text-sm"
        >
          {error}
        </motion.div>
      )}

      {/* Submit Button */}
      <button
        onClick={() => handleSubmit()}
        disabled={isLoading || code.some((digit) => !digit)}
        className={`
          inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 !rounded-2xl w-full !h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible relative
          ${isLoading || code.some((digit) => !digit) ? "opacity-50 cursor-not-allowed" : ""}
        `}
      >
        {isLoading ? <Loader className="relative" /> : "Verify"}
      </button>

      {/* Resend Code */}
      <div className="mt-4 text-[13px] text-center text-gray-lighter">
        {countdown > 0 ? (
          <span>Resend code in {countdown}s</span>
        ) : (
          <span
            onClick={handleResendCode}
            className="hover:opacity-80 font-semibold text-white transition-opacity duration-200 cursor-pointer"
          >
            Resend code
          </span>
        )}
      </div>

      {/* Help Text */}
      <div className="mt-4 text-[13px] text-center text-gray-lighter">
        Having trouble?{" "}
        <span
          onClick={() => setIsHelpModalOpen(true)}
          className="hover:opacity-80 font-semibold text-white transition-opacity duration-200 cursor-pointer"
        >
          Get help
        </span>
      </div>

      <GetHelpModal
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        email={email}
      />
    </div>
  );
};

export default EmailVerification;
