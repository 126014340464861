import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TOKEN_BALANCES_BY_ADDRESS, GET_TRANSACTIONS_BY_ADDRESS, GET_TOTAL_VALUE_BY_ADDRESS } from '../queriesAndMutations';

function useAddressAssets(address) {
    const [balances, setBalances] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalValue, setTotalValue] = useState(0);
    const { data: tokenBalancesData, loading: tokenBalancesLoading, error: tokenBalancesError } = useQuery(GET_TOKEN_BALANCES_BY_ADDRESS, {
        variables: { address },
        skip: !address,
    });

    const { data: transactionsData, loading: transactionsLoading, error: transactionsError } = useQuery(GET_TRANSACTIONS_BY_ADDRESS, {
        variables: { address },
        skip: !address,
    });

    const { data: totalValueData, loading: totalValueLoading, error: totalValueError } = useQuery(GET_TOTAL_VALUE_BY_ADDRESS, {
        variables: { address },
        skip: !address,
    });

    useEffect(() => {
        if (tokenBalancesData) {
            setBalances(tokenBalancesData.getTokenBalancesByAddress.tokenAssets);
        }
        if (transactionsData) {
            setTransactions(transactionsData.getTransactionsByAddress.transactions);
        }
        if (totalValueData) {
            setTotalValue(totalValueData.getTotalValueByAddress.totalValue);
        }
    }, [tokenBalancesData, transactionsData, totalValueData]);

    useEffect(() => {
        setLoading(tokenBalancesLoading || transactionsLoading);
        setError(tokenBalancesError || transactionsError);
    }, [tokenBalancesLoading, transactionsLoading, tokenBalancesError, transactionsError]);

    return { balances, transactions, totalValue, loading, error };
}

export default useAddressAssets;
