import InvesthubLogo from "../media/images/dark-mode-investhub-logo.svg"

// Dummy Coinbase with image captured
import CoinBaseOne from "../assets/images/coinbasesmartwallet/imageflow-1.PNG"
import CoinBaseTwo from "../assets/images/coinbasesmartwallet/imageflow-2.PNG"
import CoinBaseThree from "../assets/images/coinbasesmartwallet/imageflow-3.PNG"
import CoinBaseFour from "../assets/images/coinbasesmartwallet/imageflow-4.PNG"
import CoinBaseFive from "../assets/images/coinbasesmartwallet/imageflow-5.PNG"
import CoinBaseSix from "../assets/images/coinbasesmartwallet/imageflow-6.PNG"
import CoinBaseSeven from "../assets/images/coinbasesmartwallet/iamgeflow-7.PNG"

// Dummy metamask with image captured
import MetamaskOne from "../assets/images/metamasksmartwallet/metamask-1.png"
import MetamaskTwo from "../assets/images/metamasksmartwallet/metamask-2.png"
import MetamaskThree from "../assets/images/metamasksmartwallet/metamask-3.png"

// Dummy singpass with image captured
import SingPassOne from "../assets/images/Singpass/step-1.png"
import SingPassTwo from "../assets/images/Singpass/step-2.png"
import SingPassThree from "../assets/images/Singpass/step-3.png"

// eslint-disable-next-line import/prefer-default-export
export const COMPANY_ASSETS = {
  siteLogo: InvesthubLogo
}

// eslint-disable-next-line import/prefer-default-export
export const DUMMY_COINBASE = [
  { src: CoinBaseOne, alt: "Dummy one" },
  { src: CoinBaseTwo, alt: "Dummy two" },
  { src: CoinBaseThree, alt: "Dummy three" },
  { src: CoinBaseFour, alt: "Dummy four" },
  { src: CoinBaseFive, alt: "Dummy five" },
  { src: CoinBaseSix, alt: "Dummy six" },
  { src: CoinBaseSeven, alt: "Dummy seven" }
]

// eslint-disable-next-line import/prefer-default-export
export const DUMMY_METAMASK = [
  { src: MetamaskOne, alt: "Dummy one" },
  { src: MetamaskTwo, alt: "Dummy two" },
  { src: MetamaskThree, alt: "Dummy three" }
]

// eslint-disable-next-line import/prefer-default-export
export const DUMMY_SINGPASS = [
  { src: SingPassOne, alt: "Dummy one" },
  { src: SingPassTwo, alt: "Dummy two" },
  { src: SingPassThree, alt: "Dummy three" }
]
