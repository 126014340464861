import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NAVIGATION_ITEMS from '../constant/menuConfig';

export const useSettingsNavigation = () => {
  const history = useHistory();
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showMobileSelect, setShowMobileSelect] = useState(false);

  // Set initial tab from URL or default to basic
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab') || 'basic';
    const index = NAVIGATION_ITEMS.findIndex(item => item.id === tab);
    if (index !== -1) {
      setActiveIndex(index);
    }
  }, [location.search]);

  const handleNavigate = useCallback((index) => {
    const tab = NAVIGATION_ITEMS[index].id;
    history.push(`/my-profile?tab=${tab}`);
    setActiveIndex(index);
    setShowMobileSelect(false);
  }, [history]);

  const handleDeleteAccount = useCallback(() => {
    setShowDeleteAccount(true);
  }, []);

  const toggleMobileSelect = useCallback(() => {
    setShowMobileSelect(prev => !prev);
  }, []);

  return {
    activeIndex,
    showDeleteAccount,
    showMobileSelect,
    handleNavigate,
    handleDeleteAccount,
    toggleMobileSelect
  };
};

export default useSettingsNavigation;
