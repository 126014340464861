import React from "react"
import PropTypes from "prop-types"

const Loader = ({
  size = 'default',
  center = false,
  className = '',
  ...props
}) => {
  const getLoaderClass = () => {
    switch (size) {
      case 'large':
        return 'is-large';
      case 'xlarge':
        return 'is-xlarge';
      default:
        return '';
    }
  };

  return (
    <div
      className={`loader__wrapper ${center ? 'loader__wrapper--center' : ''} ${className}`}
      {...props}
    >
      <div className={`loader ${getLoaderClass()}`} />
    </div>
  )
}

Loader.propTypes = {
  size: PropTypes.oneOf(['default', 'large', 'xlarge']),
  center: PropTypes.bool,
  className: PropTypes.string
};

export default Loader;
