/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from '@apollo/client';

import Icons from "../../../../components/shared/Icons";
import { ModalWide } from "../../../../components/shared/Modals";
import ListCountries from "./ListCountries";
// import ListTokens from "./ListTokens";
import ListTokens from "../../FieldBuyInvest/FieldBuy/ListTokens";

import payments from "../../../../services/paymentData";
import IBANDetail from "./IBANDetail";
import VoltDetail from "./VoltDetail";
import RampNetworkDetail from "../RampNetworkDetail";
import { PAY_INVESTMENT } from '../../../../queriesAndMutations/investmentMutation';
import StripeOnrampDetail from './StripeOnrampDetail';
import { toaster, isProduction } from '../../../../utils'
import CoinbaseDetail from '../CoinbaseDetail';
import { constants } from '@monerium/sdk';
import { ethereum, walletClient } from '../../../../myHooks/useCoinbaseAuth';
import { useMe } from '../../../../myHooks'
import metamaskService from '../../../../services/metamask'
import { providers } from 'ethers'
// eslint-disable-next-line import/no-unresolved
import { useAppKitAccount, useAppKitProvider, useDisconnect } from '@reown/appkit/react'
import Loader from '../../../../components/shared/Loader'

import styles from "./Style.module.scss"
import classNames from "classnames";

const StepBeforeInsufficientBalance = ({ onNext, onBack, onSubmit, currentInvestment, moneriumCode, currentIssuer }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputWidth, setInputWidth] = useState(48); // Initial width for placeholder
  const [selectedAmount, setSelectedAmount] = useState(null);
  const { formState, handleSubmit, getValues, setValue } = useFormContext()
  const [inputValue, setInputValue] = useState(getValues('amount') || 100);
  const [isLoading, setIsLoading] = useState(false);

  const { data: { me: user } = {} } = useMe()
  const [isOpenModalIBAN, setIsOpenModalIBAN] = useState(false);
  const [isOpenModalRampNetwork, setIsOpenModalRampNetwork] = useState(false);
  const [isOpenModalStripe, setIsOpenModalStripe] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  // const [moneriumAccessData, setMoneriumAccessData] = useState(null);
  const { walletProvider } = useAppKitProvider('eip155')
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount()
  const [voltViban, setVoltViban] = useState(null);

  const textRef = useRef(null);

  // Modal iban
  const toggleModalIBAN = () => {
    setIsOpenModalIBAN(!isOpenModalIBAN);
  };
  // Modal volt io
  const [isOpenModalVolt, setIsOpenModalVolt] = useState(false);
  const toggleModalVolt = () => {
    setIsOpenModalVolt(!isOpenModalVolt);
  };
  // Modal ramp network
  const toggleModalRampNetwork = () => {
    setIsOpenModalRampNetwork(!isOpenModalRampNetwork);
  };

  useEffect(() => {
    // Sync the input's width with the width of the hidden div
    if (textRef.current) {
      const newWidth = textRef.current.offsetWidth + 10;
      setInputWidth(newWidth);
    }
  }, [inputValue]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setValue('amount', e.target.value);
  };

  const handleButtonClick = (amount) => {
    setSelectedAmount(amount);
    setInputValue(amount);
    setValue('amount', amount);
  };

  // Modal payment
  const [isOpenModalPayment, setIsOpenModalPayment] = useState(false);
  const toggleModalPayment = () => {
    setIsOpenModalPayment(!isOpenModalPayment);
  };

  const [isOpenModalCoinbase, setIsOpenModalCoinbase] = useState(false);
  const [isOpenModalDFX, setIsOpenModalDFX] = useState(false);
  useEffect(() => {
    if (moneriumCode) {
      setIsOpenModalIBAN(true);
      setIsOpenModalPayment(false);
    }
  }, [moneriumCode]);

  // Add Apollo mutation
  const [payInvestment, { loading: paymentLoading }] = useMutation(PAY_INVESTMENT);

  // Handle payment method selection
  const handlePaymentMethod = useCallback(async (method) => {
    try {
      if (method === 'MTPELERIN') {
        const signMessage = async (code) => {
          try {
            const message = `MtPelerin-${code}`;

            if (user.login_provider === 'coinbase') {
              await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: isProduction ? 0x2105 : 0x14a34 }],
              })

              const signature = await walletClient.signMessage({
                account: user.wallet_address,
                message: message,
              })

              return signature
            } else if (user.login_provider === 'walletconnect' && isWcConnected) {
              const ethersProvider = new providers.Web3Provider(walletProvider)

              const hexMessage = `0x${Buffer.from(message, "utf8").toString("hex")}`

              // Request signature from user
              const signature = await ethersProvider.provider.request({
                method: 'personal_sign',
                params: [
                  hexMessage,
                  wcAddress,
                ]
              });

              return signature
            }

            if (metamaskService.isMetamaskNotAvailable()) {
              toaster.error('You need to install Metamask to use this feature')
              return null
            }

            return await metamaskService.signMessage(user.wallet_address, message);
          } catch (error) {
            toaster.error(error.message)
            console.log(error)
            return null
          }
        }

        // generate random 4 digits code
        const code = Math.floor(1000 + Math.random() * 9000).toString()
        const signature = await signMessage(code)

        if (!signature) {
          toaster.error('Failed to sign message')
          return
        }

        const currency = getValues('currency');

        const opts = {
          lang: 'en',
          _ctkn: process.env.REACT_APP_MTPELERIN_INTEGRATION_KEY,
          allow: "usb; ethereum; clipboard-write; payment; microphone; camera",
          loading: "lazy",
          dnet: 'base_mainnet',
          bdc: currency.toUpperCase(),
          bda: getValues('amount'),
          addr: user.wallet_address,
          code,
          hash: Buffer.from(signature.replace('0x', ''), 'hex').toString('base64'),
        }

        if (!isProduction) {
          opts.env = 'development'
        }
        // eslint-disable-next-line no-undef
        showMtpModal(opts)

        setIsOpenModalPayment(false);
        onNext();
        return
      }

      if (method === 'DFX') {
        setIsOpenModalDFX(true);
        setIsOpenModalPayment(false);
        return
      }

      const { data } = await payInvestment({
        variables: {
          input: {
            investment_id: +currentInvestment.id,
            payment_method: method,
          }
        }
      });

      const paymentResponse = data.payInvestment;

      if (paymentResponse.success) {
        setPaymentDetails(paymentResponse);

        switch (paymentResponse.payment_method) {
          case 'STRIPE':
            setStripeClientSecret(paymentResponse.client_secret);
            setIsOpenModalStripe(true);
            setIsOpenModalPayment(false);
            break;

          case 'IBAN':
            // window.localStorage.setItem(constants.STORAGE_ACCESS_TOKEN, paymentResponse.access_token);
            // window.localStorage.setItem(constants.STORAGE_ACCESS_EXPIRY, new Date().getTime() + paymentResponse.expiry * 1000);

            // setMoneriumAccessData(paymentResponse)
            setIsOpenModalIBAN(true);
            setIsOpenModalPayment(false);
            break;

          case 'VOLT':
            setVoltViban({
              iban: paymentResponse.iban,
              virtual_account_id: paymentResponse.virtual_account_id
            });
            setIsOpenModalVolt(true);
            setIsOpenModalPayment(false);
            break;

          case 'COINBASE':
            setIsOpenModalCoinbase(true);
            setIsOpenModalPayment(false);
            break;

          default:
            console.error('Unknown payment method');
            toaster.error('Unsupported payment method');
        }
      }
    } catch (error) {
      console.error('Payment error:', error);
      toaster.error(error.message);
    }
  }, [currentInvestment, payInvestment, onNext, getValues, user, walletProvider, isWcConnected, wcAddress]);

  const handlePaymentSuccess = useCallback(() => {
    toaster.success('Payment successful');
    onNext();
  }, [onNext]);

  const handleStripeError = useCallback((error) => {
    console.error('Stripe error:', error);
    toaster.error('Payment failed. Please try again.');
  }, []);

  const triggerSubmit = () => {
    handleSubmit(async (data) => {
      setIsLoading(true);
      const investment = await onSubmit(data);
      console.log('investment', investment);

      if (investment) {
        setIsOpenModalPayment(true);
      }

      setIsLoading(false);
    })();
  };

  return (
    <>
      <div
        className={`${styles.wrapper} relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none ${
          isFocused ? "before:border-white/20" : "before:border-transparent"
        }`}
      >
        <div className="flex justify-between items-center mb-2 w-full">
          <div className="text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
            You're buying
          </div>

          <ListCountries />
        </div>

        <div className="relative flex flex-col items-center gap-x-2 gap-y-2 min-w-0 min-h-0">
          <div className="inline-flex justify-center items-center">
            {/* <span
              className={`font-medium text-[#6F737E] text-[70px] text-left leading-[60px] select-none  ${
                inputValue ? "text-white" : "text-[#6F737E]"
              } `}
            >
              €
            </span> */}
            <input
              type="number"
              className="relative bg-transparent p-0 border-none w-auto text-[70px] text-left text-white placeholder:text-[#6F737E] leading-[60px] transition-opacity duration-[250ms] pointer-events-auto ease-[ease-in-out] field__buy outline-none"
              placeholder="0"
              onChange={handleInputChange}
              value={inputValue}
              style={{ width: `${inputWidth}px` }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
            <div
              ref={textRef}
              className="right-0 bottom-0 absolute font-medium text-[70px] text-left leading-[60px] invisible"
            >
              {inputValue || "0"}
            </div>
          </div>

          <ListTokens currentIssuer={currentIssuer} />

          <div className="inline-flex justify-center items-center gap-2 mt-4">
            <div
              className={`inline-flex relative justify-start items-center gap-1 backface-visible-hidden ${
                selectedAmount === 100
                  ? "bg-[#0052ff] opacity-90"
                  : "bg-black-dark hover:bg-[#0052ff] focus:bg-[#0052ff]"
              } px-4 py-2 rounded-full font-bold text-white whitespace-nowrap transition-all duration-[0.2s] cursor-pointer overflow-hidden`}
              onClick={() => handleButtonClick(100)}
            >
              100
            </div>

            <div
              className={`inline-flex relative justify-start items-center gap-1 backface-visible-hidden ${
                selectedAmount === 300
                  ? "bg-[#0052ff] opacity-90"
                  : "bg-black-dark hover:bg-[#0052ff] focus:bg-[#0052ff]"
              } px-4 py-2 rounded-full font-bold text-white whitespace-nowrap transition-all duration-[0.2s] cursor-pointer overflow-hidden`}
              onClick={() => handleButtonClick(300)}
            >
              300
            </div>

            <div
              className={`inline-flex relative justify-start items-center gap-1 backface-visible-hidden ${
                selectedAmount === 1000
                  ? "bg-[#0052ff] opacity-90"
                  : "bg-black-dark hover:bg-[#0052ff] focus:bg-[#0052ff]"
              } px-4 py-2 rounded-full font-bold text-white whitespace-nowrap transition-all duration-[0.2s] cursor-pointer overflow-hidden`}
              onClick={() => handleButtonClick(1000)}
            >
              1000
            </div>
          </div>

          <div className="inline-flex relative justify-center items-center mt-2">
            {inputValue && <span className="font-bold text-xs">{inputValue} {getValues('currency').toUpperCase()}</span>}
          </div>
        </div>
      </div>
      <div className="w-full h-2" />

      {/* Act like button */}
      <div
        className="inline-flex justify-center items-center bg-[#0052ff] hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 !rounded-2xl w-full !h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
        onClick={triggerSubmit}
        disabled={isLoading}
      >
        {isLoading ? <Loader className="relative" /> : 'Submit and Continue'}
      </div>

      <ModalWide
        isOpen={isOpenModalPayment}
        onClose={toggleModalPayment}
        title="Buy Your Crypto"
        customClassModal="!max-w-[380px] "
        headerTitleLeft={true}
      >
        <div className="flex flex-col gap-2 -mt-4 w-full">
          {payments.filter(payment => payment.cryptos.includes(getValues('currency')) && payment.supportedProviders.includes(user.login_provider)).map((payment, index) => (
            <button
              className={classNames("flex justify-start items-stretch gap-1 bg-black-light p-6 border-solid rounded-xl w-full font-medium text-sm text-white hover:text-white transition-colors duration-[0.2s] ease-linear cursor-pointer group outline-none",
                isProduction && payment.name === 'Volt.io' && 'hidden'
              )}
              key={index}
              // disabled={paymentLoading}
              onClick={(e) => {
                e.preventDefault();

                switch (payment.name) {
                  case 'IBAN':
                    handlePaymentMethod('IBAN');
                    break;
                  case 'Volt.io':
                    handlePaymentMethod('VOLT');
                    break;
                  case 'Ramp.network':
                    handlePaymentMethod('CRYPTO');
                    break;
                  case 'Stripe':
                    handlePaymentMethod('STRIPE');
                    break;
                  case 'Coinbase':
                    handlePaymentMethod('COINBASE');
                    break;
                  case 'Mt Pelerin':
                    handlePaymentMethod('MTPELERIN');
                    break;
                  case 'DFX':
                    handlePaymentMethod('DFX');
                    break;
                  default:
                    window.open(payment.url_site, '_blank');
                }
              }}
            >
              <div className="flex flex-row flex-wrap flex-1 justify-start items-center">
                <div className="rounded-full w-10 h-10 overflow-hidden">
                  <img
                    src={require(`../../../../assets/images/payments/${payment.image}`)}
                    alt={payment.name}
                    className="w-full h-full object-fill"
                  />
                </div>

                <div className="flex flex-col flex-nowrap flex-1 justify-start items-start gap-1 ml-[12px] text-left">
                  <span className="font-bold text-base tracking-[-0.02em]">
                    {payment.name}
                  </span>
                  <span className="text-[#6F737E] text-[13px] leading-[1.4] tracking-[-0.02em]">
                    {payment.desc}
                  </span>
                </div>
              </div>

              <div className="flex justify-center items-center w-4 h-auto text-white">
                <Icons
                  nameIcon="external-link"
                  className="group-hover:visible opacity-0 group-hover:opacity-100 w-4 h-4 transition-all duration-[0.2s] invisible"
                />
              </div>
            </button>
          ))}
        </div>

        {paymentLoading && (
          <div className="flex justify-center items-center mt-4">
            <div className="border-white border-b-2 rounded-full w-8 h-8 animate-spin" />
          </div>
        )}

        <div className="mt-4 w-full text-[#6f767e] text-[13px] text-left">
          You'll continue to the provider's portal to see the fees associated
          with your transaction.
        </div>
      </ModalWide>

      <IBANDetail
        isOpen={isOpenModalIBAN}
        onClose={toggleModalIBAN}
        onNext={onNext}
        currentIssuer={currentIssuer}
        // refreshToken={(moneriumAccessData || {}).refresh_token}
      />

      <VoltDetail
        isOpen={isOpenModalVolt}
        onClose={toggleModalVolt}
        onNext={onNext}
        voltViban={voltViban}
        currentInvestment={currentInvestment}
      />

      <RampNetworkDetail
        isOpen={isOpenModalRampNetwork}
        onClose={toggleModalRampNetwork}
        onNext={onNext}
      />

      <StripeOnrampDetail
        isOpen={isOpenModalStripe}
        onClose={() => setIsOpenModalStripe(false)}
        clientSecret={stripeClientSecret}
        onSuccess={handlePaymentSuccess}
        onError={handleStripeError}
      />

      <CoinbaseDetail
        isOpen={isOpenModalCoinbase}
        onClose={() => setIsOpenModalCoinbase(false)}
        onNext={onNext}
        amount={(paymentDetails || {}).amount}
        destinationAddress={(paymentDetails || {}).wallet_address}
      />

      <ModalWide
        isOpen={isOpenModalDFX}
        onClose={() => {
          setIsOpenModalDFX(false)
          onNext()
        }}
        title="Buy Your Crypto"
        customClassModal="!max-w-[450px] "
        headerTitleLeft={true}
      >
        <div style={{ width: '100%', maxWidth: '450px', height: '700px' }}>
          <dfx-services mode="buy-evm" lang="en" refcode="000-000" wallet="IFRAME" >Loading ...</dfx-services>
        </div>
      </ModalWide>
    </>
  );
};

export default StepBeforeInsufficientBalance;
