// src/utils/lang.js
export const supportedLanguages = {
  bg: {
    code: 'bg',
    label: 'Bulgarian',
    lcid: 'bg_BG',
    flag: 'https://flagcdn.com/w80/bg.png',
    flagIcon: 'https://flagcdn.com/w40/bg.png',
  },
  hr: {
    code: 'hr',
    label: 'Croatian',
    lcid: 'hr_HR',
    flag: 'https://flagcdn.com/w80/hr.png',
    flagIcon: 'https://flagcdn.com/w40/hr.png',
  },
  cs: {
    code: 'cs',
    label: 'Czech',
    lcid: 'cs_CZ',
    flag: 'https://flagcdn.com/w80/cz.png',
    flagIcon: 'https://flagcdn.com/w40/cz.png',
  },
  da: {
    code: 'da',
    label: 'Danish',
    lcid: 'da_DK',
    flag: 'https://flagcdn.com/w80/dk.png',
    flagIcon: 'https://flagcdn.com/w40/dk.png',
  },
  nl: {
    code: 'nl',
    label: 'Dutch',
    lcid: 'nl_NL',
    flag: 'https://flagcdn.com/w80/nl.png',
    flagIcon: 'https://flagcdn.com/w40/nl.png',
  },
  en: {
    code: 'en',
    label: 'English',
    lcid: 'en_GB',
    flag: 'https://flagcdn.com/w80/gb.png',
    flagIcon: 'https://flagcdn.com/w40/gb.png',
  },
  et: {
    code: 'et',
    label: 'Estonian',
    lcid: 'et_EE',
    flag: 'https://flagcdn.com/w80/ee.png',
    flagIcon: 'https://flagcdn.com/w40/ee.png',
  },
  fi: {
    code: 'fi',
    label: 'Finnish',
    lcid: 'fi_FI',
    flag: 'https://flagcdn.com/w80/fi.png',
    flagIcon: 'https://flagcdn.com/w40/fi.png',
  },
  fr: {
    code: 'fr',
    label: 'French',
    lcid: 'fr_FR',
    flag: 'https://flagcdn.com/w80/fr.png',
    flagIcon: 'https://flagcdn.com/w40/fr.png',
  },
  de: {
    code: 'de',
    label: 'German',
    lcid: 'de_DE',
    flag: 'https://flagcdn.com/w80/de.png',
    flagIcon: 'https://flagcdn.com/w40/de.png',
  },
  el: {
    code: 'el',
    label: 'Greek',
    lcid: 'el_GR',
    flag: 'https://flagcdn.com/w80/gr.png',
    flagIcon: 'https://flagcdn.com/w40/gr.png',
  },
  hu: {
    code: 'hu',
    label: 'Hungarian',
    lcid: 'hu_HU',
    flag: 'https://flagcdn.com/w80/hu.png',
    flagIcon: 'https://flagcdn.com/w40/hu.png',
  },
  is: {
    code: 'is',
    label: 'Icelandic',
    lcid: 'is_IS',
    flag: 'https://flagcdn.com/w80/is.png',
    flagIcon: 'https://flagcdn.com/w40/is.png',
  },
  ga: {
    code: 'ga',
    label: 'Irish',
    lcid: 'ga_IE',
    flag: 'https://flagcdn.com/w80/ie.png',
    flagIcon: 'https://flagcdn.com/w40/ie.png',
  },
  it: {
    code: 'it',
    label: 'Italian',
    lcid: 'it_IT',
    flag: 'https://flagcdn.com/w80/it.png',
    flagIcon: 'https://flagcdn.com/w40/it.png',
  },
  lv: {
    code: 'lv',
    label: 'Latvian',
    lcid: 'lv_LV',
    flag: 'https://flagcdn.com/w80/lv.png',
    flagIcon: 'https://flagcdn.com/w40/lv.png',
  },
  lt: {
    code: 'lt',
    label: 'Lithuanian',
    lcid: 'lt_LT',
    flag: 'https://flagcdn.com/w80/lt.png',
    flagIcon: 'https://flagcdn.com/w40/lt.png',
  },
  lb: {
    code: 'lb',
    label: 'Luxembourgish',
    lcid: 'lb_LU',
    flag: 'https://flagcdn.com/w80/lu.png',
    flagIcon: 'https://flagcdn.com/w40/lu.png',
  },
  mt: {
    code: 'mt',
    label: 'Maltese',
    lcid: 'mt_MT',
    flag: 'https://flagcdn.com/w80/mt.png',
    flagIcon: 'https://flagcdn.com/w40/mt.png',
  },
  no: {
    code: 'no',
    label: 'Norwegian',
    lcid: 'no_NO',
    flag: 'https://flagcdn.com/w80/no.png',
    flagIcon: 'https://flagcdn.com/w40/no.png',
  },
  pl: {
    code: 'pl',
    label: 'Polish',
    lcid: 'pl_PL',
    flag: 'https://flagcdn.com/w80/pl.png',
    flagIcon: 'https://flagcdn.com/w40/pl.png',
  },
  pt: {
    code: 'pt',
    label: 'Portuguese',
    lcid: 'pt_PT',
    flag: 'https://flagcdn.com/w80/pt.png',
    flagIcon: 'https://flagcdn.com/w40/pt.png',
  },
  ro: {
    code: 'ro',
    label: 'Romanian',
    lcid: 'ro_RO',
    flag: 'https://flagcdn.com/w80/ro.png',
    flagIcon: 'https://flagcdn.com/w40/ro.png',
  },
  sk: {
    code: 'sk',
    label: 'Slovak',
    lcid: 'sk_SK',
    flag: 'https://flagcdn.com/w80/sk.png',
    flagIcon: 'https://flagcdn.com/w40/sk.png',
  },
  sl: {
    code: 'sl',
    label: 'Slovenian',
    lcid: 'sl_SI',
    flag: 'https://flagcdn.com/w80/si.png',
    flagIcon: 'https://flagcdn.com/w40/si.png',
  },
  es: {
    code: 'es',
    label: 'Spanish',
    lcid: 'es_ES',
    flag: 'https://flagcdn.com/w80/es.png',
    flagIcon: 'https://flagcdn.com/w40/es.png',
  },
  sv: {
    code: 'sv',
    label: 'Swedish',
    lcid: 'sv_SE',
    flag: 'https://flagcdn.com/w80/se.png',
    flagIcon: 'https://flagcdn.com/w40/se.png',
  },
  tr: {
    code: 'tr',
    label: 'Turkish',
    lcid: 'tr_TR',
    flag: 'https://flagcdn.com/w80/tr.png',
    flagIcon: 'https://flagcdn.com/w40/tr.png',
  }
};

export const getLocale = () => {
  const locale = localStorage.getItem('lang') || 'en'
  return supportedLanguages[locale] ? locale : 'en'
}

export const setLocale = (lang) => localStorage.setItem('lang', lang)

export const getCurrentFlagIcon = () => supportedLanguages[getLocale()].flagIcon
export const getCurrentLang = () => supportedLanguages[getLocale()].label
export const getCurrentLocalLcid = () => supportedLanguages[getLocale()].lcid

// Tambahan helper functions
export const getLanguageByCode = (code) => supportedLanguages[code]
export const getLabelByCode = (code) => supportedLanguages[code] && supportedLanguages[code].label
export const getFlagByCode = (code) => supportedLanguages[code] && supportedLanguages[code].flag

export default {
  supportedLanguages,
  getLocale,
  setLocale,
  getCurrentFlagIcon,
  getCurrentLang,
  getCurrentLocalLcid,
  getLanguageByCode,
  getLabelByCode,
  getFlagByCode,
}
