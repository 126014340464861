import React from 'react'
import cn from 'classnames'
import Icons from '../../../../../../shared/Icons'

const MobileTabSelect = ({ activeTab, isOpen, tabs, onToggle, onSelect }) => (
  <div className="relative custom-dropdown mb-6">
    <button
      type="button"
      onClick={onToggle}
      className={cn(
        "w-full flex justify-between items-center px-4 py-2 rounded-xl border-2 border-solid transition-all duration-200 font-semibold",
        {
          "border-white/20": isOpen
        }
      )}
    >
      <span className="font-sans text-[13px] text-white tracking-[-0.01em]">
        {tabs[activeTab]}
      </span>
      <Icons
        nameIcon="chevron-down"
        className={cn("w-5 h-5 text-gray-custom transition-all duration-200", {
          "rotate-180": isOpen
        })}
      />
    </button>

    <div className={cn(
      "top-full z-[9] absolute inset-x-0 bg-black-light mx-0 mt-0.5 mb-0 px-0 py-2 shadow-[#33383f_0_0_0_2px_inset] border-solid rounded-xl origin-[50%_0] overflow-hidden align-baseline w-full",
      {
        "opacity-1 translate-y-0 scale-100 !bg-black-light pointer-events-auto": isOpen,
        "opacity-0 translate-y-[-16px] scale-75 pointer-events-none": !isOpen
      }
    )}>
      {tabs.map((tab, index) => (
        <button
          key={tab}
          type="button"
          onClick={() => {
            onSelect(index)
            onToggle()
          }}
          className={cn(
            "block border-0 bg-[initial] font-sans bg-none m-0 px-4 py-2 p-[4px16px] min-h-[auto] font-semibold text-left text-[13px] leading-[1.71429] transition-all duration-[0.2s] cursor-pointer align-baseline w-full",
            {
              "text-primary": activeTab === index,
              "text-gray-custom hover:text-white": activeTab !== index
            }
          )}
        >
          {tab}
        </button>
      ))}
    </div>
  </div>
)

export default MobileTabSelect
