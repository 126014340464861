export const tableHeaders = [
  {
    key: "token",
    title: "Token",
    customStyle: {
      width: '15%',
      minWidth: '120px',
      position: 'sticky',
      left: 0,
      zIndex: 2
    },
  },
  {
    key: 'network',
    title: 'Network',
    customStyle: {
      width: '25%',
      minWidth: '140px'
    }
  },
  {
    key: 'limit',
    title: 'Limit',
    customStyle: {
      width: '25%',
      minWidth: '150px',
      textAlign: 'right'
    }
  },
  {
    key: 'used',
    title: 'Used',
    customStyle: {
      width: '25%',
      minWidth: '150px',
      textAlign: 'right'
    }
  },
  {
    key: 'action',
    title: 'Action',
    customStyle: {
      width: '10%',
      minWidth: '100px',
      textAlign: 'center',
      justifyContent: 'center'
    }
  },
];

export const dummySpendingCaps = [
  {
    token: 'USDC',
    caps: [
      {
        network: 'Ethereum',
        limit: '1,000 USDC',
        used: '300 USDC'
      },
      {
        network: 'Base',
        limit: '2,000 USDC',
        used: '750 USDC'
      },
      {
        network: 'Arbitrum',
        limit: '500 USDC',
        used: '100 USDC'
      }
    ]
  },
  {
    token: 'EURE',
    caps: [
      {
        network: 'Ethereum',
        limit: '5,000 EURE',
        used: '1,200 EURE'
      },
      {
        network: 'Polygon',
        limit: '10,000 EURE',
        used: '4,500 EURE'
      },
      {
        network: 'Base',
        limit: '3,000 EURE',
        used: '900 EURE'
      }
    ]
  }
];
