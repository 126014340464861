import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Flag from "react-world-flags";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import Icons from "../../../../../components/shared/Icons";
import countries from '../../../../../constants/countryWithCode'

const FlagSkeleton = () => (
  <div className="flex items-center gap-x-3 border-0 m-0 px-0 py-2 rounded-2xl">
    <div className="rounded-[50%] w-10 h-10 overflow-hidden">
      <Skeleton circle width={40} height={40} className="bg-gray-lighter" />
    </div>
    <div className="flex-1">
      <Skeleton width={120} height={20} className="bg-gray-lighter" />
    </div>
  </div>
);

const ListCountries = () => {
  const defaultCountry = countries.find(
    (country) => country.name === "Singapore"
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(defaultCountry);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isOpenModal) return undefined;

    const timer = setTimeout(() => setIsLoading(false), 800);
    return () => clearTimeout(timer);
  }, [isOpenModal]);

  const toggleModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsOpenModal(false);
      }
    };

    if (isOpenModal) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpenModal]);

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCountrySelect = (country) => {
    setCurrentCountry(country);
    toggleModal();
  };

  return (
    <>
      <div
        className="inline-flex relative justify-start items-center gap-4 backface-visibility-hidden bg-black-dark hover:opacity-90 px-4 py-2 rounded-full text-white whitespace-nowrap transition-all duration-[0.2s] cursor-pointer overflow-hidden"
        onClick={toggleModal}
      >
        <div className="rounded-full w-6 h-6 overflow-hidden">
          <Flag
            code={currentCountry.code}
            alt={currentCountry.name}
            className="w-full h-full object-cover"
          />
        </div>

        <Icons nameIcon="chevron-down" className="w-4 h-4" />
      </div>

      <AnimatePresence>
        {isOpenModal && (
          <motion.div
            className="z-50 fixed inset-0 p-6 max-767:p-0 overflow-auto modal scroll-smooth"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className="fixed inset-0 bg-black-light opacity-75 modal__overlay"
              onClick={toggleModal}
            />

            <motion.div
              className="relative top-1/2 z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[380px] modal__wrapper"
              initial={{ opacity: 0, y: "calc(-50% + 16px)" }}
              animate={{ opacity: 1, y: "-50%" }}
              exit={{ opacity: 0, y: "calc(-50% + 16px)" }}
              transition={{ duration: 0.2 }}
            >
              {/* Start header */}
              <button
                className="inline-flex top-5 right-6 z-[2] absolute justify-center items-center bg-[#0000] shadow-[inset_0_0_0_2px_#fff] hover:shadow-[inset_0_0_0_2px_#6F737E] rounded-[50%] w-8 h-8 text-white hover:text-[#6F737E] transition-all duration-[0.2s] cursor-pointer"
                onClick={toggleModal}
              >
                <Icons nameIcon="close" className="w-4 h-4" />
              </button>
              <div className="relative flex items-center gap-3 pt-6 pr-[62px] pb-4 pl-6">
                <div className="block m-0 pb-4 w-full text-left text-lg text-white break-words leading-6">
                  Select your region
                </div>
              </div>
              {/* End header */}

              <div className="border-0 m-0 px-6 max-767:px-6 pt-0 pb-6 align-baseline">
                <div className="relative mb-3">
                  <Icons
                    nameIcon="search"
                    className="top-2/4 left-3 z-[2] absolute justify-center items-center w-6 h-6 -translate-y-3 pointer-events-none"
                  />
                  <input
                    type="text"
                    className="block border-[#72798a] border-2 bg-[initial] py-0 !pr-4 !pl-12 border-solid rounded-xl w-full !h-11 font-medium font-sans !text-sm text-white leading-[1.71429] tracking-[-0.02em] transition-[border-color] duration-[0.2s] appearance-none outline-none placeholder-[#6F737E]"
                    placeholder="Search by country or region..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <div className="-mx-6 my-0 px-6 max-h-80 overflow-y-auto scroll-smooth scrollbar-custom">
                  <div className="w-full h-4" />
                  <div className="justify-center items-stretch gap-y-3 grid grid-cols-1">
                    {isLoading ? (
                      Array(6).fill(null).map((_, index) => (
                        <FlagSkeleton key={index} />
                      ))
                    ) : (
                      filteredCountries.map((country, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-x-3 border-0 hover:opacity-60 m-0 px-0 py-2 rounded-2xl transition-all duration-[0.2s] cursor-pointer"
                          onClick={() => handleCountrySelect(country)}
                        >
                          <div className="rounded-[50%] w-10 h-10 overflow-hidden">
                            <Flag
                              code={country.code}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="flex flex-wrap flex-1 justify-start items-center font-semibold text-white">
                            <span className="flex-1">{country.name}</span>
                            {currentCountry.name === country.name && (
                              <Icons
                                nameIcon="checks"
                                className="inline-block ml-1 w-4 h-4 text-green-500"
                              />
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ListCountries;
