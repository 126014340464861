import { useState } from 'react'

export const useAdvancedFilter = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedOptions, setSelectedOptions] = useState([])
  const [isFocusSearch, setIsFocusSearch] = useState(false)

  const handleOptionSelect = (id) => {
    setSelectedOptions((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((optionId) => optionId !== id)
      }
      return [...prevSelected, id]
    })
  }

  const resetFilters = () => {
    setSearchTerm("")
    setSelectedOptions([])
  }

  const handleSearch = (value) => {
    setSearchTerm(value)
  }

  return {
    searchTerm,
    selectedOptions,
    isFocusSearch,
    setIsFocusSearch,
    handleSearch,
    handleOptionSelect,
    resetFilters,
  }
}

export default useAdvancedFilter
