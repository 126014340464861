import React, { useEffect } from "react"

import PageTitle from "../../components/layouts/Dashboard/PageTitle"
import { UseIsMax767 } from "../../components/shared/MediaQueries"
// Components
// import VolumeFilter from "./VolumeFilter"
// import AddWallet from "./AddWallet"
// import AdvancedFilter from "./AdvancedFilter"
import Table from "./Table"

const DashboardTokens = ({ setPageTitle }) => {
  const isMobile = UseIsMax767()

  useEffect(() => {
    setPageTitle('Tokens')
  }, [setPageTitle])

  return (
    <div className="px-6 max-480:px-4 py-32 2xl:py-20 max-1023:pt-10 max-1023:pb-10 rounded-t-[1.25rem] md:rounded-none">
      <PageTitle
        title="Your all in one Dashboard for all Crypto Assets"
        subtitle={(
          <>
            We enable all assets, to be accessible to you through your wallet
            {!isMobile && <br />}
            which self custody your asset
          </>
                  )}
      />

      <div className="mt-8">
        {/* <div className="flex max-480:flex-col-reverse max-480:flex-wrap justify-between items-center gap-4 mb-6">
          <VolumeFilter />

          <div className="flex max-480:flex-row flex-wrap justify-start items-center gap-4 max-480:w-full">
            <AddWallet />
            <AdvancedFilter />
          </div>
        </div> */}

        <Table />
      </div>
    </div>
  )
}

export default DashboardTokens
