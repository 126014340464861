/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import cn from "classnames"
import Avatar from 'react-avatar'
import { motion, AnimatePresence } from 'framer-motion'
// import { Tooltip } from 'react-tooltip'
// import 'react-tooltip/dist/react-tooltip.css'
import config from '../../../../../../config'
import { logout } from '../../../../../../utils'
import useAddressAssets from "../../../../../../myHooks/useAddressAssets"
// Shared
import Icons from "../../../../../shared/Icons"
import ShortToken from "../../../../../shared/ShortToken"
import { UseIsMax767, UseIsMax400 } from "../../../../../shared/MediaQueries"

// Inner
import ContentToggle from "./ContentToggle"
import Tab from "./Tab"
import PreferenceContent from "./PreferenceContent"

const Sidebar = ({ isOpen, onClose, original, user }) => {
  const { t } = useTranslation()
  const [showPreferences, setShowPreferences] = useState(false)
  const [,, removeCookie] = useCookies()
  const isMobile = UseIsMax767()
  const isMobileSmallest = UseIsMax400();
  const { transactions } = useAddressAssets(user.wallet_address);

  const triggerLogout = () => {
    removeCookie(config.jwtCookie)
    const logoutFunc = logout()
    logoutFunc()
  }

  const handleClose = () => {
    onClose()
    setShowPreferences(false)
  }

  const NetworkIcon = ({ transactions = [] }) => {
    const getNetworkIcon = (chainId) => {
      const icons = {
        '137': 'polygon.png',
        '8453': 'base.svg',
        '1': 'ethereum.png',
        '11155111': 'sepolia.png'
      };
      return icons[chainId] || 'ethereum.png';
    };

    if (!transactions.length) return null;

    const lastTx = transactions[0];
    const networkIcon = getNetworkIcon(lastTx.chainIndex);

    return (
      <div className="-right-1 -bottom-1 z-10 absolute rounded-full w-6 h-6">
        <img
          src={require(`../../../../../../assets/images/tokenSymbol/${networkIcon}`)}
          alt={`Network ${lastTx.chainIndex}`}
          className="rounded-full w-full h-full"
        />
      </div>
    );
  };

  const MainContent = ({ handleClose }) => (

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="relative flex-1 p-6 w-full"
    >
      <div className="flex flex-row max-767:flex-wrap flex-nowrap justify-start items-center mb-8">
        <div className={cn(
          "flex flex-1 justify-start items-center gap-4",
          {
            "mr-4 flex-1" : !isMobile,
            "w-full mt-6 min-w-full order-3 mb-4": isMobile
          }
        )}>
          <div className="relative rounded-full w-12 h-12">
            <Avatar
              name={user.fullname || user.email || 'User'}
              size="48"
              round={true}
              className="relative"
            />

            <NetworkIcon transactions={transactions} />
          </div>
          {original && <span className="font-bold font-sans text-white text-xs"><ShortToken original={original} hasCopy /></span>}
        </div>

        <button
          type="button"
          onClick={() => setShowPreferences(true)}
          className="inline-flex justify-center items-center hover:bg-gray-custom/20 ml-2 max-767:ml-0 p-[6px] rounded-2xl transition-all duration-200"
          // data-tooltip-id="sidebar-show-preferences-tooltip"
          // data-tooltip-content="Global preferences"
        >
          <Icons nameIcon="settings" className="w-5 h-5 text-gray-custom" />
        </button>
        {/* <Tooltip id="sidebar-show-preferences-tooltip" className="z-10 !bg-gray-custom !px-1 !py-1 !rounded-md" /> */}

        {user && (
          <>
            <button
              type="button"
              onClick={triggerLogout}
              className="inline-flex justify-center items-center hover:bg-gray-custom/20 ml-2 p-[6px] rounded-2xl transition-all duration-200"
              // data-tooltip-id="sidebar-show-logout-tooltip"
              // data-tooltip-content="Logout"
            >
              <Icons nameIcon="logout" className="w-5 h-5 text-gray-custom" />
            </button>
            {/* <Tooltip id="sidebar-show-logout-tooltip" className="z-10 !bg-gray-custom !px-1 !py-1 !rounded-md" /> */}
          </>
        )}

        {isMobile && (
          <>
            <button
              type="button"
              onClick={() => handleClose()}
              className="inline-flex justify-center items-center hover:bg-gray-custom/20 ml-2 p-[6px] rounded-2xl transition-all duration-200"
              // data-tooltip-id="sidebar-show-close-sidebar-tooltip"
              // data-tooltip-content="Close sidebar"
            >
              <Icons nameIcon="close" className="w-5 h-5 text-gray-custom" />
            </button>
            {/* <Tooltip id="sidebar-show-close-sidebar-tooltip" className="z-10 !bg-gray-custom !px-1 !py-1 !rounded-md" /> */}
          </>
        )}
      </div>

      <Tab user={user} onClose={onClose} />
    </motion.div>
  )

  return (
    <ContentToggle isOpen={isOpen} onClose={handleClose}>
      <AnimatePresence mode="wait">
        {showPreferences ?
          <PreferenceContent setShowPreferences={setShowPreferences} handleClose={handleClose} /> :
          <MainContent handleClose={handleClose} />
        }

        <>
          {/* Information */}
          <div className="p-6 w-full font-sans text-white">
            {/* Identification wallet */}
            <div className="block">
              <div className="flex flex-row flex-wrap justify-between items-center gap-2 mb-[6px] font-sans leading-[1.1]">
                <span className="text-[15px] text-white capitalize tracking-[-0.02em]">Identified Wallets</span>

                <span className='text-[13px] text-gray-custom tracking-[-0.02em]'>6,500 / 50,000</span>
              </div>
              <div className="bg-black-light mb-8 rounded-full h-[6px]">
                <div
                  className="bg-green-500 rounded-full h-full transition-all duration-300"
                  style={{ width:"40%" }}
                />
              </div>
            </div>

            {/* Allocated (millions) */}
            <div className="block mt-4">
              <div className="flex flex-row flex-wrap justify-between items-center gap-2 mb-[6px] font-sans leading-[1.1]">
                <span className="text-[15px] text-white capitalize tracking-[-0.02em]">Allocated (millions)</span>

                <span className='text-[13px] text-gray-custom tracking-[-0.02em]'>80 / 1,000</span>
              </div>
              <div className="bg-black-light mb-8 rounded-full h-[6px]">
                <div
                  className="bg-primary rounded-full h-full transition-all duration-300"
                  style={{ width:"20%" }}
                />
              </div>
            </div>

            {/* Allocated (millions) */}
            <div className="block mt-4">
              <div className="flex flex-row flex-wrap justify-between items-center gap-2 mb-[6px] font-sans leading-[1.1]">
                <span className="text-[15px] text-white capitalize tracking-[-0.02em]">Deployable (millions)</span>

                <span className='text-[13px] text-gray-custom tracking-[-0.02em]'>1 / 1,000</span>
              </div>
              <div className="bg-black-light mb-8 rounded-full h-[6px]">
                <div
                  className="bg-purple rounded-full h-full transition-all duration-300"
                  style={{ width:"1%" }}
                />
              </div>
            </div>

            <div className="block mt-4 font-sans text-[15px] text-yellow-500 tracking-[-0.02em]">
              Join the exclusive VVIP group - Limited to first 50k identified wallets!
            </div>
          </div>
        </>
      </AnimatePresence>
    </ContentToggle>
  )
}

export default Sidebar
