import React from 'react'
import Avatar from 'react-avatar'

const Token = ({
  imgSrc, tokenName, tokenSymbol, onClick, balance, networkIcon
}) => {
  const getImage = (src, name) => {
    try {
      // Handle null/undefined src
      if (!src) {
        return (
          <Avatar
            name={name}
            size="32"
            round={true}
            className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
          />
        );
      }

      // Handle object module or string src
      const imgSrc = typeof src === 'string' ? src : (src.default || src);

      // Check if src contains default broken image path
      if (imgSrc.includes('default-icon-day-v3')) {
        return (
          <Avatar
            name={name}
            size="32"
            round={true}
            className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
          />
        );
      }

      return (
        <img
          src={imgSrc}
          alt={tokenName}
          className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
            const avatar = <Avatar
              name={name}
              size="32"
              round={true}
              className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
            />;
            e.target.parentNode.appendChild(avatar);
          }}
        />
      );
    } catch (err) {
      return (
        <Avatar
          name={name}
          size='32'
          round={true}
          className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
        />
      )
    }
  }

  return (
    <div className="flex flex-row flex-wrap justify-start items-center gap-x-3 gap-y-3 border-0 hover:opacity-60 m-0 p-0 rounded-2xl transition-all duration-[0.2s] cursor-pointer" onClick={onClick}>
      <div className="relative border-0 m-0 mr-3 p-0 rounded-[50%] w-9 h-9">
        {getImage(imgSrc, tokenName)}
        {/* <img
          src={imgSrc}
          alt={tokenName}
          className="relative rounded-full w-full max-w-8 h-full max-h-8 object-cover"
        /> */}

        {networkIcon && (
          <span className="right-0 bottom-0 absolute bg-white rounded-full w-4 h-4 overflow-hidden">
            <img
              src={networkIcon}
              alt="Network"
              className="w-full h-full object-cover"
            />
          </span>
        )}
      </div>

      <div className="flex flex-row flex-wrap flex-[1_1_0] justify-start items-center border-0 m-0 p-0 font-semibold text-white">
        <span className="flex-1 font-sans text-[15px] tracking-[-0.02em]">{tokenSymbol}</span>

        <span className="border-0 m-0 p-0 font-[600] font-sans text-[13px] text-gray-custom tracking-[-0.02em]">{balance ? (+balance).toFixed(2) : ''} {tokenSymbol}</span>
      </div>
    </div>
  )
}

export default Token
