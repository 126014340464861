import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useClickOutside } from "../../../hooks/common/useClickOutside"
import { dropdownFilterVariants } from "../../../utils/animations"
import Icons from '../../../components/shared/Icons'

const TIME_RANGES = ['1M', '6M', '12M', '24M']

const ChartControls = ({
  isDesktopSmall,
  selectedRange,
  onRangeChange,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  const toggleDropdown = () => setIsDropdownOpen(prev => !prev)
  const closeDropdown = () => setIsDropdownOpen(false)
  const ref = useClickOutside(isDropdownOpen, closeDropdown)

  const handleSelectRange = (range) => {
    onRangeChange(range)
    closeDropdown()
  }

  if (!isDesktopSmall) {
    return (
      <div className="flex flex-wrap justify-start items-start gap-1">
        {TIME_RANGES.map((range) => (
          <button
            key={range}
            onClick={() => onRangeChange(range)}
            className={`border-[2px] m-0 px-4 py-0 rounded-[22px] font-medium text-[13px] border-solid tracking-[-0.02em] transition-all duration-200 ${range === selectedRange ? 'border-primary text-white' : 'border-transparent text-gray-lighter hover:text-white'}`}
          >
            {range}
          </button>
        ))}
      </div>
    )
  }

  return (
    <div ref={ref} className="relative max-480:w-full min-w-[120px] max-480:min-w-full">
      <div className={`block relative float-none clear-both hover:border-[#dbdbdb] focus:border-white/20 active:border-white/20 bg-[initial]  bg-none opacity-100 shadow-[#6F737E_0_0_0_2px_inset] hover:shadow-[#33383f_0_0_0_2px_inset] custom-dropdown m-0 py-0 pr-0 pl-4 border-none rounded-xl w-auto h-10 font-semibold text-left text-sm text-white leading-10 transition-all duration-[0.2s] cursor-pointer select-none align-baseline ease-[ease-in-out] text-[13px] ${isDropdownOpen ? "is-active !bg-black-light": ""}`}>
        <div
          className="z-50 flex justify-between items-center pr-2 text-nowrap overflow-hidden text-overflow-ellipsis current"
          onClick={toggleDropdown}
        >
          {selectedRange}
          <span className={`inline-flex text-white/20 relative transition-transform duration-[0.2s] ${isDropdownOpen ? '-rotate-180' : 'rotate-0'}`}>

            <Icons nameIcon='chevron-down' className="w-4 h-4" />

          </span>
        </div>

        <AnimatePresence>

          {isDropdownOpen && (
            <motion.ul
              {...dropdownFilterVariants}
              className="top-full z-[9] absolute inset-x-0 bg-black-light shadow-[#33383f_0_0_0_2px_inset] mx-0 mt-0.5 mb-0 px-0 py-2 border-solid rounded-xl w-full overflow-hidden align-baseline"
            >
              {TIME_RANGES.map((range) => (
                <li
                  key={range}
                  className={`
                    block border-0 bg-[initial] bg-none m-0 px-4 py-2 
                    p-[4px16px] min-h-[auto] font-semibold text-left 
                    text-[13px] leading-[1.71429] transition-all duration-[0.2s] 
                    cursor-pointer align-baseline
                    ${range === selectedRange
                      ? 'text-primary'
                      : 'text-gray-lighter hover:text-white'
                    }
                  `}
                  onClick={() => handleSelectRange(range)}
                >
                    {range}
                </li>
              ))}
            </motion.ul>
          )}

        </AnimatePresence>
      </div>
    </div>
  )
};

export default ChartControls;
