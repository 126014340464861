/* eslint-disable no-use-before-define */
/* eslint-disable no-return-await */
import React, { useState, useEffect, useCallback } from "react";
import { useFormContext } from "react-hook-form"
import { motion, AnimatePresence } from "framer-motion";
import confetti from "canvas-confetti";
import { Link } from "react-router-dom";
import { useMutation } from '@apollo/client';

import Icons from "../../../../../components/shared/Icons";
import eureImg from "../../../../../assets/images/eure.png";
import dummyCoin from "../../../../../assets/images/issuer/default-icon-day-v3.svg";

import ProcessOne from "../../../../../assets/images/approve/approve-1.png";
import ProcessTwo from "../../../../../assets/images/approve/approve-2.png";
import ProcessThree from "../../../../../assets/images/approve/approve-3.png";

import { COMPLETE_INVESTMENT } from '../../../../../queriesAndMutations/investmentMutation';

import { toaster, isProduction } from '../../../../../utils';
import contractAbi from '../../../../../constants/smartContract';
import { useMe } from '../../../../../myHooks'
import { useAccount } from 'wagmi';

import Web3 from 'web3'

import metamaskService from '../../../../../services/metamask'
import { ethereum, walletClient } from '../../../../../myHooks/useCoinbaseAuth';

import { useCoinbaseTrans } from './useCoinbaseTrans';
// eslint-disable-next-line import/no-unresolved
import { useAppKitProvider, useAppKitAccount } from '@reown/appkit/react'
import { providers, Contract } from 'ethers'
import { useWeb3Provider, COIN_DECIMALS, getCoinAddress } from '../../../../../myHooks/useWeb3Provider';

const processApprove = [
  { src: ProcessOne, alt: "Process step one" },
  { src: ProcessTwo, alt: "Process step two" },
  { src: ProcessThree, alt: "Process step three" },
];

const Processing = ({ currentInvestment, currentIssuer }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const { data: { me: user } = {} } = useMe()
  const { getValues } = useFormContext()
  const { provider } = useWeb3Provider(currentIssuer.network, currentIssuer.id);

  const launchConfetti = () => {
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const randomInRange = (min, max) => Math.random() * (max - min) + min;
    const interval = window.setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });

      return () => {};
    }, 250);
  };

  let smartContractAddress = '0x6f942124C1E0846cee88D0CfB0D653Fcc06864Af'

  const [allowance, setAllowance] = useState(0)
  const [isApproving, setIsApproving] = useState(false)
  const [isBuyingToken, setIsBuyingToken] = useState(false)
  const [isApproveContract, setIsApproveContract] = useState(false)
  const [transactionHash, setTransactionHash] = useState('')
  const [completeInvestment] = useMutation(COMPLETE_INVESTMENT);

  const { walletProvider } = useAppKitProvider('eip155')
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount()

  let coinAddress

  const currency = getValues('currency') || 'eure';
  const minAllowance = getValues('amount');
  const tokenAmount = getValues('token_amount');

  const approveAmount = Math.ceil(minAllowance * (10 ** COIN_DECIMALS[currency]))

  const { connect, isConnected, handleApprove, handleBuyToken, handleSignMessage } = useCoinbaseTrans(currentIssuer);

  coinAddress = getCoinAddress(currency, currentIssuer.network)

  if (currentIssuer.coin_name === 'DAKS' || currentIssuer.coin_name === 'DEXA') {
    coinAddress = getCoinAddress(currency, 'mainnet-only')
  }

  const STABLE_COIN_ENUM = {
    dai: 1,
    usdc: 2,
    eure: 3,
    usdt: 4,
    busd: 5,
  }

  smartContractAddress = currentIssuer.paymentHub || process.env.REACT_APP_SMART_CONTRACT_ADDRESS

  const fetchAllowance = useCallback(async (approved) => {
    console.log('fetchAllowance')
    console.log('coinAddress', coinAddress)

    const web3 = new Web3(provider);

    const contract = new web3.eth.Contract([{
      constant: true,
      inputs: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' }
      ],
      name: 'allowance',
      outputs: [
        { name: '', type: 'uint256' }
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    }], coinAddress)

    try {
      const allowance = await contract.methods.allowance(user.wallet_address, metamaskService.formatAddress(smartContractAddress)).call()

      console.log('allowance', +(allowance.toString()) / (10 ** COIN_DECIMALS[currency]));
      console.log('minAllowance', minAllowance);

      if (+(allowance.toString()) / (10 ** COIN_DECIMALS[currency]) >= minAllowance) {
        if (!isApproveContract) {
          setIsApproveContract(true)
          setCurrentStep(1)
        }
        automaticMoveNextStep(1)
      } else if (approved) {
        setIsApproveContract(true)
        setCurrentStep(1)
        automaticMoveNextStep(1)
      } else {
        automaticMoveNextStep(0)
      }
      setAllowance(+(allowance.toString()) / (10 ** COIN_DECIMALS[currency]))
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  const allowContract = async () => {
    setIsApproving(true)
    try {
      const web3 = new Web3(provider);

      if (user.login_provider === 'coinbase') {
        const approveAmount = Math.ceil(minAllowance * (10 ** COIN_DECIMALS[currency]))

        const result = await handleApprove(approveAmount, currency);

        if (result) {
          await new Promise((resolve) => setTimeout(resolve, 3000));

          await fetchAllowance(true);
        }

        setIsApproving(false);

        return
      }

      const contract = new web3.eth.Contract([{
        constant: false,
        inputs: [
          { name: 'spender', type: 'address' },
          { name: 'value', type: 'uint256' }
        ],
        name: 'approve',
        outputs: [
          { name: '', type: 'bool' }
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function'
      }], coinAddress)

      console.log('currency', currency)

      console.log('minAllowance', minAllowance)

      const approveAmount = Math.ceil(minAllowance * (10 ** COIN_DECIMALS[currency]))

      const result = await contract.methods.approve(metamaskService.formatAddress(smartContractAddress), BigInt(approveAmount).toString()).send({
        from: user.wallet_address
      });

      if (result) {
        await new Promise((resolve) => setTimeout(resolve, 3000));

        await fetchAllowance(true)
      }
    } catch (error) {
      toaster.error(error.message)

      console.log(error)
    }

    setIsApproving(false)
  }

  const signMessage = async () => {
    try {
      const message = 'I confirm that I am the Beneficial Owner of this Wallet';

      if (user.login_provider === 'coinbase') {
        const result = await handleSignMessage(message);

        console.log('result', result);

        if (result) {
          await new Promise((resolve) => setTimeout(resolve, 2000));

          automaticMoveNextStep(2)
          setCurrentStep(2)
        }

        return
      } else if (user.login_provider === 'walletconnect' && isWcConnected) {
        const ethersProvider = new providers.Web3Provider(walletProvider)

        const hexMessage = `0x${Buffer.from(message, "utf8").toString("hex")}`

        // Request signature from user
        const signature = await ethersProvider.provider.request({
          method: 'personal_sign',
          params: [
            hexMessage,
            wcAddress,
          ]
        });

        if (signature) {
          await new Promise((resolve) => setTimeout(resolve, 2000));

          automaticMoveNextStep(2)
          setCurrentStep(2)
        }

        return
      }

      if (metamaskService.isMetamaskNotAvailable()) {
        toaster.error('You need to install Metamask to use this feature')

        return
      }
      await metamaskService.signMessage(user.wallet_address, message);

      automaticMoveNextStep(2)
      setCurrentStep(2)
    } catch (error) {
      toaster.error(error.message)

      console.log(error)
    }
  }

  const onPayViaMetamask = async () => {
    try {
      setIsBuyingToken(true);

      const payViaMetamask = async () => {
        const web3 = new Web3(provider);

        if (currentIssuer.coin_name === 'DAKS' || currentIssuer.coin_name === 'DEXA') {
          const brokerbotAddress = currentIssuer.brokerbotAddress;

          const ethersProvider = new providers.Web3Provider(provider)

          const paymentHubAddress = currentIssuer.paymentHub;

          const paymentHubABI = [
            "function payAndNotify(address brokerbot, uint256 amount, bytes calldata reference) external returns (bool)"
          ];

          const signerAddress = await ethersProvider.getSigner(user.wallet_address)

          const paymentHubContract = new Contract(paymentHubAddress, paymentHubABI, signerAddress);

          // reference can be '0x' if none needed
          const reference = "0x";

          const currencyAmount = +minAllowance * (10 ** COIN_DECIMALS[currency])

          console.log('currencyAmount', currencyAmount)
          console.log('paymentHubContract', paymentHubContract)

          const txBuy = await paymentHubContract.payAndNotify(brokerbotAddress, currencyAmount.toString(), reference);
          await txBuy.wait();
          console.log("Purchase transaction confirmed!");
          return txBuy.hash;
        }

        const contract = new web3.eth.Contract(contractAbi.crowdsale, metamaskService.formatAddress(smartContractAddress));

        if (!(await contract.methods.isWhitelisted(user.wallet_address))) {
          throw new Error('Your wallet has not been whitelisted. Please wait for approval or contact admin for support');
        }

        const rate = await contract.methods.rate().call();

        // const [daiPerToken, daiPerEth] = await Promise.all([
        //   contract.methods.rate().call(),
        //   contract.methods.calculateDAIForEther(1).call(),
        // ]);

        // const daiAmount = transaction.token_amount * daiPerToken;
        const BN = Web3.utils.BN

        if (currency === 'eth') {
          const ethRate = await contract.methods.calculateEthRate().call()
          const ethRateBN = new BN(ethRate)

          const formattedTokenAmount = new BN(Math.ceil(tokenAmount));
          const ethAmount = formattedTokenAmount.mul(ethRateBN).add(new BN(1000));

          if (ethAmount > await metamaskService.getBalance(user.wallet_address)) {
            throw new Error('Your wallet has not had enough assets to perform the action');
          }

          const { transactionHash } = await contract.methods
            .buyTokens(metamaskService.formatAddress(user.wallet_address), 0, 0)
            .send({
              from: user.wallet_address,
              value: ethAmount,
            });

          console.log('transactionHash', transactionHash)

          return transactionHash
        } else if (Object.keys(STABLE_COIN_ENUM).includes(currency)) {
          const formattedRate = +(rate.toString());

          const currencyAmount = +tokenAmount * (formattedRate / 10 ** COIN_DECIMALS.rate) * (10 ** COIN_DECIMALS[currency])

          if (user.login_provider === 'coinbase') {
            return await handleBuyToken(currencyAmount, STABLE_COIN_ENUM[currency]);
          }

          // setCurrencyAmount(currencyAmount);

          const coinContract = new web3.eth.Contract(
            [
              {
                constant: true,
                inputs: [{ name: "account", type: "address" }],
                name: "balanceOf",
                outputs: [{ name: "", type: "uint256" }],
                payable: false,
                stateMutability: "view",
                type: "function",
              },
            ],
            coinAddress
          );

          if (currencyAmount > await coinContract.methods.balanceOf(user.wallet_address)) {
            throw new Error('Your wallet has not had enough assets to perform the action');
          }

          const { transactionHash } = await contract.methods
            .buyTokens(metamaskService.formatAddress(user.wallet_address), BigInt(currencyAmount).toString(), `${STABLE_COIN_ENUM[currency]}`)
            .send({
              from: user.wallet_address,
              value: 0,
            });

          return transactionHash
        }

        return "";
      }

      console.log('onPayViaMetamask');

      const txHash = await payViaMetamask()

      console.log('txHash', txHash);

      setIsBuyingToken(false);

      if (txHash) {
        await completeInvestment({
          variables: {
            tnxHash: txHash,
            investmentId: currentInvestment.id,
            tenantId: currentIssuer.tenantId,
          }
        });
        setTransactionHash(txHash)
        setCurrentStep(3)
        setSuccessModalOpen(true);
        launchConfetti();
      }
    } catch (error) {
      console.error(error);
      toaster.error(error.message);
      setIsBuyingToken(false);
    }
  }

  useEffect(() => {
    console.log('useEffect')
    if (!user) {
      return;
    }

    if (user.login_provider === 'metamask' && metamaskService.isMetamaskNotAvailable()) {
      return;
    }

    if (currentStep > 0) {
      return
    }

    fetchAllowance()
  }, [currentStep, user, fetchAllowance])

  const handleModalClick = () => {
    if (currentStep === 0) {
      allowContract()
    } else if (currentStep === 1) {
      signMessage()
    } else if (currentStep === 2) {
      onPayViaMetamask()
    } else {
      window.location.reload();
    }
  };

  const automaticMoveNextStep = (step) => {
    if (currentStep > step) {
      return
    }

    if (step === 0) {
      allowContract()
    } else if (step === 1) {
      signMessage()
    } else if (step === 2) {
      onPayViaMetamask()
    }
  }

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const getTransactionExplorer = (network) => {
    switch (network) {
    case 'mainnet-only':
      return 'https://etherscan.io/tx';
    case 'mainnet':
      return isProduction ? 'https://etherscan.io/tx' : 'https://sepolia.etherscan.io/tx'
    case 'base':
        return isProduction ? 'https://basescan.org/tx' : 'https://sepolia.basescan.org/tx'
    case 'polygon':
      return isProduction ? 'https://polygonscan.com/tx' : 'https://amoy.polygonscan.com/tx'
    case 'xinfin':
      return isProduction ? 'https://explorer.xinfin.network/tx' : 'https://apothem.xinfin.network/tx'
    default:
      return 'https://basescan.org/tx'
    }
  }

  const transactionExplorer = getTransactionExplorer(currentIssuer.network)

  return (
    <div className="px-0">
      <ol className="relative border-[#2b2d33] border-s list-none">
        {[0, 1, 2].map((step, index) => (
          <li key={index} className="relative mb-8 -ms-4">
            <motion.span
              className={`left-0 absolute flex justify-center items-center overflow-hidden p-1 rounded-full w-8 h-8 -start-4 -top-[2px] ring-[#2b2d33] ring-4 ${
                currentStep >= step ? "bg-white/20" : "bg-black-light"
              }`}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {step === 0 ? (
                <img
                  src={eureImg}
                  alt="EURE token"
                  className="rounded-full w-full h-full object-cover"
                />
              ) : step === 1 ? (
                <Icons
                  nameIcon="lock"
                  className={`w-4 h-4 ${
                    currentStep >= step ? "text-white" : "text-white/20"
                  }`}
                />
              ) : (
                <Icons
                  nameIcon="rosette-discount-check"
                  className={`w-4 h-4 ${
                    currentStep >= 2 ? "text-white" : "text-white/20"
                  }`}
                />
              )}
            </motion.span>

            <div className="flex flex-wrap justify-between items-center pl-12 w-full">
              <div
                className={`flex-1 mt-[6px] w-full text-[13px] text-left leading-[1.2] tracking-[-0.02em
                                ${
                                  currentStep >= step
                                    ? "text-white"
                                    : "text-[#6F737E]"
                                }`}
              >
                {step === 0
                  ? `Approve ${currency.toUpperCase()} spending`
                  : step === 1
                  ? "Sign message"
                  : "Confirm investment in wallet"}
              </div>

              <div className="inline-flex justify-center items-center mt-[2px] w-6 h-6 checklist">
                {currentStep > step ? (
                  <Icons
                    nameIcon="double-check"
                    className="w-4 h-4 text-green-500"
                  />
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ol>

      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            className="z-50 fixed inset-0 flex p-6 max-767:p-0 transition-all duration-[0.2s] overflow-auto modal scroll-smooth"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              className="fixed inset-0 bg-black-light opacity-75 modal__overlay"
              onClick={() => setModalOpen(false)}
            />
            <motion.div
              className="relative z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[380px] transition-all duration-[0.2s] modal__wrapper"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
            >
              <img
                src={processApprove[currentStep].src}
                alt={processApprove[currentStep].alt}
                className="rounded-md w-full h-auto cursor-pointer"
                onClick={handleModalClick}
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isSuccessModalOpen && (
          <motion.div
            className="z-50 fixed inset-0 flex p-6 max-767:p-0 transition-all duration-[0.2s] overflow-auto modal scroll-smooth"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="fixed inset-0 bg-black-light opacity-75 modal__overlay"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.75 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              onClick={closeSuccessModal}
            />

            <motion.div
              className="relative z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-[578px]"
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
            >
              <div className="relative border-0 m-0 px-6 pt-12 pb-6 text-center align-baseline">
                <div className="mx-auto mb-4 w-32 h-32 text-center">
                  <Icons nameIcon="check-color" className="w-32 h-32" />
                </div>
                <div className="block m-0 pb-4 w-full font-sans text-2xl text-white break-words leading-6">
                  Investment Success
                </div>

                <p className="inline-flex justify-center items-center gap-4 mt-6 mb-4 text-base text-white">
                  <span className="inline-flex justify-center items-center gap-1 font-sans">
                    <img
                      src={eureImg}
                      alt="EURE"
                      className="rounded-full w-6 h-6"
                    />
                    {minAllowance} {currency}
                  </span>
                  ➜
                  <span className="inline-flex justify-center items-center gap-2 font-sans font-semibold">
                    <img
                      src={dummyCoin}
                      alt={currentIssuer.coin_name}
                      className="rounded-full w-6 h-6"
                    />
                    {tokenAmount} {currentIssuer.coin_name}
                  </span>
                </p>
                <br />

                <div className="text-[#6f767e] text-[13px]">
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${transactionExplorer}/${transactionHash}`}
                    className="font-sans font-semibold text-[#0052ff] hover:text-[#0148dd] transition-opacity duration-[0.2s]"
                  >
                    View on Explorer
                  </a>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <button
        type="button"
        // disabled={isBuyingToken || isApproving}
        onClick={handleModalClick}
        className="inline-flex justify-center items-center bg-[#0052ff] hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 !rounded-2xl w-full !h-14 font-medium font-sans text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
      >
        Continue
      </button>
    </div>
  );
};

export default Processing;
