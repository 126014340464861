import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWide } from '../../../../components/shared/Modals';

const DeleteModal = ({ isOpen, onClose, selectedItem, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <ModalWide
      isOpen={isOpen}
      onClose={onClose}
      title={t("settings.spending_cap.modal.delete")}
      subtitle={
        selectedItem
          ? t(`Are you sure you want to delete spending cap for {{token}} with network {{network}}, limit {{limit}} and used {{used}}?`, {
              token: selectedItem.token,
              network: selectedItem.network,
              limit: selectedItem.limit,
              used: selectedItem.used
            })
          : ''
      }
    >
      <div className="flex flex-row flex-wrap justify-center items-center gap-[12px] w-full">
        <button
          onClick={onConfirm}
          type='button'
          className='inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium font-sans text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none'
        >
          {t('Delete')}
        </button>
        <button
          onClick={onClose}
          type='button'
          className='inline-flex justify-center items-center bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium font-sans text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none'
        >
          {t('Cancel')}
        </button>
      </div>
    </ModalWide>
  );
};

export default DeleteModal;
