import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import styles from './MyRewards.module.scss'
import Icons from '../../../components/shared/Icons'

import { NETWORK_IMAGES } from '../../../constant/images'

const TABS = [
  { id: 'active', label: 'Active' },
  { id: 'history', label: 'History' }
];

const TabContent = ({ tab }) => {
  if (tab === 'active') {
    return (
      <div className="border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-full min-w-0 text-start text-white transition-all duration-[0.2s] ease-linear outline-none">
        <div className="flex items-center gap-4 mb-4">
          <img src={NETWORK_IMAGES.ethereum} alt="Ethereum" className='rounded-full w-6 h-6' />
          <div className="flex-1">
            <div className="flex justify-between items-center mb-1">
              <div className="m-0 font-sans font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s]">ETH Staking</div>
              <div className="font-sans text-green-500 tracking-[-0.02em]">3.67% APR</div>
            </div>
            <div className="flex justify-between items-center font-sans text-[13px] text-gray-lighter truncate tracking-[-0.02em]">
              <div>0.5 ETH staked</div>
              <div>+$21.33 earned</div>
            </div>
          </div>
        </div>
        <div className="bg-black-light rounded-full h-1 overflow-hidden">
          <div className="bg-primary w-3/4 h-full" />
        </div>
        <div className="mt-1 font-sans text-[13px] text-gray-lighter tracking-[-.02em]">Next reward in 2 days</div>
      </div>
    );
  }

  return (
    <div className="border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-full min-w-0 text-start text-white transition-all duration-[0.2s] ease-linear outline-none">
      <div className="py-8 font-sans text-[13px] text-center text-gray-lighter tracking-[-.02em]">
        No historical rewards yet
      </div>
    </div>
  );
};

const MyRewards = () => {
  const [activeTab, setActiveTab] = useState('active');

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className={styles.wrapper}>
      <div className="mb-8">
        <h2 className="mb-6 font-sans font-semibold text-lg text-start text-white leading-6 tracking-[-0.02em]">Rewards</h2>
        <div className="mb-1 font-sans text-[13px] text-gray-lighter tracking-[-0.02em]">Total earned this month</div>
        <div className="font-bold font-sans text-3xl text-green-500">+$33.55</div>
      </div>

      {/* Tabs */}
      <div className="inline-flex gap-2 mb-2 w-full">
        {TABS.map(({ id, label }) => (
          <button
            key={id}
            onClick={() => handleTabChange(id)}
            className={`
              border-2 px-4 py-1 rounded-full font-medium text-[13px] 
              transition-all duration-200 outline-none border-solid tracking-[-0.02em] font-sans
              ${activeTab === id
                ? 'border-primary text-white'
                : 'border-transparent text-gray-lighter hover:text-white'
              }
            `}
          >
            {label}
          </button>
        ))}
      </div>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
        >
          <TabContent tab={activeTab} />
        </motion.div>
      </AnimatePresence>

      {/* Unclaimed Rewards */}
      <div className="mt-12 max-767:mt-10" />
      <div className="bg-white/10 w-full h-[1px]" />
      <div className="mt-12">
        <h2 className="mb-6 font-sans font-semibold text-lg text-start text-white leading-6 tracking-[-0.02em]">Unclaimed Rewards</h2>

        <div className="border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-full min-w-0 text-start text-white transition-all duration-[0.2s] ease-linear outline-none">
          <div className="flex justify-start items-center">
            <div className="bg-green-500/20 rounded-full w-6-h6" />

            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="m-0 font-sans font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s]">Trading Rewards</h4>
                  <div className="font-sans text-gray-lighter tracking-[-0.02em]">Feb 2024</div>
                </div>
                <div className="font-sans text-green-500 tracking-[-0,02em]">+$12.22</div>
              </div>
            </div>
          </div>

          <button className="inline-flex justify-center items-center bg-green-500 hover:bg-green-600 hover:opacity-90 m-0 mt-4 px-6 py-3 rounded-3xl w-full h-10 font-medium font-sans text-[100%] text-white normal-case leading-none tracking-[-0.02em] transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none">
            Claim All Rewards ($12.22)
          </button>
        </div>
      </div>

      {/* Recent Claims */}
      <div className="mt-12 max-767:mt-10" />
      <div className="bg-white/10 w-full h-[1px]" />
      <div className="mt-12">
        <h2 className="mb-6 font-sans font-semibold text-lg text-start text-white leading-6 tracking-[-0.02em]">Recent Claims</h2>
        <div className="border-2 border-white/10 bg-[#1e2025] p-4 border-solid rounded-xl w-full min-w-0 text-start text-white transition-all duration-[0.2s] ease-linear outline-none">
          <div className="flex justify-between items-center">
            <div>
              <div className="m-0 font-sans font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s]">Trading Rewards</div>
              <div className="font-sans text-gray-lighter tracking-[-0.02em]">Jan 2024</div>
            </div>
            <div className="font-sans text-gray-lighter tracking-[-0.02em]">$15.88</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRewards;
