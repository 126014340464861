import React, { useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import "./customstyle.css"

const ControlledEmailField = ({
  name,
  placeholder,
  label,
  icon,
  validator,
  defaultValue,
  customClassOnField,
  onFocus,
  onBlur,
}) => {
  const { control } = useFormContext()
  const [isFocused, setIsFocused] = useState(false)
  const [hasValue, setHasValue] = useState(!!defaultValue)

  return (
    <div className="relative field__wrapper">
      {label && <label htmlFor={name} className="mb-2 font-semibold">{label}</label>}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        rules={validator ? {
          required: 'Email is required',
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: 'Invalid email address',
          },
        } : {}}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className="relative">
              {icon && <span className={`left-[6px] absolute inset-y-0 flex items-center ${(isFocused || hasValue) ? 'text-white' : ''}`}>{icon}</span>}
              <input
                {...field}
                type="email"
                className={`${customClassOnField} w-full text-base leading-6 font-normal min-w-0 text-white opacity-90 text-start p-4 bg-black-light transition-colors duration-[0.2s] ease-linear rounded-xl appearance-none outline-none autofill:shadow-[inset_0_0_0px_1000px_#6F737E] autofill-focus:shadow-[inset_0_0_0px_1000px_#6F737E] ${icon ? 'pl-12' : ''}`}
                placeholder={placeholder}
                onFocus={(e) => {
                  setIsFocused(true)
                  if (onFocus) onFocus(e)
                  if (field.onFocus) field.onFocus(e)
                }}
                onBlur={(e) => {
                  setIsFocused(false)
                  if (onBlur) onBlur(e)
                  if (field.onBlur) field.onBlur(e)
                }}
                onChange={(e) => {
                  setHasValue(!!e.target.value)
                  field.onChange(e)
                }}
              />
            </div>

            {error && <span className="block relative pt-1 text-[13px] text-red-200 tracking-[-0.02em]">{error.message}</span>}
          </>
        )}
      />
    </div>
  )
}

export default ControlledEmailField
