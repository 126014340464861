import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Container,
} from '@material-ui/core'

import {
  GET_ADMIN_PROJECT_PAGE,
  UPDATE_PROJECT_PAGE,
} from '../queriesAndMutations'
import { toaster } from '../utils'

import AppBar from '../components/common/AppBar/AppBar'
import Footer from '../components/common/Footer'
import AdminNavigation from '../components/common/AdminNavigation'
import ProjectPageForm from '../components/ProjectPageForm'

const AdminEditProjectPage = ({ match: { params: { projectId } } }) => {
  const { t } = useTranslation()
  const { data: { getAdminProjectPage: data } = {}, loading } = useQuery(GET_ADMIN_PROJECT_PAGE, {
    variables: {
      id: +projectId,
    },
  })
  const [updateProjectPage, updateProjectPageStatus] = useMutation(UPDATE_PROJECT_PAGE)
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false)
  const submitUpdateProjectPage = (submitData) => {
    updateProjectPage({
      variables: {
        id: projectId,
        input: submitData,
      },
    }).then(() => {
      toaster.success('Project created successfully')
      setUpdatedSuccessfully(true)
    }).catch(() => {})
  }

  if (updatedSuccessfully) {
    return <Redirect to="/admin/pages/projects" />
  }

  return (
    <div className="page page-admin-dashboard">
      <AppBar />

      <main className="site-main site-main__dashboard">
        <AdminNavigation />

        <div className="site-main__dashboard-content">
          <Container>
            <h1>{t('Edit Project')}</h1>

            {loading && (
              <CircularProgress />
            )}
            {!loading && data && (
              <ProjectPageForm
                id={projectId}
                data={data}
                onSubmit={submitUpdateProjectPage}
                submitLoading={updateProjectPageStatus.loading}
              />
            )}
          </Container>
        </div>
      </main>

      <Footer />
    </div>
  )
}

AdminEditProjectPage.propTypes = {
  match: PropTypes.object.isRequired,
}

export default AdminEditProjectPage
