import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { UseIsMax767 } from '../../../../../../shared/MediaQueries'
import HeadingTab from './HeadingTab'
import MobileTabSelect from './MobileTabSelect'
import TabContent from './TabContent'

const Tab = ({ user, onClose }) => {
  const isMobile = UseIsMax767()
  const [activeTab, setActiveTab] = useState(0)
  const [isOpenSelect, setIsOpenSelect] = useState(false)

  const tabs = ["My Assets", "Tokens", "Transactions"]

  const handleToggleSelect = () => setIsOpenSelect(!isOpenSelect)

  useEffect(() => {
    if (!isMobile) {
      setIsOpenSelect(false)
    }
  }, [isMobile])

  return (
    <>
      {!isMobile ? (
        <div className="flex flex-wrap justify-start items-start gap-2 mb-6">
          {tabs.map((tab, index) => (
            <HeadingTab
              key={tab}
              index={index}
              activeTab={activeTab}
              onClick={() => setActiveTab(index)}
            >
              {tab}
            </HeadingTab>
          ))}
        </div>
      ) : (
        <MobileTabSelect
          activeTab={activeTab}
          isOpen={isOpenSelect}
          tabs={tabs}
          onToggle={handleToggleSelect}
          onSelect={setActiveTab}
        />
      )}

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <TabContent tabHeading={tabs[activeTab]} user={user} onClose={onClose} />
        </motion.div>
      </AnimatePresence>
    </>
  )
}

export default Tab
