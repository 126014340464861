import React from 'react'
import { useTranslation } from 'react-i18next'

const renderContentWithLink = (content) => {
  return typeof content === 'object' ? (
    <a
      href={content.url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-primary hover:text-primary-hover transition-colors duration-300"
    >
      {content.text}
    </a>
  ) : content
}

const ContentColumnOne = ({ issuer }) => {
  const { t } = useTranslation()

  return (
    <div className="flex-1 max-1023:order-4 pr-16 max-1023:pr-0 max-1279:pr-12 grow">
      {issuer.video_embed_url && (
        <div className="relative mb-12 max-767:mb-8 pb-[56.25%] rounded-2xl w-full overflow-hidden">
          <iframe
            title="Issuer Video"
            src={issuer.video_embed_url}
            allowFullScreen
            className="top-0 left-0 absolute w-full h-full"
          />
        </div>
      )}

      <div className="mb-5 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out]">{t('tokendetail.overview')}</div>

      <div className="gap-4 max-1279:gap-0 grid grid-cols-[1fr_1fr] max-1279:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mt-3">
        {issuer.ticker && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.ticker')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.ticker)}
            </div>
          </div>
        )}

        {issuer.clasification && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.clasification')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.clasification)}
            </div>
          </div>
        )}

        {issuer.status && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.status')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.status)}
            </div>
          </div>
        )}

        {issuer.networks && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.networks')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.networks)}
            </div>
          </div>
        )}

        {issuer.sectors && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.sectors')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.sectors)}
            </div>
          </div>
        )}
      </div>

      <div className="w-full h-10" />

      <div className="mb-5 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out]">{t('tokendetail.contract_information')}</div>
      <div className="gap-4 max-1279:gap-0 grid grid-cols-[1fr_1fr] max-1279:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mt-3">
        {issuer.token_contract_address && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.token_contract_address')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.token_contract_address)}
            </div>
          </div>
        )}

        {issuer.offering_contract && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.offering_contract')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.offering_contract)}
            </div>
          </div>
        )}

        {issuer.protocol && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.protocol')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.protocol)}
            </div>
          </div>
        )}

        {issuer.smart_contract_audits && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.smart_contract_audits')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.smart_contract_audits)}
            </div>
          </div>
        )}

        {issuer.smart_contract_insurance && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.smart_contract_insurance')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.smart_contract_insurance)}
            </div>
          </div>
        )}
      </div>

      <div className="w-full h-10" />
      <div className="mb-5 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em] transition-[color] duration-[0.02s] ease-[ease-out]">{t('tokendetail.company')}</div>
      <div className="gap-4 max-1279:gap-0 grid grid-cols-[1fr_1fr] max-1279:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mt-3">
        {issuer.issuer_Legal_name && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.issuer_Legal_name')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.issuer_Legal_name)}</div>
          </div>
        )}

        {issuer.founding_year && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.founding_year')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.founding_year)}</div>
          </div>
        )}

        {issuer.incorporation_country && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.incorporation_country')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.incorporation_country)}
            </div>
          </div>
        )}

        {issuer.company_data && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.company_data')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.company_data)}
            </div>
          </div>
        )}

        {issuer.custodians && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.custodians')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.custodians)}</div>
          </div>
        )}

        {issuer.auditor && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.auditor')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.auditor)}</div>
          </div>
        )}

        {issuer.technical_manager && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.technical_manager')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.technical_manager)}</div>
          </div>
        )}
      </div>

      <div className="w-full h-10" />
      <div className="mb-5 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em]">
        {t('tokendetail.identifiers')}
      </div>
      <div className="grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mt-3">
        {issuer.isin && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.isin')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.isin)}</div>
          </div>
        )}

        {issuer.lei && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.lei')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.lei)}</div>
          </div>
        )}

        {issuer.wallet && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.wallet')}
            {typeof issuer.wallet === 'object' ? (
              <>
                <a
                  href={issuer.wallet.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-3 text-primary hover:text-primary-hover transition-colors duration-300"
                >
                  {issuer.wallet.url}
                </a>
              </>
            ) : issuer.wallet}
          </div>
        )}
      </div>

      <div className="w-full h-10" />
      <div className="mb-5 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em]">{t('tokendetail.compliance')}</div>
      <div className="grid grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mt-3">
        {issuer.registration_license && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.registration_license')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.registration_license)}</div>
          </div>
        )}

        {issuer.license_or_regulatory_framework && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.regulatory_framework')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.license_or_regulatory_framework)}</div>
          </div>
        )}

        {issuer.whitepaper_terms_prospectus && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.prospectus')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.whitepaper_terms_prospectus)}
            </div>
          </div>
        )}

        {issuer.transparency && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.transparency')}
            <div className='inline-block ml-3 text-white/70'>
              {renderContentWithLink(issuer.transparency)}
            </div>
          </div>
        )}
      </div>

      <div className="w-full h-10" />
      <div className="mb-5 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em]">
        {t('tokendetail.investment')}
      </div>

      <div className="gap-4 max-1279:gap-0 grid grid-cols-[1fr_1fr] max-1279:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mt-3">
        {issuer.min_investment && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.min_investment')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.min_investment)}</div>
          </div>
        )}

        {issuer.market_cap && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.market_cap')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.market_cap)}</div>
          </div>
        )}

        {issuer.holders && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.holders')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.holders)}</div>
          </div>
        )}

        {issuer.committed && (
          <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
            {t('tokendetail.committed')}
            <div className='inline-block ml-3 text-white/70'>{renderContentWithLink(issuer.committed)}</div>
          </div>
        )}
      </div>

      <div className="w-full h-10" />
      <div className="mb-5 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em]">
        {t('tokendetail.risk')}
      </div>
      <div className="relative border-white/10 mb-3 pb-3 border-b-[1px] border-solid font-sans font-semibold text-gray-lighter text-sm tracking-[-0.02em]">
        {issuer.risks ? (
          issuer.risks.split('\n').map((paragraph, i) => (
            <p key={i} className="mb-4">{paragraph}</p>
          ))
        ) : (
          <p>{t("tokendetail.no_risk_available")}</p>
        )}
      </div>
  </div>
  )
}

export default ContentColumnOne
