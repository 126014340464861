import React, {useState, useEffect} from 'react'

import { UseIsMax1023, UseIsMax1279 } from '../../components/shared/MediaQueries'

// Inner
import Card from './Card'
import PortfolioOverview from './PortfolioOverview'
import MyAssets from './MyAssets'
import MyRewards from "./MyRewards"
import DemographicsAnalysis from './DemographicsAnalysis'

const DashboardHomepage = ({ setPageTitle }) => {
  useEffect(() => {
    setPageTitle("Dashboard")
  }, [setPageTitle])

  // Media Query Hooks
  const isTablet = UseIsMax1023()
  const isDesktopSmall = UseIsMax1279()

  // Modal States
  const [activeModal, setActiveModal] = useState(null)
  const [selectedData, setSelectedData] = useState(null)
  const [totalBalance, setTotalBalance] = useState(0)

  // Dashboard States
  const [selectedTimeRange, setSelectedTimeRange] = useState('1M')

  return (
    <div className="flex flex-row max-1279:flex-col gap-10 max-1279:gap-4 px-10 max-1279:px-4 py-6 max-1279:py-4 w-full">
      {/* Start left column - portfolio overview */}
      <div className="flex-1 min-w-0 max-w-[calc(100%-24rem)] max-1279:max-w-full">
        <Card className="!mx-0 w-full">
          <PortfolioOverview
            timeRange={selectedTimeRange}
            onTimeRangeChange={setSelectedTimeRange}
            isTablet={isTablet}
            isDesktopSmall={isDesktopSmall}
            totalBalance={totalBalance}
          />
        </Card>

        <Card className="!mx-0 !mt-10 max-1279:!mt-4 w-full">
          <MyAssets setTotalBalance={setTotalBalance} />
        </Card>

      </div>
      {/* End left column - portfolio overview */}

      {/* Start right column - actions & earnings */}
      <div className="w-[22.5rem] max-1279:w-full shrink-0">
        <Card className="!mx-0 max-1279:!mt-0 w-full">
          <MyRewards />
        </Card>

        <Card className="!mx-0 !mt-10 max-1279:!mt-4 w-full">
          <DemographicsAnalysis />
        </Card>
      </div>
      {/* End right column - actions & earnings */}
    </div>
  )
}

export default DashboardHomepage
