import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DASHBOARD_DATA_TOKEN_EXCEL } from '../../constant/dataTokenFromExcel'
import { findIssuerByIdentifier } from '../../utils/issuer'

// Inner
import Heading from './Heading'
import ContentColumnOne from './ContentColumnOne'
import InstantInvestment from './InstantInvestment'
// Cross component
import OnchainProviders from '../../components/layouts/InstantInvestment/OnchainProviders'

const IssuerDetail = ({ setPageTitle }) => {
  const { t } = useTranslation()

  const formMethods = useForm({
    defaultValues: {
      currency: 'eure',
      token_name: 'ENC',
      token_amount: 100,
      amount: 100,
    }
  });

  const location = useLocation()
  const fullPath = location.pathname.replace('/explore/tokens/', '')
  const issuer = findIssuerByIdentifier(DASHBOARD_DATA_TOKEN_EXCEL, fullPath)

  useEffect(() => {
    setPageTitle(t('tokendetail.title'));
  }, [setPageTitle, t]);

  if (!issuer) {
    return <div className="text-center text-white">{t('tokendetail.not_found')}</div>;
  }

  return (
    <div className="flex flex-1 mx-auto px-6 max-1023:px-5 py-6 md:py-0 w-full">
      <div className="relative flex justify-start items-start max-w-full grow">
        <div className="relative flex flex-col md:pt-18 pb-10 max-w-full grow">

          <Heading issuer={issuer} />

          {/* Content */}
          <div className="max-1023:block flex flex-row flex-wrap justify-start items-start p-0">
            <ContentColumnOne issuer={issuer} />

            {/* Column 2 */}
            <div className="max-1023:mt-8 w-[308px] max-1023:w-full max-1023:min-h-[initial] min-h-[80vh] shrink-0 [&>div]:top-6 [&>div]:sticky">
              {/* <FormProvider {...formMethods}>
                <OnchainProviders>
                  <InstantInvestment />
                </OnchainProviders>
              </FormProvider> */}
            </div>
            {/* End column 2 */}
          </div>
          {/* End content */}
        </div>
      </div>
    </div>
  )
}

export default IssuerDetail
