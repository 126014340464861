// src/pages/Signup/components/SMS/index.jsx

import React, { useState } from "react"
import PhoneInputStep from "./PhoneInputStep"
import CodeVerification from "./CodeVerification"
import VerificationSuccess from "./VerificationSuccess"

const SMS_STEPS = {
  PHONE_INPUT: 'phone-input',
  VERIFY_CODE: 'verify-code',
  SUCCESS: 'success'
}

const SMS = ({ onBack, onNext }) => {
  const [currentStep, setCurrentStep] = useState(SMS_STEPS.PHONE_INPUT)
  const [phoneNumber, setPhoneNumber] = useState("")

  const handleStepComplete = (step, data = {}) => {
    switch (step) {
      case SMS_STEPS.PHONE_INPUT:
        setCurrentStep(SMS_STEPS.VERIFY_CODE)
        break
      case SMS_STEPS.VERIFY_CODE:
        setCurrentStep(SMS_STEPS.SUCCESS)
        break
      case 'finish':
        onNext({ phoneNumber, ...data })
        break
      default:
        break
    }
  }

  const handleBack = () => {
    switch (currentStep) {
      case SMS_STEPS.PHONE_INPUT:
        onBack()
        break
      case SMS_STEPS.VERIFY_CODE:
        setCurrentStep(SMS_STEPS.PHONE_INPUT)
        break
      case SMS_STEPS.SUCCESS:
        setCurrentStep(SMS_STEPS.VERIFY_CODE)
        break
      default:
        break
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case SMS_STEPS.PHONE_INPUT:
        return (
          <PhoneInputStep
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            onBack={handleBack}
            onNext={(data) => handleStepComplete(SMS_STEPS.PHONE_INPUT, data)}
          />
        )
      case SMS_STEPS.VERIFY_CODE:
        return (
          <CodeVerification
            phoneNumber={phoneNumber}
            onBack={handleBack}
            onNext={() => handleStepComplete(SMS_STEPS.VERIFY_CODE)}
          />
        )
      case SMS_STEPS.SUCCESS:
        return (
          <VerificationSuccess
            phoneNumber={phoneNumber}
            onBack={handleBack}
            onFinish={(data) => handleStepComplete('finish', data)}
          />
        )
      default:
        return null
    }
  }

  return renderStep()
}

export default SMS
