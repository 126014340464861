const NAVIGATION_ITEMS = [
  { id: 'basic', label: 'Basic' },
  { id: 'security', label: 'Security' },
  { id: 'wallets', label: 'Wallets' },
  { id: 'verification', label: 'Verification'},
  { id: 'spending-cap', label: 'Spending Cap'},
  { id: 'account-number', label: 'Account Number' },
  { id: 'upgrade', label: 'Upgrade' }
];

export default NAVIGATION_ITEMS
