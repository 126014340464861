import gql from 'graphql-tag'

// eslint-disable-next-line import/prefer-default-export
export const GET_MANAGE_UPGRADE = gql`
  query getManageUpgrade {
    getManageUpgrade {
      id
      status
      manage_settings
    }
  }
`;

export const GET_USER_MANAGE_UPGRADE = gql`
  query getUserManageUpgrade {
    getUserManageUpgrade {
      id
      status
      manage_settings
    }
  }
`;
