// src/pages/DashboardTokens/AddWallet/GroupedTokenList.jsx

import React from 'react'
import TokenWithPrice from './TokenWithPrice'
import TokenWithoutPrice from './TokenWithoutPrice'

const GroupedTokenList = ({
  tokensWithPrice = [],
  tokensWithoutPrice = [],
  onPriceChange,
  manualPrices = {},
}) => {
  return (
    <div className="w-full">
      {/* Tokens with Price Section */}
      {tokensWithPrice.length > 0 && (
        <div className="mb-4">
          {tokensWithPrice.map((token, index) => (
            <TokenWithPrice
              key={`${token.symbol}-${index}`}
              token={token}
            />
          ))}
        </div>
      )}

      {/* Tokens without Price Section */}
      {tokensWithoutPrice.length > 0 && (
        <div className="mt-4">
          <div className="mb-3 text-[13px] text-white/70">
            No USD Price available for some tokens
          </div>
          <div className="w-full h-2" />

          {tokensWithoutPrice.map((token, index) => (
            <TokenWithoutPrice
              key={`${token.symbol}-${index}`}
              token={token}
              onPriceChange={(priceData) => onPriceChange(token.symbol)(priceData)}
              manualPrices={manualPrices[token.symbol] || {
                entryPrice: '',
                currentPrice: '',
              }}
            />
          ))}
        </div>
      )}

      {tokensWithPrice.length === 0 && tokensWithoutPrice.length === 0 && (
        <div className="py-4 text-[13px] text-center text-white/70">
          No tokens found for this wallet
        </div>
      )}
    </div>
  )
}

export default GroupedTokenList
