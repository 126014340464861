import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import { useQuery } from '@apollo/client'
import MediaSiteLogo from '../../media/images/investhub-logo-v1.svg'
import { PUBLIC_SETTINGS } from '../../queriesAndMutations'

// OPTIMIZITATION STYLESHEET
// import '../../styles/index-output.css'

const GuestAppBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const mobileMenuSetter = (state) => () => setMobileMenuOpen(state)
  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const maybeRenderNavigation = () => (
    <div className="py-12 min-w-[320px] h-full site-header__sidebar">
      <nav className="flex flex-col flex-wrap justify-center items-center mx-6 site-header__sidebar-navigation">
        <span className="mb-2">
          <NavLink to="/dashboard" className="py-2 text-base text-black hover:text-brand uppercase leading-[1.4]">Home</NavLink>
        </span>
        <span className="mb-2">
          <NavLink to="/explore" className="py-2 text-base text-black hover:text-brand uppercase leading-[1.4]">Explore</NavLink>
        </span>
        <span className="mb-2">
          <NavLink to="/dashboard" className="py-2 text-base text-black hover:text-brand uppercase leading-[1.4]">My Account</NavLink>
        </span>
      </nav>
    </div>
  )
  return (
    <>
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={mobileMenuSetter(false)}
      >
        {maybeRenderNavigation(true)}
      </Drawer>

      <header className="relative z-50 py-4 lg:py-2 site-header">
        <div className="top-0 left-0 absolute bg-white bg-opacity-20 w-full h-full site-header__layer">&nbsp;</div>
        <div className="relative z-10 flex justify-start items-center mx-auto pr-3 md:pr-3 lg:pr-6 pl-3 md:pl-3 lg:pl-6 container">
          <div className="mr-12 max-w-full xl:max-w-[160px] site-header__logo">
            <NavLink to="/dashboard" className="flex items-center">
              {
                publicSettings && publicSettings.siteLogo ? (
                  <img
                    src={`${publicSettings.siteLogo}`}
                    alt="front"
                  />
                )
                  : (
                    <img src={MediaSiteLogo} alt="Investhub" className="w-full" />

                  )
              }
            </NavLink>
          </div>

          <nav className="lg:block flex-1 hidden site-header__navigation">
            <NavLink to="/dashboard" className="inline-block mr-9 px-3 py-6 font-medium text-base text-grey-pure hover:text-brand transition">Home</NavLink>
            <NavLink to="/explore" className="inline-block mr-9 px-3 py-6 font-medium text-base text-grey-pure hover:text-brand transition">Explore</NavLink>
            <NavLink to="/dashboard" className="inline-block mr-9 px-3 py-6 font-medium text-base text-grey-pure hover:text-brand transition">My Account</NavLink>
          </nav>

          <div className="lg:flex lg:items-center hidden site-header__cta">
            <NavLink to="/onboarding" className="mr-3 py-2 btn-investhub__primary">Create</NavLink>
            <NavLink to="/connect-wallet" className="py-2 btn-investhub__primary-line">Connect wallet</NavLink>
          </div>

          <div
            className="flex-1 lg:hidden site-header__hamburger"
            role="button"
            tabIndex="0"
            onClick={mobileMenuSetter(true)}
          >
            <div className="relative ml-auto w-[32px] h-[32px] transition cursor-pointer toggle-icon__wrapper">
              <div className="relative top-[12px] w-[32px] toggle-icon">&nbsp;</div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default GuestAppBar
