import React from 'react'
import NAVIGATION_ITEMS from './constant/menuConfig'

const DesktopNavigation = ({ activeIndex, onNavigate, onDeleteAccount }) => {
  return (
    <div className="top-2 sticky flex flex-col justify-start items-start gap-2 w-[280px] max-1279:w-[210px] align-baseline shrink-0">
      {NAVIGATION_ITEMS.map((item, index) => (
        <button
          key={item.id}
          className={`${index === activeIndex ? "text-primary bg-primary/15" : "text-white/70 bg-transparent hover:opacity-90 hover:text-white"} relative flex flex-wrap justify-start items-center border-[2px] m-0 px-4 border-transparent border-solid rounded-2xl w-full h-10 font-semibold text-sm leading-6 tracking-[-0.02em] transition-all duration-[0.2s] font-sans`}
          onClick={() => onNavigate(index)}
        >
          {item.label}
        </button>
      ))}

      <div className="mt-1 w-full" />
      <div className="bg-white/10 w-full h-[1px]" />
      <div className="mb-1 w-full" />

      <button
        className="relative flex flex-wrap justify-start items-center border-[2px] bg-transparent m-0 px-4 border-transparent border-solid rounded-2xl w-full h-10 font-sans font-semibold text-red-500 text-sm leading-6 tracking-[-0.02em] transition-all duration-[0.2s]"
        onClick={onDeleteAccount}
      >
          Delete Account
      </button>
    </div>
  )
}

export default DesktopNavigation
