import gql from 'graphql-tag'

export const GET_USER_TENANT = gql`
  query getUserTenant {
    getUserTenant {
      id
      host
      schema
      status
      userId
      createdAt
    }
  }
`;

export const GET_TENANT = gql`
  query getTenant($id: ID!) {
    getTenant(id: $id) {
      id
      host
      schema
      status
      userId
      planType
      createdAt
      Subscription {
        id
        userId
        tenantId
        plan
        subscriptionId
        expiredDate
        createdAt
        updatedAt
        SubscriptionPlan {
          title
          plan
          price
          currency
        }
      }
    }
  }
`;

export const GET_ADMIN_TENANT = gql`
  query getAdminTenant($page: Int!, $rowsPerPage: Int!){
    getAdminTenant(page: $page, rowsPerPage: $rowsPerPage) {
      tenants {
        id
        host
        schema
        status
        userId
        createdAt
        isStatic
        User {
          id
          fullname
          email
        }
      }
      count
    }
  }
`;

export const GET_ADMIN_TENANT_DETAILS = gql`
  query getAdminTenantDetails($id: ID!) {
    getAdminTenantDetails(id: $id) {
      id
      host
      schema
      status
      userId
      createdAt
      Subscription {
        userId
        tenantId
        plan
        subscriptionId
        expiredDate
        createdAt
        updatedAt
        SubscriptionPlan {
          title
          plan
          price
          currency
        }
      }
    }
  }
`;

export const GET_TENANT_META_INFO = gql`
  query getTenantMetaInfo($input: GetTenantMetaInfoInput){
    getTenantMetaInfo(input: $input)
  }
`;

export const GET_PERSONALISED_OPPORTUNITIES = gql`
  query getPersonalisedOpportunities($input: GetTenantMetaInfoInput){
    getPersonalisedOpportunities(input: $input)
  }
`;

export const GET_SINGLE_TENANT_META_INFO = gql`
  query getSingleTenantMetaInfo($host: String!){
    getSingleTenantMetaInfo(host: $host)
  }
`;

export const GET_TENANT_LOG = gql`
  query getTenantLog($id: ID!){
    getTenantLog(id: $id) {
      id
      changeType
      data,
      createdAt
    }
  }
`;


export const GET_TENANT_SERVICE_USAGE = gql`
  query getTenantServiceUsage($id: ID!){
    getTenantServiceUsage(id: $id) {
      id
      label
      usage
      notCharge
      lastUse
      unit
      shortUnit
    }
  }
`;

export const GET_ADMIN_TENANT_LOG = gql`
  query getAdminTenantLog($id: ID!){
    getAdminTenantLog(id: $id) {
      id
      changeType
      data,
      createdAt
    }
  }
`;


export const GET_ADMIN_TENANT_SERVICE_USAGE = gql`
  query getAdminTenantServiceUsage($id: ID!){
    getAdminTenantServiceUsage(id: $id) {
      id
      label
      usage
      notCharge
      lastUse
      unit
      shortUnit
    }
  }
`;



export const GET_STATISTIC_DATA = gql`
  query getStatisticData {
    getStatisticData
  }
`;

export const GET_ACTIVE_EVOLVE_TENANT = gql`
  query getActiveEvolveTenant {
    getActiveEvolveTenant {
      id
      host
      schema
      status
      userId
      planType
    }
  }
`;
