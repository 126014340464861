import React, { useState, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import Avatar from "react-avatar"
import ShortToken from '../../../components/shared/ShortToken'
import { ModalWide } from '../../../components/shared/Modals'
import Icons from '../../../components/shared/Icons'
import { GET_USER_WALLETS, GET_TOTAL_VALUE_BY_ADDRESS } from '../../../queriesAndMutations'
import TableHeader from './TableHeader'
import WalletRow from './WalletRow'
import AddWalletContent from './AddWalletContent'
import style from "./style.module.scss"

const providers = [
  { id: 'metamask', name: 'MetaMask', icon: '🦊' },
  { id: 'smartwallet', name: 'Smart Wallet', icon: '⚪' },
  { id: 'walletconnect', name: 'WalletConnect', icon: '🔗' }
]

const ConnectedWallet = () => {
  const [selectedWallets, setSelectedWallets] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [formattedWallets, setFormattedWallets] = useState([])
  const { loading, error, data } = useQuery(GET_USER_WALLETS);
  const [getTotalValueByAddress] = useLazyQuery(GET_TOTAL_VALUE_BY_ADDRESS);

  const formatWalletData = async (wallet) => {
    // Get total balance from wallet tokens, also fetch from api

    let totalBalance = wallet.tokens.reduce((sum, token) => {
      const tokenValue = parseFloat(token.quantity) * parseFloat(token.current_price || 0);
      return sum + tokenValue;
    }, 0);

    const { data: totalValueData } = await getTotalValueByAddress({ variables: { address: wallet.wallet_address } });

    totalBalance += totalValueData.getTotalValueByAddress.totalValue;

    // Format wallet address
    const shortAddress = `${wallet.wallet_address.slice(0, 6)}...${wallet.wallet_address.slice(-4)}`;

    // Get wallet name (could be ENS or short address)
    const name = shortAddress; // TODO: Add ENS support

    return {
      id: wallet.id,
      name,
      provider: wallet.source,
      icon: wallet.source.toLowerCase(),
      balance: `US$${totalBalance.toFixed(2)}`,
      address: shortAddress,
      originalAddress: wallet.wallet_address
    };
  };

  useEffect(() => {
    const formatWallets = async () => {
      if (data && data.getUserWallets) {
        const formatted = await Promise.all(data.getUserWallets.map(formatWalletData));
        setFormattedWallets(formatted);
      }
    };

    formatWallets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSelectWallet = (id) => {
    setSelectedWallets(prev => (
      prev.includes(id)
        ? prev.filter(walletId => walletId !== id)
        : [...prev, id]
    ))
  }

  const handleSelectAll = () => {
    setSelectedWallets(prev => (
      prev.length === formattedWallets.length
        ? []
        : formattedWallets.map(w => w.id)
    ))
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading wallets</div>;

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <div className="flex justify-between items-center w-full">
          <div className="font-sans font-semibold text-2xl text-start text-white leading-7 tracking-[-0.02em]">
            Manage wallets
          </div>
          <button
            className="inline-flex justify-center items-center gap-4 bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 py-4 rounded-3xl font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
            onClick={() => setShowAddModal(true)}
          >
            Add wallet
          </button>
        </div>
      </div>

      <div className="flex justify-between items-center mb-6 text-white">
        <div className="font-sans font-semibold text-base text-start text-white leading-6 tracking-[-0.02em]">
          Connected addresses
        </div>
      </div>

      <div className={`relative w-full max-w-full overflow-x-auto ${style.wrapper}`}>
        <div className="z-10 min-w-[1024px] transition-opacity">
          <table className='w-full'>
            <TableHeader
              selectedWallets={selectedWallets}
              wallets={formattedWallets}
              onSelectAll={handleSelectAll}
            />

            <tbody className='min-w-full'>
              {formattedWallets.map(wallet => (
                <WalletRow
                  key={wallet.id}
                  wallet={wallet}
                  isSelected={selectedWallets.includes(wallet.id)}
                  onSelect={handleSelectWallet}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ModalWide
        isOpen={showAddModal}
        onClose={() => setShowAddModal(false)}
        title="Add New Wallet"
        subtitle="Choose a wallet provider to connect"
      >
        <AddWalletContent onClose={() => setShowAddModal(false)} />
      </ModalWide>
    </>
  )
}

export default ConnectedWallet
