import React, { useEffect, useState, useRef } from 'react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext, Controller } from 'react-hook-form'
import style from "./Style.module.scss"

const BirthDayField = ({
  name,
  placeholder,
  label,
  icon,
  validator,
  customClassOnField,
  onFocus,
  onBlur,
  readOnly
}) => {
  const { control } = useFormContext();
  const maxDate = new Date();
  const minDate = new Date();
  minDate.setFullYear(maxDate.getFullYear() - 100);
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  return (
    <div className={`relative field__wrapper ${style.field__datewrapper}`}>
      {label && (
        <label htmlFor={name} className="mb-2 font-semibold">
          {label}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        rules={validator || {}}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className="relative">
              {icon && <span className="left-[6px] absolute inset-y-0 flex items-center">{icon}</span>}
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(date) => field.onChange(date)}
                dateFormat="dd MMMM yyyy"
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                placeholderText={placeholder}
                minDate={minDate}
                maxDate={maxDate}
                className={`${customClassOnField} !w-full text-base leading-6 font-sans font-normal min-w-0 text-white opacity-90 text-start p-4 bg-black-light transition-colors duration-[0.2s] ease-linear focus:bg-gray-700 rounded-xl outline-none ${icon ? '!pl-12' : ''}`}
                readOnly={readOnly}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </div>
            {error && <span className="block relative pt-1 text-[13px] text-red-200 tracking-[-0.02em]">{error.message}</span>}
          </>
        )}
      />
    </div>
  );
};

export default BirthDayField
