// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/common/StepIndicator/index.jsx

import React from 'react'
import { motion } from 'framer-motion'

const StepIndicator = ({ currentStep, totalSteps, progress }) => {
  return (
    <div className="relative border-white/10 bg-black-dark max-767:mt-8 mb-4 px-0 py-3 border-b w-full">
      <div className="relative flex justify-between items-center pb-2 w-full">

        {/* Progress percentage */}
        <span className="font-medium text-[12px] text-gray-lighter tracking-[-0.02em]">
          <span className='font-bold text-primary'>
            {progress}%
          </span>{' '}
          Complete
        </span>

        {/* Step count */}
        <span className="font-medium text-[12px] text-gray-lighter tracking-[-0.02em]">
          Step {currentStep} of {totalSteps}
        </span>
      </div>
      <div className="relative h-[1px] overflow-hidden">

        {/* Progress bar */}
        <motion.div
          className="top-0 left-0 absolute bg-[#0052ff] rounded-full h-[1px]"
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        />
        {/* Background track */}
        <div className="top-0 left-0 absolute bg-[#2c2f36] rounded-full w-full h-[1px]" />
      </div>
    </div>
  )
}

export default StepIndicator
