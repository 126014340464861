import { gql } from '@apollo/client';

export const GET_WALLET = gql`
  query GetWallet($id: ID!) {
    getWallet(id: $id) {
      id
      wallet_address
      chain_index
      tokens {
        id
        symbol
        contract_address
        entry_price
        current_price
        quantity
        chain_index
      }
      created_at
      updated_at
    }
  }
`;

export const GET_USER_WALLETS = gql`
  query GetUserWallets {
    getUserWallets {
      id
      wallet_address
      chain_index
      tokens {
        id
        symbol
        contract_address
        entry_price
        current_price
        quantity
        chain_index
      }
      source
      created_at
      updated_at
    }
  }
`;

export const GET_WALLET_TOKEN = gql`
  query GetWalletToken($id: ID!) {
    getWalletToken(id: $id) {
      id
      wallet_id
      symbol
      contract_address
      entry_price
      current_price
      quantity
      chain_index
      created_at
      updated_at
    }
  }
`;

export const GET_WALLET_TOKENS = gql`
  query GetWalletTokens($wallet_id: ID!) {
    getWalletTokens(wallet_id: $wallet_id) {
      id
      wallet_id
      symbol
      contract_address
      entry_price
      current_price
      quantity
      chain_index
      created_at
      updated_at
    }
  }
`;