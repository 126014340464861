// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/steps/InvestmentFrequency/index.jsx
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../common/Button'
import StepIndicator from '../../common/StepIndicator'
import Icons from '../../../../../../../shared/Icons'

const InvestmentFrequency = ({
  onNext,
  onBack,
  progress,
  currentStep,
  totalSteps,
}) => {
  const { t } = useTranslation()
  const [selectedFrequency, setSelectedFrequency] = useState(null)

  const frequencyOptions = [
    { id: 'less-than-5', content: 'Less than 5 transactions' },
    { id: 'more-than-5', content: 'More than 5 transactions' }
  ]

  const handleContinue = () => {
    if (selectedFrequency) {
      onNext({ investmentFrequency: selectedFrequency })
    }
  }

  return (
    <>
      <div className="flex flex-col flex-1 justify-start items-start gap-4 px-4 py-6">
        <StepIndicator
          currentStep={currentStep}
          totalSteps={totalSteps}
          progress={progress}
        />

        <div className="flex-1 w-full">
          <button
            className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
            onClick={onBack}
          >
            <Icons nameIcon="arrow-left" className="w-3 h-3" />
            <span className="inline-block">
              {t('common.back')}
            </span>
          </button>
          <div className="block w-full h-6" />

          <div className="mb-4 w-full">
            <div className="mb-2 font-medium text-4xl text-ellipsis text-white leading-[1.1875] tracking-[-0.02em]">
              {t('questionnaire.investmentFrequency.title')}
            </div>
            <p className="border-0 mt-2 p-0 text-gray-lighter">
              {t('questionnaire.investmentFrequency.subtitle')}
            </p>
          </div>

          {frequencyOptions.map((option) => (
            <label
              key={option.id}
              className={`flex flex-row justify-start border-solid items-stretch mb-4 border-2 bg-black-light rounded-xl cursor-pointer ${
                selectedFrequency === option.id ? "border-primary" : "border-transparent"
              }`}
              onClick={() => setSelectedFrequency(option.id)}
            >
              <input
                type="radio"
                name="investment-frequency"
                checked={selectedFrequency === option.id}
                onChange={() => setSelectedFrequency(option.id)}
                className="absolute border-0 bg-[0_0] bg-[initial] opacity-0 m-0 p-0 border-solid rounded-none font-[inherit] text-inherit leading-[inherit] overflow-visible align-middle"
              />
              <div className="flex flex-col justify-center p-8 w-full">
                <div className="m-0 pb-2 font-semibold text-base text-start text-white leading-6 transition-[color] duration-[0.02s] ease-[ease-out]">
                  {t(`questionnaire.investmentFrequency.options.${option.id}`)}
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>

      <div className="bottom bottom-0 sticky bg-black-dark px-4 py-6 w-full">
        <Button
          fullWidth
          size="md"
          onClick={handleContinue}
          disabled={!selectedFrequency}
        >
          {t('common.continue')}
        </Button>
      </div>
    </>
  )
}

export default InvestmentFrequency
