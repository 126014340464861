import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { DUMMY_RAMP_NETWORK_IMAGES } from "../../../../constant/images";

const RampNetworkDetail = ({ isOpen, onClose, onNext }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const rampImages = [
    DUMMY_RAMP_NETWORK_IMAGES.stepOne,
    DUMMY_RAMP_NETWORK_IMAGES.stepTwo,
    DUMMY_RAMP_NETWORK_IMAGES.stepThree,
    DUMMY_RAMP_NETWORK_IMAGES.stepFour,
  ];

  useEffect(() => {
    if (!isOpen) {
      setCurrentIndex(0);
    }
  }, [isOpen]);

  const handleStepClick = () => {
    if (currentIndex < rampImages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      onClose();
      onNext();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="z-50 fixed inset-0 flex p-6 max-767:p-0 overflow-auto modal scroll-smooth"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="overlay"
        >
          <motion.div
            className="relative z-10 bg-black-dark shadow-[transparent_0_0_0_0,transparent_0_0_0_0,#1e2025_0_0_0_1px_inset,#00000054_0_32px_64px_-16px] m-auto rounded-3xl w-full max-w-sm overflow-hidden modal__wrapper"
            initial={{ opacity: 0, translateY: 16 }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: 16 }}
            transition={{ duration: 0.3 }}
          >
            {rampImages.map(
              (image, index) =>
                index === currentIndex && (
                  <motion.button
                    key={`step-${index + 1}`}
                    className="bg-transparent border-none rounded-2xl w-full h-full overflow-hidden outline-none"
                    initial={{ opacity: 0, y: 16 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 16 }}
                    transition={{ duration: 0.3 }}
                    onClick={handleStepClick}
                  >
                    <img
                      src={image}
                      alt={`Ramp network Step ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </motion.button>
                )
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default RampNetworkDetail;
