// src/pages/InstantInvestment/Steps/StepShareHolderRegistration/index.jsx

import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_AUTHORIZATION_URL, SINGPASS_CALLBACK } from '../../../../queriesAndMutations';
// Shared
import Icons from "../../../../components/shared/Icons";
import { CountryField, TextField } from "../../../../components/shared/Fields";
import { SINGPASS_ASSETS_IMAGES } from "../../../../constant/images";
import { UseIsMax480 } from "../../../../components/shared/MediaQueries";
import { isProduction } from "../../../../utils";

const StepShareHolderRegistration = ({
  isLogin,
  prefillData,
}) => {
  const isMobile = UseIsMax480();
  const { setValue, getValues, register } = useFormContext();
  const [isCountry, setIsCountry] = useState(false);
  const [isAddressLine1, setIsAddressLine1] = useState(false);
  const [isAddressLine2, setIsAddressLine2] = useState(false);
  const [isZipCode, setIsZipCode] = useState(false);
  const [isCity, setIsCity] = useState(false);
  const [getAuthorizationUrl] = useLazyQuery(GET_AUTHORIZATION_URL);

  const handleClickSingPass = async () => {
    console.log('handleClickSingPass');
    const { data } = await getAuthorizationUrl();
    console.log(data);
    const { nonce } = data.authorizationUrl;

    // store in local storage
    localStorage.setItem('singpass_nonce', nonce);

    localStorage.setItem('investmentData', JSON.stringify({
      amount: getValues('amount'),
      token_amount: getValues('token_amount'),
      token_name: getValues('token_name'),
      currency: getValues('currency'),
    }));

    window.location.href = data.authorizationUrl.authorizationUrl;
  }

  console.log('prefillData', prefillData);
  console.log('getValues', getValues());

  return (
    <>
      { !prefillData && !isProduction && (
        <div className="mt-0 max-480:text-center">
          <button
            type="button"
            className="inline-flex max-480:flex-col justify-center max-480:justify-start items-center max-480:items-start bg-[#F4333D] hover:bg-[#B0262D] max-480:mx-auto px-6 max-480:px-4 max-480:py-4 rounded-2xl w-full max-480:!w-auto h-14 max-480:h-auto font-bold text-[100%] text-base text-white max-480:text-left normal-case leading-none transition-al duration-200"
            onClick={handleClickSingPass}
          >
            {!isMobile ? (
              <>
                <span className="inline-flex mr-1 tracking-normal">Retrieve Myinfo with</span>
                <img
                  src={SINGPASS_ASSETS_IMAGES.logoWhite}
                  alt="Retrieve myinfo with sing pass"
                  className="relative top-[2px] w-auto h-4 object-contain"
                  aria-label="sing pass"
                />
              </>
            ) : (
              <>
                <span className="inline-flex text-xl tracking-normal">Retrieve Myinfo</span>
                <span className="inline-flex justify-start items-end text-sm">
                  <span className="mr-1">with</span>
                  <img
                    src={SINGPASS_ASSETS_IMAGES.logoWhite}
                    alt="Retrieve myinfo with sing pass"
                    className="relative w-auto h-[14px] object-contain"
                    aria-label="sing pass"
                  />
                </span>
              </>
            )}
          </button>
        </div>
      )}
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 px-0 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent w-full ${
          isCountry ? "before:border-white/20" : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <CountryField
            name="country_of_residence"
            placeholder="Select your country"
            setIsCountry={setIsCountry}
            {...register('country_of_residence')}
            defaultCountryValue={(prefillData || {}).country_of_residence} // Tambahkan ini
            readOnly={!!(prefillData || {}).country_of_residence}
            value={(prefillData || {}).country_of_residence} // Tambahkan ini
            validator={{
              required: "Please select your country",
            }}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isAddressLine1
            ? "before:border-white/20"
            : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <TextField
            name="residential_address"
            placeholder="Address line 1"
            validator={true}
            defaultValue=""
            icon={
              <Icons
                nameIcon="home-bookmark"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            customClassOnField="!bg-transparent pl-10 !opacity-100"
            onFocus={() => setIsAddressLine1(true)}
            onBlur={() => setIsAddressLine1(false)}
            readOnly={!!(prefillData || {}).residential_address}
            {...register('residential_address')}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isAddressLine2
            ? "before:border-white/20"
            : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <TextField
            name="residential_address_two"
            placeholder="Address line 2"
            validator={false}
            defaultValue=""
            icon={
              <Icons
                nameIcon="home-bookmark"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            customClassOnField="bg-transparent pl-10 focus:bg-transparent"
            onFocus={() => setIsAddressLine2(true)}
            onBlur={() => setIsAddressLine2(false)}
            readOnly={!!(prefillData || {}).residential_address_two}
            {...register('residential_address_two')}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isZipCode ? "before:border-white/20" : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <TextField
            name="postal_code"
            placeholder="Zipcode"
            validator={true}
            defaultValue=""
            icon={
              <Icons
                nameIcon="building"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            customClassOnField="bg-transparent pl-10 focus:bg-transparent"
            onFocus={() => setIsZipCode(true)}
            onBlur={() => setIsZipCode(false)}
            readOnly={!!(prefillData || {}).postal_code}
            {...register('postal_code')}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isCity ? "before:border-white/20" : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <TextField
            name="city"
            placeholder="City"
            validator={true}
            defaultValue=""
            icon={
              <Icons
                nameIcon="building"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            customClassOnField="bg-transparent pl-10 focus:bg-transparent"
            onFocus={() => setIsCity(true)}
            onBlur={() => setIsCity(false)}
            readOnly={!!(prefillData || {}).city}
            {...register('city')}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      {/* <div className="relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-red-500/20 before:bg-[100%] py-6 p-4 before:border-red-500 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-center text-lg text-red-500 leading-5 tracking-[-0.02em] before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none">
        Something went wrong!
      </div>
      <div className="w-full h-2" /> */}
    </>
  );
};

export default StepShareHolderRegistration;
