import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from './TextField';

const IpWhitelist = () => {
  const { t } = useTranslation();
  const [ipAddresses, setIpAddresses] = useState(['192.168.1.1']);
  const [newIp, setNewIp] = useState('');

  const addIpAddress = (e) => {
    e.preventDefault();
    console.log('click')
    if (newIp && !ipAddresses.includes(newIp)) {
      setIpAddresses([...ipAddresses, newIp]);
      setNewIp('');
    }
  };

  const removeIpAddress = (ip) => {
    setIpAddresses(ipAddresses.filter(addr => addr !== ip));
  };

  return (
    <form onSubmit={addIpAddress} className="gap-5 max-767:gap-6 grid grid-cols-[1fr] max-767:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mb-6">
      <TextField
        value={newIp}
        onChange={(e) => setNewIp(e.target.value)}
        placeholder={t('settings.monerium.security.ip_placeholder')}
        prefixAction={true}
        label={t('settings.monerium.security.ip_label')}
        items={ipAddresses}
        onRemove={removeIpAddress}
      />
    </form>
  )
}

export default IpWhitelist
