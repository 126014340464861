// src/pages/Commission/index.jsx

import React, { useState, useCallback, useEffect, useRef } from "react";
import { useMe } from "../../myHooks/useMe";
import { GET_INVESTMENT_COMMISSION } from "../../queriesAndMutations";
import { useQuery } from "@apollo/client";

const Commission = ({ setPageTitle }) => {
  useEffect(() => {
    setPageTitle('Commission')
  }, [setPageTitle])

  const { data, loading, error } = useQuery(GET_INVESTMENT_COMMISSION)

  console.log(data)

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-4 text-left border">Amount</th>
            <th className="p-4 text-left border">Currency</th>
            <th className="p-4 text-left border">Token Amount</th>
            <th className="p-4 text-left border">Token Name</th>
            <th className="p-4 text-left border">First Name</th>
            <th className="p-4 text-left border">Last Name</th>
            <th className="p-4 text-left border">Country of Residence</th>
            <th className="p-4 text-left border">Wallet Address</th>
          </tr>
        </thead>
        <tbody>
          {data && data.getInvestmentCommission.map((investment) => (
            <tr key={investment.id} className="text-white">
              <td className="p-4 border">{investment.amount}</td>
              <td className="p-4 border">{investment.currency}</td>
              <td className="p-4 border">{investment.token_amount}</td>
              <td className="p-4 border">{investment.token_name}</td>
              <td className="p-4 border">{investment.first_name}</td>
              <td className="p-4 border">{investment.last_name}</td>
              <td className="p-4 border">{investment.country_of_residence}</td>
              <td className="p-4 border">{investment.wallet_address}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Commission;
