import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { TextField, SelectField } from '../../../../components/shared/Fields'
import Layout from '../Layout'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UPDATE_USER } from '../../../../queriesAndMutations'
import { useMe } from '../../../../myHooks'
import { motion } from 'framer-motion'
import Loader from '../../../../components/shared/Loader'

const NetworkOptions = [
  { label: 'Mainnet', value: 'mainnet' },
  { label: 'Polygon', value: 'polygon' },
  { label: 'Base', value: 'base' },
]

const ReferralPartnerFinalize = ({ signupData, setSignupData, onNext }) => {
  const { data = {}, loading, error } = useMe()
  const { t } = useTranslation()
  const [isDesiredUrlFocused, setIsDesiredUrlFocused] = useState(false)
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const [updateUser, updateUserStatus] = useMutation(UPDATE_USER)

  const referralPartnerMethods = useForm()

  const onSubmitReferralPartner = (data) => {
    console.log(data)
    updateUser({
      variables: {
        input: data,
      },
    }).then((data) => {
      console.log(data)
      onNext()
    }).catch(() => {})
  }

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="pw:mb-8 pw:text-center"
      >
        <div className="pw:relative pw:mb-4 pw:text-center">
          <Loader className=""/>
        </div>
        <p className="pw:mb-2 pw:font-semibold pw:text-white">{t("Setting up your passkey...")}</p>
        <p className="pw:text-gray-lighter pw:text-sm">{t("Please respond to your browser's prompt")}</p>
      </motion.div>
    )
  }

  return (
    <Layout
      title={t("Finalize")}
      description={t("Review your details and submit to complete the registration.")}
      currentStep={3}
      totalSteps={5}
    >
      <FormProvider {...referralPartnerMethods}>
        <form onSubmit={referralPartnerMethods.handleSubmit(onSubmitReferralPartner)} >
          <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
            <div className="pw:bg-black-light pw:mb-8 pw:p-6 pw:rounded-2xl">
              <div className="pw:relative">
                <div className="max-480:px-4 w-full">
                  <div className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${isDesiredUrlFocused ? 'before:border-white/20' : 'before:border-transparent'}`}>
                    <div className="mx-auto mt-0 mb-0 p-0">
                      {/* <TextFieldPrefixEnd
                        name="referral_contract_network"
                        placeholder="Enter the network"
                        validator={true}
                        defaultValue=""
                        customClassOnField="bg-transparent focus:bg-transparent"
                        onFocus={() => setIsDesiredUrlFocused(true)}
                        onBlur={() => setIsDesiredUrlFocused(false)}
                      /> */}
                      <SelectField
                        name="referral_contract_network"
                        placeholder="Network"
                        defaultOptionValue={NetworkOptions[0].value}
                        value={NetworkOptions[0].value}
                        validator={{
                          required: "Please select your network",
                        }}
                        options={NetworkOptions}
                      />
                    </div>
                  </div>

                  <div className="w-full h-2" />

                  <div className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${isEmailFocused ? 'before:border-white/20' : 'before:border-transparent'}`}>
                    <div className="mx-auto mt-0 mb-0 p-0">
                      <TextField
                        name="referral_contract_address"
                        placeholder="Enter your contract address"
                        validator={true}
                        defaultValue=""
                        customClassOnField="bg-transparent focus:bg-transparent"
                        onFocus={() => setIsEmailFocused(true)}
                        onBlur={() => setIsEmailFocused(false)}
                    />
                    </div>
                  </div>

                  <div className="w-full h-2" />

                  <button
                    type="submit"
                    disabled={updateUserStatus.loading}
                    className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 !rounded-2xl w-full !h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </Layout>
  )
}

export default ReferralPartnerFinalize
