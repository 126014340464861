// src/pages/Signup/constants.js

// eslint-disable-next-line import/prefer-default-export
export const SIGNUP_STEPS = {
  ACCOUNT_TYPE: 'account-type',
  SIGNUP_METHOD: 'signup-method',
  PASSKEY: 'passkey',
  SECURITY: 'security',
  AUTHENTICATOR: 'authenticator',
  SMS: 'sms',
  ISSUER_FINALIZE: 'issuer-finalize',
  TENANT_SUBSCRIBE: 'tenant-subscribe',
  REFERRAL_PARTNER_FINALIZE: 'referral-partner-finalize',
}
