import { useState, useEffect } from 'react';

const UseIsMax480 = () => {
    const [isMax480, setIsMax480] = useState(window.innerWidth <= 480);

    useEffect(() => {
        const handleResize = () => {
            setIsMax480(window.innerWidth <= 480);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return isMax480;
};

export default UseIsMax480;
