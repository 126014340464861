import { useState, useEffect } from 'react';

const UseIsMax767 = () => {
    const [isMax767, setIsMax767] = useState(window.innerWidth <= 767);

    useEffect(() => {
        const handleResize = () => {
            setIsMax767(window.innerWidth <= 767);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return isMax767;
};

export default UseIsMax767;
