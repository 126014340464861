import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Avatar from 'react-avatar'
import Icons from '../../components/shared/Icons'

const Heading = ({ issuer }) => {
  const { t } = useTranslation()
  return (
    <div className="mb-10 max-767:mb-8 text-left">
      <Link
        to="/tokenlist"
        className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium font-sans text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
      >
        <Icons
          nameIcon="chevron-left"
          className="w-3 h-3"
        />
        <span className="inline-block">{t('tokendetail.back_button')}</span>
      </Link>

      <div className="w-full h-10" />

      <div className="flex flex-row flex-wrap justify-start items-start gap-4 mb-4">
        <Avatar
          name={issuer.company}
          round={true}
          size="64"
          className="rounded-full"
        />

        <div className="relative top-1 font-bold font-sans text-5xl text-white max-1023:text-[2.5rem] leading-[3.5rem] max-1023:leading-[3rem] -tracking-wide max-1023:tracking-[-.045em]">{issuer.company}</div>
      </div>

      {issuer.risks && issuer.risks !== "-" &&
        <div className="text-[1.5rem] text-gray-lighter text-style-3lines leading-[2.25rem] tracking-[-.03em]">
          {issuer.risks}
        </div>
      }
    </div>
  )
}

export default Heading
