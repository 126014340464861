import React from "react";

const DetailItem = ({
  label,
  value,
  valueClass = "font-bold text-sm text-white",
}) => {
  return (
    <div className="flex max-480:flex-col justify-between max-480:justify-start items-center max-480:items-start gap-2 max-480:gap-0 w-full">
      <div className="text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
        {label}
      </div>
      <div className={valueClass}>{value}</div>
    </div>
  );
};

export default DetailItem;
