import React from 'react'
import { NavLink } from 'react-router-dom'
import SiteLogoLight from '../../../media/images/investhub-logo-v1.svg'
import SiteLogoDark from '../../../media/images/dark-mode-investhub-logo.svg'

const Logo = () => (
  <NavLink to="/dashboard" className="header__logo">
    <img src={SiteLogoLight} alt="Investhub" className="some-icon" />
    <img src={SiteLogoDark} alt="Investhub" className="some-icon-dark" />
  </NavLink>
)

export default Logo
