import { useState, useEffect } from 'react';

const UseIsMax1279 = () => {
    const [isMax1279, setIsMax1279] = useState(window.innerWidth <= 1279);

    useEffect(() => {
        const handleResize = () => {
            setIsMax1279(window.innerWidth <= 1279);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMax1279;
};

export default UseIsMax1279;
