import React, { useState } from 'react'
import EmailField from '../../../../components/shared/Fields/EmailField'
import PasswordField from '../../../../components/shared/Fields/PasswordField'

import { useTranslation } from 'react-i18next'
import Icons from '../../../../components/shared/Icons'
import { FormProvider, useForm } from 'react-hook-form'
import { SIMPLE_REGISTRATION } from '../../../../queriesAndMutations'
import { useMutation } from '@apollo/client'
import { ControlEmailField } from '../../../../components/shared/Fields'
import { useCookies } from 'react-cookie'
import config from '../../../../config'
import EmailVerification from './EmailVerification'
import { useEmailVerification } from '../../../../myHooks/useEmailVerification'

const EmailForm = ({onNext}) => {
  const { t } = useTranslation()
  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const [isFocused, setIsFocused] = useState(false)
  const [, setCookie] = useCookies()
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
  const {
    loading: isLoading,
    codeSent,
    countdown,
    sendVerificationCode,
    verifyCode,
    canResend
  } = useEmailVerification(true);

  const handleSubmit = async (data) => {
    try {
      const result = await sendVerificationCode(data.email)
      if (result) {
        onNext(methods.getValues())
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div className={`
          pw:relative before:pw:top-0 before:pw:left-0 before:pw:absolute before:pw:content-['']
          before:pw:border-[2px] pw:bg-black-light before:pw:bg-[100%] pw:p-4
          before:pw:border-solid pw:rounded-2xl before:pw:rounded-[inherit]
          before:pw:w-full before:pw:h-full pw:font-medium pw:text-[#9b9b9b]
          pw:text-sm pw:leading-5 before:pw:transition-all before:pw:duration-[0.2s]
          before:pw:ease-linear before:pw:pointer-events-none before:pw:outline-none
          before:pw:border-transparent
          ${isFocused ? 'before:pw:border-white/20' : 'before:pw:border-transparent'}
        `}>
          <div className="pw:mb-2 pw:text-[13px] pw:text-gray-lighter">
            {t('Or continue with email')}
          </div>

          <div className="pw:relative">
            <ControlEmailField
              name="email"
              placeholder={t('Enter your email')}
              validator
              icon={
                <Icons
                  nameIcon="email"
                  className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2"
                />
              }
              customClassOnField="bg-transparent pl-10 focus:bg-transparent w-full"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
        </div>

        <div className="pw:w-full pw:h-4" />

        <button
          disabled={isLoading || !methods.getValues('email')}
          type="submit"
          className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary hover:pw:opacity-90 pw:m-0 pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-[100%] pw:text-white pw:normal-case pw:leading-none pw:transition-colors pw:duration-[0.2s] pw:cursor-pointer pw:overflow-visible"
        >
          {t('Continue')}
        </button>
      </form>
    </FormProvider>
  )
}

export default EmailForm
