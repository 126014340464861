// src/pages/Signup/index.jsx
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// Inner components
import AccountType from './components/AccountType'
import SignupMethod from './components/SignupMethod'
import Authenticator from "./components/Authenticator"
import Passkey from './components/Passkey'
import Security from './components/Security'
import SMS from './components/SMS'
import IssuerFinalize from './components/IssuerFinalize'
import ReferralPartnerFinalize from './components/ReferralPartnerFinalize'
// Constant
import { SIGNUP_STEPS } from './constant/steps'
import TenantSubscribe from './components/TenantSubscribe'
import { useMutation } from '@apollo/client';
import { SINGPASS_REGISTER } from '../../queriesAndMutations';
import { toaster } from '../../utils';
import { useCookies } from 'react-cookie'
import config from '../../config'

const Signup = () => {
  const history = useHistory()
  const [, setCookie] = useCookies()
  const [currentStep, setCurrentStep] = useState(SIGNUP_STEPS.ACCOUNT_TYPE)
  const [signupData, setSignupData] = useState({
      accountType: 'ISSUER',
      signupMethod: null,
      securityMethod: null,
      tenantId: null,
  })

  const handleTwoFactorComplete = () => {
    if (signupData.accountType === 'ISSUER') {
      setCurrentStep(SIGNUP_STEPS.ISSUER_FINALIZE)
    } else if (signupData.accountType === 'REFERRAL_PARTNER') {
      setCurrentStep(SIGNUP_STEPS.REFERRAL_PARTNER_FINALIZE)
    } else {
      history.push('/dashboard')
    }
  }

  console.log('signupData', signupData)

  const handleStepComplete = (step, data) => {
    switch (step) {
      case SIGNUP_STEPS.ACCOUNT_TYPE:
        setCurrentStep(SIGNUP_STEPS.SIGNUP_METHOD)
        break

      case SIGNUP_STEPS.SIGNUP_METHOD:
        if (data.method === 'passkey') {
            setCurrentStep(SIGNUP_STEPS.PASSKEY)
        } else if (data.method === 'email') {
            setCurrentStep(SIGNUP_STEPS.SECURITY)
        } else {
          setCurrentStep(SIGNUP_STEPS.AUTHENTICATOR)
        }
        break

      case SIGNUP_STEPS.PASSKEY:
        handleTwoFactorComplete()
        break

      case SIGNUP_STEPS.SECURITY:
        if (data.securityMethod === 'authenticator') {
            setCurrentStep(SIGNUP_STEPS.AUTHENTICATOR)
        } else if (data.securityMethod === 'sms') {
            setCurrentStep(SIGNUP_STEPS.SMS)
        }
        break

      case SIGNUP_STEPS.AUTHENTICATOR:
      case SIGNUP_STEPS.SMS:
        handleTwoFactorComplete()
        break

      case SIGNUP_STEPS.ISSUER_FINALIZE:
        setCurrentStep(SIGNUP_STEPS.TENANT_SUBSCRIBE)
        break

      case SIGNUP_STEPS.REFERRAL_PARTNER_FINALIZE:
        history.push('/commission')
        break

      case SIGNUP_STEPS.TENANT_SUBSCRIBE:
        history.push('/dashboard')
        break

      default:
        break
    }
  }

  const handleBack = () => {
    switch (currentStep) {
      case SIGNUP_STEPS.SIGNUP_METHOD:
          setCurrentStep(SIGNUP_STEPS.ACCOUNT_TYPE)
          break
      case SIGNUP_STEPS.PASSKEY:
          setCurrentStep(SIGNUP_STEPS.SIGNUP_METHOD)
          break
      case SIGNUP_STEPS.SECURITY:
          setCurrentStep(SIGNUP_STEPS.SIGNUP_METHOD)
          break
      case SIGNUP_STEPS.AUTHENTICATOR:
      case SIGNUP_STEPS.SMS:
          setCurrentStep(SIGNUP_STEPS.SECURITY)
          break
      default:
          break
    }
  }

  const [isFetchingSingpass, setIsFetchingSingpass] = useState(false);
  const [singpassRegister] = useMutation(SINGPASS_REGISTER);

  useEffect(() => {
    const handleSingpassCallback = async (receivedQueryParams) => {
      try {
        setIsFetchingSingpass(true);
        const storedSignupData = localStorage.getItem('signupData');

        if (!storedSignupData) {
          toaster.error('No signup data found');
          return;
        }

        const parsedSignupData = JSON.parse(storedSignupData);
        console.log('parsedSignupData', parsedSignupData);

        setSignupData(parsedSignupData);

        const { data } = await singpassRegister({
          variables: {
            input: {
              receivedQueryParams,
              nonce: localStorage.getItem('singpass_nonce'),
              accountType: parsedSignupData.accountType,
              referralModel: parsedSignupData.referralModel,
            }
          }
        });

        const { accessToken, refreshToken } = data.singpassRegister;
        console.log('accessToken', accessToken);
        console.log('refreshToken', refreshToken);

        if (accessToken && refreshToken) {
          setCookie(config.jwtCookie, accessToken, { path: '/' })

          // Handle the mapped data for signup flow
          handleStepComplete(SIGNUP_STEPS.SIGNUP_METHOD, {
            method: 'singpass',
            data: {
              accessToken,
              refreshToken,
            }
          });
        }
      } catch (error) {
        console.error('Error fetching singpass callback:', error);
        toaster.error(error.message);
      } finally {
        setIsFetchingSingpass(false);
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const singpass = urlParams.get('singpass');

    if (singpass) {
      // get url query params except singpass
      const receivedQueryParams = {};
      urlParams.forEach((value, key) => {
        if (key !== 'singpass') {
          receivedQueryParams[key] = value;
        }
      });

      console.log('receivedQueryParams', receivedQueryParams);
      handleSingpassCallback(receivedQueryParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singpassRegister]);

  const renderStep = () => {
    switch (currentStep) {
      case SIGNUP_STEPS.ACCOUNT_TYPE:
        return (
          <AccountType
            signupData={signupData}
            setSignupData={setSignupData}
            onNext={(data) => handleStepComplete(SIGNUP_STEPS.ACCOUNT_TYPE, data)}
          />
        )

      case SIGNUP_STEPS.SIGNUP_METHOD:
        return (
          <SignupMethod
            accountType={signupData.accountType}
            signupData={signupData}
            onBack={handleBack}
            onNext={(data) => handleStepComplete(SIGNUP_STEPS.SIGNUP_METHOD, data)}
          />
        )

      case SIGNUP_STEPS.PASSKEY:
        return (
          <Passkey
            onBack={handleBack}
            onNext={(data) => handleStepComplete(SIGNUP_STEPS.PASSKEY, data)}
          />
        )

      case SIGNUP_STEPS.SECURITY:
        return (
          <Security
            onBack={handleBack}
            onNext={(data) => handleStepComplete(SIGNUP_STEPS.SECURITY, data)}
          />
        )

      case SIGNUP_STEPS.AUTHENTICATOR:
        return (
          <Authenticator
            onBack={handleBack}
            onNext={(data) => handleStepComplete(SIGNUP_STEPS.AUTHENTICATOR, data)}
          />
        )

      case SIGNUP_STEPS.SMS:
        return (
          <SMS
            onBack={handleBack}
            onNext={(data) => handleStepComplete(SIGNUP_STEPS.SMS, data)}
          />
        )

      case SIGNUP_STEPS.ISSUER_FINALIZE:
        return (
          <IssuerFinalize signupData={signupData} setSignupData={setSignupData} onNext={() => handleStepComplete(SIGNUP_STEPS.ISSUER_FINALIZE)} />
        )

      case SIGNUP_STEPS.REFERRAL_PARTNER_FINALIZE:
        return (
          <ReferralPartnerFinalize signupData={signupData} setSignupData={setSignupData} onNext={() => handleStepComplete(SIGNUP_STEPS.REFERRAL_PARTNER_FINALIZE)} />
        )

      case SIGNUP_STEPS.TENANT_SUBSCRIBE:
        return (
          <TenantSubscribe signupData={signupData} onNext={() => handleStepComplete(SIGNUP_STEPS.TENANT_SUBSCRIBE)} />
        )

      default:
        return null
    }
  }

  return renderStep()
}

export default Signup
