import React, { useState } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  CREATE_PROJECT_PAGE,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'
import ProjectPageForm from '../components/ProjectPageForm'

const AdminCreateProjectPage = () => {
  const { t } = useTranslation()
  const [createProjectPage, createProjectPageStatus] = useMutation(CREATE_PROJECT_PAGE)
  const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
  const submitCreateProjectPage = (data) => {
    createProjectPage({
      variables: {
        input: data,
      },
    }).then(() => {
      toaster.success('Project created successfully')
      setCreatedSuccessfully(true)
    }).catch(() => {})
  }

  if (createdSuccessfully) {
    return <Redirect to="/admin/pages/projects" />
  }

  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Create new Project')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq admin-settings">
        <div className="faq__center admin-settings__center center">
          <div className="faq__top admin-settings__top">
            <div className="faq__title admin-settings__title h2">{t('Create new Project')}</div>
          </div>

          <div className="faq__row admin-settings__row">
            <div className="faq__col admin-settings__col">
              <ProjectPageForm
                onSubmit={submitCreateProjectPage}
                submitLoading={createProjectPageStatus.loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminCreateProjectPage
