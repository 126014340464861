import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  TablePagination,
} from '@material-ui/core'

import {
  GET_ADMIN_USER,
} from '../../queriesAndMutations'
import Icon from '../Icon'

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const goToFirstPage = (event) => {
    onPageChange(event, 0)
  }
  const goToPrevPage = (event) => {
    if (page > 0) {
      onPageChange(event, page - 1)
    }
  }
  const gotoNextPage = (event) => {
    if (page < Math.ceil(count / rowsPerPage) - 1) {
      onPageChange(event, page + 1)
    }
  }
  const goToLastPage = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }
  const goToPage = (event, nextPage) => {
    if (page !== nextPage) {
      onPageChange(event, nextPage)
    }
  }
  const adjacentPages = [page - 2, page - 1, page, page + 1, page + 2].filter((adjacentPage) => (
    adjacentPage >= 0 && adjacentPage <= Math.max(0, Math.ceil(count / rowsPerPage) - 1)
  ))

  return (
    <div className="explore__pagination">
      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_prev display-none"
        onClick={goToFirstPage}
        disabled={page === 0}
        aria-label="first page"
      >
        <Icon
          name="arrow-prev"
          className={classNames('arrow-prev')}
          size="20"
        />
      </button>

      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_prev"
        onClick={goToPrevPage}
        disabled={page === 0}
        aria-label="previous page"
      >
        <Icon
          name="arrow-prev"
          className={classNames('arrow-prev')}
          size="20"
        />
      </button>
      {adjacentPages.map((adjacentPage) => (
        <button
          type="button"
          key={adjacentPage}
          onClick={(event) => goToPage(event, adjacentPage)}
          className="subscription__btn explore__pagination_button"
        >
          {adjacentPage + 1}
        </button>
      ))}
      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_next"
        onClick={gotoNextPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <Icon
          name="arrow-next"
          className={classNames('arrow-next')}
          size="20"
        />
      </button>

      <button
        type="button"
        className="subscription__btn explore__pagination_button explore__pagination_next display-none"
        onClick={goToLastPage}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <Icon
          name="arrow-next"
          className={classNames('arrow-next')}
          size="20"
        />
      </button>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
}

const AdminUserTable = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('admin-user_table-rows_per_page') || 5)
  const { data, loading } = useQuery(GET_ADMIN_USER, {
    variables: {
      page,
      rowsPerPage,
    },
  })
  const allUsers = data ? data.getAdminUser.users : []
  const userCount = data ? data.getAdminUser.count : 0
  const formatDate = (timestamp) => moment(+timestamp).tz('Europe/Vaduz').format('YYYY-MM-DD HH:mm z')
  const changePage = (event, nextPage) => {
    setPage(nextPage)
  }
  const changeRowsPerPage = (event) => {
    localStorage.getItem('admin-user_table-rows_per_page', +event.target.value)
    setPage(0)
    setRowsPerPage(+event.target.value)
  }
  const renderStatus = (user) => {
    if (user.isActive) {
      return <span className="status-stroke-green">{t('Activated')}</span>
    }
    return <span className="status-stroke-orange">{t('Inactivated')}</span>
  }

  return (
    <>
      <div className="investhub-table__wrapper">
        <div className="investhub-table__box investhub-table__box-styled">
          <div className="investhub-table__row">
            <div className="investhub-table__col">{t('ID')}</div>
            <div className="investhub-table__col">{t('Email')}</div>
            <div className="investhub-table__col">{t('Created At')}</div>
            <div className="investhub-table__col">{t('Status')}</div>
          </div>

          { loading && (
            <div className="investhub-table__row investhub-table__row-empty">
              <div className="investhub-table__col">
                <CircularProgress />
              </div>
            </div>
          )}
          { allUsers.map((user) => (
            <div className="investhub-table__row" key={user.id}>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('ID')}</div>
                {user.id}
              </div>

              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Email')}</div>
                {user.email}
              </div>

              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Created At')}</div>
                {formatDate(user.createdAt)}
              </div>

              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Status')}</div>
                {renderStatus(user)}
              </div>
            </div>
          ))}
        </div>

        <div className="investhub-table__pagination">
          <TablePagination
            colSpan={5}
            rowsPerPageOptions={[5, 10, 20]}
            count={userCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            ActionsComponent={TablePaginationActions}
            component="div"
          />
        </div>
      </div>
    </>
  )
}

export default AdminUserTable
