// src/pages/SignIn/components/Layout.jsx

import React from "react"
import Icons from "../../../components/shared/Icons"

const Layout = ({ title, description, onBack, children }) => {
  return (
    <div className="flex flex-col flex-wrap flex-1 justify-center items-center pt-10">
      {/* Header Section */}
      <div className="z-10 border-0 mx-auto mt-0 mb-10 min-1419:mb-8 max-767:px-4 p-0 max-w-[480px] text-center align-baseline">
        {onBack && (
          <>
            <button
              onClick={onBack}
              className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
            >
              <Icons nameIcon="chevron-left" className="w-3 h-3" />
              <span className="inline-block">Back</span>
            </button>
            <div className="w-full h-4" />
          </>
        )}

        {title && (
          <div className="relative border-0 m-0 p-0 font-semibold text-[40px] text-center text-white max-767:text-[2rem] leading-[1.2] tracking-[-0.02em]">
            {title}
          </div>
        )}

        {description && (
          <div className="border-0 mt-2 p-0 text-gray-lighter">
            {description}
          </div>
        )}
      </div>

      {/* Content Section */}
      <div className="max-480:px-4 w-full">
        <div className="mx-auto w-full max-w-[382px]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
