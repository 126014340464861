export const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const isAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /android/.test(userAgent);
};

export const getAuthenticatorStoreLink = () => {
  if (isIOS()) {
    return 'https://apps.apple.com/us/app/google-authenticator/id388497605';
  }
  if (isAndroid()) {
    return 'intent://scan/#Intent;scheme=googlegauthenticator;package=com.google.android.apps.authenticator2;end;';
  }
  return 'https://support.google.com/accounts/answer/1066447?hl=en'; // Desktop fallback
};
