import React from 'react'
import { useTranslation } from 'react-i18next';
import WalletCard from './WalletCard';

const WalletGroup = ({ currency }) => {
  const { t } = useTranslation();

  // Mock data
  const wallets = [
    {
      address: '0x7e51...098c',
      score: 95,
      networks: [
        {
          name: 'Ethereum',
          icon: 'ethereum',
          balance: 60002,
          currency: 'EUR',
          hasIban: true
        },
        {
          name: 'Polygon',
          icon: 'polygon',
          balance: 2100000,
          currency: 'EUR',
          hasIban: false
        }
      ]
    }
  ];

  const totalBalance = wallets.reduce((total, wallet) => total + wallet.networks.reduce((sum, network) => sum + network.balance, 0), 0);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center bg-black-dark p-4 rounded-xl">
        <span className="font-sans font-semibold text-white">{currency}</span>
        <span className="font-sans text-white">
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          }).format(totalBalance)}
        </span>
      </div>

      {wallets.map(wallet => (
        <WalletCard key={wallet.address} wallet={wallet} />
      ))}
    </div>
  );
}

export default WalletGroup
