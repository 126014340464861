import React from 'react'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import Icons from '../../../../components/shared/Icons'

import { SIGNUP_STEPS } from '../../constant/steps'

// Animation variants
const contentVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
  transition: { duration: 0.3 }
}

const Layout = ({
  title,
  description,
  currentStep,
  totalSteps,
  onBack,
  hideProgress,
  children,
  stepType = SIGNUP_STEPS.ACCOUNT_TYPE
}) => {
  const { t } = useTranslation()

  if (stepType === SIGNUP_STEPS.ACCOUNT_TYPE) {
    return (
        <div className="flex justify-start border-0 m-0 px-12 max-767:px-4 w-full align-baseline grow">
            <div className="w-full">
                <div className="border-0 mx-auto my-0 p-0 w-full max-w-[1120px] align-baseline">
                    {title && (
                        <h1 className="m-0 pt-10 font-sans font-semibold text-[28px] text-start text-white leading-9 transition-[color] duration-[0.2s] ease-[ease-out]">
                            {title}
                        </h1>
                    )}

                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentStep}
                            {...contentVariants}
                            className="flex flex-row max-1023:flex-col md:justify-between max-1023:gap-10 md:space-x-4 pt-10"
                        >
                            {children}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        </div>
    )
  }

  return (
    <div className="flex flex-col flex-wrap flex-1 justify-center items-center">
      <div className="z-10 border-0 mx-auto mt-0 mb-10 min-1419:mb-8 max-767:px-4 p-0 max-w-[480px] text-center align-baseline">
        {onBack && (
          <>
            <button
                className="inline-flex justify-center items-center gap-1 bg-black-light hover:bg-gray-700 hover:opacity-90 m-0 px-4 rounded-[40px] h-8 font-medium text-white text-xs normal-case leading-none transition-colors duration-[0.2s]"
                onClick={onBack}
            >
                <Icons nameIcon="chevron-left" className="w-3 h-3" />
                <span className="inline-block">{t('Back')}</span>
            </button>
            <div className="w-full h-4" />
          </>
        )}

        {title && (
          <div className="relative border-0 m-0 p-0 font-semibold text-[40px] text-center text-white max-767:text-[2rem] leading-[1.2] tracking-[-0.02em]">{title}</div>
        )}

        {description && (
          <div className="border-0 mt-2 p-0 text-gray-lighter">{description}</div>
        )}
      </div>

      <div className="max-480:px-4 w-full">
        {children}
      </div>
    </div>
  )
}

export default Layout
