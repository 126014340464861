import React from 'react'
// import PageFooter from '../Footer'
import SiteFooter from '../SiteFooter/SiteFooter'
import AppBar from '../common/AppBar/AppBar'

const PageDashboard = ({ children }) => (
  <div className="outer page-dashboard pagewrap v2">
    <AppBar />
    <div className="outer__inner">
      {children}
    </div>

    <SiteFooter />
  </div>
)

export default PageDashboard
