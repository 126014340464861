/* eslint-disable jsx-a11y/label-has-associated-control */
// src/pages/DashboardTokens/AddWallet/PriceInputFields.jsx

import React, { useState } from 'react'

const PriceInputFields = ({
  entryPrice,
  currentPrice,
  onEntryPriceChange,
  onCurrentPriceChange,
}) => {
  const [entryFocused, setEntryFocused] = useState(false)
  const [currentFocused, setCurrentFocused] = useState(false)

  const handlePriceChange = (handler) => (e) => {
    const { value } = e.target
    // Validate: only numbers and decimals allowed, no negative values
    if (value === '' || (/^\d*\.?\d*$/.test(value) && !value.startsWith('-'))) {
      handler(value)
    }
  }

  const getInputClass = (isFocused) => `
        block border-2 bg-black-dark px-4 py-0 rounded-xl w-full h-11
        font-medium text-sm text-white leading-[1.71429] tracking-[-0.02em]
        transition-[border-color] duration-[0.2s] appearance-none outline-none
        placeholder-[#6F737E]
        ${isFocused ? 'border-[#72798a]' : 'border-white/20'}
        hover:border-[#72798a]
    `

  return (
    <div className="space-y-4 mt-2">
      {/* Entry Price Field */}
      <div>
        <label
          htmlFor="entryPrice"
          className="block mb-2 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]"
        >
          Entry Price
        </label>
        <div className="relative">
          <input
            id="entryPrice"
            type="text"
            value={entryPrice || ''}
            onChange={handlePriceChange(onEntryPriceChange)}
            onFocus={() => setEntryFocused(true)}
            onBlur={() => setEntryFocused(false)}
            placeholder="0.00"
            className={getInputClass(entryFocused)}
          />
          <div className="top-1/2 right-4 absolute text-[#6F737E] text-sm -translate-y-1/2">
            USD
          </div>
        </div>
      </div>

      {/* Current Market Price Field */}
      <div>
        <label
          htmlFor="currentPrice"
          className="block mb-2 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]"
        >
          Current Market Price
        </label>
        <div className="relative">
          <input
            id="currentPrice"
            type="text"
            value={currentPrice || ''}
            onChange={handlePriceChange(onCurrentPriceChange)}
            onFocus={() => setCurrentFocused(true)}
            onBlur={() => setCurrentFocused(false)}
            placeholder="0.00"
            className={getInputClass(currentFocused)}
          />
          <div className="top-1/2 right-4 absolute text-[#6F737E] text-sm -translate-y-1/2">
            USD
          </div>
        </div>
      </div>

      {/* Price Change Indicator */}
      {entryPrice && currentPrice && (
        <div className={`text-[13px] text-right ${
          parseFloat(currentPrice) >= parseFloat(entryPrice)
            ? 'text-green-500'
            : 'text-red-500'
        }`}
        >
          {(((parseFloat(currentPrice) - parseFloat(entryPrice)) / parseFloat(entryPrice)) * 100).toFixed(2)}
          % change
        </div>
      )}
    </div>
  )
}

export default PriceInputFields
