// src/pages/DashboardTokens/Table/Footer.jsx

import React from 'react'

const Footer = ({
  toggleScrollPosition,
  scrollText,
  rangeValue,
  handleRangeChange,
}) => {
  return (
    <div className="bottom-0 z-10 sticky flex flex-col items-center bg-zinc-800 px-6 py-4">
      <div className="flex lg:flex-row flex-col items-start lg:items-center gap-4 lg:gap-2 mt-0 w-full">
        {scrollText && (
        <div className="lg:flex items-center min-w-[11rem] whitespace-nowrap">
          <span
            className="font-semibold text-[13px] text-white/70 hover:text-white leading-[1.2] tracking-[-0.02em] cursor-pointer scroll__active"
            onClick={toggleScrollPosition}
          >
            {scrollText}
          </span>
        </div>
        )}

        <input
          className="relative w-full scroll-bar"
          type="range"
          min="0"
          max="100"
          value={rangeValue}
          onChange={handleRangeChange}
        />
      </div>
    </div>
  )
}

export default Footer
