import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Icons from '../../../components/shared/Icons';
import Heading from '../Heading';
import Table from './components/Table';
import DeleteModal from './components/DeleteModal';
import EditModal from './components/EditModal';
import NewModal from './components/NewModal';
import { dummySpendingCaps } from './constants/tableConfig';
import styles from './style.module.scss';

const SpendingCap = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [isModalNewOpen, setIsModalNewOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [buttonRect, setButtonRect] = useState(null);

  const handleDropdownClick = (e, tokenId) => {
    e.stopPropagation();
    const rect = e.currentTarget.getBoundingClientRect();
    setButtonRect(rect);
    setOpenDropdownId(openDropdownId === tokenId ? null : tokenId);
  };

  const handleDocumentClick = useCallback((e) => {
    if (!e.target.closest('.dropdown-trigger')) {
      setOpenDropdownId(null);
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  const handleNavigateToDetail = (e, token, cap) => {
    e.preventDefault();
    setSelectedItem({
      token: token.token,
      network: cap.network,
      limit: cap.limit,
      used: cap.used
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const handleConfirmDelete = () => {
    console.log('Deleting:', selectedItem);
    handleCloseModal();
  };

  const handleNavigateToInvestment = (e, item) => {
    e.preventDefault();
    setEditItem({
      token: item.token,
      network: item.network,
      limit: item.limit,
      used: item.used
    });
    setIsModalEditOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsModalEditOpen(false);
    setEditItem(null);
  };

  const handleSaveEdit = () => {
    console.log('Saving edit:', editItem);
    handleCloseEditModal();
  };

  const handleAddNew = () => {
    setIsModalNewOpen(true);
  };

  const handleCloseNewModal = () => {
    setIsModalNewOpen(false);
  };

  const handleSaveNew = () => {
    console.log('Saving new');
    handleCloseNewModal();
  };

  return (
    <>
      <Heading
        title={t('settings.spending_cap.title')}
        description={t('settings.spending_cap.description')}
      />

      <div className={`relative border-2 border-white/10 border-solid rounded-xl w-full max-w-full overflow-x-auto ${styles.wrapper}`}>
        <Table
          data={dummySpendingCaps}
          buttonRect={buttonRect}
          openDropdownId={openDropdownId}
          setOpenDropdownId={setOpenDropdownId}
          handleDropdownClick={handleDropdownClick}
          handleNavigateToDetail={handleNavigateToDetail}
          handleNavigateToInvestment={handleNavigateToInvestment}
        />
      </div>

      <div className="flex justify-end mt-6">
        <button
          onClick={handleAddNew}
          className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
        >
          <Icons nameIcon="plus" className="w-4 h-4" />
          {t('settings.spending_cap.fields.new')}
        </button>
      </div>

      <DeleteModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        selectedItem={selectedItem}
        onConfirm={handleConfirmDelete}
      />

      <EditModal
        isOpen={isModalEditOpen}
        onClose={handleCloseEditModal}
        editItem={editItem}
        onSave={handleSaveEdit}
      />

      <NewModal
        isOpen={isModalNewOpen}
        onClose={handleCloseNewModal}
        onSave={handleSaveNew}
      />
    </>
  );
};

export default SpendingCap;
