import React from 'react'

const SelectionCheckbox = ({ checked, onChange }) => {
  return (
    <label className="relative flex justify-start gap-3 border-0 m-0 p-0 cursor-pointer select-none align-baseline checkbox__wallet">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="top-0 left-0 absolute border-0 opacity-0 m-0 p-0"
      />
      <span className="flex border-0 m-0 p-0 align-baseline">
        <span className="relative after:top-2/4 after:left-2/4 after:absolute after:content-[''] border-2 border-white/20 after:bg-[50%_50%] after:bg-[initial] after:bg-no-repeat after:opacity-0 m-0 p-0 border-solid rounded-md w-6 after:w-[17px] h-6 after:h-3 transition-all after:transition-opacity after:-translate-x-2/4 after:-translate-y-2/4 duration-[0.2s] after:duration-[0.2s] align-baseline shrink-0" />
      </span>
    </label>
  )
}

export default SelectionCheckbox
