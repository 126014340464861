import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import FloatingLegalDisclaimer from '../../FloatingLegalDisclaimer'

import {
  UseIsMax1023,
  UseIsMax767
} from "../../shared/MediaQueries"

// Inner
// import Header from './Header'
import OnchainProviders from './OnchainProviders'
// Cross component
import Sidebar from '../Dashboard/Sidebar'
import HeaderFromDashboard from "../Dashboard/Header"

import "../../../styles/style.css"

const InstantInvestment = ({
  pageTitle,
  isContain,
  isContentFullHeight,
  children,
  isLogin
}) => {
  const { t } = useTranslation()
  const isMobile = UseIsMax767()
  const isTablet = UseIsMax1023()
  const location = useLocation()

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const [isOpenAccountWallet, setIsOpenAccountWallet] = useState(false)
  const [isHamburgerToggle, setIsHamburgerToggle] = useState(false)

  const toggleSidebar = () => {
    const newCollapsedState = !isSidebarCollapsed
    setIsSidebarCollapsed(newCollapsedState)

    if (isMobile && isHamburgerToggle) {
      setIsHamburgerToggle(false)
    }
  }

  // Close account wallet when URL changes
  useEffect(() => {
    setIsOpenAccountWallet(false)
  }, [location.pathname])

  const toggleAccountWallet = (e) => {
    if (e) e.preventDefault()
    setIsOpenAccountWallet(!isOpenAccountWallet)
  }

  const toggleHamburger = () => {
    setIsHamburgerToggle(!isHamburgerToggle)
  }

  useEffect(() => {
    setIsSidebarCollapsed(isTablet)
    setIsHamburgerToggle(false)
  }, [isTablet])

  const formMethods = useForm({
    defaultValues: {
      currency: 'eure',
      token_name: 'ENC',
      token_amount: 100,
      amount: 100,
    }
  });

  return (
    <>
      <div className="relative z-[222] max-767:z-[50] bg-yellow-500 px-4 py-1 w-full font-sans font-semibold text-black-light text-center uppercase tracking-[-0.02em]">Beta version</div>
      <div className={`bg-black-dark flex flex-row flex-nowrap justify-start items-stretch ${isMobile ? 'pl-0' : (isTablet || isSidebarCollapsed ? 'pl-24' : 'pl-[21.25rem]')} min-h-screen `}>
        {(!isMobile || isHamburgerToggle) && (
          <Sidebar
            isCollapsed={isMobile ? false : isSidebarCollapsed}
            toggleSidebar={toggleSidebar}
          />
        )}

        <div className={`flex flex-col relative flex-nowrap flex-1 py-6 w-full ${!isMobile ? 'bg-black-light' : 'bg-black-dark pt-4'}`}>
          <div className={`flex-1 pb-10 max-767:pb-6 ${!isMobile && 'bg-black-dark rounded-2xl'} `}>
            <div className={`${isContentFullHeight ? 'h-full flex flex-col' : ''} w-full mx-auto ${isContain ? 'max-w-[75rem]' : ''}`}>
              <HeaderFromDashboard
                pageTitle={pageTitle}
                isHamburgerToggle={isHamburgerToggle}
                toggleHamburger={toggleHamburger}
                isContain={isContain}
                toggleAccountWallet={toggleAccountWallet}
                isOpenAccountWallet={isOpenAccountWallet}
              />

              <div className='flex flex-1 max-767:px-4 py-6 md:py-0'>
                <div className="relative flex justify-center items-center max-w-full grow">
                  <div className="relative flex flex-col md:pt-18 max-w-full grow">
                    <FormProvider {...formMethods}>
                      <OnchainProviders>
                        {children}
                      </OnchainProviders>
                    </FormProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex justify-center items-center mt-6 w-full">
            <FloatingLegalDisclaimer />
          </div>
        </div>
      </div>

    </>
  )
}

export default InstantInvestment
