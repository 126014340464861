import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// Inner
import { FieldBuy, FieldInvest } from "../../FieldBuyInvest";

const SkeletonLoading = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={117.18} className="bg-gray-lighter !w-full" />
  </div>
)

const StepBuyAndInvest = ({
  isLogin,
  buyValue,
  investValue,
  handleInvestChange,
  setCurrentIssuer,
  currentIssuer,
  balanceAfterTopup,
  fetchingData,
  fetchingRateAndPrice,
}) => {
  return (
    <>
      <FieldInvest
        isLogin={isLogin}
        onChange={handleInvestChange}
        value={investValue}
        setCurrentIssuer={setCurrentIssuer}
        currentIssuer={currentIssuer}
      />
      <div className="w-full h-2" />
      {fetchingData || fetchingRateAndPrice ? <SkeletonLoading /> : (
        <FieldBuy
          isLogin={isLogin}
          // onChange={handleInvestChange}
          value={buyValue}
          balanceAfterTopup={balanceAfterTopup}
          currentIssuer={currentIssuer}
        />
      )}
      <div className="w-full h-2" />
    </>
  );
};

export default StepBuyAndInvest;
