import React from 'react'
import cn from 'classnames'
import { useFormContext } from 'react-hook-form'
import style from "./style.module.scss"

const CheckboxField = ({
  name,
  label,
  checked,
  onChange,
  className,
  clearSpaceTop,
  isOneLine,
  isTextWhite,
  useForm = false
}) => {
  // Only call useFormContext if useForm is true
  const formContext = useFormContext();
  const inputProps = useForm ? {
    ...formContext.register(name)
  } : {
    checked,
    onChange
  };

  return (
    <label
      htmlFor={`checkbox-${name}`}
      className={`relative !flex justify-start items-center gap-3 border-0 m-0 p-0 cursor-pointer select-none ${style.checkboxWrapper}  ${className || ''}`}
    >
      <input
        id={`checkbox-${name}`}
        type="checkbox"
        className="top-0 left-0 absolute border-0 opacity-0 m-0 p-0"
        {...inputProps}
      />
      <span
        className={cn(
          'flex border-0 m-0 p-0',
          {
            'mt-1': clearSpaceTop
          }
        )}
      >
        <span className="relative after:top-2/4 after:left-2/4 after:absolute after:content-[''] border-2 border-white/60 after:bg-[50%_50%] after:bg-[initial] after:bg-no-repeat after:opacity-0 !m-0 p-0 border-solid rounded-md w-6 after:w-[17px] h-6 after:h-3 transition-all after:transition-opacity after:-translate-x-2/4 after:-translate-y-2/4 duration-[0.2s] after:duration-[0.2s] align-baseline shrink-0" />
      </span>
      <div
        className={cn(
          "text-sm font-sans tracking-[-0.02em] transition-colors duration-200",
          {
            'text-style-1lines' : isOneLine,
            'text-white/70 is-text-white' : isTextWhite,
            'text-[#6f767e]' : !isTextWhite
          }
        )}
      >
        {label}
      </div>
    </label>
  )
}

export default CheckboxField
