/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useLazyQuery } from '@apollo/client';
import VolumeFilter from '../../DashboardTokens/VolumeFilter';
import AddWallet from '../../DashboardTokens/AddWallet';
import AdvancedFilter from '../../DashboardTokens/AdvancedFilter';
import { GET_USER_WALLETS, GET_TOKEN_BALANCES_BY_ADDRESS, GET_HISTORICAL_PRICE } from '../../../queriesAndMutations';
import { NETWORK_IMAGES } from '../../../constant/images';
import styles from './MyAssets.module.scss';
import tokenList from "../../../services/listDataToken";

const MyAssets = ({ setTotalBalance }) => {
  const { t } = useTranslation();
  const { loading: walletsLoading, data: walletsData } = useQuery(GET_USER_WALLETS);
  const [getTokenBalances] = useLazyQuery(GET_TOKEN_BALANCES_BY_ADDRESS);
  const [getHistoricalPrice] = useLazyQuery(GET_HISTORICAL_PRICE);

  const [assets, setAssets] = useState([]);
  const [assetsChange, setAssetsChange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [volumeFilter, setVolumeFilter] = useState('1M');

  const getNetworkName = (chainIndex) => {
    const networks = {
      '137': 'Polygon',
      '8453': 'Base',
      '1': 'Ethereum',
      '11155111': 'Ethereum',
    };
    return networks[chainIndex] || 'Unknown';
  };

  const getTokenImage = (tokenSymbol) => {
    const token = tokenList.find(token => token.symbol.toLowerCase() === tokenSymbol.toLowerCase());
    return token ? require(`../../../assets/images/tokenSymbol/${token.image}`) : NETWORK_IMAGES.defaultToken;
  };

  const getTimeRange = (filter) => {
    const now = Date.now();
    switch (filter) {
      case '1M':
        return {
          begin: now - (30 * 24 * 60 * 60 * 1000), // 30 days ago
          period: '1d'
        };
      case '6M':
        return {
          begin: now - (180 * 24 * 60 * 60 * 1000), // 180 days ago
          period: '1d'
        };
      case '12M':
        return {
          begin: now - (360 * 24 * 60 * 60 * 1000), // 360 days ago
          period: '1d'
        };
      case '24M':
        return {
          begin: now - (720 * 24 * 60 * 60 * 1000), // 720 days ago
          period: '1d'
        };
      default:
        return {
          begin: now - (30 * 24 * 60 * 60 * 1000), // default to 30 days
          period: '1d'
        };
    }
  };

  const calculatePriceChange = async (token) => {
    try {
      const timeRange = getTimeRange(volumeFilter);

      const { data } = await getHistoricalPrice({
        variables: {
          input: {
            tokenAddress: token.contract_address || "",
            chainIndex: parseInt(token.chain_index),
            limit: 1,
            begin: timeRange.begin.toString(),
            end: (timeRange.begin + 24 * 60 * 60 * 1000).toString(),
            period: timeRange.period
          }
        }
      });

      if (data && data.getHistoricalPrice && data.getHistoricalPrice.historicalPrices) {
        const prices = data.getHistoricalPrice.historicalPrices;
        if (prices.length > 0) {
          const oldPrice = parseFloat(prices[0].price);

          // new pric is current price
          const newPrice = token.price;
          return ((newPrice - oldPrice) / oldPrice) * 100;
        }
      }
      return 0;
    } catch (error) {
      console.error('Error fetching historical price:', error);
      return 0;
    }
  };

  const transformTokensToAssets = async (wallets, tokenPrices) => {
    if (!wallets) return [];

    // Create a map to accumulate token quantities across wallets
    const tokenMap = new Map();

    // First, process stored tokens from all wallets
    wallets.forEach(wallet => {
      wallet.tokens.forEach(token => {
        const key = `${token.symbol}-${token.chain_index}-${token.contract_address}`;
        if (!tokenMap.has(key)) {
          tokenMap.set(key, {
            symbol: token.symbol,
            chain_index: token.chain_index,
            contract_address: token.contract_address,
            quantity: 0,
            current_price: parseFloat(token.current_price || 0),
            wallet_addresses: new Set(),
            hasPrice: parseFloat(token.current_price || 0) > 0
          });
        }
      });
    });

    // Then, process real-time token balances
    Object.entries(tokenPrices).forEach(([key, priceData]) => {
      const [symbol, chainIndex, contractAddress] = key.split('-');
      const mapKey = `${symbol}-${chainIndex}-${contractAddress}`;

      if (!tokenMap.has(mapKey)) {
        tokenMap.set(mapKey, {
          symbol,
          chain_index: chainIndex,
          contract_address: contractAddress,
          quantity: 0,
          current_price: parseFloat(priceData.price || 0),
          wallet_addresses: new Set(),
          hasPrice: parseFloat(priceData.price || 0) > 0
        });
      }

      const existingToken = tokenMap.get(mapKey);
      existingToken.quantity += parseFloat(priceData.balance || 0);
      existingToken.current_price = parseFloat(priceData.price || existingToken.current_price || 0);
      existingToken.wallet_addresses.add(priceData.wallet_address);
      existingToken.hasPrice = existingToken.hasPrice || parseFloat(priceData.price || 0) > 0;
    });

    // Convert map to array and format for display
    const assetsArray = Array.from(tokenMap.values())
      .map(token => {
        const tokenValue = token.quantity * token.current_price;
        const icon = getTokenImage(token.symbol);

        return {
          id: `${token.symbol}-${token.chain_index}-${token.contract_address}`,
          name: token.symbol,
          symbol: token.symbol,
          chain_index: token.chain_index,
          contract_address: token.contract_address,
          icon,
          balance: {
            amount: tokenValue,
            units: `${token.quantity.toFixed(6)} ${token.symbol}`
          },
          price: {
            amount: token.current_price,
            change: 0 // Will be updated later
          },
          allocation: 0, // Will be calculated after
          wallet_count: token.wallet_addresses.size,
          hasPrice: token.hasPrice
        };
      });

    // Calculate total value and allocations
    const totalValue = assetsArray.reduce((sum, asset) => sum + asset.balance.amount, 0);
    setTotalBalance(totalValue);

    // Sort assets: first by value (if has price), then by name
    const sortedAssets = assetsArray.sort((a, b) => {
      if (a.hasPrice && b.hasPrice) {
        return b.balance.amount - a.balance.amount;
      }
      if (a.hasPrice) return -1;
      if (b.hasPrice) return 1;
      return a.name.localeCompare(b.name);
    });

    return sortedAssets.map(asset => ({
      ...asset,
      allocation: totalValue > 0 ? ((asset.balance.amount / totalValue) * 100) : 0
    }));
  };

  useEffect(() => {
    const fetchTokenPrices = async () => {
      if (!walletsData || !walletsData.getUserWallets) return;

      try {
        const walletAddresses = [...new Set(walletsData.getUserWallets.map(w => w.wallet_address))];
        const pricePromises = walletAddresses.map(address =>
          getTokenBalances({ variables: { address } })
        );

        const priceResults = await Promise.all(pricePromises);
        const tokenPrices = {};

        priceResults.forEach(result => {
          if (result.data && result.data.getTokenBalancesByAddress && result.data.getTokenBalancesByAddress.tokenAssets) {
            result.data.getTokenBalancesByAddress.tokenAssets.forEach(token => {
              const key = `${token.symbol}-${token.chainIndex}-${token.tokenAddress}`;

              if (!tokenPrices[key]) {
                tokenPrices[key] = {
                  price: parseFloat(token.tokenPrice),
                  balance: parseFloat(token.balance),
                  wallet_address: result.data.getTokenBalancesByAddress.address
                };
              } else {
                tokenPrices[key].balance += parseFloat(token.balance)
              }
            });
          }
        });

        const transformedAssets = await transformTokensToAssets(walletsData.getUserWallets, tokenPrices);
        setAssets(transformedAssets);
      } catch (error) {
        console.error('Error fetching token prices:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenPrices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsData, getTokenBalances, getHistoricalPrice]);

  // Add new useEffect for volume filter changes
  useEffect(() => {
    const updatePriceChanges = async () => {
      if (!assets.length) return;

      setLoading(true);

      const priceChangePromises = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const asset of assets) {
        if (asset.price.amount) {
          const priceChange = await calculatePriceChange({
            contract_address: asset.contract_address,
            chain_index: asset.chain_index,
            price: asset.price.amount
          });

          // sleep 0.2s to avoid rate limiting
          await new Promise(resolve => setTimeout(resolve, 200));

          priceChangePromises.push({
            ...asset,
            price: {
              ...asset.price,
              change: parseFloat(priceChange.toFixed(2))
            }
          });
        } else {
          priceChangePromises.push(asset);
        }
      }

      const updatedAssets = await Promise.all(priceChangePromises);
      setAssetsChange(updatedAssets);
      setLoading(false);
    };

    updatePriceChanges();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeFilter, assets]);

  if (loading) return <div>Loading...</div>;
  if (!walletsData || !walletsData.getUserWallets) return <div>Error loading assets</div>;

  return (
    <div className={styles.wrapper}>
      <div className="mb-6">
        <div className="mb-2 font-sans font-semibold text-lg text-start text-white leading-6 tracking-[-0.02em]">Assets</div>

        <div className="flex max-480:flex-col-reverse max-480:flex-wrap justify-between items-center gap-4 mb-6">
          <VolumeFilter setVolumeFilter={setVolumeFilter} volumeFilter={volumeFilter} />

          <div className="flex max-480:flex-row flex-wrap justify-start items-center gap-4 max-480:w-full">
            <AddWallet />
            <AdvancedFilter />
          </div>
        </div>
      </div>

      <div className="overflow-x-auto assets">
        <table className="w-full">
          <thead>
            <tr className="border-white/10 border-b-2 border-solid font-sans text-left">
              <th className="p-4 text-ellipsis text-white/70 tracking-[-0.02em] overflow-hidden hover:overflow-visible select-none">
                Name
              </th>
              <th className="p-4 text-ellipsis text-white/70 tracking-[-0.02em] overflow-hidden hover:overflow-visible select-none">
                Balance
              </th>
              <th className="p-4 text-ellipsis text-white/70 tracking-[-0.02em] overflow-hidden hover:overflow-visible select-none">
                Price
              </th>
              <th className="p-4 text-ellipsis text-white/70 tracking-[-0.02em] overflow-hidden hover:overflow-visible select-none">
                Allocation
              </th>
            </tr>
          </thead>
          <tbody>
            {assetsChange.map((asset) => (
              <tr key={asset.id} className={`font-sans ${!asset.hasPrice ? 'opacity-50' : ''}`}>
                <td className="px-4 py-3 text-left text-white/70 align-middle">
                  <div className="flex items-center gap-4 w-full group">
                    <img src={asset.icon} alt={asset.name} className='flex-shrink-0 rounded-full w-6 h-6' />
                    <div className='flex-1'>
                      <div className="font-semibold text-[13px] text-white truncate tracking-[-0.01em]">
                        {asset.name}
                        {asset.wallet_count > 1 && (
                          <span className="ml-2 text-xs text-white/50">
                            ({asset.wallet_count} wallets)
                          </span>
                        )}
                      </div>
                      <div className="text-[13px] truncate tracking-[-0.02em]">
                        {asset.symbol} {asset.chain_index && `• ${getNetworkName(asset.chain_index)}`}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-4 py-3 text-left text-white/70 align-middle">
                  <div className="font-semibold text-[13px] text-white truncate tracking-[-0.01em]">
                    {asset.hasPrice ? (
                      `$${asset.balance.amount.toFixed(2)}`
                    ) : (
                      'No price data'
                    )}
                  </div>
                  <div className="text-[13px] truncate tracking-[-0.02em]">{asset.balance.units}</div>
                </td>
                <td className="px-4 py-3 text-left text-white/70 align-middle">
                  <div className="font-semibold text-[13px] text-white truncate tracking-[-0.01em]">
                    ${asset.price.amount.toFixed(6)}
                  </div>
                  {asset.price.change !== 0 && (
                    <div className={`text-[13px] truncate tracking-[-0.02em] ${asset.price.change > 0 ? 'text-green-500' : 'text-red-500'}`}>
                      {asset.price.change > 0 ? '+' : ''}{asset.price.change}%
                    </div>
                  )}
                </td>
                <td className="px-4 py-3 text-left text-white/70 align-middle">
                  <div className="font-semibold text-[13px] text-white truncate tracking-[-0.01em]">
                    {asset.allocation.toFixed(2)}%
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyAssets;
