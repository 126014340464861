// src/pages/SignIn/components/steps/Welcome.jsx

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

import { ControlEmailField, EmailField } from "../../../../components/shared/Fields";
import { UseIsMax480 } from "../../../../components/shared/MediaQueries";
import Icons from "../../../../components/shared/Icons";
import { SINGPASS_ASSETS_IMAGES } from "../../../../constant/images";
import classNames from "classnames";
import { isProduction } from "../../../../utils";
import { useLazyQuery } from "@apollo/client";
import { GET_AUTHORIZATION_URL } from "../../../../queriesAndMutations";

const Welcome = ({ onContinue, isLoading }) => {
  const isMobile = UseIsMax480();
  const [isFocusedEmail, setIsFocusedEmail] = useState(false);
  const methods = useForm({
    defaultValues: {
      email: "",
    },
  });

  const [showErrorSingPass, setShowErrorSingPass] = useState(false);

  const [getAuthorizationUrl] = useLazyQuery(GET_AUTHORIZATION_URL);

  const onSubmit = (data) => {
    console.log("onSubmit", data);

    onContinue("email", { email: data.email });
  };

  const handleClickSingPass = async () => {
    console.log('handleClickSingPass');
    const { data } = await getAuthorizationUrl(
      {
        variables: {
          context: 'login'
        }
      }
    );
    console.log(data);
    const { nonce } = data.authorizationUrl;

    // store in local storage
    localStorage.setItem('singpass_nonce', nonce);

    window.location.href = data.authorizationUrl.authorizationUrl;
  }

  const AuthButton = ({ onClick, children }) => (
    <button
      type="button"
      onClick={onClick}
      className="inline-flex justify-center items-center bg-black-light hover:bg-gray-lighter hover:opacity-90 m-0 px-6 rounded-3xl h-10 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
    >
      {children}
    </button>
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="text-[13px] text-gray-lighter leading-[1.2] tracking-[-0.02em]">
          Sign in with
        </div>

        <div className="justify-center items-stretch gap-x-4 gap-y-4 max-480:gap-x-[12px] max-480:gap-y-[12px] border-0 grid grid-cols-[1fr_1fr] max-480:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mx-auto mt-2 max-480:mt-4 mb-0 p-0 align-baseline">
          <AuthButton onClick={() => onContinue("wallet")}>
            Connect Wallet
          </AuthButton>
          <AuthButton onClick={() => onContinue("passkey")}>Passkey</AuthButton>

          <div className={classNames("max-480:col-span-1 w-full text-center col-[span_2]", {
            "hidden": isProduction
          })}>
            <button
              type="button"
              onClick={handleClickSingPass}
              className="inline-flex justify-center items-center bg-[#F4333D] hover:bg-[#B0262D] max-480:mx-auto px-6 rounded-2xl w-full h-14 font-bold text-white normal-case leading-none transition-al duration-200"
            >
              <span className="inline-flex mr-1 text-lg tracking-normal">Log in with</span>
              <img
                src={SINGPASS_ASSETS_IMAGES.logoWhite}
                alt="Retrieve myinfo with sing pass"
                className="relative top-[2px] w-auto h-4 object-contain"
                aria-label="sing pass"
              />
            </button>
          </div>
        </div>
        <div className="w-full h-4" />

        {/* Email Section */}
        <div
          className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
            isFocusedEmail
              ? "before:border-white/20"
              : "before:border-transparent"
          }`}
        >
          <div className="mb-2 text-[13px] text-gray-lighter">
            Or continue with email
          </div>
          <div className="relative">
            <ControlEmailField
              name="email"
              placeholder="Enter your email"
              icon={
                <Icons
                  nameIcon="email"
                  className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2"
                />
              }
              customClassOnField="bg-transparent pl-10 focus:bg-transparent w-full"
              onFocus={() => setIsFocusedEmail(true)}
              onBlur={() => setIsFocusedEmail(false)}
              validator={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              }}
            />
            {methods.formState.errors.email && (
              <span className="mt-1 text-red-500 text-sm">
                {methods.formState.errors.email.message}
              </span>
            )}
          </div>
        </div>

        {showErrorSingPass && (
          <>
          <div className="w-full h-4" />
            <div className="relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-red-500/20 before:bg-[100%] py-6 p-4 before:border-red-500 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-center text-lg text-red-500 leading-5 tracking-[-0.02em] before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none">
              Something went wrong!
            </div>
          </>
        )}

        <div className="w-full h-4" />

        {/* Continue Button */}
        <button
          disabled={isLoading}
          type="submit"
          className="inline-flex justify-center items-center bg-primary hover:bg-primary-hover hover:opacity-90 m-0 px-6 rounded-2xl w-full h-14 font-medium text-white transition-colors duration-200"
        >
          Continue
        </button>

        {/* Sign Up Link */}
        <div className="mt-4 text-[13px] text-center text-gray-lighter">
          Don't have an account?{" "}
          <Link
            to="/signup"
            className="hover:opacity-80 font-semibold text-white transition-opacity duration-200"
          >
            Sign up
          </Link>
        </div>
      </form>
    </FormProvider>
  );
};

export default Welcome;
