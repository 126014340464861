/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useCallback } from 'react';
import { ModalWide } from "../../../../components/shared/Modals";
import { initOnRamp } from "@coinbase/cbpay-js";
import { toaster } from '../../../../utils';

const CoinbaseDetail = ({ isOpen, onClose, onNext, amount, destinationAddress }) => {
  const initializeCoinbase = useCallback(async () => {
    if (!amount || !destinationAddress) {
      return;
    }

    console.log('destinationAddress', destinationAddress);
    console.log('process.env.REACT_APP_COINBASE_APP_ID', process.env.REACT_APP_COINBASE_APP_ID)

    try {
      initOnRamp({
        appId: process.env.REACT_APP_COINBASE_APP_ID,
        widgetParameters: {
          // Specify the addresses and which networks they support
          addresses: { [destinationAddress]: ['base', 'ethereum', 'polygon']},
          // (Optional) Filter the available assets on the above networks to just these ones
          assets: ['ETH', 'USDC', 'BTC'],
          presetCryptoAmount: amount,
          defaultNetwork: 'base'
        },
        onSuccess: () => {
          toaster.success('Payment successful');
          onNext();
          onClose();
        },
        onExit: () => {
          onClose();
        },
        onEvent: (event) => {
          console.log('Coinbase event:', event);
        },
        experienceLoggedIn: 'popup',
        experienceLoggedOut: 'popup',
        closeOnExit: true,
        closeOnSuccess: true,
      }, (error, instance) => {
        if (error) {
          console.error('Coinbase initialization error:', error);
          toaster.error('Failed to initialize Coinbase payment');
          onClose();
          return;
        }
        instance.open();
      });
    } catch (error) {
      console.error('Coinbase initialization error:', error);
      toaster.error('Failed to initialize Coinbase payment');
      onClose();
    }
  }, [amount, destinationAddress, onClose, onNext]);

  useEffect(() => {
    if (isOpen) {
      initializeCoinbase();
    }
  }, [isOpen, initializeCoinbase]);

  return (
    <ModalWide
      isOpen={isOpen}
      onClose={onClose}
      title="Coinbase Payment"
      customClassModal="!max-w-[480px]"
      headerTitleLeft={true}
    >
      <div className="flex flex-col gap-4">
        {/* <div id="coinbase-widget" className="min-h-[400px]" /> */}
        <div className="text-sm text-gray-400 mt-4">
          You will be redirected to Coinbase to complete your purchase
        </div>
      </div>
    </ModalWide>
  );
};

export default CoinbaseDetail;
