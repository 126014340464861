import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { UseIsMax767 } from '../../../../../../shared/MediaQueries'
import Icons from '../../../../../../shared/Icons'
import TabButton from './TabButton'
import MobileTabSelect from './MobileTabSelect'
import TabContent from './TabContent'
import tokens from '../../../../../../../services/dummyDataToken'

const tabs = [
  { id: 0, label: 'preferences.language' },
  { id: 1, label: 'preferences.currency' }
]

const PreferenceContent = ({ setShowPreferences, handleClose }) => {
  const { t } = useTranslation()
  const isMobile = UseIsMax767()
  const [activeTab, setActiveTab] = useState(0)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleTabClick = (index) => {
    setActiveTab(index)
    setIsDropdownOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isDropdownOpen && !event.target.closest('.custom-dropdown')) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isDropdownOpen])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="relative flex-1 p-6 w-full"
    >
      <div className="relative mb-6">
        <button
          type="button"
          onClick={() => setShowPreferences(false)}
          className="inline-flex top-0 left-0 absolute justify-center items-center hover:bg-gray-custom/20 rounded-2xl w-6 h-6 transition-all duration-200"
          // data-tooltip-id="sidebar-show-back-tooltip"
          // data-tooltip-content="Back"
        >
          <Icons nameIcon="arrow-left" className="w-5 h-5 text-gray-custom" />
        </button>
        {/* <Tooltip id="sidebar-show-back-tooltip" className="!bg-gray-custom !px-1 !py-1 !rounded-md" /> */}

        <div className="block px-8 w-full font-sans font-semibold text-base text-center text-white leading-6 tracking-[-0.02em]">
          {t('preferences.title')}
        </div>

        <button
          type="button"
          onClick={() => handleClose()}
          className="inline-flex top-0 right-0 absolute justify-center items-center hover:bg-gray-custom/20 rounded-2xl w-6 h-6 transition-all duration-200"
              // data-tooltip-id="sidebar-show-close-sidebar-tooltip2"
              // data-tooltip-content="Close sidebar"
        >
          <Icons nameIcon="close" className="w-5 h-5 text-gray-custom" />
        </button>
        {/* <Tooltip id="sidebar-show-close-sidebar-tooltip2" className="!bg-gray-custom !px-1 !py-1 !rounded-md" /> */}
      </div>

      {!isMobile ? (
        <div className='flex flex-wrap justify-start items-start gap-2 mb-6'>
          {tabs.map((tab) => (
            <TabButton
              key={tab.id}
              label={t(tab.label)}
              isActive={activeTab === tab.id}
              onClick={() => handleTabClick(tab.id)}
            />
          ))}
        </div>
      ) : (
        <MobileTabSelect
          activeTab={activeTab}
          isOpen={isDropdownOpen}
          tabs={tabs}
          onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
          onSelect={handleTabClick}
          t={t}
        />
      )}

      <TabContent activeTab={activeTab} tokens={tokens} />
    </motion.div>
  )
}

export default PreferenceContent
