import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Dialog,
  TablePagination,
  TextField,
} from '@material-ui/core'

import {
  GET_ADMIN_PROJECT_PAGE_LIST,
  DELETE_PROJECT_PAGE,
} from '../queriesAndMutations'
import ControlAdminBreadcrumbs from '../components/ControlAdminBreadcrumbs'
import TablePaginationActions from '../components/common/TablePaginationActions'
import Icon from '../components/Icon'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'

const AdminPage = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [keyword, setKeyword] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(+localStorage.getItem('admin-project_page_table-rows_per_page') || 5)
  const [selectedProjectPage, setSelectedProjectPage] = useState(null)
  const [isShowDeleteConfirmDialog, setIsShowDeleteConfirmDialog] = useState(false)

  const { data, loading, refetch } = useQuery(GET_ADMIN_PROJECT_PAGE_LIST, {
    variables: {
      search: keyword,
      page,
      rowsPerPage,
    },
  })
  const [deleteProjectPage, deleteProjectPageStatus] = useMutation(DELETE_PROJECT_PAGE)
  const allPages = data ? data.getAdminProjectPageList.pages : []
  const pageCount = data ? data.getAdminProjectPageList.count : 0
  const changePage = (event, nextPage) => {
    setPage(nextPage)
  }
  const changeRowsPerPage = (event) => {
    localStorage.getItem('admin-tenant_table-rows_per_page', +event.target.value)
    setPage(0)
    setRowsPerPage(+event.target.value)
  }
  const showConfirmDelete = (projectPage) => () => {
    setSelectedProjectPage(projectPage)
    setIsShowDeleteConfirmDialog(true)
  }
  const hideConfirmDelete = () => {
    setSelectedProjectPage(null)
    setIsShowDeleteConfirmDialog(false)
  }
  const deletePage = (projectPage) => () => {
    deleteProjectPage({
      variables: {
        id: projectPage.id,
      },
    }).then(() => {
      hideConfirmDelete()
      refetch()
    }).catch(() => {})
  }
  return (
    <>
      <ControlAdminBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/admin/dashboard" className="breadcrumbs__link">{t('Admin Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Projects')}
        </li>
      </ControlAdminBreadcrumbs>

      <div className="section-pt80 faq admin-settings">
        <div className="faq__center admin-settings__center center">
          <div className="faq__top admin-settings__top h2">
            {t('Projects')}
          </div>

          <div className="faq__row admin-settings__row">
            <div className="faq__col admin-settings__col flex__row">
              <div className="catalog__sorting admin-settings__sorting">
                <NavLink to="/admin/pages/projects/create" className="button admin-settings__newproject">
                  {t('Create new Project')}
                </NavLink>

                <div className="catalog__search admin-settings__search">
                  <TextField
                    value={keyword}
                    onChange={({ target: { value } }) => { setKeyword(value) }}
                    placeholder={t('Search by title, tenant, uri...')}
                    className="investhub__field catalog__input"
                  />

                  <span className="catalog__result admin-settings__result">
                    <Icon
                      name="searchh"
                      className={classNames('searchh')}
                      size="16"
                    />
                  </span>
                </div>
              </div>

              <div className="investhub-table__wrapper">
                <div className="investhub-table__box investhub-table__box-styled">
                  <div className="investhub-table__row">
                    <div className="investhub-table__col">{t('ID')}</div>
                    <div className="investhub-table__col">{t('Title')}</div>
                    <div className="investhub-table__col">{t('URI')}</div>
                    <div className="investhub-table__col">{t('Tenant')}</div>
                    <div className="investhub-table__col">{t('Status')}</div>
                    <div className="investhub-table__col">{t('Action')}</div>
                  </div>

                  {loading && (
                    <div className="investhub-table__row investhub-table__row-empty">
                      <div className="investhub-table__col">
                        <CircularProgress />
                      </div>
                    </div>
                  )}

                  {!loading && allPages.length > 0 && allPages.map((projectPage) => (
                    <div className="investhub-table__row" key={projectPage.id}>
                      <div className="investhub-table__col">
                        <div className="investhub-table__label">{t('ID')}</div>
                        {projectPage.id}
                      </div>
                      <div className="investhub-table__col">
                        <div className="investhub-table__label">{t('Title')}</div>
                        {projectPage.title}
                      </div>
                      <div className="investhub-table__col">
                        <div className="investhub-table__label">{t('URI')}</div>
                        <NavLink to={`/projects/${projectPage.uri}`} target="_blank" className="investhub-table__link">
                          {projectPage.uri}
                        </NavLink>
                      </div>
                      <div className="investhub-table__col">
                        <div className="investhub-table__label">{t('Tenant')}</div>
                        {projectPage.tenant}
                      </div>
                      <div className="investhub-table__col">
                        <div className="investhub-table__label">{t('Status')}</div>
                        {t(projectPage.status)}
                      </div>
                      <div className="investhub-table__col">
                        <div className="investhub-table__label">{t('Action')}</div>
                        <div className="investhub-table__btns">
                          <NavLink
                            to={`/admin/pages/projects/${projectPage.id}/edit`}
                            className="button-stroke button-small investhub-table__btn"
                          >
                            {t('Edit')}
                          </NavLink>

                          <button
                            type="button"
                            className="button error button-small"
                            onClick={showConfirmDelete(projectPage)}
                          >
                            {t('Delete')}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="investhub-table__pagination">
                  <TablePagination
                    colSpan={6}
                    rowsPerPageOptions={[5, 10, 20]}
                    count={pageCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={changePage}
                    onRowsPerPageChange={changeRowsPerPage}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                    component="div"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="site-main site-main__dashboard">

        <div className="site-main__dashboard-content">
          <Dialog
            open={isShowDeleteConfirmDialog}
            onClose={hideConfirmDelete}
            className="investhub-modal investhub-modal__m"
          >
            <ModalInnerMaterialDialog
              modalTitle="Delete Page?"
              modalOnClickClose={hideConfirmDelete}
            >
              <div className="upload__item">
                {selectedProjectPage && (
                  <div className="field">
                    <div className="variants__info">
                      {t('Do you really want to delete page')}
                      {' '}
                      {selectedProjectPage.title}
                      ?
                    </div>
                  </div>
                )}
              </div>

              <div className="upload__foot details__foot">
                <div className="details__btns details__btns_clear">
                  {deleteProjectPageStatus.loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={hideConfirmDelete}
                        className="button-stroke button-small"
                      >
                        {t('Cancel')}
                      </button>
                      <button
                        className="button-pink button-small"
                        onClick={deletePage(selectedProjectPage)}
                        type="button"
                      >
                        {t('Delete')}
                      </button>
                    </>
                  )}
                </div>
              </div>

            </ModalInnerMaterialDialog>
          </Dialog>
        </div>
      </main>

    </>
  )
}

export default AdminPage
