import React from 'react'
import { useHistory} from 'react-router-dom'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Transactions, TransactionsSkeleton } from "../../../../../../shared/Cards"

const TransactionList = ({ loading, transactions, getImageUrl, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const handleUpgradeClick = (onClose) => {
    history.push('/my-profile?tab=upgrade')
    if (onClose) onClose()
  }

  return (
    <>
      {loading ? (
        <div className='gap-4 grid grid-cols-1 w-full'>
          {Array(3).fill(null).map((_, index) => (
            <TransactionsSkeleton key={`transaction-skeleton-${index}`} />
          ))}
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="gap-4 grid grid-cols-1 w-full"
        >
          <div className="mb-4">
            <button
              type="button"
              className="inline-flex justify-center items-center gap-2 border-[2px] border-white/20 bg-transparent hover:opacity-90 m-0 px-4 border-solid rounded-[40px] w-full h-10 font-medium font-sans text-[100%] text-white hover:text-white normal-case leading-none tracking-[-0.02em] transition-colors duration-[0.2s] overflow-visible"
              onClick={() => handleUpgradeClick(onClose)}
            >
              {t('common.upgrade')}
            </button>
          </div>
          {transactions.map((tx, index) => (
            <Transactions
              key={`transaction-${index}`}
              type={tx.type}
              amount={tx.amount}
              value={tx.value}
              to={tx.to}
              from={tx.from}
              contract={tx.contract}
              imgSrc={getImageUrl('tokenSymbol', tx.image)}
              networkIcon={getImageUrl('tokenSymbol', tx.network.image)}
              timestamp={tx.timestamp}
              status={tx.status}
              tag={tx.tag}
              chainIndex={tx.chainIndex}
              txHash={tx.txHash}
              txStatus={tx.txStatus}
              datetime={tx.datetime}
            />
          ))}
        </motion.div>
      )}
    </>
  )
}

export default TransactionList
