import { useMutation, gql } from '@apollo/client';
import { startRegistration, startAuthentication } from '@simplewebauthn/browser';

const GENERATE_PASSKEY_OPTIONS_FOR_EXISTING_USER = gql`
  mutation GeneratePasskeyOptionsForExistingUser($input: PasskeyForExistingUserInput!) {
    generatePasskeyOptionsForExistingUser(input: $input) {
      challenge
      rp
      user
      pubKeyCredParams
      timeout
      attestation
      authenticatorSelection
    }
  }
`;

const GENERATE_REGISTRATION_OPTIONS = gql`
  mutation GenerateRegistrationOptions($input: PasskeyRegistrationInput!) {
    generatePasskeyRegistrationOptions(input: $input) {
      challenge
      rp
      user
      pubKeyCredParams
      timeout
      attestation
      authenticatorSelection
    }
  }
`;

const VERIFY_REGISTRATION = gql`
  mutation VerifyRegistration($credential: JSON!, $userId: Int!) {
    verifyPasskeyRegistration(credential: $credential, userId: $userId) {
      verified
      user {
        id
        email
        wallet_address
      }
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

const GENERATE_AUTH_OPTIONS = gql`
  mutation GenerateAuthenticationOptions {
    generatePasskeyAuthenticationOptions {
      challenge
      timeout
      rpId
      allowCredentials
      userVerification
      challengeId
    }
  }
`;

const VERIFY_AUTH = gql`
  mutation VerifyAuthentication($credential: JSON!, $challengeId: String!) {
    verifyPasskeyAuthentication(credential: $credential, challengeId: $challengeId) {
      verified
      user {
        id
        email
        wallet_address
      }
      token {
        accessToken
        refreshToken
      }
    }
  }
`;

export function usePasskey() {
  const [generateRegistrationOptions] = useMutation(GENERATE_REGISTRATION_OPTIONS);
  const [verifyRegistration] = useMutation(VERIFY_REGISTRATION);
  const [generateAuthOptions] = useMutation(GENERATE_AUTH_OPTIONS);
  const [generatePasskeyOptionsForExistingUser] = useMutation(GENERATE_PASSKEY_OPTIONS_FOR_EXISTING_USER);
  const [verifyAuth] = useMutation(VERIFY_AUTH);

  const registerPasskey = async (email) => {
    try {
      // 1. Get registration options from server
      const { data } = await generateRegistrationOptions({
        variables: {
          input: { email }
        }
      });
      const options = data.generatePasskeyRegistrationOptions;

      // 2. Start registration process in browser
      const credential = await startRegistration(options);

      // 3. Verify registration with server
      const { data: verificationData } = await verifyRegistration({
        variables: { credential, userId: options.user.id }
      });

      return verificationData.verifyPasskeyRegistration;
    } catch (error) {
      console.error('Passkey registration error:', error);
      throw error;
    }
  };

  const registerPasskeyForExistingUser = async (email) => {
    try {
      // 1. Get registration options from server
      const { data } = await generatePasskeyOptionsForExistingUser({
        variables: {
          input: { email }
        }
      });
      const options = data.generatePasskeyOptionsForExistingUser;

      // 2. Start registration process in browser
      const credential = await startRegistration(options);

      // 3. Verify registration with server
      const { data: verificationData } = await verifyRegistration({
        variables: { credential, userId: options.user.id }
      });

      return verificationData.verifyPasskeyRegistration;
    } catch (error) {
      console.error('Passkey registration error:', error);
      throw error;
    }
  };

  const authenticateWithPasskey = async () => {
    try {
      // 1. Get authentication options from server
      const { data } = await generateAuthOptions();
      const options = data.generatePasskeyAuthenticationOptions;

      // 2. Start authentication process in browser
      const credential = await startAuthentication(options);

      // 3. Verify authentication with server
      const { data: verificationData } = await verifyAuth({
        variables: { credential, challengeId: options.challengeId }
      });

      return verificationData.verifyPasskeyAuthentication;
    } catch (error) {
      console.error('Passkey authentication error:', error);
      throw error;
    }
  };

  return {
    registerPasskey,
    registerPasskeyForExistingUser,
    authenticateWithPasskey
  };
}

export default usePasskey;
