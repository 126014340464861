import React from 'react'

import GuestAppBar from '../components/common/GuestAppBar'
import Footer from '../components/common/Footer'
import SignUpConfirmCheck from '../components/SignUpConfirmCheck'

const SignUpConfirm = ({ history }) => (
  <div className="page page-sign-up">
    <GuestAppBar />

    <div className="page-sign-up-body page-body">
      <SignUpConfirmCheck
        history={history}
      />
    </div>

    <Footer />
  </div>
)

export default SignUpConfirm
