// Create this compoenent for requirement for TOK-1151
// This will replace for TenantLogTable.jsx

import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  GET_TENANT_LOG,
  GET_ADMIN_TENANT_LOG,
} from '../../queriesAndMutations'
import { formatDate } from '../../utils'
import TenantStatusChip from '../common/TenantStatusChip'
import CustomLoader from '../Loader'

const TenantLogItem = ({
  tenantId,
  isAdmin,
}) => {
  const { t } = useTranslation()
  const {
    data: {
      [isAdmin ? 'getAdminTenantLog' : 'getTenantLog']: data,
    } = {},
    loading,
    error,
  } = useQuery(isAdmin ? GET_ADMIN_TENANT_LOG : GET_TENANT_LOG, {
    variables: {
      id: tenantId,
    },
  })
  const renderLogMessage = (log) => {
    switch (log.changeType) {
      case 'CREATED':
        return (
          <>
            {t('Register tenant with domain')}
            {' '}
            {log.data.domain}
          </>
        )
      case 'CHANGE_STATUS':
        return (
          <>
            {t('Tenant status was changed from')}
            {' '}
            <TenantStatusChip status={log.data.oldStatus || t('Unknown')} />
            {' '}
            {t('to')}
            {' '}
            <TenantStatusChip status={log.data.newStatus || t('Unknown')} />
          </>
        )
      case 'CHANGE_PLAN':
        return (
          <>
            {t('Tenant plan was changed from')}
            {' '}
            <TenantStatusChip status={log.data.oldPlan || t('Unknown')} />
            {' '}
            {t('to')}
            {' '}
            <TenantStatusChip status={log.data.newPlan || t('Unknown')} />
          </>
        )
      default:
        return (
          <>
            <strong>
              {log.changeType}
              :
            </strong>
            {' '}
            {JSON.stringify(log.data)}
          </>
        )
    }
  }

  return (
    <>
      {loading && (
        <div className="activity__item change-log__item">
          <CustomLoader />
        </div>
      )}

      {error && (
        <div className="activity__item change-log__item">
          <div className="activity__details">
            <div className="activity__subtitle">
              {t('Error')}
            </div>
            <div className="activity__description">
              <em>{t('Cannot load data')}</em>
            </div>
          </div>
        </div>
      )}

      {data && data.map((log) => (
        <div className="activity__item change-log__item" key={log.id}>
          <div className="activity__details change-log__details">
            <div className="activity__description change-log__title">
              <div className="field__label mt__8 mb__4">{t('Action')}</div>
              {renderLogMessage(log)}
            </div>
            <div className="activity__date change-log__date">
              <div className="field__label mt__8 mb__4">{t('Timestamp')}</div>
              {formatDate(+log.createdAt)}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

TenantLogItem.propTypes = {
  tenantId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
}

export default TenantLogItem
