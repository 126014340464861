import React from 'react'
import cn from 'classnames'
import Icons from '../../../../../../shared/Icons'

const CurrencyTab = ({ tokens, selectedCurrency, onSelect }) => (
  <div>
    {tokens.map((token) => (
      <button
        type="button"
        key={token.symbol}
        onClick={() => onSelect(token)}
        className={cn(
          "flex justify-between items-center bg-transparent px-0 py-3 rounded-xl w-full font-medium text-sm transition-colors font-sans duration-200",
          {
            "text-white": selectedCurrency.name === token.name,
            "text-gray-lighter hover:text-white": selectedCurrency.name !== token.name
          }
        )}
      >
        {token.symbol}
        {selectedCurrency.name === token.name && (
          <Icons nameIcon="rosette-discount-check" className="w-4 h-4 text-gray-custom" />
        )}
      </button>
    ))}
  </div>
)

export default CurrencyTab
