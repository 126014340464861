// src/components/layouts/Dashboard/Header/ClientQuestionnaire/components/QuestionnaireModal/index.jsx

import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import Icons from '../../../../../../shared/Icons'

// Components
import Welcome from '../steps/Welcome'
import MifidRiskAssessment from '../steps/MifidRiskAssessment'
import AtlasIncome from '../steps/AtlasIncome'
import Experience from '../steps/Experience'
import VirtualCurrency from '../steps/VirtualCurrency'
import TransactionsVirtual from '../TransactionsVirtual'
import InvestmentFrequency from '../steps/InvestmentFrequency'
import RoleOccupation from '../steps/RoleOccupation'
import RelevantStudies from '../steps/RelevantStudies'
import BothExperience from '../steps/BothExperience'
import AverageInvestment from '../steps/AverageInvestment'
import AssetExperience from '../steps/AssetExperience'
import BusinessHistory from '../steps/BusinessHistory'
import OrganizationName from '../steps/OrganizationName'
import PricingTables from '../steps/PricingTables'
import Review from '../steps/Review'
import Complete from '../steps/Complete'

const QuestionnaireModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState('welcome');
  const [formData, setFormData] = useState({});
  const [userCountry, setUserCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [stepHistory, setStepHistory] = useState(['welcome']);

  useEffect(() => {
    const detectCountry = async () => {
      try {
        if (process.env.REACT_APP_MOCK_COUNTRY) {
          setUserCountry(process.env.REACT_APP_MOCK_COUNTRY);
          setIsLoading(false);
          return;
        }

        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();

        if (data.error) {
          throw new Error(data.reason || 'Failed to detect country');
        }

        setUserCountry(data.country_code);
      } catch (err) {
        console.error('Error:', err);
        setUserCountry('US');
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      detectCountry();
    }
  }, [isOpen]);

  const getStepSequence = useCallback(() => {
    const baseSequence = {
      welcome: {
        next: (data) => {
          if (data.purposeType === 'purpose-experience') {
            return 'virtual-currency';
          }
          return 'experience';
        },
        back: null
      },
      experience: {
        next: (data) => {
          switch (data.experienceType) {
            case 'experience-one':
              return 'role-occupation';
            case 'experience-two':
              return 'relevant-studies';
            case 'experience-three':
              return 'both-experience';
            default:
              return 'virtual-currency';
          }
        },
        back: 'welcome'
      },
      'role-occupation': {
        next: () => 'virtual-currency',
        back: 'experience'
      },
      'relevant-studies': {
        next: () => 'virtual-currency',
        back: 'experience'
      },
      'both-experience': {
        next: () => 'virtual-currency',
        back: 'experience'
      },
      'virtual-currency': {
        next: (data) => {
          return data.usesVirtualCurrency
            ? 'transactions-virtual'
            : 'investment-frequency'
        },
        back: (formData) => {
          if (formData.experience) {
            const expType = formData.experience.experienceType;
            if (['experience-one', 'experience-two', 'experience-three'].includes(expType)) {
              return 'experience';
            }
          }
          return formData.welcome && formData.welcome.purposeType === 'purpose-experience'
            ? 'welcome'
            : 'experience';
        }
      },
      'transactions-virtual': {
        next: 'average-investment',
        back: 'virtual-currency'
      },
      'investment-frequency': {
        next: 'average-investment',
        back: 'virtual-currency'
      },
      'average-investment': {
        next: 'asset-experience',
        back: (formData) => {
          return formData.virtualCurrency && formData.virtualCurrency.usesVirtualCurrency
            ? 'transactions-virtual'
            : 'investment-frequency';
        }
      },
      'asset-experience': {
        next: 'business-history',
        back: 'average-investment'
      },
      'business-history': {
        next: (data) => {
          return data.businessHistory.includes('none')
            ? 'pricing-tables'
            : 'organization-name'
        },
        back: 'asset-experience'
      },
      'organization-name': {
        next: 'pricing-tables',
        back: 'business-history'
      },
      'pricing-tables': {
        next: 'review',
        back: (formData) => {
          return formData.businessHistory && formData.businessHistory.businessHistory.includes('none')
            ? 'business-history'
            : 'organization-name';
        }
      },
      review: {
        next: 'complete',
        back: 'pricing-tables'
      },
      complete: {
        next: null,
        back: null
      }
    };

    switch (userCountry) {
      case 'DE':
        return {
          welcome: {
            next: () => 'mifid-risk',
            back: null
          },
          'mifid-risk': {
            next: 'financial-knowledge',
            back: 'welcome'
          },
          'financial-knowledge': {
            next: 'investment-horizon',
            back: 'mifid-risk'
          },
          'investment-horizon': {
            next: 'loss-tolerance',
            back: 'financial-knowledge'
          },
          'loss-tolerance': {
            next: 'portfolio-size',
            back: 'investment-horizon'
          },
          'portfolio-size': {
            next: 'pricing-tables',
            back: 'loss-tolerance'
          },
          'pricing-tables': {
            next: 'review',
            back: 'portfolio-size'
          },
          review: {
            next: 'complete',
            back: 'pricing-tables'
          },
          complete: {
            next: null,
            back: null
          }
        };

      case 'CA':
        return {
          welcome: {
            next: () => 'atlas-income',
            back: null
          },
          'atlas-income': {
            next: 'net-worth',
            back: 'welcome'
          },
          'net-worth': {
            next: 'investment-qualification',
            back: 'atlas-income'
          },
          'investment-qualification': {
            next: 'professional-background',
            back: 'net-worth'
          },
          'professional-background': {
            next: 'investment-history',
            back: 'investment-qualification'
          },
          'investment-history': {
            next: 'pricing-tables',
            back: 'professional-background'
          },
          'pricing-tables': {
            next: 'review',
            back: 'investment-history'
          },
          review: {
            next: 'complete',
            back: 'pricing-tables'
          },
          complete: {
            next: null,
            back: null
          }
        };

      default:
        return baseSequence;
    }
  }, [userCountry]);

  const stepComponents = {
    'welcome': Welcome,
    'mifid-risk': MifidRiskAssessment,
    'atlas-income': AtlasIncome,
    'experience': Experience,
    'virtual-currency': VirtualCurrency,
    'transactions-virtual': TransactionsVirtual,
    'investment-frequency': InvestmentFrequency,
    'role-occupation': RoleOccupation,
    'relevant-studies': RelevantStudies,
    'both-experience': BothExperience,
    'average-investment': AverageInvestment,
    'asset-experience': AssetExperience,
    'business-history': BusinessHistory,
    'organization-name': OrganizationName,
    'pricing-tables': PricingTables,
    'review': Review,
    'complete': Complete
  }

  const handleNext = (stepData) => {
    setFormData(prev => ({
      ...prev,
      [currentStep]: stepData
    }));

    const sequence = getStepSequence();
    const nextStep = typeof sequence[currentStep].next === 'function'
      ? sequence[currentStep].next(stepData)
      : sequence[currentStep].next;

    if (nextStep) {
      setStepHistory(prev => [...prev, nextStep]);
      setCurrentStep(nextStep);
    }
  };

  const handleBack = () => {
    const sequence = getStepSequence();
    if (stepHistory.length > 1) {
      const previousStep = typeof sequence[currentStep].back === 'function'
        ? sequence[currentStep].back(formData)
        : sequence[currentStep].back || stepHistory[stepHistory.length - 2];

      const newHistory = [...stepHistory];
      newHistory.pop();
      setStepHistory(newHistory);
      setCurrentStep(previousStep);
    }
  };

  const getProgress = () => {
    const sequence = Object.keys(getStepSequence());
    const totalSteps = sequence.length;
    const currentIndex = stepHistory.length - 1;
    return Math.round((currentIndex / (totalSteps - 1)) * 100);
  };

  const CurrentStepComponent = stepComponents[currentStep]

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  if (isLoading) {
    return null
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="top-0 left-0 z-[999] fixed flex items-center bg-black-dark m-0 p-0 w-full h-full min-h-svh overflow-auto align-baseline"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {currentStep !== 'complete' && (
            <button
              className="inline-flex top-5 right-6 z-[2] absolute justify-center items-center bg-transparent shadow-[inset_0_0_0_2px_#fff] hover:shadow-[inset_0_0_0_2px_#6F737E] rounded-full w-8 h-8 text-white hover:text-gray-lighter transition-all duration-200 cursor-pointer"
              onClick={onClose}
            >
              <Icons
                nameIcon="close"
                className="w-4 h-4"
              />
            </button>
          )}

          <motion.div
            className={`z-10 mx-auto h-full align-baseline shadow-none flex flex-col flex-nowrap justify-start items-start ${
              currentStep === 'pricing-tables' ? 'max-w-full' : 'max-w-[536px]'
            }`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {CurrentStepComponent && (
              <CurrentStepComponent
                onNext={currentStep === 'complete' ? onClose : handleNext}
                onBack={handleBack}
                formData={formData}
                progress={getProgress()}
                currentStep={stepHistory.length}
                totalSteps={getStepSequence().length}
                countryCode={userCountry}
              />
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default QuestionnaireModal
