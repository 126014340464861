// src/components/layouts/Wrapper/Dashboard/Header/index.jsx

import React from "react"
import { Link } from "react-router-dom"
// Shared
import { UseIsMax767, UseIsMax480 } from "../../../shared/MediaQueries"
// Inner
import Search from "./Search"
// import GlobalPreferences from "./GlobalPreferences"
import AccountWallet from "./AccountWallet"
// import Notification from "./Notification"
import ClientQuestionnaire from "./ClientQuestionnaire"
import { INVESTHUB_IMAGES } from "../../../../constant/images"

const Header = ({
  pageTitle,
  isHamburgerToggle,
  toggleHamburger,
  toggleAccountWallet,
  isOpenAccountWallet
}) => {
  const isMobileSmall = UseIsMax480()
  const isMobile = UseIsMax767()

  return (
    <div className={`mx-auto w-full ${isMobile ? 'px-6 max-480:px-4' : 'pt-2 pb-10'} `}>
      <div className={`flex justify-between items-center mx-auto  ${isMobile ? 'bg-black-light rounded-3xl px-4 h-16' : 'px-10 md:px-4 lg:px-6 h-24 md:h-16'}`}>
        {!isMobile ? (
          <>
            {pageTitle && (
            <div className="relative border-0 m-0 p-0 font-sans font-semibold text-[32px] text-left text-white max-767:text-[2rem] leading-[1.2] tracking-[-0.02em] motion-reduce:transition-none motion-reduce:duration-[0s] align-baseline">
              {pageTitle}
            </div>
            )}
          </>
        ) : (
          <Link
            to="/"
            className="rounded-2xl w-10 h-10 overflow-hidden"
          >
            <img
              src={INVESTHUB_IMAGES.logoCircle}
              alt="Investhub"
              className="w-full h-full object-cover"
            />
          </Link>
        )}
        <div className="flex-1" />

        <div className="inline-flex flex-row flex-wrap justify-end items-center max-767:gap-[2px] pt-1">
          {!isMobileSmall ? (
            <>
              <Search />
              <div className="max-767:hidden w-2" />
              {/* <Notification /> */}
              {/* <GlobalPreferences /> */}
              <ClientQuestionnaire />
            </>
          ) : null}
          <AccountWallet toggleAccountWallet={toggleAccountWallet} isOpenAccountWallet={isOpenAccountWallet} />
        </div>

        {isMobile && (
          <>
            <div
              className="inline-flex relative z-10 flex-col justify-center items-center mt-1 ml-2 w-8 h-8 cursor-pointer"
              onClick={toggleHamburger}
              data-hamburger-toggle
            >
              <span
                className={`bg-white my-0.5 rounded-full w-5 h-0.5 transition-all ${isHamburgerToggle ? 'translate-y-[0.1875rem] rotate-45' : ''}`}
              />
              <span
                className={`bg-white my-0.5 rounded-full w-5 h-0.5 transition-all ${isHamburgerToggle ? '-translate-y-[0.1875rem] -rotate-45' : ''}`}
              />
            </div>
          </>
        )}
      </div>

      {isMobile && pageTitle && (
        <div className="flex justify-start items-center mt-6 font-semibold text-2xl text-white tracking-[-0.02em]">
          {pageTitle}
        </div>
      )}
    </div>
  )
}

export default Header
