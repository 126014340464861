import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { toaster } from '../../utils'
import Loader from '../../components/shared/Loader'
import { useMe } from '../../myHooks'
import { UPDATE_USER } from '../../queriesAndMutations/authMutations'
import Icons from '../../components/shared/Icons'

const SectionBasicSkeleton = () => (
  <>
    <div className="m-0 mb-8 font-sans font-semibold text-start text-white text-xl leading-7 tracking-[-0.02em]">
      <Skeleton width={240} height={16} className="bg-gray-lighter" />
    </div>

    <div className="gap-5 max-767:gap-6 grid grid-cols-[1fr_1fr] max-767:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mb-6">
      <Skeleton width={100} height={24} className="bg-gray-lighter !w-full" />

      <Skeleton width={100} height={24} className="bg-gray-lighter !w-full" />
    </div>
  </>
)

const SectionBasic = () => {
  const { t } = useTranslation()

  const { data: { me } = {}, loading } = useMe({ fetchPolicy: 'network-only' })
  const [name, setName] = useState('')
  const [isFocusedFullname, setIsFocusedFullname] = useState(false)
  // const [isFocusedEmail, setIsFocusedEmail] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (me && me.fullname) {
      setName(me.fullname)
    }
  }, [me])

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['me']
  })

  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsSubmitting(true)
      await updateUser({
        variables: {
          input: {
            fullname: name
          }
        }
      })
      toaster.success('Profile updated successfully')
    } catch (error) {
      console.error('Error:', error)
      toaster.error(error.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  if (loading) return <SectionBasicSkeleton />

  return (
    <>
      <div className="m-0 mb-8 font-sans font-semibold text-2xl text-start text-white leading-7 tracking-[-0.02em]">
        {t('settings.basic.profile_information')}
      </div>

      <form onSubmit={onSubmit}>
        <div className="gap-5 max-767:gap-6 grid grid-cols-[1fr_1fr] max-767:grid-cols-[1fr] grid-rows-[auto] auto-cols-[1fr] mb-6">
          <div className="relative">
            <label className={`flex font-sans font-semibold flex-row flex-wrap gap-1 mb-3 text-[13px] leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s] ${(name || isFocusedFullname) ? 'text-white' : 'text-gray-lighter'}`}>
              {t('settings.fields.fullname')}

              <span className="relative top-[1px] cursor-pointer" data-tooltip-id="tooltip_fullname">
                <Icons nameIcon='alert-circle' className="w-[14px] h-[14px]" />
              </span>

              <Tooltip
                id="tooltip_fullname"
                auto
                effect="solid"
                className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-bold font-sans !text-white !text-xs"
              >
                {t('settings.fields.fullname_tooltip')}
              </Tooltip>
            </label>
            <div className="relative">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`w-full font-sans text-base leading-6 font-normal min-w-0 text-white  text-start !p-4 bg-black-dark transition-all duration-[0.2s] ease-linear rounded-xl outline-none border-2 border-solid ${(name || isFocusedFullname) ? 'border-white/10 opacity-100' : 'border-transparent opacity-90'
                }`}
                onFocus={() => setIsFocusedFullname(true)}
                onBlur={() => {
                  if (!name) {
                    setIsFocusedFullname(false)
                  }
                }}
              />
            </div>
          </div>

          <div className="relative">
            <label className="flex flex-row flex-wrap gap-1 mb-3 font-sans font-semibold text-[13px] text-white leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s]">
              {t('settings.fields.wallet_address')}

              <span className="relative top-[1px] ml-1 cursor-pointer" data-tooltip-id="tooltip_wallet_address">
                <Icons nameIcon='alert-circle' className="w-[14px] h-[14px]" />
              </span>

              <Tooltip id="tooltip_wallet_address" auto effect="solid" className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-bold font-sans !text-white !text-xs">
                {t('settings.fields.wallet_address_tooltip')}
              </Tooltip>
            </label>
            <div className="relative">
              <input
                type="email"
                value={me && me.wallet_address}
                disabled
                className="border-2 border-white/10 bg-black-dark opacity-100 !p-4 border-solid rounded-xl w-full min-w-0 font-normal font-sans text-base text-start text-white leading-6 transition-all duration-[0.2s] ease-linear outline-none"
              />
            </div>
          </div>

          {me && me.email && (
            <div className="relative">
              <label className="flex flex-row flex-wrap gap-1 mb-3 font-sans font-semibold text-[13px] text-white leading-[1.2] tracking-[-0.02em] transition-colors duration-[0.2s]">
                {t('settings.fields.email')}

                <span className="relative top-[1px] ml-1 cursor-pointer" data-tooltip-id="tooltip_email">
                  <Icons nameIcon='alert-circle' className="w-[14px] h-[14px]" />
                </span>

                <Tooltip id="tooltip_email" auto effect="solid" className="!inline-flex !justify-center !items-center !bg-black-light !px-2 !py-[2px] !rounded-md !font-bold font-sans !text-white !text-xs">
                  {t('settings.fields.email_tooltip')}
                </Tooltip>
              </label>
              <div className="relative">
                <input
                  type="email"
                  value={me && me.email}
                  disabled
                  className="border-2 border-white/10 bg-black-dark opacity-100 !p-4 border-solid rounded-xl w-full min-w-0 font-normal font-sans text-base text-start text-white leading-6 transition-all duration-[0.2s] ease-linear outline-none"
                />
              </div>
            </div>
          )}
        </div>

        <div className="text-left">
          <button
            type="submit"
            className="inline-flex justify-center items-center gap-4 bg-primary hover:bg-[#0148dd] hover:opacity-90 m-0 px-6 !rounded-3xl h-12 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
          >
            {t('Save')}
            {isSubmitting ? <Loader className='scale-75' /> : '' }
          </button>
        </div>
      </form>
    </>
  )
}

export default SectionBasic
