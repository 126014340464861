import React, { useState, createRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { generateUuid } from '../../utils'

import {
  getOriginalFileExtension,
  getFileUrl,
} from '../../utils/file'
import Icon from '../Icon'

const useStyles = makeStyles({
  responsiveImg: {
    maxWidth: '100%',
  },
  dropZone: {
    backgroundColor: '#b6d3ff',
    border: '1px solid #63a1ff',
    borderRadius: '5px',
    minHeight: '95px',
  },
  disableUploadBtn: {
    pointerEvents: 'none',
    fontWeight: 'bold',
  },
})

const UploadPreview = ({
  file,
  ...rest
}) => {
  const classes = useStyles()

  if (getOriginalFileExtension(file.storageKey) === 'pdf' || (file.file && file.file.type === 'application/pdf')) {
    return (
      <embed
        src={file.storageKey ? getFileUrl(file.storageKey) : file.dataUrl}
        height="375"
        type="application/pdf"
        {...rest}
      />
    )
  }

  return (
    <img
      className={classes.responsiveImg}
      alt={file.storageKey}
      src={file.storageKey ? getFileUrl(file.storageKey) : file.dataUrl}
      {...rest}
    />
  )
}

UploadPreview.propTypes = {
  file: PropTypes.object.isRequired,
}

const Upload = ({
  state,
  setState,
  label,
  multiple,
  preview,
  accept,
  maxSize,
  fieldFormat,
}) => {
  const { t } = useTranslation()
  const [error, setError] = useState({
    notAcceptedFileSize: [],
    notAcceptedFileType: [],
  })
  const [isDragOver, setIsDragOver] = useState(false)
  const hasExtension = (fileName) => {
    if (!accept || accept === '*/*' || accept === '*') {
      return true
    }

    const pattern = '(\\.jpg|\\.jpeg|\\.gif|\\.png|\\.pdf)$'

    return new RegExp(pattern, 'i').test(fileName)
  }
  const readFile = (file) => (
    new Promise((resolve) => {
      const reader = new FileReader()

      // Read the image via FileReader API and save image result in state.
      reader.onload = (e) => {
        // Add the file name to the data URL
        let dataUrl = e.target.result
        dataUrl = dataUrl.replace(';base64', `;name=${file.name};base64`)
        resolve({ file, dataUrl })
      }

      reader.readAsDataURL(file)
    })
  )
  const selectFile = (event) => {
    setError({
      notAcceptedFileSize: [],
      notAcceptedFileType: [],
    })
    const rawFiles = event.target.files || (event.dataTransfer ? event.dataTransfer.files : null)

    const fileReaders = Array.from(rawFiles).map((file) => {
      if (maxSize && file.size > maxSize) {
        setError({
          ...error,
          notAcceptedFileSize: [
            ...error.notAcceptedFileSize,
            file.name,
          ],
        })
      }
      if (hasExtension(file.name)) {
        setError({
          ...error,
          notAcceptedFileType: [
            ...error.notAcceptedFileType,
            file.name,
          ],
        })
      }
      return readFile(file)
    })

    Promise.all(fileReaders).then((files) => {
      if (multiple) {
        setState([
          ...state,
          ...files.map((file) => ({
            ...file,
            id: generateUuid(),
          })),
        ])
      } else {
        setState({
          ...files[0],
          id: generateUuid(),
        })
      }
    })
  }
  const uploadInput = createRef()
  const openSelectFileDialog = () => {
    uploadInput.current.click()
  }
  const resetUploadField = (e) => {
    e.target.value = null
  }
  const removeSelectedFile = (index) => () => {
    if (multiple) {
      setState(state.filter((file, id) => id !== index))
    } else {
      setState(null)
    }
  }

  return (
    <div className="component-upload field-upload__component">
      {label && (
        <p><strong>{label}</strong></p>
      )}

      { state && (multiple ? state : [state]).map((file, index) => (
        <div className="preview js-preview" key={file.id}>
          <div className="preview__inner">
            <div className="card field-upload__card">
              { preview && (
                <div className="card__preview field-upload__preview">
                  <UploadPreview
                    file={file}
                  />
                </div>
              )}

              <div className="card__link field-upload__link">
                <div className="card__body">
                  <div className="card__line">
                    <div className="card__title">
                      {file.storageKey || ((file || {}).file || {}).name}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              className="preview__clear preview__clear-red"
              onClick={removeSelectedFile(index)}
            >
              <Icon
                name="circle-close"
                className={classNames('searcircle-closechh')}
                size="20"
              />
              {t('Remove')}
            </button>
          </div>
        </div>
      ))}
      { (multiple || !state) && (
        <div className="field-upload__inner">
          <input
            type="file"
            ref={uploadInput}
            multiple={multiple}
            onChange={selectFile}
            onClick={resetUploadField}
            accept={accept}
            style={{ display: 'none' }}
          />
          <div
            className="field-upload__dragzone"
            onDragOver={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setIsDragOver(true)
            }}
            onDragLeave={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setIsDragOver(false)
            }}
            onDrop={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setIsDragOver(false)
              selectFile(e)
            }}
          >
            <button
              type="button"
              onClick={openSelectFileDialog}
              className={`field-upload__button ${isDragOver ? 'field-upload__button-disabled' : 'field-upload__button-contained'}`}

            >
              {isDragOver
                ? <Icon name="more" className={classNames('more')} size="20" />
                : <Icon name="upload-file" className={classNames('upload-file')} size="20" />}

              {fieldFormat && (
                <div className="field-upload__format">{fieldFormat}</div>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

Upload.defaultProps = {
  multiple: false,
  accept: '*',
  preview: false,
}

Upload.propTypes = {
  state: PropTypes.oneOfType([PropTypes.array.isRequired, PropTypes.object.isRequired]),
  setState: PropTypes.func.isRequired,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  preview: PropTypes.bool,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  fieldFormat: PropTypes.string,
}

export default Upload
