import { useEffect } from 'react'

export const useKeyPress = (key, handler) => {
  useEffect(() => {
    if (typeof handler !== 'function') return () => {}

    const handleKeyPress = (event) => {
      if (event.key === key) {
        handler()
      }
    }

    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [key, handler])
}

export default useKeyPress
