// src/pages/Signup/components/SignupMethod/Passkey/PasskeyCreation.jsx

import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
// import Layout from "../Layout"
import Icons from "../../../../components/shared/Icons"
import Loader from "../../../../components/shared/Loader"
import { usePasskey } from "../../../../myHooks/usePasskey"
import EmailField from '../../../../components/shared/Fields/EmailField'
import { useCookies } from 'react-cookie'
import config from '../../../../config'
// import { SIGNUP_STEPS } from "../../constant/steps"

const PasskeyForm = ({ me, onBack, onNext }) => {
  const { t } = useTranslation()
  const [isCreating, setIsCreating] = useState(false)
  const [email, setEmail] = useState(me.email || '');
  const [status, setStatus] = useState('');
  const { registerPasskeyForExistingUser } = usePasskey();
  const [, setCookie] = useCookies()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreating(true)
    try {
      const result = await registerPasskeyForExistingUser(email);
      if (result.verified) {
        onNext(result)
      } else {
        setStatus('Register passkey failed.');
      }
    } catch (error) {
      setStatus(`Error: ${error.message}`);
      setIsCreating(false);
    }
  };

  useEffect(() => {
    setEmail(me.email || '');
  }, [me]);

  return (
    // <Layout
    //   title={t("Create Your Passkey")}
    //   description={t("Follow your browser's prompts to create a passkey")}
    //   currentStep={3}
    //   totalSteps={5}
    //   onBack={onBack}
    //   stepType={SIGNUP_STEPS.PASSKEY}
    // >
      <form onSubmit={handleSubmit}>
        <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
          <div className="pw:bg-black-light pw:mb-8 pw:p-6 pw:rounded-2xl">
            <div className="pw:relative">
              <EmailField
                name="email"
                required
                disabled={!!me.email}
                placeholder={t('Enter your email')}
                icon={
                  <Icons
                    nameIcon="email"
                    className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2"
                  />
                }
                customClassOnField="bg-transparent pl-10 focus:bg-transparent w-full"
                onChange={(e) => {
                  const newEmail = e.target.value
                  setEmail(newEmail)
                }}
                defaultValue={email}
              />
            </div>

            {status && <p className="pw:text-red-500">{status}</p>}
          </div>

          {isCreating && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="pw:mb-8 pw:text-center"
            >
              <div className="pw:relative pw:mb-4 pw:text-center">
                <Loader className=""/>
              </div>
              <p className="pw:mb-2 pw:font-semibold pw:text-white">{t("Setting up your passkey...")}</p>
              <p className="pw:text-gray-lighter pw:text-sm">{t("Please respond to your browser's prompt")}</p>
            </motion.div>
          )}

          {!isCreating && (
            <button
              type="submit"
              className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200"
            >
              {t("Create Passkey")}
            </button>
          )}
        </div>
      </form>
    // </Layout>
  )
}

export default PasskeyForm
