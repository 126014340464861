import React from 'react'

const Separator = () => {
  return (
    <>
      <div className="mt-12 max-767:mt-10" />
      <div className="bg-white/10 w-full h-[1px]" />
      <div className="mb-12 max-767:mb-10" />
    </>
  )
}

export default Separator
