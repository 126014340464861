import { useState, useEffect } from 'react';

const UseIsMax400 = () => {
    const [isMax400, setIsMax400] = useState(window.innerWidth <= 400);

    useEffect(() => {
        const handleResize = () => {
            setIsMax400(window.innerWidth <= 400);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return isMax400;
};

export default UseIsMax400;
