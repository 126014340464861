/* eslint-disable import/no-extraneous-dependencies */
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { useMemo } from 'react';
import { http, createConfig } from 'wagmi';
import { base, baseSepolia, sepolia } from 'wagmi/chains';
import { WC_PROJECT_ID } from '../config';

export function useWagmiConfig() {
  const projectId = WC_PROJECT_ID || '';
  if (!projectId) {
    const providerErrMessage =
      'To connect to all Wallets you need to provide a WC_PROJECT_ID env variable';
    throw new Error(providerErrMessage);
  }

  return useMemo(() => {
    const connectors = connectorsForWallets(
      [
        {
          groupName: 'Recommended Wallet',
          wallets: [coinbaseWallet],
        },
        // {
        //   groupName: 'Other Wallets',
        //   wallets: [rainbowWallet, metaMaskWallet],
        // },
      ],
      {
        appName: 'onchainkit',
        projectId,
      },
    );

    const wagmiConfig = createConfig({
      chains: [base, baseSepolia, sepolia],
      // turn off injected provider discovery
      multiInjectedProviderDiscovery: false,
      connectors,
      ssr: true,
      transports: {
        [base.id]: http(),
        [baseSepolia.id]: http(),
        [sepolia.id]: http(),
      },
    });

    return wagmiConfig;
  }, [projectId]);
}

export default useWagmiConfig;
