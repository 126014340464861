// src/pages/InstantInvestment/index.jsx

import React, { useState, useCallback, useEffect, useRef } from "react";
import cn from "classnames";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_INVESTMENT, UPDATE_INVESTMENT, GET_LAST_INVESTMENT, SINGPASS_CALLBACK, UPDATE_USER } from '../../queriesAndMutations';

import metamaskService from '../../services/metamask'
// Shared
import { DummySingPass } from "../../components/shared/Modals";
import PageTitle from "../../components/layouts/InstantInvestment/PageTitle";
// Inner
import {
  StepBuyAndInvest,
  StepShareHolderRegistration,
  StepShareHolderPersonality,
  StepBeforeInsufficientBalance,
  StepDetailBuyAndInvest,
  StepReviewProcessOrder,
} from "./Steps";
import issuers from "../../services/dummyDataIssuers";
import { toaster, isProduction } from "../../utils";
import { useMe } from '../../myHooks'

import Web3 from 'web3'
import { ethereum, walletClient } from '../../myHooks/useCoinbaseAuth';
// eslint-disable-next-line import/no-unresolved
import { useAppKit, useAppKitAccount, useAppKitProvider } from '@reown/appkit/react'
import { providers, Contract, BigNumber } from 'ethers'
import { useWeb3Provider, COIN_DECIMALS, getCoinAddress, getUniswapPath, verifyPath } from '../../myHooks/useWeb3Provider';
import smartContract from "../../constants/smartContract";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonLoading = () => (
  <div className='relative flex flex-row flex-wrap justify-start items-center gap-1 [&>span]:w-full'>
    <Skeleton width={100} height={20} className="bg-gray-lighter !w-full" />
    <Skeleton width={100} height={20} className="bg-gray-lighter !w-full" />
  </div>
)

const InstantInvestment = ({ setPageTitle }) => {
  useEffect(() => {
    setPageTitle('Get Tokens')
  }, [setPageTitle])

  const [allChecked, setAllChecked] = useState(false);
  const [step, setStep] = useState("buy-and-invest");
  const [buyValue, setBuyValue] = useState(100);
  const [investValue, setInvestValue] = useState(100);
  const [isOpenSingPass, setIsOpenSingPass] = useState(false);
  const [prefillData, setPrefillData] = useState(null);
  const [currentInvestment, setCurrentInvestment] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);

  const issuerId = urlParams.get('issuer') || localStorage.getItem('issuer')
  const defaultIssuer = issuers.find((issuer) => issuer.id === +issuerId);
  const [currentIssuer, setCurrentIssuer] = useState(defaultIssuer || issuers[0]);
  const code = urlParams.get('code');
  const singpass = urlParams.get('singpass');
  const { data: { me: user } = {} } = useMe()
  const [tokenBalance, setTokenBalance] = useState(0)
  const haveKyc = useRef(false)
  const { walletProvider } = useAppKitProvider('eip155')
  const { address: wcAddress, isConnected: isWcConnected } = useAppKitAccount()
  const { open: openAppKitModal } = useAppKit()

  const { provider } = useWeb3Provider(currentIssuer.network, currentIssuer.id);
  const formMethods = useFormContext()
  const [singpassCallback] = useMutation(SINGPASS_CALLBACK);
  const [updateUser] = useMutation(UPDATE_USER)

  const [fetchingData, setFetchingData] = useState(false);
  const [fetchingDataFailed, setFetchingDataFailed] = useState(false);

  const [fetchingRateAndPrice, setFetchingRateAndPrice] = useState(false);
  const [fetchingRateAndPriceFailed, setFetchingRateAndPriceFailed] = useState(false);

  const [numberFetchFailed, setNumberFetchFailed] = useState(false);
  const [rate, setRate] = useState(1);
  const [isFetchingSingpass, setIsFetchingSingpass] = useState(false);

  const setFormValues = (data) => {
    formMethods.setValue('status', 'PENDING');
    formMethods.setValue('first_name', data.first_name);
    formMethods.setValue('last_name', data.last_name);
    formMethods.setValue('birth_date', data.birth_date);
    formMethods.setValue('place_of_birth', data.place_of_birth);
    formMethods.setValue('nationality', data.nationality);
    formMethods.setValue('residential_address', data.residential_address);
    formMethods.setValue('city', data.city);
    formMethods.setValue('postal_code', data.postal_code);
    formMethods.setValue('country_of_residence', data.country_of_residence);
  }

  const defaultNextStepMap = {
    "buy-and-invest": "sharedholder-registration",
    "sharedholder-registration": "sharedholder-personal-info",
    "sharedholder-personal-info": "before-inssuficient-balance",
    "before-inssuficient-balance": "detail-buy-invest",
    "detail-buy-invest": "review-process-investment",
  }

  const [nextStepMap, setNextStepMap] = useState(defaultNextStepMap);

  const defaultPrevStepMap = {
    "sharedholder-registration": "buy-and-invest",
    "sharedholder-personal-info": "sharedholder-registration",
    "before-inssuficient-balance": "sharedholder-personal-info",
    "detail-buy-invest": "before-inssuficient-balance",
  }

  const [prevStepMap, setPrevStepMap] = useState(defaultPrevStepMap);

  const handleNextStep = () => {
    if (nextStepMap[step]) {
      setStep(nextStepMap[step]);
    }
  };

  const handlePrevStep = () => {
    if (prevStepMap[step]) {
      setStep(prevStepMap[step]);
    }
  };

  const handleInvestChange = (value, currentRate) => {
    console.log('rate', currentRate || rate)
    setInvestValue(+value);
    setBuyValue(+value * (currentRate || rate));
    formMethods.setValue('token_amount', +value);
    formMethods.setValue('amount', +value * (currentRate || rate));
  };

  // Add checkbox change handler
  const handleCheckboxChange = (checked) => {
    setAllChecked(checked);
  };

  useEffect(() => {
    const handleSingpassCallback = async (receivedQueryParams) => {
      try {
        setIsFetchingSingpass(true);
          const { data } = await singpassCallback({
            variables: {
            input: {
              receivedQueryParams,
              nonce: localStorage.getItem('singpass_nonce'),
            }
          }
        });

        const { mappingData } = data.singpassCallback;

        console.log('mappingData', mappingData);

        if (mappingData) {
          setFormValues(mappingData);
          setPrefillData(mappingData);
        }

        if (localStorage.getItem('investmentData')) {
          const investmentData = JSON.parse(localStorage.getItem('investmentData'));
          console.log('investmentData', investmentData);
          handleInvestChange(investmentData.token_amount);
          formMethods.setValue('token_amount', investmentData.token_amount);
          formMethods.setValue('token_name', investmentData.token_name);
          formMethods.setValue('currency', investmentData.currency);
          setCurrentIssuer(issuers.find((issuer) => issuer.coin_name === investmentData.token_name));
          localStorage.removeItem('investmentData');
        }

        if (mappingData) {
          handleNextStep();
        }
      } catch (error) {
        console.error('Error fetching singpass callback:', error);
        toaster.error('Error fetching singpass callback');
      } finally {
        setIsFetchingSingpass(false);
      }
    }

    if (singpass) {
      // get url query params except singpass
      const receivedQueryParams = {};
      urlParams.forEach((value, key) => {
        if (key !== 'singpass') {
          receivedQueryParams[key] = value;
        }
      });

      console.log('receivedQueryParams', receivedQueryParams);

      // window.location.replace('/instant-investment');

      handleSingpassCallback(receivedQueryParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setIssuer = (issuer) => {
    setCurrentIssuer(issuer);
    formMethods.setValue('token_name', issuer.coin_name);
  }

  console.log('getValues', formMethods.getValues())

  const [createInvestment, { loading }] = useMutation(CREATE_INVESTMENT);
  const [updateInvestment] = useMutation(UPDATE_INVESTMENT);
  const [getLastInvestment] = useLazyQuery(GET_LAST_INVESTMENT);

  // const formMethods = useForm({
  //   defaultValues: {
  //     currency: 'eure',
  //     token_name: 'ENC',
  //     token_amount: 100,
  //     amount: 100,
  //   }
  // });

  const updateStepMap = useCallback((tokenBalance, tokenAmount) => {
    console.log('haveKyc', haveKyc);

    if (tokenBalance >= tokenAmount && haveKyc.current) {
      setNextStepMap({
        "buy-and-invest": "detail-buy-invest",
        "detail-buy-invest": "review-process-investment",
      })

      setPrevStepMap({
        "detail-buy-invest": "buy-and-invest",
        "review-process-investment": "detail-buy-invest",
      })
    } else if (tokenBalance < tokenAmount && haveKyc.current) {
      setNextStepMap({
        "buy-and-invest": "before-inssuficient-balance",
        "before-inssuficient-balance": "detail-buy-invest",
        "detail-buy-invest": "review-process-investment",
      })

      setPrevStepMap({
        "before-inssuficient-balance": "buy-and-invest",
        "detail-buy-invest": "before-inssuficient-balance",
        "review-process-investment": "detail-buy-invest",
      })
    } else if (tokenBalance >= tokenAmount && !haveKyc.current) {
      setNextStepMap({
        "buy-and-invest": "sharedholder-registration",
        "sharedholder-registration": "sharedholder-personal-info",
        "sharedholder-personal-info": "detail-buy-invest",
        "detail-buy-invest": "review-process-investment",
      })

      setPrevStepMap({
        "sharedholder-registration": "buy-and-invest",
        "sharedholder-personal-info": "sharedholder-registration",
        "detail-buy-invest": "sharedholder-personal-info",
      })
    } else {
      setNextStepMap(defaultNextStepMap);
      setPrevStepMap(defaultPrevStepMap);
    }
  }, [haveKyc, defaultNextStepMap, defaultPrevStepMap])

  const checkTokenBalance = useCallback(async (currency) => {
    if (!provider) {
      return
    }
    setFetchingData(true);

    await new Promise(resolve => setTimeout(resolve, 2000));

    console.log('checkTokenBalance')
    let coinAddress

    let selectedCurrency = currency

    if (currentIssuer && ['DAKS', 'DEXA'].includes(currentIssuer.coin_name)) {
      console.log('mainnet-only')
      coinAddress = getCoinAddress(selectedCurrency, 'mainnet-only')
    } else {
      coinAddress = getCoinAddress(selectedCurrency, currentIssuer.network)

      if (!isProduction && currentIssuer.network === 'base') {
        selectedCurrency = 'usdc'
      }
    }

    console.log('coinAddress', coinAddress)

    const abi = [
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        type: "function",
      },
    ];

    const web3 = new Web3(provider);

    const contract = new web3.eth.Contract(abi, coinAddress)

    try {
      const balance = await contract.methods.balanceOf(user.wallet_address).call()

      console.log('balance', balance)

      console.log('currency', currency)
      const balanceValue = +(balance.toString()) / (10 ** COIN_DECIMALS[selectedCurrency])

      console.log('balanceValue', balanceValue)

      setTokenBalance(balanceValue);

      updateStepMap(balanceValue, formMethods.getValues('amount'));

      setFetchingData(false);
    } catch (error) {
      console.log(error);
      setFetchingDataFailed(true);
      setFetchingData(false);

      setTokenBalance(0);
      updateStepMap(0, formMethods.getValues('amount'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, formMethods, isWcConnected, provider, currentIssuer])

  useEffect(() => {
    if (!provider) {
      return
    }

    const initialCurrency = formMethods.getValues('currency');
    checkTokenBalance(initialCurrency);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, currentIssuer]);

  const fetchRateAndPrice = async (amount) => {
    try {
      if (fetchingRateAndPrice) {
        return
      }
      setFetchingRateAndPrice(true);
      await new Promise(resolve => setTimeout(resolve, 2000));

      console.log('fetchRate')
      const brokerbotAddress = currentIssuer.brokerbotAddress;
      const ethersProvider = new providers.Web3Provider(provider)

      const brokerbotContract = new Contract(brokerbotAddress, smartContract.brokerbotABI, ethersProvider);
      console.log('investValue', amount)
      // Now using investValue directly instead of calculating from buyValue
      const numberOfShares = BigNumber.from(Math.floor(amount).toLocaleString());

      const rateContract = await brokerbotContract.getPrice();
      const rateConverted = +(rateContract.toString()) / 10 ** COIN_DECIMALS.zchf

      const priceInBase = await brokerbotContract.getBuyPrice(numberOfShares);

      console.log('priceInBase', priceInBase)

      if (formMethods.getValues('currency') === 'zchf') {
        formMethods.setValue('amount', priceInBase / 10 ** COIN_DECIMALS.zchf);

        setBuyValue(priceInBase / 10 ** COIN_DECIMALS.zchf);
      } else {
        const path = getUniswapPath();

        const verifyPathResult = await verifyPath(ethersProvider, path, priceInBase.toString());

        console.log('verifyPathResult', verifyPathResult)

        const priceInToken = await brokerbotContract.getPriceInERC20(priceInBase.toString(), path);

        console.log('priceInToken', priceInToken)
        formMethods.setValue('amount', priceInToken / 10 ** COIN_DECIMALS.usdc);

        console.log('priceInToken formatted', priceInToken / 10 ** COIN_DECIMALS.usdc)

        setBuyValue(priceInToken / 10 ** COIN_DECIMALS.usdc);
      }

      console.log('rateContract', rateContract.toString());

      setRate(rateConverted);
      // formMethods.setValue('currency', 'zchf');
      // await updateUser({
      //   variables: {
      //     input: {
      //       currency: 'zchf'
      //     }
      //   }
      // })
      // checkTokenBalance('zchf');
      setFetchingRateAndPrice(false);
    } catch (error) {
      console.log(error);
      setFetchingRateAndPriceFailed(true);
      setFetchingRateAndPrice(false);
    }
  };

  useEffect(() => {
    if (currentIssuer && ['DAKS', 'DEXA'].includes(currentIssuer.coin_name)) {
      fetchRateAndPrice(formMethods.getValues('token_amount'));
    } else {
      setRate(1);
      handleInvestChange(formMethods.getValues('token_amount'), 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, currentIssuer]);

  useEffect(() => {
    const fetchInvestment = async (code) => {
      setFetchingData(true);
      const { data: investment } = await getLastInvestment();

      console.log('investment', investment);

      const investmentData = investment.getLastInvestment;

      if (!investmentData) {
        return
      }

      setFormValues(investmentData);

      if (code && !singpass) {
        handleInvestChange(investmentData.token_amount);
        formMethods.setValue('token_amount', investmentData.token_amount);
        formMethods.setValue('token_name', investmentData.token_name);
        formMethods.setValue('currency', investmentData.currency);
        setCurrentInvestment(investment.getLastInvestment);
        setCurrentIssuer(issuers.find((issuer) => issuer.coin_name === investmentData.token_name));
        setStep('before-inssuficient-balance');
      } else {
        formMethods.setValue('currency', user.currency || 'eure');
        haveKyc.current = true;
      }

      setFetchingData(false);
    }

    fetchInvestment(code);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, getLastInvestment, singpass]);

  useEffect(() => {
    if (!provider) {
      return () => {}
    }

    const subscription = formMethods.watch(async (value, { name }) => {
      if (name === 'currency') {
        checkTokenBalance(value.currency);
        await updateUser({
          variables: {
            input: {
              currency: value.currency
            }
          }
        })
      } else if (name === 'amount') {
        updateStepMap(tokenBalance, value.amount);
      } else if (name === 'token_amount') {
        if (currentIssuer && ['DAKS', 'DEXA'].includes(currentIssuer.coin_name)) {
          setTimeout(() => {
            console.log('value.token_amount', value.token_amount)
            fetchRateAndPrice(value.token_amount);
          }, 1000);
        }
      }
    });

    return () => subscription.unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formMethods, checkTokenBalance, tokenBalance, updateStepMap, provider, updateUser]);

  // set form currency by user currency
  useEffect(() => {
    if (user) {
      console.log('set user currency', user.currency)
      formMethods.setValue('currency', user.currency || 'eure');
      formMethods.setValue('token_name', currentIssuer.coin_name || 'ENC');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (fetchingDataFailed && numberFetchFailed < 4) {
      setNumberFetchFailed(prev => prev + 1);
      setFetchingDataFailed(false);
      toaster.info('Refetching data...');

      setTimeout(() => {
        checkTokenBalance(formMethods.getValues('currency'));
      }, 3000);
    }

    if (fetchingRateAndPriceFailed && numberFetchFailed < 4) {
      setNumberFetchFailed(prev => prev + 1);
      setFetchingRateAndPriceFailed(false);
      toaster.info('Refetching data...');

      setTimeout(() => {
        fetchRateAndPrice();
      }, 3000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingDataFailed, fetchingRateAndPriceFailed, numberFetchFailed]);

  const onSubmit = async (data) => {
    console.log('data', data)
    console.log('step', step)

    let investment;

    console.log('nextStepMap[step]', nextStepMap[step])

    if (step === 'detail-buy-invest') {
      checkTokenBalance(data.currency)
    }

    switch (nextStepMap[step]) {
      case 'detail-buy-invest':
        try {
          const formData = {
            amount: buyValue,
            token_amount: investValue,
            currency: data.currency || 'eure',
            token_name: data.token_name || 'ENC',
            status: 'PENDING',
            first_name: data.first_name,
            last_name: data.last_name,
            birth_date: data.birth_date || '15 August 1989',
            place_of_birth: data.place_of_birth,
            nationality: data.nationality,
            residential_address: data.residential_address,
            residential_address_two: data.residential_address_two,
            city: data.city,
            postal_code: data.postal_code,
            country_of_residence: data.country_of_residence
          };

          if (currentInvestment) {
            const { data: investmentData } = await updateInvestment({
              variables: {
                id: +currentInvestment.id,
                input: formData
              }
            });

            investment = investmentData.updateInvestment;
          } else {
            const { data: investmentData } = await createInvestment({
              variables: {
                input: {
                  ...formData,
                  whitelist_contract_address: currentIssuer.whitelistAddress,
                  network: currentIssuer.network
                }
              }
            });

            investment = investmentData.createInvestment;
          }

          setCurrentInvestment(investment);

          if (step === 'buy-and-invest' || step === 'sharedholder-personal-info') {
            handleNextStep();
          }
        } catch (error) {
          console.error('Investment creation failed:', error);

          toaster.error(error.message);
        }
        break;
      case 'review-process-investment':
        handleNextStep();

        break;
      default:
        handleNextStep();
        break;
    }

    return investment;
  };

  if (isFetchingSingpass) {
    return (
      <div className="mx-auto w-full max-w-[382px]">
        <SkeletonLoading />
      </div>
    )
  }

  return (
    <>
      <PageTitle
        title="Invest Instantly, Anywhere You Are"
        description={
          step === "sharedholder-registration"
            ? "Register to enjoy your shareholder rights."
            : step === "sharedholder-personal-info"
            ? "we need to collect a few personal details to verify your identity."
            : ""
        }
        onBack={
          [
            "sharedholder-registration",
            "sharedholder-personal-info",
            "before-inssuficient-balance",
          ].includes(step)
            ? handlePrevStep
            : null
        }
      />

      {/* <FormProvider {...formMethods}> */}
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className="mx-auto w-full max-w-[460px]"
        >
          {step === "buy-and-invest" && (
            <StepBuyAndInvest
              isLogin={true}
              handleInvestChange={handleInvestChange}
              buyValue={buyValue}
              investValue={investValue}
              setCurrentIssuer={setIssuer}
              currentIssuer={currentIssuer}
              balanceAfterTopup={tokenBalance}
              fetchingData={fetchingData}
              fetchingRateAndPrice={fetchingRateAndPrice}
            />
          )}

          {step === "sharedholder-registration" && (
            <StepShareHolderRegistration
              isLogin={true}
              prefillData={prefillData}
            />
          )}

          {step === "sharedholder-personal-info" && (
            <StepShareHolderPersonality
              isLogin={true}
              prefillData={prefillData}
            />
          )}

          {step === "before-inssuficient-balance" && (
            <StepBeforeInsufficientBalance
              isLogin={true}
              onNext={handleNextStep}
              onSubmit={onSubmit}
              currentInvestment={currentInvestment}
              moneriumCode={code && !singpass ? code : null}
              currentIssuer={currentIssuer}
            />
          )}

          {step === "detail-buy-invest" && (
            <StepDetailBuyAndInvest
              isLogin={true}
              handleInvestChange={handleInvestChange}
              buyValue={buyValue}
              investValue={investValue}
              setCurrentIssuer={setIssuer}
              currentIssuer={currentIssuer}
              balanceAfterTopup={tokenBalance}
              onCheckboxChange={handleCheckboxChange}
            />
          )}

          {step === "review-process-investment" &&
            <StepReviewProcessOrder
              currentInvestment={currentInvestment}
              currentIssuer={currentIssuer}
            />
          }

          {step !== "before-inssuficient-balance" && step !== "review-process-investment" && (
            <button
              type="submit"
              disabled={
                fetchingData ||
                fetchingRateAndPrice ||
                (step === 'detail-buy-invest' && !allChecked)
              }
              className={cn(
                "inline-flex justify-center items-center  m-0 px-6 !rounded-2xl w-full !h-14 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s]  overflow-visible outline-none",
                {
                  "bg-gray-custom/20 cursor-not-allowed": fetchingData || fetchingRateAndPrice || (step === 'detail-buy-invest' && !allChecked),
                  "bg-primary hover:bg-primary-hover hover:opacity-90 cursor-pointer": !fetchingData && !fetchingRateAndPrice && (step !== 'detail-buy-invest' || allChecked)
                }
              )}
            >
              {step === "detail-buy-invest"
                ? "Reviewing the investment"
                : "Continue"}
            </button>
          )}

          {(step === "sharedholder-registration" || step === "sharedholder-personal-info") && prefillData && (
            <button
              type="button"
              onClick={() => {
                window.location.href = '/instant-investment';
              }}
              className="inline-flex justify-center items-center bg-white hover:bg-[#ffffffab] hover:opacity-90 m-0 mt-4 px-6 !rounded-2xl w-full !h-14 font-medium text-[100%] text-black normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible outline-none"
            >
              Cancel
            </button>
          )}
        </form>
      {/* </FormProvider> */}
    </>
  );
};

export default InstantInvestment;
