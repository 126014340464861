// src/pages/Signup/components/SignupMethod/Passkey/PasskeySuccess.jsx

import React, { useEffect } from "react"
import { motion } from "framer-motion"
import confetti from "canvas-confetti"
import { useTranslation } from 'react-i18next'
import Layout from "../Layout"
import Icons from "../../../../components/shared/Icons"
import { SIGNUP_STEPS } from "../../constant/steps"

const SuccessFeature = ({ icon, title }) => (
  <div className="pw:flex pw:flex-row pw:gap-x-2 pw:gap-y-2">
    <div className="pw:inline-flex pw:relative pw:justify-center pw:items-center pw:bg-primary/15 pw:mt-1 pw:rounded-[50%] pw:w-8 pw:h-8">
      <Icons nameIcon={icon} className="pw:w-5 pw:h-5 pw:text-primary" />
    </div>

    <div className="pw:flex pw:flex-col pw:flex-1 pw:mt-2 pw:pl-2">
      <div className="pw:flex-1 pw:m-0 pw:font-normal pw:text-[13px] pw:text-base pw:text-gray-lighter pw:text-start pw:leading-6 pw:transition-[color] pw:duration-[0.02s] pw:ease-[ease-out]">
        {title}
      </div>
    </div>
  </div>
)

const PasskeySuccess = ({ onBack, onFinish }) => {
  const { t } = useTranslation()

  useEffect(() => {
    const duration = 3 * 1000
    const animationEnd = Date.now() + duration
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

    const randomInRange = (min, max) => Math.random() * (max - min) + min

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now()

      if (timeLeft <= 0) {
        return clearInterval(interval)
      }

      const particleCount = 50 * (timeLeft / duration)

      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
      })
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
      })

      return undefined
    }, 250)

    return () => clearInterval(interval)
  }, [])

  return (
    <Layout
      title={t("Passkey Created Successfully")}
      description={t("Your account is now secured with a passkey")}
      currentStep={3}
      totalSteps={5}
      stepType={SIGNUP_STEPS.PASSKEY}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="pw:flex pw:justify-center pw:mb-8"
        >
          <div className="pw:flex pw:justify-center pw:items-center pw:bg-primary/10 pw:rounded-full pw:w-24 pw:h-24">
            <Icons
              nameIcon="check"
              className="pw:w-16 pw:h-16 pw:text-primary"
            />
          </div>
        </motion.div>

        <div className="pw:flex pw:flex-col pw:flex-wrap pw:justify-center pw:items-center pw:bg-black-light pw:mb-8 pw:p-6 pw:rounded-2xl">
          <h3 className="pw:mb-4 pw:font-semibold pw:text-white">
            {t("Security features enabled:")}
          </h3>

          <div className="pw:flex pw:flex-col pw:flex-nowrap pw:gap-4">
            <SuccessFeature
              icon="fingerprint"
              title={t("Biometric authentication enabled")}
            />
            <SuccessFeature
              icon="cloud"
              title={t("Cloud backup configured")}
            />
            <SuccessFeature
              icon="refresh"
              title={t("Cross-device sync ready")}
            />
          </div>
        </div>

        <p className="pw:mb-4 pw:text-center pw:text-gray-lighter pw:text-sm">
          {t("You can now use your passkey to sign in securely across all your devices.")}
        </p>

        <button
          onClick={onFinish}
          className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200"
        >
          {t("Continue to Dashboard")}
        </button>
      </div>
    </Layout>
  )
}

export default PasskeySuccess
