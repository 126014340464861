import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { EqualHeight, EqualHeightElement } from 'react-equal-height'
import {
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import {
  GET_VOUCHER_CODE,
} from '../queriesAndMutations/voucherQuery'

const getDiscountOffPercent = (voucher) => (voucher.percent_off ? `(${voucher.percent_off} %)` : `- ${voucher.amount_off}`)
const calculateDiscountPrice = (voucher, price) => {
  if (price) {
    return voucher.percent_off ? (price * (100 - voucher.percent_off)).toFixed(2) / 100 : price - voucher.amount_off
  }
  return false
}
const TenantPlanTable = ({
  plans,
  onSelectPlan,
  loading,
  voucherCode,
  setVoucherCode,
  setHasValidVoucher,
  tenant,
}) => {
  const { t } = useTranslation()
  const [selectedPlan, setSelectedPlan] = useState(null)
  // const getServiceKeys = () => (plans && Object.values(plans).length ? Object.values(Object.values(plans)[0].services) : [])
  // const getCbuKeys = () => (plans && Object.values(plans).length ? Object.values(Object.values(plans)[0].cbu) : [])
  const planArray = Object.values(plans)
  const { data: voucherData } = useQuery(GET_VOUCHER_CODE, {
    variables: {
      id: voucherCode,
    },
    skip: !voucherCode || voucherCode === '',
  })
  useEffect(() => {
    if ((voucherData && voucherData.getVoucherCode.id)) {
      setHasValidVoucher(true)
    }
  }, [voucherData, setHasValidVoucher])

  const renderStarterPlan = () => {
    const plan = planArray[0]

    return (
      <>
        <div className="variants__item pricings__item">
          {/* Pricing header */}
          <div className="item__title h3 pricings__item-title" key={plan.id}>
            {plan.label}
          </div>

          <div className="pricings__inner">
            <div className="item__cost pricings__cost">
              <div className="status-stroke-green item__price">39.00chf</div>
              <div className="item__counter">
                /
                {' '}
                {t('month')}
              </div>
            </div>

            <EqualHeightElement name="feature_general">
              <ul className="pricings__feature">
                <li>
                  {t('KYB Onboarding (Companies)')}
                </li>
                <li>
                  {t('Different Token Types')}
                </li>
                <li>
                  {t('Multi Currency (FIAT)')}
                </li>
                <li>
                  {t('Liveness Check / Video Ident Log')}
                </li>
                <li>
                  {t('Exchange Rates')}
                </li>
                <li>
                  {t('Enable Transaction Limit')}
                </li>
                <li>{t('Exact Investment Amount')}</li>
                <li>{t('Mandatory KYC for Referrals to create transactions')}</li>
                <li>{t('Mandatory KYC for Organic to create transactions')}</li>
                <li>{t('Allow the Investor to add Wallet later')}</li>
                <li>{t('Require referral Code for KYC')}</li>
                <li>{t('Disable ERC-20 Check for receiving wallet')}</li>
                <li>{t('Bank upload logo (allows you to use any bank)')}</li>
                <li>{t('Download Due Dilligence Files as Zipfile')}</li>
                <li>{t('Download All KYC Data')}</li>
                <li>{t('Download Pythagoras formatted CSV')}</li>
                <li>{t('Download Bank CSV')}</li>
                <li>{t('Reopen KYC with Liveness Check')}</li>
                <li>{t('Comment function')}</li>
                <li>{t('Reopen KYC workflow')}</li>
                <li>{t('Risk Level Assesment')}</li>
                <li>{t('Wallet check Score')}</li>
                <li>{t('Banking Data from your Investors (let investors enter their Banking Data)')}</li>
                <li>{t('Approve transactions and confirm its business profile')}</li>
                <li>{t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}</li>
                <li>{t('User list (a list of all your Investors)')}</li>
                <li>{t('Ethereum Address for approved KYCs export for manual whitelisting')}</li>
                <li>{t('Compliance Role (Can only access KYC/KYB related data)')}</li>
                <li>{t('Token Export File for approved transactions')}</li>
                <li>{t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}</li>
                <li className="disabled">{t('General Blacklist derived from Investhub.io (Gesetze.li)')}</li>
                <li className="disabled">{t('Pythagoras Export')}</li>
                <li className="disabled">{t('Acronis Export (Datacenter in Liechtenstein)')}</li>
                <li className="disabled">{t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}</li>
                <li className="disabled">{t('Cashlink Integration')}</li>
                <li className="disabled">{t('Volt.io Integration')}</li>
                <li className="disabled">{t('Sendwyre Integration')}</li>
                <li>{t('Walletengine.io (Beta)')}</li>
                <li className="disabled">{t('Enable Crypto.com Payment Gateway')}</li>
                <li className="disabled">{t('Signnow Integration')}</li>
                <li className="disabled">{t('2FA (Mandatory yes/no)')}</li>
                <li className="disabled">{t('Discount Levels')}</li>
                <li className="disabled">{t('Referral Codes')}</li>
                <li className="disabled">{t('Multi Language')}</li>
                <li className="disabled">{t('Google Analytics')}</li>
                <li className="disabled">{t('Google Tag Manager')}</li>
                <li className="disabled">{t('Facebook Pixel')}</li>
                <li className="disabled">{t('Hotjar Integration')}</li>
                <li className="disabled">{t('YouTube Login Page')}</li>
                <li className="disabled">{t('Auto Follow up (If no KYC, or if no Transaction)')}</li>
                <li className="disabled">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                <li className="disabled">{t('Allow non ERC-20 Receiving and Sending wallets')}</li>
                <li className="disabled">{t('Use own Mailserver (Mailgun)')}</li>
                <li className="disabled">{t('Change parameters for KYC Levels based on your AML policy')}</li>
                <li className="disabled">{t('Use KYC level 5')}</li>
                <li className="disabled">{t('Liveness check extract & compare data')}</li>
                <li className="disabled">{t('Internal Blacklist (you can maintain an extra blacklist internally)')}</li>
                <li className="disabled">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
                <li className="disabled">{t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit review')}</li>
                <li className="disabled">{t('Auto approve KYC when matched several conditions')}</li>
              </ul>
            </EqualHeightElement>

            <EqualHeightElement name="feature_charge_on_usage">
              <>
                <div className="footer__category pricings__info">
                  {t('Charge on usage')}
                </div>
                <ul className="pricings__feature">
                  <li>
                    {t('7.00 chf / Onfido liveness check')}
                  </li>
                  <li>
                    {t('1.50 chf / Onfido enhanced watchlist')}
                  </li>
                  <li>
                    {t('25.00 chf / Datastorage (Liechtenstein)')}
                  </li>
                  <li>
                    {t('2.00 chf / Wallet check')}
                  </li>
                  <li>
                    {t('100.00 chf / Signnow')}
                  </li>
                </ul>
              </>
            </EqualHeightElement>

            <EqualHeightElement name="feature_optional">
              <>
                <div className="footer__category pricings__info">
                  {t('Optional Package')}
                </div>
                <span className="pricings__not-included text-center">
                  {t('Not included')}
                </span>
              </>
            </EqualHeightElement>

            <EqualHeightElement name="feature_referal">
              <>
                <div className="footer__category pricings__info">
                  {t('Optional Referral')}
                </div>
                <ul className="pricings__feature">
                  <li>
                    {t('15% Commission for each Referral from Investhub.io')}
                  </li>
                </ul>
              </>
            </EqualHeightElement>

            <div className="footer__category pricings__info">
              {' '}
            </div>
          </div>

          <div className="pricings__cta pricings__button">
            {!plan.isActivated && (
              <RadioGroup
                value={selectedPlan}
                onChange={({ target: { value } }) => setSelectedPlan(value)}
                className="radiogroup-button"
              >
                <FormControlLabel
                  value={plan.id}
                  control={<Radio color="primary" />}
                  label={t('Choose')}
                />
              </RadioGroup>
            )}
            {plan.isActivated && (
              <button type="button" className="button button-stroke disabled" disabled>{t('Activated')}</button>
            )}
          </div>
        </div>
      </>
    )
  }

  const renderMediumPlan = () => {
    const plan = planArray[1]

    return (
      <div className="variants__item pricings__item pricings__hot">
        {/* Pricing header */}
        <div className="item__title h3 pricings__item-title">
          {t(plan.label)}
        </div>

        <div className="pricings__inner">
          <div className="item__cost pricings__cost">
            <div className="status-stroke-green item__price">150.00chf</div>
            <div className="item__counter">
              /
              {' '}
              {t('month')}
            </div>
          </div>

          <EqualHeightElement name="feature_general">
            <ul className="pricings__feature">
              <li>
                {t('KYB Onboarding (Companies)')}
              </li>
              <li>
                {t('Different Token Types')}
              </li>
              <li>
                {t('Multi Currency (FIAT)')}
              </li>
              <li>
                {t('Liveness Check / Video Ident Log')}
              </li>
              <li>
                {t('Exchange Rates')}
              </li>
              <li>
                {t('Enable Transaction Limit')}
              </li>
              <li>
                {t('Exact Investment Amount')}
              </li>
              <li>
                {t('Mandatory KYC for Referrals to create transactions')}
              </li>
              <li>
                {t('Mandatory KYC for Organic to create transactions')}
              </li>
              <li>
                {t('Allow the Investor to add Wallet later')}
              </li>
              <li>
                {t('Require referral Code for KYC')}
              </li>
              <li>
                {t('Disable ERC-20 Check for receiving wallet')}
              </li>
              <li>
                {t('Bank upload logo (allows you to use any bank)')}
              </li>
              <li>
                {t('Download Due Dilligence Files as Zipfile')}
              </li>
              <li>
                {t('Download All KYC Data')}
              </li>
              <li>
                {t('Download Pythagoras formatted CSV')}
              </li>
              <li>
                {t('Download Bank CSV')}
              </li>
              <li>
                {t('Reopen KYC with Liveness Check')}
              </li>
              <li>
                {t('Comment function')}
              </li>
              <li>
                {t('Reopen KYC workflow')}
              </li>
              <li>
                {t('Risk Level Assesment')}
              </li>
              <li>
                {t('Wallet check Score')}
              </li>
              <li>
                {t('Banking Data from your Investors (let investors enter their Banking Data)')}
              </li>
              <li>
                {t('Approve transactions and confirm its business profile')}
              </li>
              <li>
                {t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}
              </li>
              <li>
                {t('User list (a list of all your Investors)')}
              </li>
              <li>
                {t('Ethereum Address for approved KYCs export for manual whitelisting')}
              </li>
              <li>
                {t('Compliance Role (Can only access KYC/KYB related data)')}
              </li>
              <li>
                {t('Token Export File for approved transactions')}
              </li>
              <li>
                {t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}
              </li>
              <li>
                {t('General Blacklist derived from Investhub.io (Gesetze.li)')}
              </li>
              <li className="disabled">
                {t('Pythagoras Export')}
              </li>
              <li className="disabled">
                {t('Acronis Export (Datacenter in Liechtenstein)')}
              </li>
              <li>
                {t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}
              </li>
              <li className="disabled">
                {t('Cashlink Integration')}
              </li>
              <li>
                {t('Volt.io Integration')}
              </li>
              <li>
                {t('Sendwyre Integration')}
              </li>
              <li className="disabled">
                {t('Walletengine.io (Beta)')}
              </li>
              <li className="disabled">
                {t('Enable Crypto.com Payment Gateway')}
              </li>
              <li className="disabled">
                {t('Signnow Integration')}
              </li>
              <li>
                {t('2FA (Mandatory yes/no)')}
              </li>
              <li>
                {t('Discount Levels')}
              </li>
              <li>
                {t('Referral Codes')}
              </li>
              <li>
                {t('Multi Language')}
              </li>
              <li>
                {t('Google Analytics')}
              </li>
              <li>
                {t('Google Tag Manager')}
              </li>
              <li>
                {t('Facebook Pixel')}
              </li>
              <li>
                {t('Hotjar Integration')}
              </li>
              <li>
                {t('YouTube Login Page')}
              </li>
              <li>
                {t('Auto Follow up (If no KYC, or if no Transaction)')}
              </li>
              <li className="disabled">
                {t('Custodian Role (Custodian can see token owners at all times)')}
              </li>
              <li>
                {t('Allow non ERC-20 Receiving and Sending wallets')}
              </li>
              <li>
                {t('Use own Mailserver (Mailgun)')}
              </li>
              <li className="disabled">
                {t('Change parameters for KYC Levels based on your AML policy')}
              </li>
              <li className="disabled">
                {t('Use KYC level 5')}
              </li>
              <li className="disabled">
                {t('Liveness check extract & compare data')}
              </li>
              <li>
                {t('Internal Blacklist (you can maintain an extra blacklist internally)')}
              </li>
              <li>
                {t('Custodian Role (Custodian can see token owners at all times)')}
              </li>
              <li>
                {t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit review')}
              </li>
              <li className="disabled">
                {t('Auto approve KYC when matched several conditions')}
              </li>
            </ul>
          </EqualHeightElement>

          <EqualHeightElement name="feature_charge_on_usage">
            <>
              <div className="footer__category pricings__info">
                {t('Charge on usage')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('6.00 chf / Onfido liveness check')}
                </li>
                <li>
                  {t('1.20 chf / Onfido enhanced watchlist')}
                </li>
                <li>
                  {t('15.00 chf / Datastorage (Liechtenstein)')}
                </li>
                <li>
                  {t('1.50 chf / Wallet check')}
                </li>
                <li>
                  {t('50.00 chf / Signnow')}
                </li>
              </ul>
            </>
          </EqualHeightElement>

          <EqualHeightElement name="feature_optional">
            <>
              <div className="footer__category pricings__info">
                {t('Optional Package')}
              </div>
              <span className="pricings__not-included text-center">
                {t('Not included')}
              </span>
            </>
          </EqualHeightElement>

          <EqualHeightElement name="feature_referal">
            <>
              <div className="footer__category pricings__info">
                {t('Optional Referral')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('10% Commission for each Referral from Investhub.io')}
                </li>
              </ul>
            </>
          </EqualHeightElement>
        </div>

        <div className="pricings__cta pricings__button">
          {!plan.isActivated && (
            <RadioGroup
              value={selectedPlan}
              onChange={({ target: { value } }) => setSelectedPlan(value)}
              className="radiogroup-button"
            >
              <FormControlLabel
                value={plan.id}
                control={<Radio color="primary" />}
                label={t('Choose')}
              />
            </RadioGroup>
          )}
          {plan.isActivated && (
            <button type="button" className="button button-stroke disabled" disabled>{t('Activated')}</button>
          )}
        </div>
      </div>
    )
  }

  const renderPremiumPlan = () => {
    const plan = planArray[2]

    return (
      <div className="variants__item pricings__item">
        {/* Pricing header */}
        <div className="item__title h3 pricings__item-title">
          {t(plan.label)}
        </div>

        <div className="pricings__inner">
          <div className="item__cost pricings__cost">
            <div className="status-stroke-green item__price">499.00chf</div>
            <div className="item__counter">
              /
              {' '}
              {t('month')}
            </div>
          </div>

          <EqualHeightElement name="feature_general">
            <ul className="pricings__feature">
              <li>
                {t('KYB Onboarding (Companies)')}
              </li>
              <li>
                {t('Different Token Types')}
              </li>
              <li>
                {t('Multi Currency (FIAT)')}
              </li>
              <li>
                {t('Liveness Check / Video Ident Log')}
              </li>
              <li>
                {t('Exchange Rates')}
              </li>
              <li>
                {t('Enable Transaction Limit')}
              </li>
              <li>{t('Exact Investment Amount')}</li>
              <li>{t('Mandatory KYC for Referrals to create transactions')}</li>
              <li>{t('Mandatory KYC for Organic to create transactions')}</li>
              <li>{t('Allow the Investor to add Wallet later')}</li>
              <li>{t('Require referral Code for KYC')}</li>
              <li>{t('Disable ERC-20 Check for receiving wallet')}</li>
              <li>{t('Bank upload logo (allows you to use any bank)')}</li>
              <li>{t('Download Due Dilligence Files as Zipfile')}</li>
              <li>{t('Download All KYC Data')}</li>
              <li>{t('Download Pythagoras formatted CSV')}</li>
              <li>{t('Download Bank CSV')}</li>
              <li>{t('Reopen KYC with Liveness Check')}</li>
              <li>{t('Comment function')}</li>
              <li>{t('Reopen KYC workflow')}</li>
              <li>{t('Risk Level Assesment')}</li>
              <li>{t('Wallet check Score')}</li>
              <li>{t('Banking Data from your Investors (let investors enter their Banking Data)')}</li>
              <li>{t('Approve transactions and confirm its business profile')}</li>
              <li>{t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}</li>
              <li>{t('User list (a list of all your Investors)')}</li>
              <li>{t('Ethereum Address for approved KYCs export for manual whitelisting')}</li>
              <li>{t('Compliance Role (Can only access KYC/KYB related data)')}</li>
              <li>{t('Token Export File for approved transactions')}</li>
              <li>{t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}</li>
              <li>{t('General Blacklist derived from Investhub.io (Gesetze.li)')}</li>
              <li className="">{t('Pythagoras Export')}</li>
              <li className="">{t('Acronis Export (Datacenter in Liechtenstein)')}</li>
              <li className="">{t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}</li>
              <li className="">{t('Cashlink Integration')}</li>
              <li className="">{t('Volt.io Integration')}</li>
              <li className="">{t('Sendwyre Integration')}</li>
              <li>{t('Walletengine.io (Beta)')}</li>
              <li className="">{t('Enable Crypto.com Payment Gateway')}</li>
              <li className="">{t('Signnow Integration')}</li>
              <li className="">{t('2FA (Mandatory yes/no)')}</li>
              <li className="">{t('Discount Levels')}</li>
              <li className="">{t('Referral Codes')}</li>
              <li className="">{t('Multi Language')}</li>
              <li className="">{t('Google Analytics')}</li>
              <li className="">{t('Google Tag Manager')}</li>
              <li className="">{t('Facebook Pixel')}</li>
              <li className="">{t('Hotjar Integration')}</li>
              <li className="">{t('YouTube Login Page')}</li>
              <li className="">{t('Auto Follow up (If no KYC, or if no Transaction)')}</li>
              <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
              <li className="">{t('Allow non ERC-20 Receiving and Sending wallets')}</li>
              <li className="">{t('Use own Mailserver (Mailgun)')}</li>
              <li className="">{t('Change parameters for KYC Levels based on your AML policy')}</li>
              <li className="">{t('Use KYC level 5')}</li>
              <li className="">{t('Liveness check extract & compare data')}</li>
              <li className="">{t('Internal Blacklist (you can maintain an extra blacklist internally)')}</li>
              <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
              <li className="">{t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit review')}</li>
              <li className="">{t('Auto approve KYC when matched several conditions')}</li>
            </ul>
          </EqualHeightElement>

          <EqualHeightElement name="feature_charge_on_usage">
            <div className="footer__category pricings__info">
              {t('Charge on usage')}
            </div>
            <ul className="pricings__feature">
              <li>
                {t('5.00 chf / Onfido liveness check')}
              </li>
              <li>
                {t('0.90 chf / Onfido enhanced watchlist')}
              </li>
              <li>
                {t('1.00 chf / Datastorage (Liechtenstein)')}
              </li>
              <li>
                {t('1.00 chf / Wallet check')}
              </li>
              <li>
                {t('25.00 chf / Signnow')}
              </li>
            </ul>
          </EqualHeightElement>

          <EqualHeightElement name="feature_optional">
            <>
              <div className="footer__category pricings__info">
                {t('Optional Package')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('150 CHF / Month IDnow')}
                </li>
                <li>
                  {t('150 CHF / Month Wallet as a Service (Tangany)')}
                </li>
                <li>
                  {t('150 CHF / Month Auto Approval')}
                </li>
              </ul>
            </>
          </EqualHeightElement>

          <EqualHeightElement name="feature_referal">
            <>
              <div className="footer__category pricings__info">
                {t('Optional Referral')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('5% Commission for each Referral from Investhub.io')}
                </li>
              </ul>
            </>
          </EqualHeightElement>
          <div className="footer__category pricings__info">
            {' '}
          </div>
        </div>

        <div className="pricings__cta pricings__button">
          {!plan.isActivated && (
            <RadioGroup
              value={selectedPlan}
              onChange={({ target: { value } }) => setSelectedPlan(value)}
              className="radiogroup-button"
            >
              <FormControlLabel
                value={plan.id}
                control={<Radio color="primary" />}
                label={t('Choose')}
              />
            </RadioGroup>
          )}
          {plan.isActivated && (
            <button type="button" className="button button-stroke disabled" disabled>{t('Activated')}</button>
          )}
        </div>
      </div>
    )
  }

  const renderVipPlan = () => {
    const plan = planArray[3]
    return (
      <div className="variants__item pricings__item">
        <div className="item__title h3 pricings__item-title">
          {plan.label}
        </div>

        <div className="pricings__inner">
          <div className="item__cost pricings__cost">
            <div className="status-stroke-green item__price">4999.00chf</div>
            <div className="item__counter">
              /
              {' '}
              {t('month')}
            </div>
          </div>

          <EqualHeightElement name="feature_general">
            <ul className="pricings__feature">
              <li>
                {t('KYB Onboarding (Companies)')}
              </li>
              <li>
                {t('Different Token Types')}
              </li>
              <li>
                {t('Multi Currency (FIAT)')}
              </li>
              <li>
                {t('Liveness Check / Video Ident Log')}
              </li>
              <li>
                {t('Exchange Rates')}
              </li>
              <li>
                {t('Enable Transaction Limit')}
              </li>
              <li>{t('Exact Investment Amount')}</li>
              <li>{t('Mandatory KYC for Referrals to create transactions')}</li>
              <li>{t('Mandatory KYC for Organic to create transactions')}</li>
              <li>{t('Allow the Investor to add Wallet later')}</li>
              <li>{t('Require referral Code for KYC')}</li>
              <li>{t('Disable ERC-20 Check for receiving wallet')}</li>
              <li>{t('Bank upload logo (allows you to use any bank)')}</li>
              <li>{t('Download Due Dilligence Files as Zipfile')}</li>
              <li>{t('Download All KYC Data')}</li>
              <li>{t('Download Pythagoras formatted CSV')}</li>
              <li>{t('Download Bank CSV')}</li>
              <li>{t('Reopen KYC with Liveness Check')}</li>
              <li>{t('Comment function')}</li>
              <li>{t('Reopen KYC workflow')}</li>
              <li>{t('Risk Level Assesment')}</li>
              <li>{t('Wallet check Score')}</li>
              <li>{t('Banking Data from your Investors (let investors enter their Banking Data)')}</li>
              <li>{t('Approve transactions and confirm its business profile')}</li>
              <li>{t('Datacenter to upload Data for your Investors (e.g Pitchdeck)')}</li>
              <li>{t('User list (a list of all your Investors)')}</li>
              <li>{t('Ethereum Address for approved KYCs export for manual whitelisting')}</li>
              <li>{t('Compliance Role (Can only access KYC/KYB related data)')}</li>
              <li>{t('Token Export File for approved transactions')}</li>
              <li>{t('Split KYC process into two steps (gather personal data first, then allow liveness check)')}</li>
              <li>{t('General Blacklist derived from Investhub.io (Gesetze.li)')}</li>
              <li className="">{t('Pythagoras Export')}</li>
              <li className="">{t('Acronis Export (Datacenter in Liechtenstein)')}</li>
              <li className="">{t('Sales API (receive external Traffic via API with Single Sign on and send back investor related data)')}</li>
              <li className="">{t('Cashlink Integration')}</li>
              <li className="">{t('Volt.io Integration')}</li>
              <li className="">{t('Sendwyre Integration')}</li>
              <li>{t('Walletengine.io (Beta)')}</li>
              <li className="">{t('Enable Crypto.com Payment Gateway')}</li>
              <li className="">{t('Signnow Integration')}</li>
              <li className="">{t('2FA (Mandatory yes/no)')}</li>
              <li className="">{t('Discount Levels')}</li>
              <li className="">{t('Referral Codes')}</li>
              <li className="">{t('Multi Language')}</li>
              <li className="">{t('Google Analytics')}</li>
              <li className="">{t('Google Tag Manager')}</li>
              <li className="">{t('Facebook Pixel')}</li>
              <li className="">{t('Hotjar Integration')}</li>
              <li className="">{t('YouTube Login Page')}</li>
              <li className="">{t('Auto Follow up (If no KYC, or if no Transaction)')}</li>
              <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
              <li className="">{t('Allow non ERC-20 Receiving and Sending wallets')}</li>
              <li className="">{t('Use own Mailserver (Mailgun)')}</li>
              <li className="">{t('Change parameters for KYC Levels based on your AML policy')}</li>
              <li className="">{t('Use KYC level 5')}</li>
              <li className="">{t('Liveness check extract & compare data')}</li>
              <li className="">{t('Internal Blacklist (you can maintain an extra blacklist internally)')}</li>
              <li className="">{t('Custodian Role (Custodian can see token owners at all times)')}</li>
              <li className="">{t('Event Log (show all changes within the app for settings and KYC applications) perfect Audit Trail for yearly audit review')}</li>
              <li className="">{t('Auto approve KYC when matched several conditions')}</li>
            </ul>
          </EqualHeightElement>

          <EqualHeightElement name="feature_charge_on_usage">
            <>
              <div className="footer__category pricings__info">
                {t('Charge on usage')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('2.00 chf / Onfido liveness check')}
                </li>
                <li>
                  {t('0.90 chf / Onfido enhanced watchlist')}
                </li>
                <li>
                  {t('1.00 chf / Acronis')}
                </li>
                <li>
                  {t('1.00 chf / Wallet check')}
                </li>
                <li>
                  {t('3.00 chf / Signnow')}
                </li>
              </ul>
            </>
          </EqualHeightElement>

          <EqualHeightElement name="feature_optional">
            <>
              <div className="footer__category pricings__info">
                {t('Optional Package')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('150 CHF / Month IDnow')}
                </li>
                <li>
                  {t('150 CHF / Month Wallet as a Service (Tangany)')}
                </li>
                <li>
                  {t('150 CHF / Month Auto Approval')}
                </li>
              </ul>
            </>
          </EqualHeightElement>

          <EqualHeightElement name="feature_referal">
            <>
              <div className="footer__category pricings__info">
                {t('Optional Referral')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('1% Commission for each Referral from Investhub.io')}
                </li>
              </ul>
            </>
          </EqualHeightElement>

          <EqualHeightElement name="feature_additional">
            <>
              <div className="footer__category pricings__info">
                {t('Additional')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('2 Hour Legal included')}
                </li>
                <li>
                  {t('5 Hour from Investhub Contributor included')}
                </li>
              </ul>
            </>
          </EqualHeightElement>
          <div className="footer__category pricings__info">
            {' '}
          </div>
        </div>

        <div className="pricings__cta pricings__button">
          {!plan.isActivated && (
            <RadioGroup
              value={selectedPlan}
              onChange={({ target: { value } }) => setSelectedPlan(value)}
              className="radiogroup-button"
            >
              <FormControlLabel
                value={plan.id}
                control={<Radio color="primary" />}
                label={t('Choose')}
              />
            </RadioGroup>
          )}
          {plan.isActivated && (
            <button type="button" className="button button-stroke disabled" disabled>{t('Activated')}</button>
          )}
        </div>

      </div>
    )
  }

  const renderEvolvePlan = () => {
    const plan = planArray[4]
    return (
      <div className="variants__item pricings__item">
        <div className="item__title h3 pricings__item-title">
          {plan.label}
        </div>

        <div className="pricings__inner">
          <div className="item__cost pricings__cost">
            <div className="status-stroke-green item__price">39.00chf</div>
            <div className="item__counter">
              /
              {' '}
              {t('month')}
            </div>
          </div>

          <EqualHeightElement name="feature_general">
            <ul className="pricings__feature">
              <li>
                {t('Evolve Enable')}
              </li>
            </ul>
          </EqualHeightElement>

          <EqualHeightElement name="feature_referal">
            <>
              <div className="footer__category pricings__info">
                {t('Optional Referral')}
              </div>
              <ul className="pricings__feature">
                <li>
                  {t('0.1% Commission for each Transaction')}
                </li>
              </ul>
            </>
          </EqualHeightElement>
          <div className="footer__category pricings__info">
            {' '}
          </div>
        </div>

        <div className="pricings__cta pricings__button">
          {!plan.isActivated && (
            <RadioGroup
              value={selectedPlan}
              onChange={({ target: { value } }) => setSelectedPlan(value)}
              className="radiogroup-button"
            >
              <FormControlLabel
                value={plan.id}
                control={<Radio color="primary" />}
                label={t('Choose')}
              />
            </RadioGroup>
          )}
          {plan.isActivated && (
            <button type="button" className="button button-stroke disabled" disabled>{t('Activated')}</button>
          )}
        </div>

      </div>
    )
  }

  return (
    <>
      <div className="variants__center pricings__center center center__admin">
        <div className="variants__list pricings__list pricings__list_has_hot">
          <EqualHeight>
            {tenant.planType !== 'evolve_only' && renderStarterPlan()}
            {tenant.planType !== 'evolve_only' && renderMediumPlan()}
            {tenant.planType !== 'evolve_only' && renderPremiumPlan()}
            {tenant.planType !== 'evolve_only' && renderVipPlan()}
            {tenant.planType === 'evolve_only' && renderEvolvePlan()}
          </EqualHeight>

        </div>
      </div>
      <div className="upload__item">
        <div className="upload__options pricing-options__checkout">
          <div className="upload__option pricing-options__item">
            <div className="upload__box pricing-options__box">
              <div className="upload__category">{t('Total')}</div>
            </div>

            <div className="switch pricing-options__summary">
              <div className="preview__info">
                {selectedPlan ? `${plans[selectedPlan].price} ${plans[selectedPlan].currency.toUpperCase()}` : '-'}
              </div>
            </div>
          </div>

          <div className="upload__option pricing-options__item">
            <div className="upload__box pricing-options__box">
              <div className="upload__category">{t('Voucher code')}</div>
            </div>

            <div className="switch pricing-options__summary">
              <TextField
                className="investhub__field field"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                value={voucherCode}
                onChange={({ target: { value } }) => setVoucherCode(value)}
              />
            </div>
          </div>

          {
            (voucherData && voucherData.getVoucherCode.id) && (
              <div className="upload__option pricing-options__item">
                <div className="upload__box pricing-options__box">
                  <div className="upload__category">{t('Total After Discount')}</div>
                </div>
                <div className="switch pricing-options__summary">
                  <div className="preview__info">
                    {`${getDiscountOffPercent(voucherData.getVoucherCode)}`}
                    {' '}
                    {selectedPlan ? `${calculateDiscountPrice(voucherData.getVoucherCode, plans[selectedPlan].price)} ${plans[selectedPlan].currency.toUpperCase()}` : ''}
                  </div>
                </div>
              </div>
            )
          }

          <div className="upload__option pricing-options__item">
            <div className="upload__box pricing-options__box">{' '}</div>
            <div className="switch pricing-options__summary pricing-options__button">
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <button
                    type="button"
                    className="button"
                    size="large"
                    disabled={!selectedPlan}
                    onClick={() => onSelectPlan(selectedPlan)}
                  >
                    {t('Checkout')}
                  </button>
                </>
              )}
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

TenantPlanTable.propTypes = {
  plans: PropTypes.object.isRequired,
  onSelectPlan: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  voucherCode: PropTypes.string,
  setVoucherCode: PropTypes.func,
  setHasValidVoucher: PropTypes.func,
  tenant: PropTypes.object.isRequired,
}

export default TenantPlanTable
