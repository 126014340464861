/* eslint-disable spaced-comment */
import React, { useState } from 'react'
import classNames from 'classnames'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SlideToggle from "react-slide-toggle"
import config from '../../../config'
import { useMe } from '../../../myHooks'
import { USER_RIGHT_TYPES } from '../../../constants/user'
import { hasUserEnoughRights, logout } from '../../../utils'

import SiteLogoDark from '../../../media/images/dark-mode-investhub-logo.svg'
import TranslationToggle from '../../Header/TranslationToggle/TranslationToggle'
import TranslationRwd from '../../Header/TranslationToggleRwd/TranslationToggleRwd'
import Styles from './styles.module.scss'

const AppBar = () => {
  const { t } = useTranslation()
  const [,, removeCookie] = useCookies()
  const triggerLogout = () => {
    removeCookie(config.jwtCookie)

    const logoutFunc = logout()

    logoutFunc()
  }

  const { data: { me: user } = {} } = useMe()
  const [rwdVisibleValues, setRwdVisibleValues] = useState({
    isRwdVisibleActive: false,
  })
  const onRwdVisibleActive = () => {
    setRwdVisibleValues({ ...rwdVisibleValues, isRwdVisibleActive: !rwdVisibleValues.isRwdVisibleActive })
  }

  return (
    <>
      <header className={classNames(Styles.header)}>
        <div className={classNames('center', Styles.header__center)}>
          <div className={classNames(Styles.header__logo, '')}>
            <NavLink to="/dashboard">
              <img src={SiteLogoDark} alt="Investhub.io" className={classNames('header__logo-dark', Styles.header__logo_dark)} />
            </NavLink>
            {/* <span className='inline-flex relative items-center gap-1 bg-yellow-400/15 ml-2 px-4 py-1 rounded-md font-medium font-sans text-lg text-yellow-400 tracking-[-0.02em] transition-all duration-200'>Beta Version</span> */}
          </div>

          <div className={classNames('tablet-hide', Styles.header__wrapper)}>
            <nav className={Styles.header__nav}>
              <ul className={Styles.header__lists}>
                <li className={classNames(Styles.header__item)}>
                  <NavLink
                    to="/dashboard"
                    exact
                    activeClassName="active"
                    onClick={onRwdVisibleActive}
                    className={Styles.header__link}
                  >
                    {t('Back to home')}
                  </NavLink>
                </li>
              </ul>
            </nav>

            <div className={Styles.header__cta}>
              { user && hasUserEnoughRights(user.rights, USER_RIGHT_TYPES.ADMIN) && (
                <NavLink to="/admin/dashboard" className="button-small header__button" onClick={onRwdVisibleActive}>{t('Admin')}</NavLink>
              )}

              <NavLink to="#" onClick={() => { triggerLogout(); onRwdVisibleActive() }} className="button-small button-stroke header__button header__connect">
                {t('Log out')}
              </NavLink>
            </div>

            <TranslationToggle />
          </div>

          <SlideToggle
            duration={20}
            collapsed
            render={({
              toggle,
              setCollapsibleElement,
              toggleState,
            }) => (
              <>
                <div className="desktop-hide tablet-show">
                  <div className={classNames("header-wrapper__custom", Styles.header__wrapper, (toggleState || "").toLowerCase())} ref={setCollapsibleElement}>
                    <nav className={Styles.header__nav}>
                      <NavLink
                        to="/dashboard"
                        exact
                        activeClassName="active"
                        onClick={onRwdVisibleActive}
                        className={Styles.header__link}
                      >
                        {t('Back to home')}
                      </NavLink>
                    </nav>

                    <TranslationRwd />

                    { user && hasUserEnoughRights(user.rights, USER_RIGHT_TYPES.ADMIN) && (
                      <NavLink to="/admin/dashboard" className="button-small header__button header__upload" onClick={onRwdVisibleActive}>{t('Admin')}</NavLink>
                    )}

                    <NavLink to="#" onClick={() => { triggerLogout(); onRwdVisibleActive() }} className="button-small button-stroke header__button header__connect">
                      {t('Log out')}
                    </NavLink>
                  </div>
                </div>

                <button
                  type="button"
                  className={
                    classNames(
                      "header-burger__custom",
                      Styles.header__burger,
                      (toggleState || "").toLowerCase(),
                    )
                  }
                  onClick={toggle}
                >
                  &nbsp;
                </button>
              </>
            )}
          />
        </div>
      </header>
    </>
  )
}

export default AppBar
