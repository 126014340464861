// src/components/layouts/Wrapper/Dashboard/index.jsx

import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  UseIsMax1023,
  UseIsMax767,
} from "../../shared/MediaQueries"
import FloatingLegalDisclaimer from "../../FloatingLegalDisclaimer"

// Inner
import Header from "./Header"
import Sidebar from "./Sidebar"
// import "../../../styles/index.css"

const Dashboard = ({
  pageTitle,
  isContain,
  isContentFullHeight,
  children,
}) => {
  const { t } = useTranslation()
  const isMobile = UseIsMax767()
  const isTablet = UseIsMax1023()
  const location = useLocation()

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const [isOpenAccountWallet, setIsOpenAccountWallet] = useState(false)
  const [isHamburgerToggle, setIsHamburgerToggle] = useState(false)

  const toggleSidebar = () => {
    const newCollapsedState = !isSidebarCollapsed
    setIsSidebarCollapsed(newCollapsedState)

    if (isMobile && isHamburgerToggle) {
      setIsHamburgerToggle(false)
    }
  }

  // Close account wallet when URL changes
  useEffect(() => {
    setIsOpenAccountWallet(false)
  }, [location.pathname])

  const toggleAccountWallet = (e) => {
    if (e) e.preventDefault()
    setIsOpenAccountWallet(!isOpenAccountWallet)
  }

  const toggleHamburger = () => {
    setIsHamburgerToggle(!isHamburgerToggle)
  }

  useEffect(() => {
    setIsSidebarCollapsed(isTablet)
    setIsHamburgerToggle(false)
  }, [isTablet])

  return (
    <>
      <div className="relative z-[222] max-767:z-[50] bg-yellow-500 px-4 py-1 w-full font-sans font-semibold text-black-light text-center uppercase tracking-[-0.02em]">Beta version</div>
      <div className={`bg-black-dark flex flex-row flex-nowrap justify-start items-stretch ${isMobile ? 'pl-0' : (isTablet || isSidebarCollapsed ? 'pl-24' : 'pl-[21.25rem]')} min-h-screen `}>
        {(!isMobile || isHamburgerToggle) && (
          <Sidebar
            isCollapsed={isMobile ? false : isSidebarCollapsed}
            toggleSidebar={toggleSidebar}
          />
        )}

        <div className={`flex flex-col relative flex-nowrap flex-1 py-6 w-full ${!isMobile ? 'bg-black-light' : 'bg-black-dark pt-4'}`}>
          <div className={`flex-1 pb-10 max-767:pb-6 ${!isMobile && 'bg-black-dark rounded-2xl'} `}>

            <div className={`${isContentFullHeight ? 'h-full flex flex-col' : ''} w-full mx-auto ${isContain ? 'max-w-[75rem]' : ''}`}>
              <Header
                pageTitle={pageTitle}
                isHamburgerToggle={isHamburgerToggle}
                toggleHamburger={toggleHamburger}
                isContain={isContain}
                toggleAccountWallet={toggleAccountWallet}
                isOpenAccountWallet={isOpenAccountWallet}
              />

              {children}
            </div>
          </div>

          <div className="relative flex justify-center items-center mt-6 w-full">
            <FloatingLegalDisclaimer />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
