// src/components/layouts/Entry/index.jsx

import React from 'react'
import "../../../styles/style.css"
import { useTranslation } from 'react-i18next'
import FloatingLegalDisclaimer from '../../FloatingLegalDisclaimer'

import Header from './Header'

const Entry = ({children}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="relative z-[222] max-767:z-[50] bg-yellow-500 px-4 py-1 w-full font-sans font-semibold text-black-light text-center uppercase tracking-[-0.02em]">Beta version</div>
      <div className='page-wrapper'>
        <div className="flex flex-col justify-start bg-black-dark min-h-svh">
            <Header />

            {children}

            <div className="flex justify-center items-center my-6 max-1023:my-4 mt-8 max-1023:mt-10 w-full">
              <FloatingLegalDisclaimer />
            </div>
        </div>
      </div>
    </>
  )
}

export default Entry
