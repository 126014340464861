import React, { useState } from "react"
import cn from "classnames"
import Avatar from "react-avatar"
// Shared components
import ShortToken from "../../../../shared/ShortToken"
import { useMe } from '../../../../../myHooks'
import { UseIsMax767 } from "../../../../shared/MediaQueries"

// Inner components
import Sidebar from "./Sidebar"

const AccountWallet = ({ toggleAccountWallet, isOpenAccountWallet }) => {
  const { data: { me: user } = {} } = useMe()
  const isMobile = UseIsMax767()

  return (
    <>
      <button
        type="button"
        className={cn(
          "inline-flex justify-center items-center font-medium  normal-case leading-none transition-colors duration-[0.2s] overflow-visible",
          {
            "px-4 rounded-[40px] border-solid h-10 gap-2 border-[2px] border-white/20 bg-transparent hover:opacity-90 m-0 text-[100%] text-white hover:text-white" : !isMobile,
            "h-8 w-8 rounded-full mx-1" : isMobile
          }
        )}
        onClick={toggleAccountWallet}
      >
        <div
          className={cn(
            "bg-slate-100 rounded-full overflow-hidden random__img",
            {
              "w-6 h-6": !isMobile,
              "w-8 h-8": isMobile
            }
          )}
        >
          <Avatar
            name={user.fullname || user.email || 'User'}
            size={isMobile ? "32" : "24"}
            round={true}
          />
        </div>

        {!isMobile ? (
          <span className="font-bold text-xs">
            <ShortToken original={user && user.wallet_address} />
          </span>
        ) : null}
      </button>

      <Sidebar
        isOpen={isOpenAccountWallet}
        onClose={toggleAccountWallet}
        user={user || {}}
        original={user && user.wallet_address}
      />
    </>
  )
}

export default AccountWallet
