import React from 'react'

const PageTitle = ({ title, subtitle }) => {
  return (
    <div className="mx-auto max-w-5xl">
      <div className="mb-20 lg:mb-10 2xl:mb-16 max-1023:mb-10 text-center">
        {title && (
        <div className="mb-4 font-bold text-5xl text-white max-1023:text-[2.5rem] leading-[3.5rem] max-1023:leading-[3rem] -tracking-wide max-1023:tracking-[-.045em]">{title}</div>
        )}

        {subtitle && (
        <div className="text-[1.5rem] text-gray-lighter leading-[2.25rem] tracking-[-.03em]">
          {subtitle}
        </div>
        )}
      </div>
    </div>
  )
}

export default PageTitle
