import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Switch } from 'react-router-dom'
import Loader from '../shared/Loader'
import { useMe } from '../../myHooks'
import { hasUserEnoughRights } from '../../utils'
import styles from './style.module.scss'

const ProtectedZone = ({ children, urlToRedirect, minRights }) => {
  const { error, loading, data: { me: user } = {} } = useMe()

  if (loading) {
    return <div className={styles.circularProgressWrapper}><Loader size="xlarge" /></div>
  }

  if (error || !user) {
    return <Redirect to={urlToRedirect || '/login'} />
  }

  if (hasUserEnoughRights(user.rights, minRights) === false) {
    return <Redirect to={urlToRedirect || '/'} />
  }

  return <Switch>{children}</Switch>
}

ProtectedZone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  children: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  urlToRedirect: PropTypes.string,
  minRights: PropTypes.string,
}

export default ProtectedZone
