// src/pages/DashboardTokens/AddWallet/Confirmation.jsx

import React from 'react'
import { NETWORK_IMAGES } from '../../../constant/images'
import Icons from '../../../components/shared/Icons'

const Confirmation = ({
  formData,
  tokens,
  onPrev,
  onNext,
}) => {
  const allTokens = [
    ...(tokens.withPrice || []),
    ...(tokens.withoutPrice || []),
  ]

  const getTokenValue = (token) => {
    if (+token.price) {
      return {
        price: `$${token.price.toLocaleString()}`,
        change: token.change24h,
        hasMarketPrice: true,
      }
    }

    const manualPrices = formData.manualPrices[token.symbol]
    if (manualPrices) {
      console.log('manualPrices', manualPrices)

      const { entryPrice, currentPrice } = manualPrices
      const change = ((parseFloat(currentPrice) - parseFloat(entryPrice)) / parseFloat(entryPrice)) * 100
      return {
        entryPrice: `$${parseFloat(entryPrice).toLocaleString()}`,
        currentPrice: `$${parseFloat(currentPrice).toLocaleString()}`,
        change: change.toFixed(2),
        hasMarketPrice: false,
      }
    }

    return {
      entryPrice: '-',
      currentPrice: '-',
      change: '-',
      hasMarketPrice: false,
    }
  }

  return (
    <>
      <div className="flex-1 border-0 m-0 mb-3 p-0 w-full align-baseline">
        <div className="relative border-0 m-0 p-0 text-gray-lighter align-baseline">
          <span className="mt-4 text-[13px] text-white/70">
            Would you like to add this wallet?
          </span>
          <div className="mt-2 text-[13px] text-white/50">
            {formData.walletAddress}
          </div>
        </div>

        <div className="w-full h-4" />

        {/* Token List */}
        {allTokens.map((token, index) => {
          const tokenValue = getTokenValue(token)
          const isPositiveChange = parseFloat(tokenValue.change) >= 0

          return (
            <div key={`${token.symbol}-${index}`} className="bg-white/5 mb-3 p-3 rounded-xl">
              <div className="flex justify-start items-start gap-4">
                {/* Token Icon */}
                <div className="relative">
                  <img
                    src={token.icon || NETWORK_IMAGES.defaultToken}
                    alt={token.symbol}
                    className="relative rounded-full w-10 h-10"
                  />
                  <img
                    src={token.networkIcon || NETWORK_IMAGES.polygon}
                    alt="Network"
                    className="-top-[4px] -right-[4px] absolute rounded-full w-4 h-4"
                  />
                </div>

                {/* Token Info */}
                <div className="flex-1">
                  <div className="flex justify-between items-start">
                    <div>
                      <div className="font-medium text-white">
                        {token.symbol}
                      </div>
                      <div className="text-[13px] text-white/70">
                        {token.quantity.toLocaleString()}
                        {' '}
                        {token.symbol}
                      </div>
                    </div>

                    {tokenValue && (
                      <div className="text-right">
                        {tokenValue.hasMarketPrice ? (
                          <>
                            <div className="font-medium text-white">
                              {tokenValue.price}
                            </div>
                            <div className={`flex items-center justify-end text-[13px] ${isPositiveChange ? 'text-green-500' : 'text-red-500'}`}>
                              <Icons
                                nameIcon={isPositiveChange ? 'arrow-up' : 'arrow-down'}
                                className="mr-1 w-4 h-4"
                              />
                              {Math.abs(tokenValue.change)}
                              %
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="text-[13px] text-white/70">
                              Entry:
                              {' '}
                              {tokenValue.entryPrice}
                            </div>
                            <div className="text-[13px] text-white/70">
                              Current:
                              {' '}
                              {tokenValue.currentPrice}
                            </div>
                            <div className={`text-[13px] ${isPositiveChange ? 'text-green-500' : 'text-red-500'}`}>
                              {isPositiveChange ? '+' : ''}
                              {tokenValue.change}
                              %
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="bottom bottom-0 py-6 w-full">
        <div className="flex justify-between items-center gap-4 w-full">
          <button
            type="button"
            className="inline-flex flex-1 justify-center items-center bg-black-light hover:bg-gray-lighter hover:opacity-90 m-0 mx-auto px-6 rounded-2xl max-w-sm h-11 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible"
            onClick={onPrev}
          >
            Back
          </button>

          <button
            type="button"
            className="inline-flex flex-1 justify-center items-center bg-primary hover:bg-[#0148dd] hover:opacity-90 m-0 mx-auto px-6 rounded-2xl max-w-sm h-11 font-medium text-[100%] text-white normal-case leading-none transition-colors duration-[0.2s] cursor-pointer overflow-visible"
            onClick={onNext}
          >
            Add wallet
          </button>
        </div>
      </div>
    </>
  )
}

export default Confirmation
