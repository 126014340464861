import React, { useState, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { motion, AnimatePresence } from 'framer-motion'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next'
import { useMe } from '../../../../../../../myHooks'
import { UPDATE_USER } from '../../../../../../../queriesAndMutations/authMutations'
import { supportedLanguages } from '../../../../../../../utils/lang'
import LanguageTab from './LanguageTab'
import CurrencyTab from './CurrencyTab'
import Loader from '../../../../../../shared/Loader'

const TabContent = ({ activeTab, tokens }) => {
  const { t } = useTranslation()
  const [updateUser] = useMutation(UPDATE_USER)
  const { setValue } = useFormContext() || {}
  const { data: { me } = {} } = useMe()
  const defaultCurrency = useMemo(() => tokens[0], [tokens])
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency)
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const currentLang = Object.values(supportedLanguages).find(
      lang => lang.code === i18n.language
    )
    return (currentLang && currentLang.label) || ''
  })

  useEffect(() => {
    const userCurrency = tokens.find(token => (token && token.name) === (me && me.currency)) || defaultCurrency
    setSelectedCurrency(userCurrency)
  }, [me, defaultCurrency, tokens])

  const listLanguages = Object.values(supportedLanguages)
    .sort((a, b) => a.label.localeCompare(b.label))

  const handleCurrencySelect = async (currency) => {
    setSelectedCurrency(currency)
    await updateUser({
      variables: {
        input: {
          currency: currency.name
        }
      }
    })
    if (setValue) {
      setValue('currency', currency.name)
    }
  }

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language.label)
    i18n.changeLanguage(language.code)
  }

  return (
    <div className="mt-6 text-white scrollbar-custom">
      <AnimatePresence mode="wait">
        {activeTab === 0 ? (
          <motion.div
            key="language"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <LanguageTab
              languages={listLanguages}
              selectedLanguage={selectedLanguage}
              onSelect={handleLanguageSelect}
            />
          </motion.div>
        ) : (
          <motion.div
            key="currency"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <CurrencyTab
              tokens={tokens}
              selectedCurrency={selectedCurrency}
              onSelect={handleCurrencySelect}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default TabContent
