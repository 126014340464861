// src/pages/Signup/components/Authenticator/AuthenticatorSuccess.jsx

import React, { useEffect } from "react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next'
import confetti from "canvas-confetti"
import Layout from "../Layout"
import Icons from "../../../../components/shared/Icons"
import { SIGNUP_STEPS } from "../../constant/steps"

const SuccessCheck = () => (
  <motion.div
    initial={{ scale: 0 }}
    animate={{ scale: 1 }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20
    }}
    className="pw:flex pw:justify-center pw:items-center pw:bg-primary/10 pw:mx-auto pw:mb-8 pw:rounded-full pw:w-24 pw:h-24"
  >
    <Icons
      nameIcon="check"
      className="pw:w-16 pw:h-16 pw:text-primary"
    />
  </motion.div>
)

const Feature = ({ icon, title, description }) => {
  const { t } = useTranslation()
  return (
    <div className="pw:flex pw:items-start pw:gap-3 pw:mb-4">
      <div className="pw:flex pw:flex-shrink-0 pw:justify-center pw:items-center pw:bg-primary/10 pw:rounded-full pw:w-8 pw:h-8">
        <Icons nameIcon={icon} className="pw:w-4 pw:h-4 pw:text-primary" />
      </div>
      <div>
        <h4 className="pw:mb-1 pw:font-medium pw:text-white">{t(title)}</h4>
        <p className="pw:text-gray-lighter pw:text-sm">{t(description)}</p>
      </div>
    </div>
  )
}

const AuthenticatorSuccess = ({ onBack, onFinish }) => {
  const { t } = useTranslation()

  return (
    <Layout
      title={t("Two Factor Authentication Active")}
      description={t("We will ask you for a code in addition to your password when you log in from an unrecognized device.")}
      currentStep={3}
      totalSteps={5}
      stepType={SIGNUP_STEPS.SECURITY}
    >
      <div className="pw:mx-auto pw:w-full pw:max-w-[480px]">
        <SuccessCheck />

        <div className="pw:bg-black-light pw:mb-8 pw:p-6 pw:rounded-xl">
          <h3 className="pw:mb-4 pw:font-semibold pw:text-white">
            {t("Security features enabled:")}
          </h3>

          <Feature
            icon="shield"
            title={t("Two-Factor Authentication")}
            description={t("Additional security layer active for your account")}
          />

          <Feature
            icon="smartphone"
            title={t("Mobile Authenticator")}
            description={t("Google Authenticator app configured successfully")}
          />

          <Feature
            icon="backup-code"
            title={t("Backup Codes")}
            description={t("Emergency access codes saved for account recovery")}
          />

          <Feature
            icon="notification"
            title={t("Login Notifications")}
            description={t("You'll be notified of any login attempts from new devices")}
          />
        </div>

        <div className="pw:mb-8 pw:text-center">
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
          <a
            href="#"
            className="pw:text-sm hover:pw:text-primary pw:text-white pw:transition-colors pw:duration-200"
          >
            {t("Learn more about two-factor authentication")}
          </a>
        </div>

        <div className="pw:space-y-4">
          <button
            onClick={() => onFinish({ completed: true })}
            className="pw:inline-flex pw:justify-center pw:items-center hover:pw:bg-primary-hover pw:bg-primary pw:px-6 pw:rounded-2xl pw:w-full pw:h-14 pw:font-medium pw:text-white pw:transition-colors pw:duration-200"
          >
            {t("Continue to Dashboard")}
          </button>
        </div>

        <div className="pw:mt-6 pw:text-[13px] pw:text-center pw:text-gray-lighter">
          <p>
            {t("You can manage your 2FA settings anytime from your account security preferences.")}
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default AuthenticatorSuccess
