import React from 'react';
import cn from 'classnames';
import TextField from './TextField';
import ActionDropdown from './ActionDropdown';
import Icons from '../../../../components/shared/Icons';
import { tableHeaders } from '../constants/tableConfig';

const Table = ({
  data,
  buttonRect,
  openDropdownId,
  setOpenDropdownId,
  handleDropdownClick,
  handleNavigateToDetail,
  handleNavigateToInvestment
}) => {
  return (
    <div className="z-10 min-w-[1024px] transition-opacity">
      <table className='w-full'>
        <thead className='bg-black-ligh min-w-full'>
          <tr>
            {tableHeaders.map((header, index) => (
              <th
                key={`header-${index}`}
                scope="col"
                className={cn(
                  'z-40 bg-black-light px-4 py-4 font-medium font-sans text-left text-sm text-gray-custom tracking-[-0.02em]',
                )}
              >
                {header.title}
              </th>
            ))}
          </tr>
          </thead>

          <tbody className='bg-black-light min-w-full'>
            {data.map((token) => (
              token.caps.map((cap, i) => {
                const tokenId = `${token.token}-${cap.network}`;

                return (
                  <tr key={tokenId}>
                    <td className='bg-black-dark px-4 py-4 font-medium font-sans text-sm text-white tracking-[-0.02em]'>
                      {i === 0 ? token.token : ''}
                    </td>

                    <td className='bg-black-dark px-4 py-4 font-medium font-sans text-sm text-white/70 tracking-[-0.02em]'>
                      {cap.network}
                    </td>

                    <td className='bg-black-dark px-4 py-4 font-medium font-sans text-sm text-white/70 tracking-[-0.02em]'>
                      <TextField
                        disabled={true}
                        defaultValue={cap.limit}
                        wrapperClass="w-full"
                      />
                    </td>

                    <td className='bg-black-dark px-4 py-4 font-medium font-sans text-sm text-white/70 tracking-[-0.02em]'>
                      <TextField
                        disabled={true}
                        defaultValue={cap.used}
                        wrapperClass="w-full"
                      />
                    </td>

                    <td className='bg-black-dark px-4 py-4 font-medium font-sans text-sm text-white/70 tracking-[-0.02em] align-middle'>
                      <div className='inline-block'>
                        <button
                          type='button'
                          className="inline-flex justify-center items-center bg-black-light hover:bg-black-light/60 rounded-full !w-[36px] !h-[36px] text-sm text-white transition-all duration-[0.2s]"
                          onClick={(e) => handleDropdownClick(e, tokenId)}
                        >
                          <Icons nameIcon="dots" className="inline-flex w-6 h-6" />
                        </button>

                        <ActionDropdown
                          buttonRect={buttonRect}
                          isOpen={openDropdownId === tokenId}
                          setOpenDropdownId={setOpenDropdownId}
                          handleNavigateToDetail={(e) => handleNavigateToDetail(e, token, cap)}
                          handleNavigateToInvestment={(e) => handleNavigateToInvestment(e, {
                            token: token.token,
                            network: cap.network,
                            limit: cap.limit,
                            used: cap.used
                          })}
                          token={token}
                          cap={cap}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default Table;
