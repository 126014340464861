import React, { useState } from 'react'
import { MainContainer, ChatContainer, MessageList, Message, MessageInput } from '@chatscope/chat-ui-kit-react'
// import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
// import styles from './ChatInterface.module.scss';

const ChatInterface = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleSend = message => {
    const newMessage = {
      message,
      sender: "user",
      direction: "outgoing"
    };

    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInputValue('');
  };

  return (
    <div className={`bg-[#1e2025] h-screen `}>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {messages.map((message, i) => (
              <Message
                key={i}
                model={message}
                className={message.sender === 'user' ? 'bg-primary' : 'bg-black-light'}
              />
            ))}
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            onSend={handleSend}
            value={inputValue}
            onChange={val => setInputValue(val)}
            className="bg-black-light text-white"
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default ChatInterface;
