import React, { useState } from 'react'
import { motion, AnimatePresence } from "framer-motion"
import { useClickOutside } from "../../../hooks/common/useClickOutside"
import { UseIsMax1023 } from '../../../components/shared/MediaQueries'
import { dropdownFilterVariants } from '../../../utils/animations'
import Icons from '../../../components/shared/Icons'

const VOLUME_FILTER_OPTIONS = ['1M', '6M', '12M', '24M']

const VolumeFilter = ({ setVolumeFilter, volumeFilter }) => {
  const isTablet = UseIsMax1023()
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen((prev) => !prev)
  const close = () => setIsOpen(false)
  const ref = useClickOutside(isOpen, close)

  const handleSetOption = (option) => {
    setVolumeFilter(option)
    close()
  }

  return (
    <div ref={ref} className="max-480:w-full">
      {!isTablet ? (
        <div className="flex flex-wrap justify-start items-start gap-1">
          {VOLUME_FILTER_OPTIONS.map((option) => (
            <button
              type="button"
              key={option}
              className={
                            option === volumeFilter
                              ? 'border-primary border-[2px] m-0 px-4 py-1 rounded-[22px] font-medium text-[13px] text-white'
                              : 'border-transparent border-[2px] m-0 px-4 py-1 rounded-[22px] font-medium text-[13px] text-gray-lighter'
                        }
              onClick={() => handleSetOption(option)}
            >
              {option}
            </button>
          ))}
        </div>
      ) : (
        <div className="relative max-480:w-full min-w-[120px] max-480:min-w-full">
          <div
            className={`block relative float-none clear-both hover:border-[#dbdbdb] focus:border-white/20 active:border-white/20 bg-[initial] bg-none opacity-100 shadow-[#272b30_0_0_0_2px_inset] hover:shadow-[#33383f_0_0_0_2px_inset] custom-dropdown m-0 py-0 pr-0 pl-4 border-none rounded-xl w-auto h-10 font-semibold text-left text-sm text-white leading-10 transition-all duration-[0.2s] cursor-pointer select-none align-baseline ease-[ease-in-out] text-[13px] ${isOpen ? "is-active !bg-black-light" : ""}`}
          >
            <div
              role="button"
              tabIndex={0}
              className="z-50 flex justify-between items-center pr-2 text-nowrap overflow-hidden text-overflow-ellipsis current"
              onClick={toggle}
            >
              {volumeFilter}

              <span className={`inline-flex text-white/20 relative transition-transform duration-[0.2s] ${isOpen ? '-rotate-180' : 'rotate-0'}`}>
                <Icons
                  nameIcon="chevron-down"
                  className="w-4 h-4"
                />
              </span>
            </div>

            <AnimatePresence>
              {isOpen && (
                <motion.ul
                  {...dropdownFilterVariants}
                  className="top-full z-[9] absolute inset-x-0 bg-black-light shadow-[#33383f_0_0_0_2px_inset] mx-0 mt-0.5 mb-0 px-0 py-2 border-solid rounded-xl w-full overflow-hidden align-baseline"
                >
                  {VOLUME_FILTER_OPTIONS.map((option) => (
                    <li
                      key={option}
                      className={`block border-0 bg-[initial] bg-none m-0 px-4 py-2 p-[4px16px] min-h-[auto] font-semibold text-left text-[13px] leading-[1.71429] transition-all duration-[0.2s] cursor-pointer align-baseline ${option === volumeFilter ? 'text-[#0052ff]' : 'text-gray-lighter hover:text-white'}`}
                      onClick={() => handleSetOption(option)}
                    >
                      {option}
                    </li>
                  ))}
                </motion.ul>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
    </div>
  )
}

export default VolumeFilter
