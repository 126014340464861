import React, { useEffect } from 'react'
import { NavLink, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useCookies } from 'react-cookie'
import {
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { LOGIN_VIA_TOKEN } from '../queriesAndMutations'
import config from '../config'
import { isProduction, isStaging } from '../utils'

const SsoAuth = () => {
  const [, setCookie] = useCookies()
  const query = new URLSearchParams(window.location.search)
  const token = query.get('token')
  const host = query.get('host')
  const redirectMasterTenant = query.get('redirect_master_tenant')
  const redirectPath = query.get('redirect_path')
  const { t } = useTranslation()
  const { data: { loginViaToken: data } = {}, loading } = useQuery(LOGIN_VIA_TOKEN, {
    variables: {
      token,
      host,
    },
  })
  const masterTenant = isProduction ? 'kyc.investhub.io' : (isStaging ? 'kyc-dev.investhub.io' : 'localhost:3001')

  useEffect(() => {
    if (data) {
      if (data.jwt) {
        setCookie(config.jwtCookie, data.jwt.accessToken, { path: '/' })

        if (redirectMasterTenant === 'true') {
          window.location.href = `/redirect-to-tenant?host=${encodeURIComponent(masterTenant)}`
        } else if (redirectPath) {
          window.location.href = `/${redirectPath}`
        } else {
          window.location.href = '/explore'
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!token) {
    return <Redirect to="/login" />
  }

  return (
    <>
      {loading && (
        <CircularProgress />
      )}
      {!loading && !data && (
        <>
          <p>
            {t('Fail to authorize.')}
          </p>
          <NavLink to="/login">{t('Back to login')}</NavLink>
        </>
      )}
    </>
  )
}

export default SsoAuth
