import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import {
  REQUEST_SSO_TOKEN,
  CREATE_TRANSACTION,
  PORT_KYC_TO_TENANT,
} from '../../queriesAndMutations'
import { toaster } from '../../utils'
import PaymentMethodDialog from './PaymentMethodDialog'
import PaymentConfirmationDialog from './PaymentConfirmationDialog'

export const PAYMENT_TYPES = {
  BANK_TRANSFER: 'BANK_TRANSFER',
  PAYPAL: 'PAYPAL',
  CRYPTO: 'CRYPTO',
  WALLET: 'WALLET',
  VOLT: 'VOLT',
  SENDWYRE: 'SENDWYRE',
  CRYPTODOTCOM: 'CRYPTO.COM',
  STRIPE: 'STRIPE',
}

const Transaction = ({
  values,
  setValues,
  setOpenModalPayment,
  openModalPayment,
  tenant,
}) => {
  const { t } = useTranslation()
  const [disabledBtn, setDisabledBtn] = useState(false)
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false)

  const [requestSsoToken, requestSsoTokenStatus] = useMutation(REQUEST_SSO_TOKEN)
  const [portKycToTenant, portKycToTenantStatus] = useMutation(PORT_KYC_TO_TENANT)
  const [createTransaction, createTransactionStatus] = useMutation(CREATE_TRANSACTION)

  const submitSsoTokenRequest = () => {
    setDisabledBtn(true)
    requestSsoToken({
      variables: { host: tenant.host },
    }).then(async ({ data: { requestSsoToken: token } }) => {
      window.location.href = `https://${tenant.host}/sso/auth?sso_token=${token}&allowSyncData=true`
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error)
    })
  }

  const submitPortKycData = () => {
    setDisabledBtn(true)
    portKycToTenant({
      variables: { host: tenant.host },
    }).then(async ({ data: { portKycToTenant: res } }) => {
      if (res.two_fa_required) {
        toaster.error(t('2FA required'))
        setDisabledBtn(false)
        return
      }
      setOpenModalPayment(false)
      setOpenModalConfirmation(false)
      setDisabledBtn(false)
      toaster.success(t('Sync data successfully'))
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error)
    })
  }

  const onPay = async () => {
    setDisabledBtn(true)

    try {
      const createTransactionRes = await createTransaction({
        variables: {
          input: {
            token_amount: values.token_amount,
            currency: values.currency,
            host: tenant.host,
            payment_type: values.selectedTransferType,
          },
        },
      })
      // eslint-disable-next-line no-console
      console.log('createTransactionRes', createTransactionRes)
      if (createTransactionRes.data && createTransactionRes.data.createTransaction) {
        // submitSsoTokenRequest()
        setValues({ ...values, transaction: createTransactionRes.data.createTransaction.transaction })
        setOpenModalConfirmation(true)
      }
      setDisabledBtn(false)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  return (
    <>
      <PaymentMethodDialog
        values={values}
        setValues={setValues}
        openModalPayment={openModalPayment}
        setOpenModalPayment={setOpenModalPayment}
        tenant={tenant}
        disabledBtn={disabledBtn}
        onPay={onPay}
        createTransactionStatus={createTransactionStatus}
      />

      <PaymentConfirmationDialog
        values={values}
        setValues={setValues}
        openModalConfirmation={openModalConfirmation}
        setOpenModalConfirmation={setOpenModalConfirmation}
        tenant={tenant}
        disabledBtn={disabledBtn}
        submitSsoTokenRequest={submitSsoTokenRequest}
        requestSsoTokenStatus={requestSsoTokenStatus}
        submitPortKycData={submitPortKycData}
        portKycToTenantStatus={portKycToTenantStatus}
      />
    </>
  )
}

Transaction.propTypes = {
  values: PropTypes.object,
  setValues: PropTypes.func,
  setOpenModalPayment: PropTypes.func,
  openModalPayment: PropTypes.bool,
  tenant: PropTypes.object,
}

export default Transaction
