import React, { useState } from 'react'
import {
  Dialog,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import UserTenantTable from '../components/Dashboard/UserTenantTable'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'

const Dashboard = () => {
  const { t } = useTranslation()
  const [openSetupDialog, setOpenSetupDialog] = useState(false)

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          {t('Dashboard')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 faq discover tenant">
        <div className="discover__center tenant__center center">
          <div className="faq__top tenant__top">
            <div className="faq__title tenant__title h2">{t('Raise. Manage. Grow.')}</div>
            <div className="faq__info discover__info">{t('Unlock the power of streamlined financial management as you effortlessly raise funds and strategically allocate them to fuel your ventures.')}</div>

            <button
              className="button tenant__button"
              type="button"
              onClick={() => setOpenSetupDialog(true)}
            >
              {t('Add New Product')}
            </button>
          </div>

          <div className="faq__row tenant__row">
            <div className="faq__col tenant__col">
              <UserTenantTable />

              <Dialog
                open={openSetupDialog}
                onClose={() => setOpenSetupDialog(false)}
                className="investhub-modal investhub-modal__m"
              >
                <ModalInnerMaterialDialog
                  modalTitle="Products"
                  modalOnClickClose={() => setOpenSetupDialog(false)}
                >
                  <div className="variants__list onboarding-best__list flex__row onboarding-best__list-v2">
                    <div className="variants__item onboarding-best__item text-center">
                      <div className="variants__preview">
                        <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=Raise" alt="Raise" />
                      </div>

                      <div className="variants__info onboarding-best__info">
                        {t('Raise')}
                        <br />
                        {t("By harnessing the power of blockchain and tokenization, we've revolutionized the way you engage investors.")}
                      </div>

                      <a
                        href="/tenant-easy-setup"
                        type="button"
                        className="button-stroke variants__button admin-settings-page__button"
                      >
                        {t('Choose')}
                      </a>
                    </div>

                    <div className="variants__item onboarding-best__item text-center">
                      <div className="variants__preview">
                        <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=Evolve" alt="Evolve" />
                      </div>

                      <div className="variants__info onboarding-best__info">
                        {t('Evolve')}
                        <br />
                        {t('Evolve — a Business operating layer for FIAT and Crypto')}
                      </div>

                      <a
                        href="/tenant-evolve-setup"
                        type="button"
                        className="button-stroke variants__button admin-settings-page__button"
                      >
                        {t('Choose')}
                      </a>
                    </div>
                  </div>
                </ModalInnerMaterialDialog>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
