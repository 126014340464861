import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form"
import { motion, AnimatePresence } from "framer-motion";
import { CheckboxField } from "../../../../components/shared/Fields";
import { FieldBuy, FieldInvest } from "../../FieldBuyInvest";

// Inner
import DetailItem from "./DetailItem";

const StepDetailBuyInvest = ({
  buyValue,
  investValue,
  handleInvestChange,
  isLogin,
  setCurrentIssuer,
  currentIssuer,
  balanceAfterTopup,
  onCheckboxChange
}) => {
  const { watch } = useFormContext();
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const checkboxItems = [
    {
      name: 'purchase-agreement',
      label: (
        <>
          I have read, fully understood and agree to the bounded by the{" "}
          <a
            href="/agreement"
            className="hover:opacity-80 font-bold text-white transition-all duration-[0.2s]"
          >
            Purchase agreement
          </a>{" "}
          in respect of this token sale
        </>
      ),
    },
    {
      name: 'support-document',
      label: (
        <>
          I have read fully understood and agree to the bound by the other
          relevant agreements here in respect of this token sale.{" "}
          <a
            href="/document"
            className="hover:opacity-80 font-bold text-white transition-all duration-[0.2s]"
          >
            Support Document
          </a>
        </>
      ),
    },
  ];

  const detailItems = [
    { label: "Protocol Fee (0.1%)", value: "Є 2.27" },
    {
      label: "Network Fee (0.1%)",
      value: "Free sponsored by investhub",
      valueClass: "font-bold text-[#0052ff] text-sm",
    },
    { label: "Total", value: "Є 102.27" },
  ];

  const keyInfoItems = [
    { label: "Raised", value: "Є 454,087.60" },
    { label: "Investors", value: "225" },
    {
      label: "Target",
      value: "Є 450,400",
      valueClass: "font-bold text-green-500 text-sm",
    },
    { label: "Equity", value: "0.89%" },
    { label: "Pre-Money Valuation", value: "Є 49,897,716" },
    { label: "Tax Relief", value: "IR-PME" },
    { label: "Tax Reduction", value: "50 %" },
  ];

  // Watch checkbox values
  const purchaseAgreement = watch('purchase-agreement', false);
  const supportDocument = watch('support-document', false);

  // Pass allChecked status to parent
  useEffect(() => {
    const allChecked = purchaseAgreement && supportDocument;
    onCheckboxChange(allChecked);
  }, [purchaseAgreement, supportDocument, onCheckboxChange]);

  return (
    <>
      <FieldInvest
        isLogin={isLogin}
        onChange={handleInvestChange}
        value={investValue}
        setCurrentIssuer={setCurrentIssuer}
        currentIssuer={currentIssuer}
        readOnly
      />
      <div className="w-full h-2" />
      <FieldBuy
        isLogin={isLogin}
        onChange={handleInvestChange}
        value={buyValue}
        balanceAfterTopup={balanceAfterTopup}
        currentIssuer={currentIssuer}
        readOnly
      />
      <div className="w-full h-2" />

      <div className="relative flex flex-row flex-nowrap justify-center items-center mt-4 overflow-hidden">
        <div className="flex-1 bg-white/20 w-full h-[2px]" />
        <div
          className="inline-flex relative px-4 font-bold text-center text-white text-xs hover:text-white/60 uppercase transition-all duration-[0.2s] cursor-pointer"
          onClick={toggleDetails}
        >
          {!showDetails ? <>See Details</> : <>See Less</>}
        </div>
        <div className="flex-1 bg-white/20 w-full h-[2px]" />
      </div>
      <div className="w-full h-4" />

      {checkboxItems.map((item, index) => (
        <React.Fragment key={index}>
          <CheckboxField name={item.name} label={item.label} useForm={true} />
          {index < checkboxItems.length - 1 && <div className="w-full h-2" />}
        </React.Fragment>
      ))}

      <AnimatePresence>
        {showDetails && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="text-white detail"
          >
            <div className="pt-4 w-full">
              {detailItems.map((item, index) => (
                <React.Fragment key={index}>
                  <DetailItem {...item} />
                  <div className="w-full h-3" />
                </React.Fragment>
              ))}

              <div className="m-0 mt-6 mb-4 w-full min-w-0 font-bold text-base text-ellipsis text-nowrap text-white leading-6 tracking-[-0.01em] whitespace-nowrap overflow-hidden">
                Key Information
              </div>

              <div className="flex max-480:flex-col justify-between max-480:justify-start items-center max-480:items-start gap-2 max-480:gap-0 w-full">
                <div className="text-left">
                  <div className="mb-2 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
                    Company
                  </div>

                  <div className="flex justify-start items-start gap-2">
                    <div className="rounded-full w-10 h-10 overflow-hidden">
                      <img
                        src="images/placehold.svg"
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>

                    <div className="flex flex-col flex-nowrap justify-start items-start gap-1">
                      <div className="font-bold text-sm text-white">
                        Enercom AG
                      </div>
                      <div className="mb-2 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
                        Austria
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-right mt-4 max-480:text-left">
                  <div className="mb-2 max-480:mb-0 text-[#6F737E] text-[13px] leading-[1.2] tracking-[-0.02em]">
                    Industry
                  </div>
                  <div className="font-bold text-sm text-white">
                    IT General Contractor
                  </div>
                </div>
              </div>
              <div className="w-full h-3" />

              {keyInfoItems.map((item, index) => (
                <React.Fragment key={index}>
                  <DetailItem {...item} />
                  <div className="w-full h-3" />
                </React.Fragment>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="w-full h-4" />
    </>
  );
};
export default StepDetailBuyInvest;
