import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  CountryField,
  TextField,
  BirthDayField,
} from "../../../../components/shared/Fields";
import Icons from "../../../../components/shared/Icons";

const StepShareHolderPersonality = ({ prefillData }) => {
  const { setValue, reset, register } = useFormContext();
  const [isFirstName, setIsFirstName] = useState(false);
  const [isLastName, setIsLastName] = useState(false);
  const [isPlaceOfBirth, setIsPlaceOfBirth] = useState(false);
  const [isDateBirth, setIsDateOfBirth] = useState(false);
  const [isCountry, setIsCountry] = useState(false);

  return (
    <>
      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 px-0 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isCountry ? "before:border-white/20" : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <CountryField
            name="nationality"
            placeholder="Nationality"
            setIsCountry={setIsCountry}
            defaultCountryValue={(prefillData || {}).nationality}
            value={(prefillData || {}).nationality}
            validator={{
              required: "Please select your country",
            }}
            readOnly={!!(prefillData || {}).nationality}
            {...register("nationality")}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isFirstName ? "before:border-white/20" : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <TextField
            name="first_name"
            placeholder="First name"
            validator={true}
            defaultValue=""
            icon={
              <Icons
                nameIcon="user"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            customClassOnField="bg-transparent pl-10 focus:bg-transparent"
            onFocus={() => setIsFirstName(true)}
            onBlur={() => setIsFirstName(false)}
            readOnly={!!(prefillData || {}).first_name}
            {...register("first_name")}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isLastName ? "before:border-white/20" : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <TextField
            name="last_name"
            placeholder="Last name"
            validator={true}
            defaultValue=""
            icon={
              <Icons
                nameIcon="user"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            customClassOnField="bg-transparent pl-10 focus:bg-transparent"
            onFocus={() => setIsLastName(true)}
            onBlur={() => setIsLastName(false)}
            readOnly={!!(prefillData || {}).last_name}
            {...register("last_name")}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isDateBirth ? "before:border-white/20 focused" : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <BirthDayField
            name="birth_date"
            icon={
              <Icons
                nameIcon="calendar"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            placeholder="Select a date"
            customClassOnField="bg-transparent pl-10 focus:bg-transparent"
            onFocus={() => setIsDateOfBirth(true)}
            onBlur={() => setIsDateOfBirth(false)}
            readOnly={!!(prefillData || {}).birth_date}
            {...register("birth_date")}
          />
        </div>
      </div>
      <div className="w-full h-2" />

      <div
        className={`relative before:top-0 before:left-0 before:absolute before:content-[''] before:border-[2px] bg-black-light before:bg-[100%] p-4 before:border-solid rounded-2xl before:rounded-[inherit] before:w-full before:h-full font-medium text-[#9b9b9b] text-sm leading-5 before:transition-all before:duration-[0.2s] before:ease-linear before:pointer-events-none before:outline-none before:border-transparent ${
          isPlaceOfBirth
            ? "before:border-white/20"
            : "before:border-transparent"
        }`}
      >
        <div className="flex flex-row flex-wrap justify-between items-center m-0">
          <TextField
            name="place_of_birth"
            placeholder="Place of Birth"
            validator={true}
            defaultValue=""
            icon={
              <Icons
                nameIcon="building"
                className="top-1/2 left-0 z-10 absolute w-6 h-6 transition-all -translate-y-1/2 duration-[0.2s] ease-in-out"
              />
            }
            customClassOnField="bg-transparent pl-10 focus:bg-transparent"
            onFocus={() => setIsPlaceOfBirth(true)}
            onBlur={() => setIsPlaceOfBirth(false)}
            readOnly={!!(prefillData || {}).place_of_birth}
            {...register("place_of_birth")}
          />
        </div>
      </div>
      <div className="w-full h-2" />
    </>
  );
};

export default StepShareHolderPersonality;
