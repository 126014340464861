import React from 'react'

const BaseButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`inline-flex justify-center items-center transition-colors duration-[0.2s] font-medium text-white cursor-pointer ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

export default BaseButton
